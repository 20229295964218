const specificHeatCapacity = {
    status: 'Active',
    name: 'Specific Heat Capacity',
    imageFileName: 'images/calorimeter-icon.jpg',
    imageAlt: 'A constant pressure calorimeter: Two styrofoam cups are placed inside a beaker. It is covered with a piece of cardboard. A temperature probe is inserted through the cardboard and measures the contents inside the styrofoam cups.',
    description: 'Experimental guide for determining the specific heat capacity of a metal.',
    tags: ['Pre-Lab', 'Wet Lab', 'Experiment', 'Tutorial', 'Chem-101A'],
    page: '/specific-heat-capacity',
    content: {
      overview: {
        name: 'Overview',
        link: 'overview',
        tabs: [
          {
            name: 'NOTAB',
            order: 1,
            steps: [
              {
                name: 'NOSTEP',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Text',
                    text: 'This lab will have two experiment parts. The first is to determine the heat capacity of a calorimeter in a constant pressure environment (a styrofoam cup open to air). The second is to determine the specific heat capacity of an unknown metal.',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Begin by reading the <strong><em>Introduction</em></strong> to review the equations needed to calculate the heat capacity of a calorimeter and the specific heat capacity of a substance. Next, read the <strong><em>Experiment</em></strong> section to learn the steps of the experiment.',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'After you do the experiment in lab, use the <strong><em>Tutorial</em></strong> as a guide for calculating the heat capacity of the Styrofoam cup and the specific heat capacity of an unknown metal. Practice data is provided. Once you go through the <strong><em>Tutorial</em></strong>, complete the data analysis and calculations for your own experimental data in your lab notebook.',
                  },
                ],
              },
            ],
          },
        ],
      },
      introduction: {
        name: 'Introduction',
        link: 'introduction',
        tabs: [
          {
            name: 'NOTAB',
            order: 1,
            steps: [
              {
                name: 'Heat',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Text',
                    text: 'Heat is a form of energy, often called thermal energy. Energy can be transformed from one form to another (electric energy to heat and light in a light bulb, for example), but it cannot be created or destroyed; rather, energy is conserved. The higher the temperature of a material, the more thermal energy it possesses. In addition, at a given temperature, the more of a given substance there is, the more total thermal energy the material possesses.',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'On an atomic level, absorbed heat causes the atoms of a solid to vibrate, much as if they were bonded to one another through springs. As the temperature is raised, the energy of the vibrations increases. In a metal, this is the only motion possible. In a liquid or gas, absorbed heat causes the atoms in the molecule to vibrate, and the molecule to both rotate and move from place to place. Because there are more “storage” possibilities for energy in liquids and gases, their heat capacities are larger than in metals. <strong>Heat capacity</strong>, C<sub>p</sub>, is the amount of heat required to change the heat content of <strong>1 mole</strong> of a material by exactly 1°C. <strong>Speciﬁc heat capacity</strong>, c<sub>sp</sub>, is the amount of heat required to change the heat content of exactly <strong>1 gram</strong> of a material by exactly 1°C.',
                  },
                ],
              },
              {
                name: 'Specific Heat Capacity',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Text',
                    text: 'Speciﬁc heat capacity values can be determined in the following way: When two materials, each initially at a different temperature, are placed in contact with one another, heat always ﬂows from the warmer material into the colder material until the two materials are at the same temperature. From the law of conservation of energy, the heat gained by the initially colder material must equal the heat lost by the initially warmer material:',
                  },
                  {
                    order: 2,
                    type: 'Image',
                    imageFileName: '/images/specific-heat/transfer-of-heat.jpg',
                    imageAlt: 'The heat lost of hot object is equal to the heat gained of a cold object.',
                    centered: true,
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'Since heat lost is a negative term due to the release of energy, and heat gained is a positive term due to the absorption of energy, the equation can be written as:',
                  },
                  {
                    order: 4,
                    type: 'Image',
                    imageFileName: '/images/specific-heat/transfer-of-heat-2.jpg',
                    imageAlt: 'The following is represented in an equation form: The release of heat of a hot object (given by the negative sign) is equal to the absorption of heat of the cold object',
                    centered: true,
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'where q is used as the abbreviation for heat. The heat quantities in this equation would be calculated as: <ul>(speciﬁc heat) x (mass of the object) x (the temperature change of the object)</ul> because speciﬁc heat has units of energy per mass per degrees Celcius (or Kelvin). The last equation then becomes:',
                  },
                  {
                    order: 6,
                    type: 'Image',
                    imageFileName: '/images/specific-heat/transfer-of-heat-3.jpg',
                    imageAlt: 'The negative of open parenthesis the specific heat capacity times the mass times the change in temperauture close parenthesis of a hot object is equation to open parenthesis the specific heat capacity times the mass times the change in temperauture close parenthesis of a cold object',
                    centered: true,
                  },
                ],
              },
              {
                name: 'Experimental Overview',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Text',
                    text: 'The goal of the experiment is to find the specific heat capacity of an unknown metal. To do so, you will place a hot metal into cold water. The water will be in a styrofoam cup with a thermometer in it. The cup and the thermometer will absorb some of the heat. If the heat that the cup and thermometer absorb per degree is labeled C<sub>cup</sub>, then the equation becomes:',
                  },
                  {
                    order: 2,
                    type: 'Image',
                    imageFileName: '/images/specific-heat/styrofoam-cup-equation.jpg',
                    imageAlt: 'A generic equation is provided. The negative of open parenthesis the specific heat capacity times the mass times the change in temperauture close parenthesis of a hot object is equation to open parenthesis the specific heat capacity times the mass times the change in temperauture close parenthesis of a cold object plus the heat capacity of the cup times the change in temperature of the cup.',
                    centered: true,
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'The speciﬁc heat of water is 4.2 J·g<sup>-1</sup>·K<sup>-1</sup>. Temperature changes will be written in Kelvins as the numerical value is exactly the same as the value measured in Celsius. (That is, a change in temperature of 10°C will be written as 10 K).',
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'In Part 1 of this experiment, you will pour hot water into cold water to determine the value of the heat capacity of the cup, C<sub>cup</sub>. In Part 2 of the experiment, you will pour the hot metal into water to determine the specific heat capacity of a metal, c<sub>sp</sub>. For Part 1, the equation becomes:',
                  },
                  {
                    order: 5,
                    type: 'Image',
                    imageFileName: '/images/specific-heat/styrofoam-cup-equation-2.jpg',
                    imageAlt: 'The negative of open parenthesis the specific heat capacity of water, which is 4.2 Joules per grams per Kelvin, times the mass times the change in temperauture close parenthesis of a hot water is equal to open parenthesis the specific heat capacity of water, which is 4.2 Joules per grams per Kelvin times the mass times the change in temperauture close parenthesis of a cold water plus the heat capacity of the cup times the change in temperature of the cup.',
                    centered: true,
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: 'You will solve the equation for C<sub>cup</sub>.',
                  },
                  {
                    order: 7,
                    type: 'Text',
                    text: 'For Part 2, the equation will pertain to a hot metal added to cold water instead of hot water added to cold water. The equation becomes:',
                  },
                  {
                    order: 8,
                    type: 'Image',
                    imageFileName: '/images/specific-heat/styrofoam-cup-equation-3.jpg',
                    imageAlt: 'A generic equation is provided. The negative of open parenthesis the specific heat capacity of a metal times the mass times the change in temperauture close parenthesis of a hot metal is equal to open parenthesis the specific heat capacity of water, which is 4.2 Joules per grams per Kelvin times the mass times the change in temperauture of the cold water plus the heat capacity of the cup close parenthesis times the change in temperature of the cold water and cup.',
                    centered: true,
                  },
                  {
                    order: 9,
                    type: 'Text',
                    text: 'You will solve the equation for c<sub>sp</sub>, the speciﬁc heat capacity of the metal.',
                  },
                ],
              },
            ],
          },
        ],
      },
      experiment: {
        name: 'Experiment',
        link: 'experiment',
        tabs: [
          {
            name: 'Start Here',
            order: 1,
            steps: [
              {
                name: 'Summary',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Summary: Experimental Steps',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'The general experimental steps are: <li>First part: Mix hot water with cold water.</li><li>Measure the temperature increase of the combined waters.</li><li>Second part: Add a hot metal to cold water.</li><li>Measure the temperature increase of the water.</li>',
                  },
                ],
              },
              {
                name: 'Equipment',
                order: 2,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Equipment',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'The equipment needed for this experiment:',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/specific-heat/equipment.jpg',
                    imageAlt: 'A lay-out of equipment with numbers listing them. 1. ring stand, 2. two large rings, 3. wire gauze, 4. 1 L beaker filled with 800 mL water, 5. Bunsen burner, hose for the burner (and striker),6. 2 styrofoam cups, 7. carboard with hole, 8. thermometer, 9. 600 mL beaker, 10. 100 mL graduated cylinder, 11. three large test tubes with cork stoppers, 12. sitrring rod, temperature probe, 14. Labquest handheld device, and 15. unknown metal sample.',
                    imageTitle: 'equipment',
                    centered: true,
                  },
                ],
              },
              {
                name: 'Notebook Prep',
                order: 3,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Notebook Preparation',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'To prepare for your lab data collection, draw the following tables for Part I and II in your notebook.',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/specific-heat/part-1-data-table.jpg',
                    imageAlt: 'There are three columns and 7 rows. The first row has the header labels: Part 1 Data Table, Trial 1 and Trial 2. The first column starting from row 2 to 7 hae the following labels: 1. Mass of two cups, 2. Mass of additionof 70mL water, 3. Initial temperature, T sub i, of the hot water 4. Initial temperature, T sub i, of water in the cups, 5. Final temperature, T sub f, of water in the cups after hot water is added, 6. Final mass of cups and water. Columns 2 and 3 are for the data to be entered once the experiment has started. The units are provided in these cells. Column 2 and 3 row 2 have grams with the following information following (assumed to be constant for all trials). Row 3 and 7 have units of grams. Rows 4, 5 and 6 have units of degrees Celsius.',
                    centered: true,
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: '',
                  },
                  {
                    order: 5,
                    type: 'Image',
                    imageFileName: '/images/specific-heat/part-2-data-table.jpg',
                    imageAlt: 'There are two columns and 6 rows. The first row has the header labels: Part 2 Data Table, Metal # with a blank next to it. The first column starting from row 2 to 6 have the following labels: 1. Mass of cups and 100 mL water, 2. Initial temperature, T sub i, of water in the cups, 3. Initial temperature, T sub i, of the hot metal (via the hot water bath) 4. Final temperature, T sub f, of water in the cups after hot metal is added, 5. Final mass of cups, water and metal. Columns 2 is for the data to be entered once the experiment has started. The units are provided in these cells. Column 2 row 2 and 6 have grams. Row 3 and 4 have units of degrees Celsius.',
                    centered: true,
                  },
                ],
              },
            ],
          },
          {
            name: 'Part I',
            order: 2,
            steps: [
              {
                name: 'Equipment Set-up',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Equipment Set-up',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'After you obtain the equipment from the lab cart, place two styrofoam cup into a 600 mL beaker for support. Without this support, the weight of a thermometer could cause the set-up to tip over, which could break the thermometer.',
                  },
                  {
                    order: 3,
                    type: 'Images',
                    images: [
                      {
                        imageFileName: '/images/specific-heat/styrofoam-cup-set-up.jpg',
                        imageAlt: 'A 600 milliliter beaker contains two nested styrofoam cups.',
                      },
                      {
                        imageFileName: '/images/specific-heat/styrofoam-cup-graphic.jpg',
                        imageAlt: 'A side-view diagram of a beaker with a styrofoam cup inside and a thermometer resting inside the cup.',
                      },
                    ],
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Set a 1000 mL beaker on a ring stand as shown in the diagram below. Add about 800 ml of water.',
                  },
                  {
                    order: 5,
                    type: 'Image',
                    imageFileName: '/images/specific-heat/safety-ring.jpg',
                    imageAlt: 'A ring stand with two large rings attached to it. A wire gauze rests on the lower ring. A 1 liter beaker filled with 800 milliliters of water is placed on top of the wire gauze. The higher ring is surrounding the beaker a little over half way up the beaker. Above the test tubes is the text: "3 large test tubes in a 1000 mL beaker. Water level in beaker should be highter than contents of test tubes." An arrow points to the corks with text: "Corks are loose in test tubes".',
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: '&nbsp;',
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: 'What is the purpose of having the top ring in the set-up above?',
                  },
                  {
                    order: 7,
                    type: 'Multiple Choice',
                    choices: [
                      {
                    label: 'Adds weight to the set-up.',
                    tip: 'Not really relevant. The large beaker of water is already heavy.',
                    validChecked: false,
                      },
                      {
                    label: 'Acts as a stability guard',
                    tip: 'The higher ring gives the set-up extra stability in case the large beaker gets knocked.',
                    validChecked: true,
                      },
                    ],
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: '&nbsp;',
                  },
                  {
                    order: 8,
                    type: 'Text',
                    text: 'Turn on the burner to a hot ﬂame so that the hot water bath will be heating while you are preparing the test tubes for the experiment.',
                  },
                  {
                    order: 9,
                    type: 'Text',
                    text: 'Plug the Labquest charger to the outlet and turn on. Choose <strong>New</strong> from the <strong>File</strong> menu. On the <strong>Meter</strong> screen, tap <strong>Rate</strong>. Change the rate to 1 sample per seconed and the duraction to 60 sections. Select Done and then, Ok. ',
                  },
                  {
                    order: 10,
                    type: 'Text',
                    text: 'Watch: How to set-up the Labquest<p><iframe width="560" height="315" src="https://www.youtube.com/embed/Ci3suvH4LN0?&playlist=Ci3suvH4LN0&loop=1" title="Labquest set-up" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>',
                  },
                ],
              },
              {
                name: 'Test Tube Set-up',
                order: 2,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Test Tube Set-up',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Get an unknonwn metal sample vial from your instructor. Record the number of the metal sample vial in the  Part 1 data table. Pour the metal onto a paper towel and make sure the metal is dry.',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'Place the metal in a large clean and dry test tube and loosely stopper it. Place it in the hot water bath. The water level in the bath should be above the metal level.',
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Add 30 ml of deionized water into each of the other two large test tubes. Loosely stopper them, and place them into the hot water bath. ',
                  },
                  {
                    order: 5,
                    type: 'Image',
                    imageFileName: '/images/specific-heat/heating-set-up.jpg',
                    imageAlt: 'A diagram shows a ring stand with two large rings attached to it. A wire gauze rests on the lower ring. A bunsen burner is beneath the wire gauze and ring with a flame. A large liter beaker is filled high with water and there are three test tubes with stoppers on. Inside two of the test tubes is water less than half full. In the third test tube are circle pellets filled to more than half way the tube.',
                    centered: true,
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: 'When  the water is boiling, turn the burner down to maintain a gentle boil in the beaker. The test tubes should reach the temperature in the water bath after 10 minutes of boiling. Assume that the temperature of the boiling water is 100.0°C.',
                  },
                ],
              },
              {
                name: 'Mass Measurements',
                order: 3,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Mass Measurements',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Remove the two nestled styrofoam cups from the 600 mL beaker and weigh them on a decigram balance. Record the mass as the <strong>mass of the two cups</strong>.',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'Pour 70 mL of deionized water into the styrofoam cups. Weigh the cups and record the mass in the Part 1 data table as the <strong>mass of 2 cups</strong>.',
                  },
                  {
                    order:4,
                    type: 'Text',
                    text: 'Place the cups with water inside the 600 mL beaker. Place the cardboard piece to cover the cups and place the Temperature probe through the board\'s opening, resting inside the cups.',
                  },
                  {
                    order: 5,
                    type: 'Image',
                    imageFileName: '/images/specific-heat/probe-set-up.jpg',
                    imageAlt: 'The nestled styrofoam cups in a 600 mL beaker. Covering the cups is a square piece of cardboard. Poking through the cardboard and only seeing thetop of the handle is the temperature probe and is cable.',
                    centered: true,
                  },
                 ],
                },
              {
                name: 'Temperature Measurements',
                order: 3,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Temperature Measurements',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Tear off a section of paper towel and fold it until it is an inch or so wide. Make a handle for the test tubes by placing the middle of the length of paper at the top of the test tube and folding the paper around the test tube. Practice holding the test using the paper towel handle to be ready for the next steps. Return the test tube back to the hot water bath.',
                  },
                  {
                    order: 3,
                    type: 'Input',
                    directions: 'Which is the proper way to hold the test tube?',
                    validAnswers: ['A', 'a'],
                    tip: 'One method looks organized and the other disorganized. A pinched handle prevents touching any warm glassware and gives stability when holding the test tube and pouring its liquid into the Styrofoam cup.',
                  },
                  {
                    order: 4,
                    type: 'Images',
                    images: [
                      {
                        imageFileName: '/images/specific-heat/test-tube-handing.jpg',
                        imageAlt: 'A test tube filled less than half way with water has a cork stopper. At the top-end of the test tube just below is opening, is a paper towel handle pinched by the thumb and index fingers. ',
                        imageTitle: 'A',
                      },
                      {
                        imageFileName: '/images/specific-heat/test-tube-handing-2.jpg',
                        imageAlt: 'A test tube filled less than half way with water has a cork stopper. The test tube is handled with a crumbled piece of paper towel.',
                        imageTitle: 'B',
                      },
                    ],
                  },
                  {
                    order: 5,
                    type: 'Header',
                    text: 'Initial Temperature of the Hot Water',
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: 'With your thermometer, measure the temperature of the water inside the large test tube that are in the bath. It must be near 100 °C. Record this <strong>temperature</strong> as the initial temperature, T<sub>i</sub> of the hot water</strong>.',
                  },
                  {
                    order: 7,
                    type: 'Header',
                    text: 'Initial Temperature of the "Cold" Water',
                  },
                  {
                    order: 8,
                    type: 'Text',
                    text: 'Press <strong>Play</strong> on the lower right of the screen on the Labquest to start data collection and obtain the initial temperature, T<sub>i</sub>. Monitor the temperature (in °C) on the screen. It may take several seconds for the temperature probe to equilibrate at the temperature of the water. Record the <strong>initial temperature, T<sub>i</sub> of water in the cups</strong>.',
                  },
                  {
                    order: 9,
                    type: 'Header',
                    text: 'Final Temperature of the Combined Waters',
                  },
                  {
                    order: 10,
                    type: 'Text',
                    text: 'After three or four readings at the same temperature T<sub>i</sub> have been obtained, add the hot water from one of the large test tubes into the Styrofoam cups. Gently stir the cup contents with the temperature probe until a maximum temperature has been reached and the temperature starts to drop. As long as the curve shows a maximum temperature, press <strong>Stop</strong> on the lower right of the screen. Record the <strong>maximum (final) temperature, T<sub>f</sub></strong> in your Part I data table.',
                  },
                  {
                    order: 11,
                    type: 'Header',
                    text: 'Mass of Cups and Combined Waters',
                  },
                  {
                    order: 8,
                    type: 'Text',
                    text: 'Now weigh the cups and record the mass as the <strong>final mass of cups and water</strong>.',
                  },
                ],
              },
              {
                name: 'Repeat',
                order: 4,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Repeat',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: '	Carry out one more trial of Part 1 to see how consistent your data is.',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'To begin the second trial, pour the water out of the cup, and shake out the last drops of water from the cups.',
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Repeat steps of <strong>Mass Measurements</strong> and <strong>Temperature Measurements</strong>.',
                  },
                ],
              },
            ],
          },
          {
            name: 'Part II',
            order: 2,
            steps: [
              {
                name: 'Mass Measurements',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Mass Measurements',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'You will do just one trial for Part 2.',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'Add 100 mL of deionized water to the cups. Weigh the cups with the water and record the mass in Part 2 data table as <strong>mass after addition of 100 mL water</strong>.',
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Return the cups to the 600 mL beaker. Cover with the cardboard and insert the Temperature Probe.',
                  },
                ],
              },
               {
                name: 'Temperature Measurements',
                order: 2,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Initial Temperature of the Hot Metal',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'With your thermometer measure the temperature of the hot water bath. <em>Assume that the temperature of the hot metal is the same as the temperature of the hot water bath.</em> Record this temperature in Part 2 data table as the <strong>temperature of the metal</strong>.',
                  },
                  {
                    order: 3,
                    type: 'Header',
                    text: 'Initial Temperature of the "Cold" Water',
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Start a <strong>New</strong> file in your LabQuest and press <strong>Play</strong> for data collection. Obtain the <strong>initial temperature, T<sub>i</sub></strong>. Monitor temperature (in °C) on the screen. It may take several seconds for the temperature probe to equilibrate at the temperature of the water.',
                  },
                  {
                    order: 5,
                    type: 'Header',
                    text: 'Final Temperature of the Combined Metal and Water',
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: 'After three or four readings at the same temperature (T<sub>i</sub>) have been obtained, add the hot metal to the styrofoam cups from the test tube in the water bath using a paper towel handle as before. Gently stir the cup contents with the temperature probe until a maximum temperature has been reached and the temperature starts to drop.  As long as the curve shows a maximum temperature, press <strong>Stop</strong> on the lower right of the screen. Record the <strong> maximum (final) temperature, T<sub>2</sub></strong>.',
                  },
                  {
                    order: 7,
                    type: 'Header',
                    text: 'Mass of Metal, Water and Cups',
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'Now weigh the cups with the water and the metal in them. Record this <strong>final mass of cups and metal</strong>.',
                  },
                ],
              },
              {
                name: 'Clean-up',
                order: 2,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Clean-up',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Carefully pour the water out of the cup without losing any metal. Pour the metal onto a few thicknesses of paper towel. Blot the metal dry with more paper towel. Transfer the metal to a dry piece of towel, spread it out, and let it sit in the air while you do the calculations for the experiment.',
                  },                  
                  {
                    order: 3,
                    type: 'Text',
                    text: 'When the metal is dry, carefully transfer it back into its labeled container. Return the metal sample vial to your instructor. Return all materials, the large test tubes and their stoppers, the styrofoam cups, and the 1  beaker to the lab cart.',
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'You are ﬁnished with the experiment guide!',
                    centered: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      tutorial: {
        name: 'Tutorial',
        link: 'tutorial',
        tabs: [
          {
            name: 'Part I',
            order: 1,
            steps: [
              {
                name: 'NOSTEP',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Heat Capacity',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'For our experiment, the calorimeter are the Styrofoam cups opened to the air (a constant pressure environment). In the first part of the lab, hot water was added to cold water in the Styrofoam cups. Using the data collected for the mass and the temperature change of the waters, we can solve for the heat capacity, C<sub>cup</sub>, of the calorimeter for the following equation:',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/specific-heat/heat-capacity.jpg',
                    imageAlt: 'A generic equation is provided. The negative of open parenthesis the specific heat capacity times the mass times the change in temperauture close parenthesis of a hot object is equation to open parenthesis the specific heat capacity times the mass times the change in temperauture close parenthesis of a cold object plus the heat capacity of the cup times the change in temperature of the cup.',
                    centered: true,
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Let\'s place the value for the specific heat of water, 4.2 Jg<sup>-1</sup>K<sup>-1</sup> into the equation:',
                  },
                  {
                    order: 5,
                    type: 'Image',
                    imageFileName: '/images/specific-heat/heat-capacity-with-water.jpg',
                    imageAlt: 'The negative of open parenthesis the specific heat capacity of water, which is 4.2 Joules per grams per Kelvin, times the mass times the change in temperauture close parenthesis of a hot water is equal to open parenthesis the specific heat capacity of water, which is 4.2 Joules per grams per Kelvin times the mass times the change in temperauture close parenthesis of a cold water plus the heat capacity of the cup times the change in temperature of the cup.',
                    centered: true,
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'Now solve for each of the parameters in the equation: <li>m<sub>hot</sub>, mass of hot water</li><li>∆T<sub>hot</sub>, temperature change for the hot water</li><li>m<sub>cold</sub>, mass of cold water</li><li>∆T<sub>cold</sub>, temperature change for the cold water</li><li>∆T<sub>cup</sub>, temperature change for the cup</li>',
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: 'Use the data in this table below to calculate and practice solving for the heat capacity, C<sub>cup</sub>, of the cup.',
                  },
                  {
                    order: 7,
                    type: 'Image',
                    imageFileName: '/images/specific-heat/practice-data-1.jpg',
                    imageAlt: 'There are two columns and 7 rows. The first column starting from the top down have: Part 1 Data Table, 1. Mass of two cups, 2. Mass of addition of 70 mL water, 3. Initial temperature, T sub i, of the hot water 4. Initial temperature, T sub i, of water in the cups, 5. Final temperature, T sub f, of water in the cups after hot water is added, 6. Final mass of cups and water. The second column starting from the top down have: Practice Data, 6.198 g, 75.79 g, 95.8 degrees Celsius, 20.8 degrees Celsius, 40.7 degrees Celsius, and 105.61 g',
                    centered: true,
                  },
                  {
                    order: 8,
                    type: 'Input',
                    directions:
                      '1. Mass of hot water:',
                    validAnswers: ['29.82'],
                    unitOfMeasure: 'g',
                    tip: '"The final mass of the cups and water" in the Part 1 data table refers to having the combined masses of the 30 mL of hot water, the 70 mL of cold water and the styrofoam cups. To get the mass of just the hot water, you must substract "the mass after the addition of 70 mL of water" from the "The final mass of the cups and water".',
                  },
                  {
                    order: 9,
                    type: 'Input',
                    directions:
                      '2. ∆T for the hot water:',
                    validAnswers: ['-55.1'],
                    unitOfMeasure: 'K',
                    tip: 'Notice that the units for ∆T are in Kevin, while the temperatures were recorded in degrees Celsius. The numerical value for ∆T is exactly the same whether the units are Kelvin or degrees Celsius since their scales have the same size divisions. <center>∆T = T<sub>final</sub> - T<sub>initial</sub></center><p>It is important to keep track which is your data value for the initial and final temperatures. Using the equation for the ∆T, the initial temperature is subtracted from the final temperature.</p><p>The final temperature is the maximum temperature reached, T<sub>f</sub>, and it is the same both the cold and the hot systems. Since the hot water cooled (its temperature was initially higher than the combined waters), it means that energy was released by the hot water. The ∆T value must be <strong>a negative value<strong>.</p>',
                  },
                  {
                    order: 10,
                    type: 'Input',
                    directions:
                      '3. Mass of cold water:',
                    validAnswers: ['69.59'],
                    unitOfMeasure: 'g',
                    tip: '"The mass after the addition of 70 mL of water" in the Part 1 data table refers to adding regular temperature water to the Styrofoam cups. This is the cold water. To get the mass of just the cold water, you must substract the mass of the two Styrofoam cups from the "The mass after the addition of 70 mL of water".',
                  },
                  {
                    order: 11,
                    type: 'Input',
                    directions:
                      '4. ∆T for the cold water:',
                    validAnswers: ['19.9'],
                    unitOfMeasure: 'K',
                    tip: '<center>∆T = T<sub>final</sub> - T<sub>initial</sub></center><p>Using the equation for the ∆T, the initial temperature is subtracted from the final temperature.  The final temperature is the maximum temperature reached, T<sub>f</sub>, and it is the same both the cold and the hot systems. Since the cold water heated up (its temperature was initially lower than the combined waters), it means that energy was absorbed by the 70 mL of cold water. The ∆T value must be a positive value.</p>',
                  },
                  {
                    order: 12,
                    type: 'Input',
                    directions:
                      '5. ∆T for the cup:',
                    validAnswers: ['19.9'],
                    unitOfMeasure: 'K',
                    tip: 'The temperature change of the cup is the same as the temperature change of the cold water since the cup and water were initially at the same temperature prior to the hot water being added.',
                  },
                  {
                    order: 11,
                    type: 'Input',
                    directions:
                      '6. Solve for the heat capacity of the cup, C<sub>cup</sub>:',
                    validAnswers: ['54'],
                    unitOfMeasure: 'J/K',
                    tip: 'Every parameter from 1 - 5 is calculated. Substitute these values into the equation above, and solve for C<sub>cup</sub>.<p>Ensure you use the same specific heat capacity of water, 4.2 Jg<sup>-1</sup>K<sup>-1</sup>, for the hot and cold water.</p><p>Do watch your algebra! Keep in mind that the left side of the equation above has a negative sign, meanwhile the right side of the equation has a positive sign. Many mistakes come about from the algebra dealing with negative/positive signs.</p><p>The significant figures are 2 here since the specific heat capacity of water is 2 and all other rules follow keeping 2 sig figs.</p><p>When you do these same calulations for your own lab data, the heat capacity should be a small positive number, optimally between 20 to 60. Doing two trials in lab will allow you to take the average for the  heat capacity.</p>',
                  },
                ],
              },
            ],
          },
          {
            name: 'Part II',
            order: 1,
            steps: [
              {
                name: 'NOSTEP',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Specific Heat Capacity for a Metal',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'In the second part of the lab, hot metal pieces were added to cold water in the Styrofoam cups. Using the data collected for the mass and the temperature change of the metal and water and the heat capacity of the cup, we can solve for the specific heat capacity of the metal, <em>c<sub>metal</sub></em>, for the following equation:',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/specific-heat/heat-capacity-for-metal.jpg',
                    imageAlt: 'The equation reads as: negative sign times open parenthesis, the specific heat capacity of a metal, times the mass times the change in temperauture, close parenthesis of a hot object is equal to two open parenthesis 4.2 Joules per grams per Kelvin times the mass times the change in temperauture plus the heat capacity of the cup close parenthesis times the change in temperature close parenthesis of the cold water.',
                    centered: true,
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Now solve for each of the parameters in the equation: <li>m<sub>cold</sub>, mass of cold water in the cup</li><li>∆T<sub>cold</sub>, temperature change for the cold water</li><li>m<sub>metal</sub>, mass of the metal</li><li>∆T<sub>hot</sub>, temperature change for the hot metal</li>',
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'Use the data in this table below to practice solving for the specific heat capacity of the meta, C<sub>metal</sub>, of the cup.',
                  },
                  {
                    order: 6,
                    type: 'Image',
                    imageFileName: '/images/specific-heat/practice-data-2.jpg',
                    imageAlt: 'There are two columns and 7 rows. The first column starting from the top down have: Part II Data Table, 1. Mass of two cups, 2. Mass of cups and 100 mL water, 3. Initial temperature, T sub i, of the hot metal 4. Initial temperature, T sub i, of water in the cups, 5. Final temperature, T sub f, of water in the cups after hot metal is added, 6. Final mass of cups and water. The second column starting from the top down have: Practice Data, 6.198 g, 105.75 g, 97.4 degrees Celsius, 20.5 degrees Celsius, 98.0 degrees Celsius, and 162.29.61 g',
                    centered: true,
                  },
                  {
                    order: 7,
                    type: 'Input',
                    directions:
                      '1. Mass of cold water:',
                    validAnswers: ['99.55'],
                    unitOfMeasure: 'g',
                    tip: 'To get the mass of just the cold water, you must substract "Mass of cups" from the "Mass of cups and 100 ml of water".',
                  },
                  {
                    order: 8,
                    type: 'Input',
                    directions:
                      '2. ∆T for the water:',
                    validAnswers: ['1.4'],
                    unitOfMeasure: 'K',
                    tip: 'Notice that the units for ∆T are in Kevin, while the temperatures were recorded in degrees Celsius. The numerical value for ∆T is exactly the same whether the units are Kelvin or degrees Celsius since their scales have the same size divisions. <center>∆T = T<sub>final</sub> - T<sub>initial</sub></center><p>It is important to keep track which is your data value for the initial and final temperatures. Using the equation for the ∆T, the initial temperature is subtracted from the final temperature. The final temperature is the maximum temperature reached, T<sub>f</sub>, for both the water and the metal since the metal and water are combined. Since the cold water heated (its temperature was initially lower before the metal was added), it means that energy was absorbed by the 100 mL of cold water. The ∆T value must be a positive value.</p>',
                  },
                  {
                    order: 9,
                    type: 'Input',
                    directions:
                      '3. Mass of metal:',
                    validAnswers: ['41.5'],
                    unitOfMeasure: 'g',
                    tip: 'To get the mass of just the metal, you must substract the "mass of cups and 100 mL of water" from the "Final mass of cups, water and metal".',
                  },
                  {
                    order: 10,
                    type: 'Input',
                    directions:
                      '4. ∆T for the metal:',
                    validAnswers: ['-72.9'],
                    unitOfMeasure: 'K',
                    tip: '<center>∆T = T<sub>final</sub> - T<sub>initial</sub></center><p>It is important to keep track which is your data value for the initial and final temperatures. Using the equation for the ∆T, the initial temperature is subtracted from the final temperature. The final temperature is the maximum temperature reached, T<sub>f</sub>, for both the water and the metal since the metal and water are combined. Since the hot metal\'s temperature was initially higher before adding to the cup), it means that energy was released by hot metal to the water. The ∆T value must be a negative value.</p>',
                  },
                  {
                    order: 11,
                    type: 'Input',
                    directions:
                      '5. Heat capacity of the cup, C<sub>cup</sub>::',
                    validAnswers: ['54'],
                    unitOfMeasure: 'J/K',
                    tip: 'Please revert to and re-write the heat capacity that you calculated from Part I of this <strong>Tutorial</strong>.',
                  },
                  {
                    order: 11,
                    type: 'Input',
                    directions:
                      '6. Solve for the specific heat capacity of the metal, <em>c<sub>metal</sub></em>:',
                    validAnswers: ['0.17'],
                    unitOfMeasure: 'Jg<sup>-1</sup>K<sup>-1</sup>',
                    tip: 'Every parameter is calculated from 1 - 5. Substitute these values into the equation above, and solve for <em>c<sub>metal</sub></em>.<p>Do watch your algebra! Keep in mind that the left side of the equation above has a negative sign, meanwhile the right side of the equation has a positive sign. Many mistakes come about from the algebra dealing with negative/positive signs.</p><p>The significant figures are 2 here since the specific heat capacity of water and the heat capacity are 2 and all other rules follow keeping 2 sig figs.</p>',
                  },
                  {
                    order: 12,
                    type: 'Input',
                    directions:
                      'The book value for the specific heat capacity of tin is 0.217 Jg<sup>-1</sup>K<sup>-1</sup>.<p>7. Calculate the % error:</p>',
                    validAnswers: ['20'],
                    unitOfMeasure: '%',
                    tip: '<center>% error = <em>c<sub>exp</sub></em> - <em>c<sub>book</sub></em> / <em>c<sub>book</sub></em> x 100%</center></p>Always pay attention to the numerator and the subtraction significant figures rule as that will drive the sig figs for the final % answer for this question.</p>',
                  },
                ],
              },
            ],
          },
        ],
    },
    },
    rank: 14
    
  }

export default specificHeatCapacity
