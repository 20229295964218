const solidStateClosePacking = {
    status: 'Active',
    name: 'Solid State Close Packing',
    imageFileName: 'images/simple-cubic-icon.jpg',
    imageAlt: 'A small 2 inch by 2 inch by 2 inch three dimentional model of a simple cubic atomic packing is held with a pincher grip.',
    description: 'Simple cubic, body-centered cubic and face-centered cubic are explored for their packing efficiencies.',
    tags: ['Dry Lab', 'Tutorial', 'Chem-101A'],
    page: '/close-packing',
    content: {
      overview: {
        name: 'Overview',
        link: 'overview',
        tabs: [
          {
            name: 'NOTAB',
            order: 1,
            steps: [
              {
                name: 'NOSTEP',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Text',
                    text: 'In this lab, we will explore how atoms organize in the solid state. Specifically, we are looking at the atoms that form crystalline solids, that is, solids that organize in a repetitive fashion based on a unit cell. Review the <b><i>Introduction</b></i>. Then, go through the <b><i>Tutorial</b></i>, which will take you through an exercise based on a 2-dimensional view of packing. Then, similar, but more sophisticated, 3-dimensional packing for three types of cubic unit cells are examined. The last section of the <b><i>Tutorial</b></i> covers the calculation of finding the density of an atom.',
                  },
                ],
              },
            ],
          },
        ],
      },
      tutorial: {
        name: 'Tutorial',
        link: 'tutorial',
        description: 'tbd',
          tabs: [
            {
              name: '2 Dimensional Models',
              order: 1,
              steps: [
                {
                  name: 'Packing Models',
                  order: 1,
                  contentBlocks: [
                    {
                      order: 1,
                      type: 'Header',
                      text: 'Closest Packing Models',
                    },
                    {
                      order: 2,
                      type: 'Text',
                      text: 'Note the differences on how pennies can pack on a surface. These arrays display two closest packing models: cubic closest packing and hexagonal closest packing.',
                    },
                    {
                      order: 3,
                      type: 'Images',
                      images: [
                        {
                          imageFileName: '/images/solid-state/cubic-7.jpg',
                          imageAlt: 'Pennies are organized in a cubic closest packing assembly. This means that pennies are orgnized in a two dimensional array, flush to one another in a row. The second row (above the first), the pennies are directly over another penny forming a square lattice. This assembly continues all the way up the image so that only pennies appear in the image.',
                          imageTitle: 'Cubic packing lattice',
                        },
                        {
                          imageFileName: '/images/solid-state/hexagonal.jpg',
                          imageAlt: 'Pennies are organized in a hexagonal closest packing assembly. This means that pennies are orgnized in a two dimensional array, flush to one another in a row. The second row (above the first), the pennies nestle in between the pennies of the first row and are also flush to one another. This assembly continues all the way up the image so that only pennies appear in the image.',
                          imageTitle: 'Hexagonal packing lattice',
                        },
                      ],
                    },
                    {
                      order: 4,
                      type: 'Header',
                      text: 'Coordination Number',
                    },
                    {
                      order: 5,
                      type: 'Text',
                      text: 'Coordination number refers to the number of closest neighbors to one atom. ',
                    },
                    {
                      order: 6,
                      type: 'Input',
                      directions:
                        'Look at each packing model image above and determine the coordination number for:',
                      problem: 'the cubic closest packing model',
                      validAnswers: ['4'],
                      tip: 'Start with one penny and count how many pennies "touch" the one penny, that defines its coordination number.',
                    },
                    {
                      order: 7,
                      type: 'Input',
                      problem: 'the hexagonal closest packing model',
                      validAnswers: ['6'],
                      tip: 'Start with one penny and count how many pennies "touch" the one penny, that defines its coordination number. <p>You cannot count across a hole.</p>',
                    },
                    {
                      order: 8,
                      type: 'Header',
                      text: 'Interstitial Sites',
                    },
                    {
                      order: 9,
                      type: 'Text',
                      text: 'Interstitial sites in a unit cell refer to the empty space in the unit cell of a pure substance. One can also refer to these areas as holes. These sites may be filled with smaller atoms that nestle well into the unit cell\'s empty space when an ionic crystal is formed.',
                    },
                    {
                      order: 10,
                      type: 'Input',
                      directions:
                        'Look at each packing model image above and determine the coordination number for:',
                      problem: 'the cubic closest packing model',
                      validAnswers: ['4'],
                      tip: 'Start with one hole and count how many pennies "touch" the hole, that defines its coordination number.',
                    },
                    {
                      order: 11,
                      type: 'Input',
                      problem: 'the hexagonal closest packing model',
                      validAnswers: ['3'],
                      tip: 'Start with one hole and count how many pennies "touch" the hole, that defines its coordination number. <p>You cannot count across a hole.</p>',
                    },
                  ],
                },
                {
                name: 'Packing Efficiency',
                order: 2,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Packing Efficiency',
                  },
                  {
                  order: 2,
                  type: 'Text',
                  text: 'Every unit cell has a packing efficiency, meaning how tightly the particles pack into a unit cell. The more particles inside the cell, the higher its density. For a 2 dimensional array, packing efficiency is calculated as follows:',
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName: '/images/solid-state/2D-packing-efficiency.jpg',
                  imageAlt:
                    'Packing efficiency is equal to particles per unit cell times area per particle divided by ares per unit cell.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Let\'s highlight the three parts of the equation that is required for the calculation. <li> particles per unit cell</li><li>volume per particle</><li>volume per unit cell</li><p>As long as all three parts are obtained, the calculation is possible.</p>',
                },
              ],
            },
                {
                  name: 'Closest Packing',
                  order: 2,
                  contentBlocks: [                  
                    {
                    order: 1,
                    type: 'Header',
                    text: 'Cubic Closest Packing in 2 Dimensions',
                  },
                  {
                    order: 2,
                    type: 'Image',
                    imageFileName: '/images/solid-state/cubic-unit-cell.jpg',
                    imageAlt:
                      'Pennies are organized in a cubic closest packing assembly. This means that pennies are orgnized in a two dimensional array, flush one to another in a row. The second row (above the first), the pennies are flush one over the other. This assembly continues all the way up the image so that only pennies appear in the image. Four closest pennies have a small green circle at their center. The four centers are connected by a green square, outlining the unit cell.',
                  },
                    {
                      order: 3,
                      type: 'Text',
                      text: 'In this 2-dimensional image of pennies, a green square outlines the unit cell.',
                    },
                    {
                      order: 4,
                      type: 'Text',
                      text: 'To calculate its packing efficiency, we will need the three parts of the equation:. <ul>(1) pennies per unit cell</ul><ul>(2) area per penny</ul><ul>(3) area per unit cell</ul><p>Let\'s first determine each.</p>',
                    },
                    {
                      order: 5,
                      type: 'Input',
                      directions:
                      '(1) How many pennies are in the unit cell of above?',
                      validAnswers: ['1'],
                      unitOfMeasure: 'atom',
                      tip: 'The green unit cell outlined in the image has a 1/4 portion of four different pennies. Add these four pieces together to get the total pennies in the unit cell.',
                    },
                    {
                      order: 6,
                      type: 'Input',
                      directions:
                      '(2) A penny\'s diameter is 1.90 cm. Calculate the area of the penny.',
                      validAnswers: ['2.84', '2.83'],
                      unitOfMeasure: 'cm<sup>2</sup>',
                      tip: 'The area of a circle is &pi;r<sup>2</sup>.  <p>Maintaining significant figures rules, there should be 3 digits to the answer.</p>',
                    },
                    {
                      order: 7,
                      type: 'Input',
                      directions:
                      '(3) The unit cell length is 1.90 cm. Calculate the area of the unit cell.',
                      validAnswers: ['3.61'],
                      unitOfMeasure: 'cm<sup>2</sup>',
                      tip: 'The area of a square is L<sup>2</sup>.',
                    },
                    {
                      order: 8,
                      type: 'Input',
                      directions:
                      '(4) Using 1 - 3, calculate its packing efficiency.',
                      validAnswers: ['82.7', '78.7', '78.6', '78.5', '78.4', '78.3'],
                      unitOfMeasure: '%',
                      tip: '(particles/unit cell) x (area/particle) / (area/unit) x 100%.<p>Maintaining significant figures rules, there should be 3 digits to the answer.</p>',
                    },
                  ],
                },
                {
                name: 'Hexagonal Packing',
                order: 3,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Hexagonal Packing in 2-Dimensions',
                  },
                  {
                    order: 2,
                    type: 'Image',
                    imageFileName: '/images/solid-state/hexagonal-unit-cell.jpg',
                    imageAlt:
                      'Pennies are organized in a hexagonal closest packing assembly. This means that pennies are orgnized in a two dimensional array, flush to one another in a row. The second row (above the first), the pennies nestle in between the pennies of the first row and are also flush to one another. This assembly continues all the way up the image so that only pennies appear in the image. Four closest pennies have a small green circle at their center. The four centers are connected by a green rhombus, outlining the unit cell.',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'In this 2 dimensional image of pennies, a green square outlines the unit cell.',
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'To calculate its packing efficiency, we will need the three parts of the equation:. <ul>(1) pennies per unit cell</ul><ul>(2) area per penny</ul><ul>(3) area per unit cell</ul><p>Let\'s first determine each.</p>',
                  },
                  {
                    order: 5,
                    type: 'Input',
                    directions:
                    '(1) How many pennies are in the unit cell of above?',
                    validAnswers: ['1'],
                    unitOfMeasure: 'atom',
                    tip: 'The green unit cell outlined in the image has a 1/4 portion of four different pennies. Add these four pieces together to get the total pennies in the unit cell.',
                  },
                  {
                    order: 6,
                    type: 'Input',
                    directions:
                    '(2) A penny\'s diameter is 1.90 cm. Calculate the area of the penny.',
                    validAnswers: ['2.84', '2.83'],
                    unitOfMeasure: 'cm<sup>2</sup>',
                    tip: 'The area of a circle is &pi;r<sup>2</sup>.',
                  },
                  {
                    order: 7,
                    type: 'Input',
                    directions:
                    '(3) If the diagonals of the rhombus are p = 3.40 cm and q = 2.00 cm, calculate the area of this unit cell.',
                    validAnswers: ['3.40'],
                    unitOfMeasure: 'cm<sup>2</sup>',
                    tip: 'The area of a rhombus is pq/2. <p>Maintaining significant figures rules, there should be 3 digits to the answer.</p>',
                  },
                  {
                    order: 8,
                    type: 'Input',
                    directions:
                    '(4) Using 1 - 3, calculate its packing efficiency.',
                    validAnswers: ['83.5', '83.4', '83.3', '83.2'],
                    unitOfMeasure: '%',
                    tip: '(particles/unit cell) x (area/particle) / (area/unit) x 100%. <p>Maintaining significant figures rules, there should be 3 digits to the answer.</p>',
                  },
                ],
                },
              ],
            },
/*
            {
              name: '3 Dimensional Models',
              order: 1,
              steps: [
                {
                  name: 'Simple Cubic',
                  order: 1,
                  contentBlocks: [
                    {
                      order: 1,
                      type: 'Header',
                      text: 'Simple Cubic',
                    },
                ],
              },
              {
                name: 'Body-Centered Cubic',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Body-Centered Cubic',
                  },
              ],
            },
            {
              name: 'Face-Centered Cubic',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Face-Centered Cubic',
                },
            ],
          },
              ],
            },
            {
              name: 'Density of fcc',
              order: 1,
              steps: [
                {
                  name: 'Edge Length',
                  contentBlocks: [
                    {
                      order: 1,
                      type: 'Header',
                      text: 'Unit Cell Edge Length',
                    },
                ],
              },
              {
                name: 'Volume',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Volume of a Unit Cell',
                  },
              ],
            },
            {
              name: 'Atoms in a Unit Cell',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Atoms in a Unit Cell',
                },
            ],
          },
          {
            name: 'Density Calculation',
            order: 1,
            contentBlocks: [
              {
                order: 1,
                type: 'Header',
                text: 'Density Calculation',
              },
          ],
        },
              ],
            },
*/
          ],
      },
    },
    rank: 16,
  }

export default solidStateClosePacking