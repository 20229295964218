import React from 'react';
import { Typography } from '@mui/material';

const HTMLContent = (props) => {
  /* Do not use this inside MUI typography.  A body variant will create a <p> which is not allowed to have a <div> inside */
  return (
    <Typography
      dangerouslySetInnerHTML={{
        __html: props.content,
      }}
    />
  );
};

export default HTMLContent;
