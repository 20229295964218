const beersLaw = {
  status: 'Active',
  name: "Beer's Law",
  imageFileName: 'images/cuvette-icon.jpg',
  imageAlt: 'Two cuvettes, each containing a light blue solution.',
  description: 'Experimental guide for determining the concentration of a solution.',
  tags: ['Pre-Lab', 'Wet Lab', 'Experiment', 'Chem-101A'],
  page: 'beers-law',
  content: {
    overview: {
      name: 'Overview',
      link: 'overview',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'NOSTEP',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'In this lab, you will be using graphical analysis to determine the concentration of an unknown solution. The experimental data will be obtained by using the relationship of the absorption of light and concentration found in the equation of Beer\'s Law. Read through the <strong><em>Introduction</em></strong> to learn what is Beer\'s Law. Then quickly move on to the <strong><em>Experiment</em></strong> to read the procedure and helpful guide to learn how to run your experiment smoothly.',
                },
              ],
            },
          ],
        },
      ],
    },
    introduction: {
      name: 'Introduction',
      link: 'introduction',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'NOSTEP',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'In this lab, a colorimeter will be used to measure the concentration of a solution. A colorimeter measures the amount of light that transmits through a cuvette filled with solution.',
                },
                {
                  order: 1,
                  type: 'Text',
                  text: 'The higher the concentration, the more light is absorbed and the less light transmited. The relationship between concentration and light absorption (or transmittance) is known as <strong>Beer\'s Law</strong>. You will graph this direct relationship between concentration and absorbance and use the straight line to find an unknown concentration of the same substance. In a similar way, the concentration of the unknown solution can also be found by viewing the graph and its the slope of the Beer\'s law curve.',
                },
              ],
            },
          ],
        },
      ],
    },
    experiment: {
      name: 'Experiment',
      link: 'experiment',
      tabs: [
        {
          name: 'Start Here',
          order: 1,
          steps: [
            {
              name: 'Summary',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Summary: Experimental Steps',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The general experimental steps are: <li>Prepare 5 different solutions of copper(II) sulfate</li><li>Test their absorbance using the colorimeter</li><li>Graph the data to generate an absorbance curve</li><li>Test the absorbance of an unknown concentration of a copper(II) sulfate solution</li><li>Use your graph to determine your unknown\'s concentration</li>',
                },
              ],
            },
            {
              name: 'Equipment',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Equipment',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The following pieces of equipment are needed for this experiment:',
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName: '/images/beers/equipment.jpg',
                  imageAlt: 'The equipment is shown spread out on the lab bench. Numbers are next to each and a key is to its right. 1. colorimeterk, 2. Labquest handheld device, 3. Kimwhips lint-free tissue, 4. seven cuvette (f available), 5. two 100 millilieter beakers, 6. pipete bulb (or pump), 7. two 10 millilieter pipettes, 8. stirring rod, 9. test tube rack, 10. 6 test tubes.',
                  centered: true,
                },
              ],
            },
            {
              name: 'Notebook Prep',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Notebook Preparation',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Draw the following <strong> Solutions Table</strong> in your notebook and calculate the concentrations for the 5 solutions.',
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName: '/images/beers/table.jpg',
                  imageAlt: 'A 6 row 4 column table. The first row lists the headers of the columns 1 to 4 as: Trial number, 0.4 CuSO4 (mL) Distilled H2O (mL) and Concentration (M). Starting at row 2 to row 6 for all columns: column 1 down numbers 1 to 5. Column 2 down numbers as 2, 4, 6, 8 and ~10. Column 3 down as 8, 6, 4, 2, and 0.',
                  centered: true,
                },
                {
                  order: 4,
                  type: 'Text',
                  text: '',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Next, draw the following table in your notebook and label it as <strong>Data Table</strong>.',
                },
                {
                  order: 6,
                  type: 'Image',
                  imageFileName: '/images/beers/absorbance.jpg',
                  imageAlt: 'A 7 row 3 column table. The first row lists the headers of the columns 1 to 4 as: Trial number, Concentration (M) and Absorbance. Starting at row 2 to row 7 for all columns: column 1 down numbers 1 to 6. Column 2 rows 2 to 5 are empty. Column 2 row 7 has Unknown # with underscore next to it. Column 3 row 2 to row 7 are empty.',
                  centered: true,
                },
              ],
            },
          ],
        },
        {
          name: 'Procedure',
          order: 2,
          steps: [
            {
              name: 'Solution Prep',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Solution Preparation',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Search for 5 test tubes of the same size and diameter on the lab cart or your your locker. While it is not an absolute necessity that the tubes are exact, it can be helpful to have the tubes line up when filling them to 10 mL, which checks your delivery of solutions using the 10 mL pipette.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Label your test tubes 1 to 5.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Obtain roughly 30 mL of 0.4 M CuSO<sub>4</sub> in a beaker and  in a different beaker obtain a little more than 20 mL of distilled water.',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Prepare the five test tubes using the pipette bulb with the specified amounts of liquids from the <strong>Solutions Table</strong> that you created in your notebook.',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'Watch: How to use a pipette bulb.<p><iframe width="560" height="315" src="https://www.youtube.com/embed/ItWsi2mhle8?&playlist=ItWsi2mhle8&loop=1" title="How to use a pipette bulb" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: 'You have two pipettes to prepare your solutions. One dedicated for the stock CuSO<sub>4</sub> solution and the other for distilled water. Note that test tube #5 is to be filled with the stock solution itself. For this tube, it is not necessary to fill exactly 10 mL as the table suggests. You only need enough volume for test tube 5 to fill a cuvette.',
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'Use the stirring rod to thoroughly mix the solutions once the solutions have their designated amounts. Ensure to clean and dry the stirring rod in between making of each solution.',
                },
                {
                  order: 9,
                  type: 'Input',
                  directions: 'Which is the better set-up for your experiment? (A or B)',
                  validAnswers: ['B', 'b'],
                  tip: 'Those test tubes should be labeled!',
                },
                {
                  order: 10,
                  type: 'Images',
                  images: [
                    {
                      imageFileName: '/images/beers/solutions.jpg',
                      imageAlt: 'A test tube rack holding 5 test tubes. They appear to be filled with the stock solution  as specified in the table: 2, 4, 6, 8 and 10 milliliters.',
                      imageTitle: 'A'
                    },
                    {
                      imageFileName: '/images/beers/solutions-2.jpg',
                      imageAlt: 'A test tube rack holding 5 test tubes. The test tubes are labeled 1 to 5 and follow the order of stock solution as specified in the table: 2, 4, 6, 8 and 10 milliliters.',
                      imageTitle: 'B'
                    },
                  ],
                },
                {
                  order: 11,
                  type: 'Text',
                  text: 'What is true about the picture below? Check all that apply.',
                },
                {
                  order: 12,
                  type: 'Magnified Image',
                  imageFileName:
                    '/images/beers/solutions-10mL.jpg',
                  imageAlt:
                    'A test tube rack holding 5 test tubes. The test tubes are labeled 1 to 5 and are all filled with 10 milliliters. Test tube 1 is most dilute in color and it progresses to being most concentrated by test tube 5.',
                  imageZoomFileName:
                    '/images/beers/solutions-10mL-magnefied.jpg',
                },
                {
                  order: 13,
                  type: 'Multiple Choice',
                  directions: 'What is true about the picture below? Check all that apply.',
                  choices: [
                    {
                  label: 'The test tubes are labeled well.',
                  tip: 'Yes, they are appropriately labeled as specified from the <strong>Solutions Table</strong>.',
                  validChecked: true,
                    },
                    {
                  label: 'Each test tube is filled with 10 mL.',
                  tip: 'It appears there is about 10 mL in each test tube. It is useful that all the test tubes line up ensuring that they have equal volumes <strong>if the test tubes are identical</strong>.',
                  validChecked: true,
                    },
                    {
                  label: 'The most dilute is test tube #5.',
                  tip: 'The most dilute is test tube #1. The stock solution is most concentrated and is test tube #5.',
                  validChecked: false,
                    },
                  ],
                },
                {
                  order: 14,
                  type: 'Text',
                  text: 'After making your 5 solutions, if you have any extra liquid from the beaker, bring it back to the fume hood to the bottle "extra" CuSO<sub>4</sub> bottle, as you know that any liquid chemical you take does not return to the original bottle. However, this "extra"  CuSO<sub>4</sub> bottle will remain available for students that may need more CuSO<sub>4</sub> to complete their experiment and prevents disposal of still useful chemical.',
                },
              ],
            },
            {
              name: 'Cuvette Prep',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Cuvette Preparation',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Prior to adding solutions to cuvettes, make sure the cuvettes are fully clean and dry. If not, wipe clean with a Kimwipe tissue.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'If you have enough cuvettes, transfer the test tube solutions to its own cuvette. If you do not have a cuvette for every test tube, only fill as many cuvettes that you have and reuse the cuvettes.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Start with the most dilute test tube and use a pipette to transfer the solution from test tube 1 to a cuvette. Fill the cuvettes 3/4 full. Repeat this four more times using the same pippette keeping the order of the cuvettes (assuming your pipette is cleared of solution prior to filling the next). When the time is ready (further down in the experiment), you will discard the cuvette\'s contents, clean it dry with a tissue and refill with the next solution.',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Which cuvette(s) is/are properly filled? Check all that apply.',
                },
                {
                  order: 6,
                  type: 'Image',
                  imageFileName: '/images/beers/cuvettes.jpg',
                  imageAlt: 'Four cuvettes. A is half full with a blue liquid. B is three-quarters full. C is seven-eighths full and filled to a light marking on the cuvette. D is filled to its brim.',
                },
                {
                  order: 7,
                  type: 'Multiple Choice',
                  directions: 'Which cuvettes are properly filled? Check all that apply.',
                  choices: [
                    {
                  label: 'Cuvette A',
                  tip: 'If not filled enough with liquid, the colorimeter\'s path of light will not pass through the sample, giving an incorrect reading.',
                  validChecked: false,
                    },
                    {
                  label: 'Cuvette B',
                  tip: 'This cuvette apears to be three-quarters full and should be sufficient liquid for the measurement.',
                  validChecked: true,
                    },
                    {
                  label: 'Cuvette C',
                  tip: 'While this cuvette is above the suggested three-quarters full, the liquid is actually filled to a small line that this particular cuvette happens to have. This means that one should not surpass this line when filling the cuvette.',
                  validChecked: true,
                    },
                  {
                  label: 'Cuvette D',
                  tip: 'Too full. There is a good change that the liquid could spill over the colorimeter when handling.',
                  validChecked: false,
                    },
                  ],
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'When the time is ready to discard the contents of the cuvette, empty the cuvette, clean it dry with a tissue and refill with the next solution (or return to the lab cart).',
                },
              ],
            },
            {
              name: 'Calibration of Colorimeter',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Calibration of Colorimeter',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Turn on the Labquest and connect its charger to an outlet. Connect the colorimeter to the LabQuest. <li>Choose <strong>New</strong> from the <strong>File</strong> menu.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'The colorimeter needs to be first calibrated to the solvent, distilled water.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: '<li> Fill an empty cuvette 3/4 full with distilled water. This cuvette is called the <strong>blank</strong>.</li><li>There are some cuvettes that have two sides. If you have the type of cuvette that has two different sides, wipe down the non-textured side with the Kimwipe tissue.</li><li>Place the <strong>blank</strong> in the colorimeter where the non-textured side is directed to the arrow on the colorimeter.</li><li>Close the lid.</li>',
                },
                {
                  order: 5,
                  type: 'Image',
                  imageFileName: '/images/beers/blank.jpg',
                  imageAlt: 'A open colorimeter with a cuvette containing water. An arrow is seen pointing to the top side of the cuvette. The 635 nm light is lit.',
                  centered: true,
                },
                {
                  order: 6,
                  type: 'Text',
                  text: '<li>Use the < or > buttons to change the wavelength of light to <strong>635 nm</strong>.</li><li>Press the <strong>CAL</strong> button to calibrate. The red LED will flash and stop when the calibration is complete.</li><li>You can now remove the <strong>blank</strong>.',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: 'Calibration is only done once during this experiment.',
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'Remove the cuvette from your Colorimeter.',
                },
              ],
            },
            {
              name: 'Data Collection',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Data Collection',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'To set-up the Labquest,<li>Go to the <strong>Meter</strong> screen.</li><li>Tap <strong>Mode</strong>.</li><li>Change the data-collection mode to <strong>Events with Entry</strong>.</li><li>Enter the Name (Concentration) and Units (M).</li><li>Select OK.</li>',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Data collection can now begin. On the Labquest screen, press the <strong>Play</strong> button.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: '<em><center>Do not be inclined to press the <strong>Stop</strong> button.<br>You will not press <strong>Stop</strong> button until all five cuvettes have been through the colorimeter.</center><em>',
                  centered: true,
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Pick up the first cuvette (from test tube 1) and wipe its outside with a Kimwipe. Place it into the colorimeter having the non-textured side (if there is one) facing the colorimeter\'s arrow. Close the lid.',
                },
                {
                  order: 6,
                  type: 'Images',
                  images: [
                    {
                      imageFileName: '/images/beers/whipping.jpg',
                      imageAlt: 'Fingers are holding a tissue with a cuvette filled three-fourth filled with copper(II) sulfate solution around it.',
                    },
                    {
                      imageFileName: '/images/beers/colorimeter-sample.jpg',
                      imageAlt: 'A open colorimeter with a cuvette containing copper(II) sulfate. An arrow is seen pointing to the top side of the cuvette. The 635 nm light is lit.',
                    },
                  ],
                },
                {
                  order: 7,
                  type: 'Text',
                  text: 'When the absorbance reading has stabilized,<li>Tap <strong>Keep</strong>.</li><li>Enter the first concentration that you calculated and wrote down in your <strong>Solutions Table</strong>.</li><li>Select OK.</li>The absorbance and concentration values have now been saved for the first solution. <strong>Do not stop the run</strong>.',
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'Remove cuvette 1 and replace it with cuvette 2. Do the same motions as the first:<li>Wipe its outside with a Kimwipe.</li><li>Carefully place it inside the colorimeter and close the lid</li><li>Wait fo the absorbance to stabilize, then tap <strong>Keep</strong> and enter the next concentration that you calculated. </li><li>Select OK.</li>',
                },
                {
                  order: 9,
                  type: 'Text',
                  text: 'Repeat the same steps for cuvettes 3, 4 and 5.',
                },
                {
                  order: 10,
                  type: 'Text',
                  text: 'Now press the <strong>Stop</strong> button. The graph of absorbance versus concentration for the CuSO<sub>4</sub> solutions will automatically appear on the screen.',
                },
              ],
            },
            {
              name: 'Graphing',
              order: 4,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Graphing',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Select any data point to examine the data pairs on the displayed graph. As you tap each point, their respective absorbance and concentration values are displayed to the right of the graph. Record the absorbance values in your lab notebook <strong>Data Table</strong>.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'In LabQuest, find the <strong>Analyze</strong> menu and select <strong>Curve Fit</strong>. Select <strong>Linear</strong> as the <strong>Fit Equation</strong>. Select OK.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'The linear-regression curve for our experiment\'s data follows the equation:',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'y = mx + b',
                  centered: true,
                },
                {
                  order: 6,
                  type: 'Text',
                  text: '	where x is concentration, y is absorbance, m is the slope, and b is the y-intercept. One indicator of the quality of your data is the size of b, the y-intercept. If it is a very small value, the regression line will pass through or near the origin. The correlation coefficient, r, indicates how closely the data points match up with (or fit) the regression line. A value of 1.00 indicates a nearly perfect fit.',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: 'The graph should represent Beer\'s law, the direct relationship between absorbance and concentration. The regression line should closely fit the five data points and pass through (or near) the origin of the graph.',
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'Record the best-fit line equation for the standard solutions in your <strong>Data Table</strong>.',
                },
              ],
            },
            {
              name: 'Unknown Solution',
              order: 5,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Unknown Solution',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Obtain about 5 mL of an unknown CuSO<sub>4</sub> solution in a clean, dry, test tube. Record the number of the unknown in your <strong>Data Table</strong>.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'If you do not have an empty dry cuvette, discard the contents of one of your cuvettes, rinse it twice with the unknown solution and fill it about 3/4 full. Wipe the outside of the cuvette, place it into the colorimeter, and close the lid.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Tap <strong>Meter</strong> and record the absorbance after its value has stabilized in your lab notebook <strong>Data Table</strong>.',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'You now have the data to determine the concentration of your unknown solution.',
                },
                {
                  order: 6,
                  type: 'Header',
                  text: 'Clean-up',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: 'Dispose all made solutions into the waste bottle. Dispose any stock solution into the "extra" CuSO<sub>4</sub> bottle. If you do not see one, ask your instructor.',
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'Clean up <strong>thoroughly</strong> your cuvettes. Leave the cuvettes upside drying on the lab cart drying rack.',
                },
                  {
                    order: 8,
                    type: 'Image',
                    imageFileName: '/images/beers/cleaning.jpg',
                    imageAlt: 'Two cuvettes are upside down drying on top of a Kimwipe tissue.',
                    centered: true,
                  },
                {
                  order: 9,
                  type: 'Text',
                  text: 'You are ﬁnished with the experiment guide!',
                  centered: true,
                },
              ],
            },
          ],
        },
      ],
    },
  },
  rank: 11,
};

export default beersLaw;
