const liquidVolumeMeasurements = {
  status: 'Active',
  name: 'Volume Measurements',
  imageFileName: 'images/volume-icon.jpg',
  imageAlt:
    'A 100 milliliter graduated cylinder filled with a blue liquid to the 100 milliliter mark.',
  description:
    'How to precisely measure the volume of a liquid using a variety of volumetric instruments.',
  tags: ['Lab Skills', 'Tutorial', 'Chem-101A'],
  page: 'volume',
  content: {
    overview: {
      name: 'Overview',
      link: 'overview',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'NOSTEP',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: "The following activity reviews how to measure a liquid in the chemistry laboratory. Start with the <b><em>Introduction</b></em> to review how we approach a graduated or fixed laboratory piece of glassware. Use the <b><em>Tutorial</b></em> to examine: <li>graduated cylinders of different sizes:</li><li>burette</li><li>pipette</li><li>volumetric flasks</li><li>beaker</li><li>flask</li>Each glassware has pictoral examples for you to practice measuring its volume of liquid. The estimated digit allows for room for flexibility in the measurement. Therefore, your correct measurement may be slightly different from a classmate's correct measurement.",
                },
              ],
            },
          ],
        },
      ],
    },
    introduction: {
      name: 'Introduction',
      link: 'introduction',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'NOSTEP',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'In the chemistry laboratory, we use volumetric instruments to measure the volume of a liquid. There are a variety of instruments and a range of precision within them. Some volumetric instruments are selected specifically to measure with a high degree of precision, while others are chosen knowing that the precision is less important. ',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'You will find two types of volumetric instruments in the laboratory: <li>graduated glassware (left photo), which are those that have subdivisions and can deliver a range of volumes, or </li> <li>fixed glassware (right photo), which are filled to a calibration mark and deliver a fixed volume.</li>',
                },

                {
                  order: 3,
                  type: 'Images',
                  images: [
                    {
                      imageFileName: '/images/volume-icon.jpg',
                      imageAlt:
                        'A blue liquid is filled to the 100 milliliter subdivision line in the 100 millilieter graduated cylinder.',
                    },
                    {
                      imageFileName: '/images/volume/empty-flask.jpg',
                      imageAlt: 'An empty 25 milliliter volumetric flask',
                    },
                  ],
                  centered: true,
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'When measuring the volume of an aqueous (water-based) solution using silica-based glassware, it is common to see the liquid have a concave shape within the tube. The liquid curves downward in the center and creeps slightly up the glassware as the water attracts to the glassware, as seen in the photo to your left. This curve is called the meniscus. To measure the volume of an aqueous solution, we read the liquid at the bottom of the meniscus (not where the water creeps up on the side). The 10-mL graduated cylinder to the left exhibits this meniscus well, which stems from 2.1 mL at the bottom to 2.3 mL at the top. The most accurate reading is when one positions themselves to be eye-level with the meniscus, so we can measure that the green liquid 2.10 mL because the meniscus lands on the subdivision line. Recall the certain and estimated digits of a measurement. (Provide link). If the liquid falls on the subdivision line, we assess the estimated digit to be zero. Note the subdivisions are every 0.1 mL with an error range of +/- 0.05 mL.',
                },
                {
                  order: 5,
                  type: 'Image',
                  imageFileName: '/images/volume/10mL-A.jpg',
                  imageAlt:
                    'A green liquid is filled to 2.10 mL of a 10-mL graduated cylinder.',
                  centered: true,
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'In general, reviewing the subdivisions before a measurement is good practice to recognize the location of the estimated digit. If the liquid reaches in between the smallest subdivision lines, as the green liquid below, we estimate the digit in between in the hundredth place. We measure the green liquid to be 5.95 mL as you can clearly see that the meniscus falls below the 6 mL mark, but above the 5.9 mL mark.',
                },
                {
                  order: 7,
                  type: 'Image',
                  imageFileName: '/images/volume/10mL-B.jpg',
                  imageAlt:
                    'A green liquid is filled to 5.95 mL of a 10-mL graduated cylinder.',
                  centered: true,
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'For the last two examples, we were able to measure the volume using a 10-mL graduated cylinder. The next example shows an example where the volume cannot be measured using this instrument. Since we measure at the bottom of the meniscus, the measurement falls below the 1 mL subdivision line. For a situation like this, it would be advised to use a different instrument, such as a volumetric pipette.',
                },

                {
                  order: 9,
                  type: 'Image',
                  imageFileName: '/images/volume/10mL-C.jpg',
                  imageAlt:
                    'A blue liquid is filled below the first increment line of a 10-mL graduated cylinder. ',
                  centered: true,
                },
                {
                  order: 10,
                  type: 'Text',
                  text: 'Below is a 10-mL volumetric pipette filled two different ways, with one being the correct one. Ensure that you reach the bottom of the meniscus at the increment line.',
                },
                {
                  order: 11,
                  type: 'Image',
                  imageFileName: '/images/volume/pipette-meniscus.jpg',
                  imageAlt:
                    'Two images appear side to side. Both are similar with a green liquid with a meniscus in a 10-mL pipette. The image to the left has the top of the meniscus reach the zero increment line, while the bottom of the meniscus reaches just below the zero increment line. There is a large red X across this left image. The image to the right has the bottom of the meniscus reach the zero increment line. ',
                  centered: true,
                },
              ],
            },
          ],
        },
      ],
    },
    tutorial: {
      name: 'Tutorial',
      link: 'tutorial',
      description: 'tbd',
      tabs: [
        {
          name: 'Graduated Cylinder',
          order: 1,
          steps: [
            {
              name: 'Graduated Cylinders',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Graduated Cylinders',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: "The most common graduated cylinders are 10 mL, 25 mL, 50 mL, 100 mL, 250 mL, 500 mL, 1 L, and 2 L. While most read bottom up, there are a few manufactured glassware that provide two sides to the graduated cylinder where one side is read bottom up and the other top down. Most graduated cylinders are used for measuring bottom up, as seen in every example of this section's tutorial.",
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Follow the explanation in the <b>Introduction</b>, to measure each liquid. Use the tips as a guide if you need help with your answer.',
                },
              ],
            },
            {
              name: '10 mL',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: '10 mL Graduated Cylinder',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Here we show two types of 10 mL graduated cylinders that you could see in the chemistry laboratory. One is more precise than the other.',
                },
                {
                  order: 3,
                  type: 'Magnified Image',
                  imageFileName: '/images/volume/tutorial/10mL-A.jpg',
                  imageAlt:
                    'A green liquid is filled to 3.90 mL of a 10-mL graduated cylinder.',
                  imageZoomFileName: '/images/volume/tutorial/10mL-A-zoom.jpg',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions: 'Measure the volume in milliliters of liquid A:',
                  validAnswers: ['3.90', '3.91', '3.92'],
                  unitOfMeasure: 'mL',
                  tip: 'The smallest subdivision line in this example is 0.1 mL, therefore be sure to provide an estimated digit that falls within +/- 0.05 mL (that is it includes a digit in the hundredth place). <p>The meniscus seems to fall right on the line or possibly just above the smallest subdivision.</p>',
                },
                {
                  order: 3,
                  type: 'Magnified Image',
                  imageFileName: '/images/volume/tutorial/10mL-B.jpg',
                  imageAlt:
                    'A blue liquid is filled to 5.6 mL of a 10 millimeter graduated cylinder.',
                  imageZoomFileName: '/images/volume/tutorial/10mL-B-zoom.jpg',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions: 'Measure the volume in milliliters of liquid B:',
                  validAnswers: ['5.6'],
                  unitOfMeasure: 'mL',
                  tip: 'This graduated cylinder has less precision than the previous because its subdivisions are every 0.2 mL and its estimated digit falls within the error range of +/- 0.1 mL. This means the measurement will reach to the tenth place.',
                },
              ],
            },
            {
              name: '25 mL',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: '25 mL Graduated Cylinder',
                },
                {
                  order: 2,
                  type: 'Magnified Image',
                  imageFileName: '/images/volume/tutorial/25mL-A.jpg',
                  imageAlt:
                    'A light orange liquid is filled to 25.0 millimeter of a 25 milliliter graduated cylinder.',
                  imageZoomFileName: '/images/volume/tutorial/25mL-A-zoom.jpg',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions: 'Measure the volume in milliliters of liquid :',
                  validAnswers: ['25.0', '24.9', '24.8'],
                  unitOfMeasure: 'mL',
                  tip: 'The smallest subdivision line is 0.5 mL. One can imagine 0.1 mL divisions in between the larger subdivision lines (between the 1 mL marks), which tells us the error range falls within +/- 0.1 mL The meniscus seems to fall right on the line, so that your estimated digit should be a zero. ',
                },
                {
                  order: 4,
                  type: 'Magnified Image',
                  imageFileName: '/images/volume/tutorial/25mL-B.jpg',
                  imageAlt:
                    'A light orange liquid is filled to 9.3 milliliter of a 25 milliliter graduated cylinder.',
                  imageZoomFileName: '/images/volume/tutorial/25mL-B-zoom.jpg',
                },
                {
                  order: 5,
                  type: 'Input',
                  directions: 'Measure the volume in milliliters of liquid B:',
                  validAnswers: ['9.2', '9.3', '9.4', '9.5'],
                  unitOfMeasure: 'mL',
                  tip: 'It is the same 25-mL graduated cylinder, the smallest subdivision line is 0.5 mL. One can imagine 0.1 mL in between the larger subdivision lines (between the 1 mL marks), which tells us the error range falls within +/- 0.1 mL. The meniscus is above 9.0 mL but below 9.5 mL.',
                },
              ],
            },
            {
              name: '50 mL',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: '50 mL Graduated Cylinder',
                },
                {
                  order: 2,
                  type: 'Magnified Image',
                  imageFileName: '/images/volume/tutorial/50mL-A.jpg',
                  imageAlt:
                    'A light blue liquid is filled to 23.3 milliliter of a 50 milliliter graduated cylinder.',
                  imageZoomFileName: '/images/volume/tutorial/50mL-A-zoom.jpg',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions: 'Measure the volume in milliliters of liquid :',
                  validAnswers: ['23.2', '23.3', '23.4', '23.5'],
                  unitOfMeasure: 'mL',
                  tip: 'The smallest subdivision line is 1 mL. One can estimate 0.1 mL imaginary lines in between the smallest subdivision lines. The meniscus seems to fall close to half-way in between the smallest subdivisions.',
                },
                {
                  order: 4,
                  type: 'Magnified Image',
                  imageFileName: '/images/volume/tutorial/50mL-B.jpg',
                  imageAlt:
                    'A light purple liquid is filled to 44.5 milliliter of a 50 milliliter graduated cylinder.',
                  imageZoomFileName: '/images/volume/tutorial/50mL-B-zoom.jpg',
                },
                {
                  order: 5,
                  type: 'Input',
                  directions: 'Measure the volume in milliliters of liquid B:',
                  validAnswers: ['44.2', '44.3', '44.5'],
                  unitOfMeasure: 'mL',
                  tip: 'The smallest subdivision line is 1 mL. One can estimate 0.1 mL imaginary lines in between the smallest subdivision lines. Here the meniscus is not very clear. This is a plastic graduated cylinder, so there is less of the accentuated concave shape. It is best to estimate at the lowest liquid point possible. ',
                },
              ],
            },
            {
              name: '100 mL',
              order: 4,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: '100 mL Graduated Cylinder',
                },
                {
                  order: 2,
                  type: 'Magnified Image',
                  imageFileName: '/images/volume/tutorial/100mL-A.jpg',
                  imageAlt:
                    'A light green liquid is filled to 90.0 milliliter of a 100 milliliter graduated cylinder.',
                  imageZoomFileName: '/images/volume/tutorial/100mL-A-zoom.jpg',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions: 'Measure the volume in milliliters of liquid A:',
                  validAnswers: ['90.0'],
                  unitOfMeasure: 'mL',
                  tip: 'The smallest subdivision line is 0.1 mL, while the error range falls within +/- 0.05 mL The meniscus seems to fall right on the line.',
                },
                {
                  order: 4,
                  type: 'Magnified Image',
                  imageFileName: '/images/volume/tutorial/100mL-B.jpg',
                  imageAlt:
                    'A light blue liquid is filled to 93.5 milliliter of a 100 milliliter graduated cylinder.',
                  imageZoomFileName: '/images/volume/tutorial/100mL-B-zoom.jpg',
                },
                {
                  order: 5,
                  type: 'Input',
                  directions: 'Measure the volume in milliliters of liquid B:',
                  validAnswers: ['93.4', '93.5'],
                  unitOfMeasure: 'mL',
                  tip: 'The smallest subdivision line is 0.1 mL. The meniscus falls certainly in between the smallest division, so be sure to measure within the error range of +/- 0.05 mL.',
                },
              ],
            },
            {
              name: '1 L',
              order: 5,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: '1 L Graduated Cylinder',
                },
                {
                  order: 2,
                  type: 'Magnified Image',
                  imageFileName: '/images/volume/tutorial/1L-A.jpg',
                  imageAlt:
                    'A light green liquid is filled to 250 milliliter of a 1 liter graduated cylinder.',
                  imageZoomFileName: '/images/volume/tutorial/1L-A-zoom.jpg',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions: 'Measure the volume in milliliters of liquid A:',
                  validAnswers: ['250', '2.50E2', '2.50E+2', '250.', '251'],
                  unitOfMeasure: 'mL',
                  tip: 'The smallest subdivision line is 10 mL, while the error range falls within +/- 5 mL. The meniscus seems to fall right on the line. Three significant figures are accepted.',
                },
                {
                  order: 4,
                  type: 'Magnified Image',
                  imageFileName: '/images/volume/tutorial/1L-B.jpg',
                  imageAlt:
                    'A light green liquid is filled to 173 milliliter of a 1 liter graduated cylinder.',
                  imageZoomFileName: '/images/volume/tutorial/1L-B-zoom.jpg',
                },
                {
                  order: 5,
                  type: 'Input',
                  directions: 'Measure the volume in milliliters of liquid B:',
                  validAnswers: ['171', '172', '173', '174', '175'],
                  unitOfMeasure: 'mL',
                  tip: 'The smallest subdivision line is 10 mL.. The meniscus seems to fall in between the smallest division, so be sure to measure within the error range of +/- 5 mL. Three significant figures are accepted.',
                },
              ],
            },
          ],
        },
        {
          name: 'Burette',
          order: 2,
          steps: [
            {
              name: 'Burette',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Burette',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The burette is an instrument that delivers liquid in small controllable amounts. Titration is a common technique that uses the burette. The burette is unique in that it is reads top down. When filled to the top, the burette will read 0.00 mL (an initial volume). Then liquid is dispensed to a certain amount (to a final volume). The change in volume is then calculated to determine the total volume that was delivered. &#916V = V<sub>final</sub> - V<sub>initial</sub>.',
                },
              ],
            },
            {
              name: 'Volume Difference',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Volume Difference',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The first step is to measure the volume of the liquid. Liquid will be controllably dispensed and then a second, the final, measurement will be taken. The change in volume is then calculated to determine the total volume that was delivered. &#916V = V<sub>final</sub> - V<sub>initial</sub>.',
                },
                {
                  order: 3,
                  type: 'Magnified Image',
                  imageFileName:
                    '/images/volume/tutorial/initial-burette-A.jpg',
                  imageAlt:
                    'A light green liquid is filled to 21.65 milliliter of a 50 milliliter burette.',
                  imageZoomFileName:
                    '/images/volume/tutorial/initial-burette-A-zoom.jpg',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions:
                    'Measure the <em>initial</em> volume in milliliters of liquid A: ',
                  validAnswers: [
                    '21.62',
                    '21.63',
                    '21.64',
                    '21.65',
                    '21.66',
                    '21.67',
                    '21.68',
                  ],
                  unitOfMeasure: 'mL',
                  tip: 'The most important thing to remember for the burette is to measure top down. Here, you will read 21.XX. The smallest subdivision line is 0.1 mL. The meniscus falls in between the smallest subdivisions and should fall within the error  +/- 0.05 mL.',
                },
                {
                  order: 1,
                  type: 'Text',
                  text: '&nbsp;',
                },
                {
                  order: 5,
                  type: 'Magnified Image',
                  imageFileName: '/images/volume/tutorial/final-burette-A.jpg',
                  imageAlt:
                    'A light green liquid is filled to 25.90 mL of a 50-mL burette.',
                  imageZoomFileName:
                    '/images/volume/tutorial/final-burette-A-zoom.jpg',
                },
                {
                  order: 6,
                  type: 'Input',
                  directions:
                    'Measure the <em>final</em> volume in milliliters of liquid A: ',
                  validAnswers: ['25.90', '25.89'],
                  unitOfMeasure: 'mL',
                  tip: 'The most important thing to remember for the burette is to measure top down. Here, you will read 25.XX. The smallest subdivision line is 0.1 mL and the error range falls within +/- 0.05 mL. The meniscus seems to fall right on the line.',
                },
                {
                  order: 7,
                  type: 'Input',
                  directions: 'Determine the &#916V after delivering liquid A:',
                  validAnswers: [
                    '4.21',
                    '4.22',
                    '4.23',
                    '4.24',
                    '4.25',
                    '4.26',
                    '4.27',
                    '4.28',
                    '4.29',
                  ],
                  unitOfMeasure: 'mL',
                  tip: '&#916V = V<sub>final</sub> - V<sub>initial</sub>. Your answer must be in the form X.XX mL, maintaining the significant figures rule for subtraction.',
                },
              ],
            },
          ],
        },
        {
          name: 'Pipette',
          order: 3,
          steps: [
            {
              name: 'Pipettes',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Pipettes',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Pipettes are used to deliver small amounts of volume. The most common pipettes are 1 mL, 5 mL, and 10 mL. All will require a drawing device such as the pipette bulb, to help pull and push the liquid up and down the pipette.',
                },
              ],
            },
            {
              name: 'Fixed Pipette',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Fixed Pipette',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'A fixed pipette will be filled to a volume of a dedicated amount listed on the pipette. The user must use a drawing device to pull liquid up the pipette to the drawn line at the neck of the pipitte. The pipette has an error range labeled on the glassware, which tells the user how many significant figures the measurement should have.',
                },
                {
                  order: 2,
                  type: 'Magnified Image',
                  imageFileName: '/images/volume/tutorial/pipette-A.jpg',
                  imageAlt:
                    'A green liquid is filled to the meniscus in a 10 milliliter fixed pipette.',
                  imageZoomFileName:
                    '/images/volume/tutorial/pipette-A-zoom.jpg',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions:
                    'Measure the volume of liquid A using a 10-mL volumetric pipette:',
                  validAnswers: ['10.00'],
                  unitOfMeasure: 'mL',
                  tip: 'Since this is a fixed volume, the volume will be 10 mL, but the precision needs to be determined. The uncertainty has been set by the manufacturer and can be seen at the very top of the photo where the error range is labeled as +/- 0.02 mL. This means the precision will be read to the hundredth place using zeros.',
                },
              ],
            },
            {
              name: 'Graduated Pipette',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Graduate Pipette',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Some graduated pipettes are bottom up and others top down. Here, like the burette, you will read two volumes, the initial and the final volume, in order to calculate &#916V = V<sub>final</sub> - V<sub>initial</sub>.  ',
                },
                {
                  order: 3,
                  type: 'Magnified Image',
                  imageFileName:
                    '/images/volume/tutorial/initial-pipette-B.jpg',
                  imageAlt:
                    'A light green liquid is filled to 0.87 milliliter of a 10 milliliter graduated pipette.',
                  imageZoomFileName:
                    '/images/volume/tutorial/initial-pipette-B-zoom.jpg',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions:
                    'Determine the <em>initial</em> volume in milliliters of liquid B: ',
                  validAnswers: ['0.85', '0.86', '0.87', '0.88'],
                  unitOfMeasure: 'mL',
                  tip: 'When you fill this pipette, you can see that it must be similar to the burette and read top down. The smallest subdivision line is 0.1 mL. The meniscus falls in between the smallest subdivisions and should fall within the error  +/- 0.05 mL.',
                },
                {
                  order: 5,
                  type: 'Magnified Image',
                  imageFileName: '/images/volume/tutorial/final-pipette-B.jpg',
                  imageAlt:
                    'A light green liquid is filled to 4.00 milliliter of a 10 milliliter graduated pipette.',
                  imageZoomFileName:
                    '/images/volume/tutorial/final-pipette-B-zoom.jpg',
                },
                {
                  order: 6,
                  type: 'Input',
                  directions:
                    'Determine the <em>final</em> volume in milliliters of liquid B:',
                  validAnswers: ['4.00'],
                  unitOfMeasure: 'mL',
                  tip: 'The meniscus lands right on the smallest subdivisions. Ensure that you measure to the hundredths place.',
                },
                {
                  order: 7,
                  type: 'Input',
                  directions:
                    'Determine the &#916V in milliliters after delivering liquid B:',
                  validAnswers: ['3.12', '3.14', '3.15'],
                  unitOfMeasure: 'mL',
                  tip: '&#916V = V<sub>final</sub> - V<sub>initial</sub>. Your answer must be in the form X.XX mL, maintaining the subtraction significant figures rule.',
                },
              ],
            },
          ],
        },
        {
          name: 'Volumetric Flask',
          order: 5,
          steps: [
            {
              name: 'Volumetric Flask',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Volumetric Flask',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: "Volumetric flasks come in a range of sizes, from tiny (a cute 1 mL) to huge (2 L). They all have in common the one calibration mark and the manufacturer's labeled uncertainty. Volumetric flasks are produced to be very precise. For example, the 5-mL volumetric flask has an uncertainty of +/- 0.003 mL. Therefore, the measurement should be written as with 4 significant figures to correspond with 5.000 mL",
                },
              ],
            },
            {
              name: 'The Calibration Mark',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'The Calibration Mark',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The calibration mark is located at the neck of the flask. Liquid must be properly filled to this mark in order for the flask to contain the specified volume that is labeled on its glassware.',
                },
                {
                  order: 3,
                  type: 'Images',
                  images: [
                    {
                      imageFileName: '/images/volume/tutorial/25mL-flask-A.jpg',
                      imageAlt:
                        'A light green liquid is filled near the calibration mark of a 25 milliliter volumetric flask. The bottom of the meniscus reach below the calibration mark. The instrument has been underfilled.',
                      imageTitle: 'A',
                    },
                    {
                      imageFileName:
                        '/images//volume/tutorial/25mL-flask-B.jpg',
                      imageAlt:
                        'A light green liquid is filled to the calibration mark of a 25 mL volumetric flask. The bottom of the meniscus reach only slightly below the calibration mark.',
                        imageTitle: 'B',
                    },
                    {
                      imageFileName: '/images/volume/tutorial/25mL-flask-C.jpg',
                      imageAlt:
                        'A light green liquid is filled to the calibration mark of a 25 milliliter volumetric flask. The bottom of the meniscus reaches exactly on the calibration mark. ',
                      imageTitle: 'C',
                    },
                    {
                      imageFileName:
                        '/images//volume/tutorial/25mL-flask-D.jpg',
                      imageAlt:
                        'A light green liquid is filled near the calibration mark of a 25 milliliter volumetric flask. The bottom of the meniscus reaches above the calibration mark. The instrument has been overfilled.',
                      imageTitle: 'D',
                    },
                  ],
                },
                {
                  order: 4,
                  type: 'Input',
                  directions:
                    'Select the image that best represents the correct filling of the 25 mL volumetric flask:',
                  validAnswers: ['C'],
                  tip: 'Ensure that the bottom of the meniscus is on the increment line, not above and not below.<p>While B is nearly on the calibration mark, there is a VERY tiny bubble under it, so it is not considered as a correct answer here.',
                },
              ],
            },
          ],
        },
        {
          name: 'Beaker',
          order: 6,
          steps: [
            {
              name: 'NOSTEP',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Estimating Volume for a Beaker',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The volumetric flask has subdivisions, but they are wide with lines, such as every 10 mL. Some manufacturers label the uncertainty for their glassware. Sometimes the given uncertainty is in percent form. Here, ± 5% is written on the beaker. The measured volume can be recorded as 150 mL ± 5%, or not be in percent form as 150 ± 7.5 mL.',
                },
                {
                  order: 3,
                  type: 'Magnified Image',
                  imageFileName: '/images/volume/tutorial/beaker-A.jpg',
                  imageAlt:
                    'A light green liquid is filled to 90 milliliter of a 150 milliliter beaker.',
                  imageZoomFileName:
                    '/images/volume/tutorial/beaker-A-zoom.jpg',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions: 'Measure the volume in mililiters of liquid A:',
                  validAnswers: ['90', '90.', '9.0E+1', '9.0E1', '89', '88'],
                  unitOfMeasure: 'mL',
                  tip: 'Being that the error is +/-5% = +/- 7.5 mL, there is a wider range of acceptable measurements, however the estimated digits falls on the ones place and either on or just below the subdivision line.',
                },
              ],
            },
          ],
        },
        {
          name: 'Flask',
          order: 7,
          steps: [
            {
              name: 'NOSTEP',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Estimating Volume for a Flask',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The volumetric flask has subdivisions, but they are wide like a beaker. This is another example of glassware that not selected for its precision. Here, ± 5% is written on the flask. The measured volume can be recorded as 125 mL ± 5%, or not be in percent form as 125 ± 6.25 mL.',
                },
                {
                  order: 3,
                  type: 'Magnified Image',
                  imageFileName: '/images/volume/tutorial/flask-A.jpg',
                  imageAlt:
                    'A light green liquid is filled to 90 mL of a 125-mL flask.',
                  imageZoomFileName: '/images/volume/tutorial/flask-A-zoom.jpg',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions: 'Measure the volume in mililiters of liquid A:',
                  validAnswers: [
                    '95',
                    '94',
                    '93',
                    '92',
                    '91',
                    '90.',
                    '90',
                    '9.0E+1',
                    '9.0E1',
                    '89',
                    '88',
                    '87',
                    '86',
                    '85',
                  ],
                  unitOfMeasure: 'mL',
                  tip: 'Being that the error is +/- 5%, there is a wider range of acceptable measurements, however the estimated digits falls on the ones place.',
                },
              ],
            },
          ],
        },
      ],
    },
    /*          
    exercises: {
      name: 'Exercises',
      link: 'exercises',
      problemSets: [
        {
          name: 'Graduated Cylinder',
          instructions:
            'Measure the volume of a liquid for different sized graduated cylinders. Every measurement must include significant figures in order to be correct. You must get five correct.',
          correctAnswersRequired: 5,
          skipsAllowed: 0,
          problems: [
            {
              inputLabel: 'Answer',
              problem: 'Measure the volume of liquid A: ',
              validAnswers: ['500', '5.00E+2', '5.00E2'],
              tip: 'The smallest subdivision line is 10 mL. The meniscus lands on the smallest division, and the error range is +/- 5 mL. Three significant figures are accepted. ',
            },
            {
              inputLabel: 'Answer',
              problem: 'Measure the volume of liquid B: ',
              validAnswers: ['[8.4'],
              tip: 'This graduated cylinder has subdivisions every 0.2 mL. Its estimated digit falls within the error range of +/- 0.1 mL.',
            },
            {
              inputLabel: 'Answer',
              problem: 'Measure the volume of liquid C: ',
              validAnswers: ['77.0, 77.1, 77.2'],
              tip: 'This graduated cylinder has subdivisions every 1 mL. Its estimated digit falls within the error range of +/- 0.1 mL.',
            },
            {
              inputLabel: 'Answer',
              problem: 'Measure the volume of liquid D: ',
              validAnswers: ['7.20, 7.19, 7.18, 7.17, 7.16'],
              tip: 'This graduated cylinder has subdivisions every 0.1 mL. Its estimated digit falls within the error range of +/- 0.05 mL.',
            },
            {
              inputLabel: 'Answer',
              problem: 'Measure the volume of liquid E: ',
              validAnswers: ['17.8, 17.9, 18.0'],
              tip: 'This graduated cylinder has subdivisions every 1 mL. Its estimated digit falls within the error range of +/- 0.5 mL.',
            },
          ],
        },
        {
          name: 'Burettes and Pipettes',
          instructions:
            'Measure the volume of a liquid as presented. Every measurement must include significant figures in order to be correct. You must get five correct.',
          correctAnswersRequired: 5,
          skipsAllowed: 0,
          problems: [
            {
              inputLabel: 'Answer',
              problem: 'Measure an <i>initial</i> volume of liquid A: ',
              validAnswers: ['17.80, 17.81, 17.82, 17.83'],
              tip: 'Ensure you are reading the burette top down. This graduated cylinder has subdivisions every 0.1 mL. Its estimated digit falls within the error range of +/- 0.05 mL.',
            },
            {
              inputLabel: 'Answer',
              problem: 'Measure a <i>final</i> volume of liquid B: ',
              validAnswers: ['32.00, 32.01'],
              tip: 'Ensure you are reading the burette top down. This graduated cylinder has subdivisions every 0.1 mL. Its estimated digit falls within the error range of +/- 0.05 mL.',
            },
            {
              inputLabel: 'Answer',
              problem: 'Measure a <i>final</i> volume of liquid C: ',
              validAnswers: ['9.00'],
              tip: 'Clearly, this pipette does not hold 9 mL of liquid. Instead, we are measuring the volume after dispensing liquid. Similar to the burette, it is read top down. The smallest subdivision line is 0.1 mL. The meniscus falls on the smallest subdivisions and its estimated digit falls within +/- 0.05 mL.',
            },
            {
              inputLabel: 'Answer',
              problem: 'Measure an <i>initial</i> volume of liquid D: ',
              validAnswers: ['0, 0.00'],
              tip: 'As seen toward the top, this is a 5-mL graduated pipette. Clearly, this pipette does not hold 0 mL of liquid. Instead, we are measuring an initial volume, which will be ready to dispense liquid. Similar to the burette, it is read top down. The smallest subdivision line is 0.1 mL. The meniscus falls on the smallest subdivisions and its estimated digit falls within +/- 0.05 mL.',
            },
            {
              inputLabel: 'Answer',
              problem: 'Measure an <i>initial</i> volume of liquid E: ',
              validAnswers: ['0.40'],
              tip: 'This is a 2-mL graduated pipette. We measure an initial volume, which will be ready to dispense liquid. Similar to the burette, it is read top down. The smallest subdivision line is 0.1 mL. The meniscus falls on the smallest subdivisions and its estimated digit falls within +/- 0.05 mL.',
            },
          ],
        },
        {
          name: 'Beakers and Flasks',
          instructions:
            'Measure the volume of a liquid as presented. Every measurement must include significant figures in order to be correct. You must get five correct.',
          correctAnswersRequired: 5,
          skipsAllowed: 0,
          problems: [
            {
              inputLabel: 'Answer',
              problem: 'Measure the volume of liquid A in the beaker: ',
              validAnswers: ['220, 225, 230'],
              tip: 'Being that the error is +/- 5% = +/- 20 mL, there is a wider range of acceptable measurements, however the estimated digit falls on the tens place.',
            },
            {
              inputLabel: 'Answer',
              problem:
                'Measure the volume of liquid B in the volumetric flask:',
              validAnswers: ['25.0'],
              tip: 'The 25-mL volumetric flask has an uncertainty of +/- 0.3 mL. Therefore, the measurement should be written with 3 significant figures.',
            },
            {
              inputLabel: 'Answer',
              problem: 'Measure the volume of liquid C in the beaker: ',
              validAnswers: ['105, 106, 107, 108'],
              tip: 'Being that the error is +/- 7.5 mL, there is a wider range of acceptable measurements, however the estimated digits falls on the ones place.',
            },
            {
              inputLabel: 'Answer',
              problem: 'Measure the volume of liquid D in the flask: ',
              validAnswers: ['42, 43'],
              tip: 'Being that the error is +/- 5%, there is a wider range of acceptable measurements, however the estimated digit falls on the ones place.',
            },
            {
              inputLabel: 'Answer',
              problem:
                'Measure the volume of liquid E in the volumetric flask: ',
              validAnswers: ['50.00'],
              tip: 'The 50-mL volumetric flask has an uncertainty of +/- 0.10 mL. Therefore, the measurement should be written with 4 significant figures.',
            },
          ],
        },
      ],
    },
*/
  },
  rank: 3,
};

export default liquidVolumeMeasurements;
