const lineSpectre = {
    status: 'Active',
    name: 'Light and Spectra',
    imageFileName: 'images/neon-spectra-icon.jpg',
    imageAlt:
      'About 20 bright vertical lines spaced out randomly representing the line spectra of neon. The lines range in color from yellow to fushia to pink',
    description: 'Review of the emission line spectra of the elements.',
    tags: ['Pre-Lab', 'Dry Lab', 'Complete Lab', 'Experiment', 'Chem-101A'],
    page: 'light-spectra',
    content: {
      overview: {
        name: 'Overview',
        link: 'overview',
        tabs: [
          {
            name: 'NOTAB',
            order: 1,
            steps: [
              {
                name: 'NOSTEP',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Text',
                    text: 'In this activity, you will consider several aspects of light, including:<li>The visible spectrum of colors (red to violet)</li><li>Bright line spectra as emitted by an excited gas</li><li>The relationship between color, wavelength, frequency and energy.</li>',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Read through the <strong><em>Introduction</em></strong> to review about light energy and the equations needed to calculate the energy of a photon and its frequency. Next, read through the <strong><em>Experiment</em></strong> to familiarize yourself with the dry lab activity you will do in the lab. You will be required to prepare your lab notebook with sketches and tables. The charts will be used to draw your observed bright line spectra and the tables will be used to write in your answers for the multiple sets of calculations of wavelength, frequency, and energy of photons.',
                  },
                ],
              },
            ],
          },
        ],
      },
      introduction: {
        name: 'Introduction',
        link: 'introduction',
        tabs: [
          {
            name: 'Line Spectrum',
            order: 1,
            steps: [
              {
                name: 'NOSTEP',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Text',
                    text: 'Light is a form of energy called electromagnetic radiation. It has wavelength and frequency. Wavelength, &lambda (lambda), is the distance between adjacent wave crests. Frequency, 	&nu (nu), tells how many waves pass by a point in a second. For example, violet light, with a wavelength of 400 nm, has a frequency of 7.5 x 10<sup>14</sup> per second; visible light has wavelengths in the 400 nm to 700 nm range. (1 nm = 10<sup>-9</sup>m).',
                  },
                  {
                    order: 2,
                    type: 'Image',
                    imageFileName: '/images/spectra/waves.jpg',
                    imageAlt: 'Two representations of light waves are shown. The wave on the left shows a frequency of 2 waves per time line. From the crest to the other crest is a line marking the distance in lambda. The wave on the right shows a frequency of 3 waves per time line. From the first crest to the second crest is a line marking the distance in lambda.',
                    centered: true,
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'The energy of electromagnetic radiation varies directly with the frequency, and inversely with the wavelength. Thus, violet light is of higher energy than red light, which has wavelengths in the 650 nm range. White light, such as that from an ordinary incandescent light bulb, is a mixture of wavelengths in the visible range. When white light strikes a prism or diffraction grating, the light is dispersed into a <b><i>continuous spectrum</b></i> of visible colors. A <b><i>non-continuous spectrum</b></i> occurs when an electric current pass through a gaseous element in a gas discharge tube or when metal ions are put into a ﬂame. This type of spectrum, called a bright line spectrum, is not continuous but instead contains only certain colors at particular wavelengths. A bright line spectrum is due to transitions of electrons between energy levels in atoms or ions. When an atom or ion absorbs energy, such as from a ﬂame or electrical source, it absorbs only certain discrete amounts of energy. These amounts are the difference in energy between lower and higher electron energy levels. When the electrons return to lower energy levels, energy is emitted in the form of a photon. Since atoms have a number of energy levels available, there are a number of different energies that can be absorbed and released. For example, the visible portion of the spectrum for mercury contains the following colors:',
                  },
                  {
                    order: 4,
                    type: 'Information Table',
                    headers: ['Color', '&lambda '],
                    rows: [
                      { id: 1, cells: ['violet', '405 nm'] },
                      { id: 2, cells: ['blue', '436 nm'] },
                      { id: 3, cells: ['green', '492 nm'] },
                      { id: 4, cells: ['yellow', '579 nm'] },
                      { id: 5, cells: ['orange', '623 nm'] },
                      { id: 6, cells: ['red', '689 nm'] },
                    ],
                    centered: true,
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'The energies of the emitted wavelengths can be correlated with the energies of electron transitions from higher to lower energy levels. During an electronic transition, the greater the difference between the energy levels, the larger is the energy released, and the shorter is the wavelength of the photon emitted. In the energy level diagram of a generic atom shown below, the left-hand arrow represents an electron releasing energy as it drops from the 6th to the 2nd energy level. ',
                  },
                  {
                    order: 5,
                    type: 'Image',
                    imageFileName: '/images/spectra/transitions.jpg',
                    imageAlt: 'Five horizontal lines each labeled with n = a number. The bottom line is labled n = 2, and as you progressively move up, the labels are: n = 3, n = 4, n = 5 and n = 6. There are four arrows and each point to the bottom level, n = 2. Each arrow represents a transition. The arrow on the far left transitions from n = 6 to n = 2. The next arrow to its right transitions from n = 5 to n = 2.  The next arrow to its right transitions from n = 4 to n = 2. Last, the next arrow to its right transitions from n = 3 to n = 2. ',
                    centered: true,
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: 'The energy is released in the form of a photon. This photon will have more energy (and a shorter wavelength) than the photon given off in the transition from n = 3 to n = 2. In terms of energy,',
                  },
                  {
                    order: 7,
                    type: 'Image',
                    imageFileName: '/images/spectra/transition-energies.jpg',
                    imageAlt: 'The energy associated with the transition from energy level 6 to energy level 2 is larger than energy level 3 to energy level 2 ',
                    centered: true,
                  },
                  {
                    order: 8,
                    type: 'Text',
                    text: 'The larger energy would be in the violet-blue portion of the spectrum. The smaller energy would be in the red-orange portion of the spectrum.',
                  },
                ],
              },
            ],
          },
          {
            name: 'Energy of a Photon',
            order: 2,
             steps: [
              {
                name: 'NOSTEP',
                order: 1,
                contentBlocks: [
                  {
                      order: 1,
                      type: 'Text',
                      text: 'The relationship between wavelength and frequency is:',
                    },
                    {
                      order: 2,
                      type: 'Image',
                      imageFileName: '/images/spectra/light-equation.jpg',
                      imageAlt: 'Frequency times wavelength is equal to the speed of light.',
                      centered: true,
                    },
                    {
                      order: 3,
                      type: 'Text',
                      text: 'The speed of light, <i>c</i>, equals 3.00 x 10<sup>8</sup> m/s. As an example, the violet line with λ = 405 nm from the mercury spectrum would have a frequency calculated as follows:',
                    },
                    {
                      order: 4,
                      type: 'Image',
                      imageFileName: '/images/spectra/frequency.jpg',
                      imageAlt: 'Frequency is equal to the speed of light divided by wavelength',
                      centered: true,
                    },
                    {
                      order: 5,
                      type: 'Text',
                      text: 'or',
                    },
                    {
                      order: 6,
                      type: 'Image',
                      imageFileName: '/images/spectra/violet-light-frequency.jpg',
                      imageAlt: 'Frequency is equal 3.00 x 10<sup>8</sup> meter per second divided by 405 x 10<sup>-9</sup> meter.',
                      centered: true,
                    },
                    {
                      order: 7,
                      type: 'Text',
                      text: '<p>Notice that λ is written as 405 x 10<sup>-9</sup> m, rather than 405 nm.</p>',
                    },
                    {
                      order: 8,
                      type: 'Text',
                      text: 'To ﬁnd the energy of a photon, the Planck relationship is used:',
                    },
                    {
                      order: 9,
                      type: 'Image',
                      imageFileName: '/images/spectra/energy-photon.jpg',
                      imageAlt: 'The energy of a photon is equation to Planck\'s constant times the frequency of light.',
                      centered: true,
                    },
                    {
                      order: 10,
                      type: 'Text',
                      text: 'where h, Plank\'s constant, = 6.63 x 10<sup>-34</sup> J·s.',
                    },
                    {
                      order: 11,
                      type: 'Text',
                      text: 'To ﬁnd the energy of the violet line, we would calculate:',
                    },
                    {
                      order: 12,
                      type: 'Image',
                      imageFileName: '/images/spectra/voilet-light-energy.jpg',
                      imageAlt: 'Energy is equal to 6.63 x 10<sup>-34</sup> Joules times seconds times 7.41 x 10<sup>-14</sup> per second and equal to 4.91 x 10<sup>-19</sup> Joules.',
                      centered: true,
                    },
                    {
                      order: 13,
                      type: 'Text',
                      text: 'This is the energy emitted when an electron in one particular energy level in a mercury atom drops down to a lower particular energy level. 4.91 x 10<sup>-19</sup> J might seem extraordinarily small, but in terms of a mole of atoms, there would be 296 kJ per mole. (Can you verify this?).',
                    },
                    {
                      order: 13,
                      type: 'Text',
                      text: 'This is a good time to make a general observation about quantities. You will hear people say, and perhaps you say, “that seems like an awfully small amount”. The response to this is, “compared to what?”. A mercury atom, one of the really heavy atoms, weighs about 3 x 10<sup>-22</sup>g. Compared to your weight, a mercury atom weighs “nothing”. Compared to a neon atom, the mercury atom weighs 10 times as much. A thousand percent heavier! The moral of the story is: Consider everything in perspective.',
                    },
                  ],
              },
            ],
          },
        ],
      },
      experiment: {
        name: 'Experiment',
        link: 'experiment',
        tabs: [
          {
            name: 'Diffraction Grating',
            order: 1,
            steps: [
              {
                name: 'Diffraction Grating',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Text',
                    text: 'Diffraction gratings split a light source into its component wavelengths. Here is a simple version that is the size of a 2" x 2" diffraction slide.',
                  },
                  {
                    order: 2,
                    type: 'Image',
                    imageFileName: '/images/spectra/grating.jpg',
                    imageAlt: 'A diffraction slide, 2 inches by 2 inches. Written on the top of the slide is the name of the comany "Edmund Scientific Co, Barrington, NJ 08007. 1-609-573-6250". On the bottom of the slide, it reads"500 Lines/mm Linear Diffraction Grating"',
                    centered: true,
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'To use the grating, hold it so the “MADE IN U.S.A.” is on the bottom facing you. The right-side cardboard frame should be between your dominant eye and the light source, blocking the light source from your eye. The plastic should be to the left of your eye. You will see the spectrum by glancing into the plastic.',
                  },
                  {
                    order: 4,
                    type: 'Image',
                    imageFileName: '/images/spectra/viewer.jpg',
                    imageAlt: 'A drawing of a male head looking through a diffraction slide grating. A dotted line extends from the eye through the grating to a vertical gas light source The artist name is provided, Julie Enriquez.',
                    centered: true,
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'You will use this grating to view the continuous spectra of a light bulb and of three elements emitting light from their gas discharge tubes.',
                  },
                ],
              },
              {
                name: 'Continuous Spectrum',
                order: 2,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Continuous Spectrum',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'When a clear light bulb is connected to a variable power transformer (a rheostat), which is turned on to its highest setting, a bright light shines.',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/spectra/light-bulb.jpg',
                    imageAlt: 'A light bulb is brightly shining. It is resting on a stand and pointing up.',
                    centered: true,
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'If you hold the diffraction grating in front of the light bulb and view the grating from the side (as the camera was done to take the picture), a full continuous light spectrum is visible.',
                  },
                  {
                    order: 5,
                    type: 'Image',
                    imageFileName: '/images/spectra/continuous-spectrum.jpg',
                    imageAlt: 'A light bulb is brightly shining and a diffraction grating is shown to its right. The grating is being used to view a continuous light spectra.',
                    centered: true,
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: 'If you turn off the room lights, and observe the spectrum as the rheostat is turned down, then back up. You should notice how the blues and greens diminish in intensity more than the reds diminish as the temperature of the ﬁlament decreases, then reappear as the temperature increases.',
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: 'The theory available 100 years ago predicted that as the temperature increased, the spectrum would completely shift over into the ultraviolet, with no reds or yellows or greens remaining. This inadequate theory had physicists mumbling about the <strong>ultraviolet catastrophe</strong>. Theoretical physicists looked to develop theories consistent with experimental data. Max Planck developed a new theory which has held up for close to a century. The equation: E = h&nu; is part of the theory.',
                  },
                ],
              },
              {
                name: 'Line Spectra',
                order: 3,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Line Spectra',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'To view a line spectra of an element, one needs a low-pressure gas discharge tube set into high voltage apparatus, seen here as a black box that the tube sets in.',
                  },
                  {
                    order: 3,
                    type: 'Images',
                    images: [
                      {
                        imageFileName: '/images/spectra/gas-discharge-tube.jpg',
                        imageAlt: 'A vertical glass tube has electrodes at the top and bottom.',
                        imageTitle: 'Gas discharge tube',
                      },
                      {
                        imageFileName: '/images/spectra/black-box.jpg',
                        imageAlt: 'A black rectangular box has a gas discharge tube placed in the middle. The apparatus is not on, so the tube appears unlit',
                        imageTitle: 'High voltage source',
                      },
                    ],
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'When the high voltage source is turned on, the low pressure gas will produce a characteristic line spectrum for that element.',
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'Below are the three  elements you will view in the lab: barium, neon and mercury.',
                  },
                  {
                    order: 6,
                    type: 'Images',
                    images: [
                      {
                        imageFileName: '/images/spectra/barium.jpg',
                        imageAlt: 'A fume hood with a lit barium gas discharge tube turned on. The tube emits a light blue color. A sign next to it reads "Ba"',
                      },
                      {
                        imageFileName: '/images/spectra/neon.jpg',
                        imageAlt: 'A fume hood with a lit neon gas discharge tube turned on. The tube emits a red color. A sign next to it reads "Neon" ',
                      },
                      {
                        imageFileName: '/images/spectra/mercury.jpg',
                        imageAlt: 'A fume hood with a lit mercury gas discharge tube turned on. The tube emits a light turquoise color. A sign next to it reads "Hg".',
                      },
                    ],
                  },
                  {
                    order: 7,
                    type: 'Text',
                    text: 'With the lights out in the lab, you wil observe the spectra of all three elements in their respective fume hoods.',
                  },
                  {
                    order: 8,
                    type: 'Text',
                    text: 'Prepare for lab by drawing this rectangular strip <strong> three times in your lab notebook</strong>, to record the unique spectra lines that you will observe for each element. You will be drawing the location of the spectral lines above the labeled colors.',
                  },
                  {
                    order: 9,
                    type: 'Image',
                    imageFileName: '/images/spectra/chart.jpg',
                    imageAlt: 'A long horizontal rectangle. Below it spread evenly from left to right are the colors of the rainbow: violet, blue, green, yellow and red.',
                    centered: true,
                  },
                  {
                    order: 10,
                    type: 'Text',
                    text: 'The unique spectrum given by each element provides a simple means of analysis for elements. After exciting a substance with a spark or in a ﬂame, the position of the spectral lines produced gives the identity of the elements in the substance, and the intensity of the lines gives the quantity of the elements. Of course, instruments that do this have data bases built in that allow for the experimental data to be compared with previously compiled data.',
                  },
                ],
              },
              {
                name: 'Mercury Spectrum',
                order: 3,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Text',
                    text: 'The spectrum that you will you observe in the lab is a low resolution spectrum. On the wall in the lab, we have a spectral chart for mercury of higher resolution spectra. You will use this chart to sketch the spectral lines of mercury roughly to scale in the rectangular strip below. Note the wavelength values are in units of nanometers. Prepare for lab by drawing one rectangular strip with the wavelength values below it.',
                  },
                  {
                    order: 2,
                    type: 'Image',
                    imageFileName: '/images/spectra/mercury-chart.jpg',
                    imageAlt: 'A long horizontal rectangle. Below it spread evenly from left to right are the numbers: 750, 700, 650, 600, 550, 500, 450 and 400.',
                    centered: true,
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'Take note, when in the lab, of the three lines shown in the yellow region of the high resolution mercury spectra. Measure the wavelength values of these three lines. Prepare your notebook for recording the three wavelength values, and then you will calculate the frequency and energy of each of these three lines. Use this template for your notebook:',
                  },
                  {
                    order: 4,
                    type: 'Image',
                    imageFileName: '/images/spectra/calculations-triage.jpg',
                    imageAlt: 'Three columns with four rows. The top row left to right are labeled as lambda (nm), nu (Hz) and E (J). The bottom three rows are empty and ready for students to write their data.',
                    centered: true,
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'Take note that in the mercury spectrum, the bright lines have different widths. This is typical of spectral lines, and spectroscopists rank lines according to their appearance and abundance. Hence the designations s, p, d, and f for electron orbitals (from sharp, principal, diffuse and fundamental.',
                  },
                ],
              },
            ],
          },
          {
            name: 'Hydrogen Spectrum',
            order: 2,
            steps: [
              {
                name: 'Hydrogen Spectrum',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Hydrogen Spectrum',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'The hydrogen atom is the simplest atom and a detailed study of its spectrum leads to valuable insights. Shown in the figure below are the energy levels in the hydrogen atom as calculated from the Schrödinger equation. The numbers on the left give the energy of each level in joules. The number on the right gives the principal quantum number, n, of each level.',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/spectra/energy-levels.jpg',
                    imageAlt: 'A figure showing "the energy levels and some possible transitions for the hydrogen atom". A total of nine horizontal lines spread out closer together toward the top and lengthen out toward the bottom. The left side fo the figure is labeled "Energy (10^-19 Joules) and each line has the energies listed next to it. From bottom to top, the energies are -21.8, -5.45, -2.42, -1.36, -0.872, -0.606, -0.445, -0.341 and 0. The right side has another set of labels, "n value". From bottom to top, the values are listed from n = 1 to n = 8 and the very top n = infinity (13 or 14). Three sets of 4 arrows start at high energy levels and point to a specific lower energy levels. The first set of arrows is labeled as the "Lyman Ultraviolet". Each arrow is labeled as follows with the arrow following the transition, 5 to 1, 4 to 1, 3 to 1 and 2 to 1.The second set of arrows is labeled as the "Balmer Visible". Each arrow is labeled as follows with the arrow following the transition, 6 to 2, 5 to 2, 4 to 2 and 3 to 2. The third set of arrows is labeled as the "Paschen Infrared". Each arrow is labeled as follows with the arrow following the transition, 7 to 3, 6 to 3, 5 to 3 and 4 to 3. In the lower right of the figure, it reads "Each arrow represents an energy release, delta E, as an electron goes from a higher energy level to a lower one. The energy is given off as photons. The photons are in the part of the spetrum indiated above the arrows."',
                    centered: true,
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Shown in the next figure is a black and white representation of the experimentally observed spectrum of the hydrogen atom. To the far left is IR (infrared), further to the right is visible and to the furtherest right is UV (ultraviolet) wavelengths of light. The wavelength of each line in nanometers is listed below the line. This figure, which is experimental, can be calculated from the above, which is theoretical.',
                  },
                  {
                    order: 5,
                    type: 'Image',
                    imageFileName: '/images/spectra/hydrogen-spectrum.jpg',
                    imageAlt: 'The figure represents the "Hydrogen Atom Spectrum". A horizontal spectra is shown where wavelengths numbers are listed below specific vertical line locations. The bottom x-axis is labeled as lambda in nanometers. The wavenlengths from left to right are: 4,052, 1,876, 1,282, 1,094, 1,005, 955, 656, 486, 434, 410, 397, 390, 122, 102, 97.3. The spacing of the lines are proportional to the numbers listed. Above the lines are sections that correspond to the type of wavelength classification. 4,052 associates with Far IR, between 1,094 and 1,282 associates with IR. Between 410 and 656 associates with Visible. 97.3 to 122 associates with UV.',
                    centered: true,
                  },
                ],
              },
              {
                name: 'Calculations',
                order: 2,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Hydrogen Spectrum Calculations',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'According to spectral theory, each line in the spectrum comes as an electron drops from a higher to a lower energy level. In the figure, the various vertical arrows show a number of such possible transitions. The names along the top are the names of the scientists who ﬁrst characterized the spectrum corresponding to these transitions.',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/spectra/energy-levels.jpg',
                    imageAlt: 'A figure showing "the energy levels and some possible transitions for the hydrogen atom". A total of nine horizontal lines spread out closer together toward the top and lengthen out toward the bottom. The left side of the figure is labeled "Energy (10^-19 Joules) and each line has the energies listed next to it. From bottom to top, the energies are -21.8, -5.45, -2.42, -1.36, -0.872, -0.606, -0.445, -0.341 and 0. The right side has another set of labels, "n value". From bottom to top, the values are listed from n = 1 to n = 8 and the very top n = infinity (13 or 14). Three sets of 4 arrows start at high energy levels and point to a specific lower energy levels. The first set of arrows is labeled as the "Lyman Ultraviolet". Each arrow is labeled as follows with the arrow following the transition, 5 to 1, 4 to 1, 3 to 1 and 2 to 1.The second set of arrows is labeled as the "Balmer Visible". Each arrow is labeled as follows with the arrow following the transition, 6 to 2, 5 to 2, 4 to 2 and 3 to 2. The third set of arrows is labeled as the "Paschen Infrared". Each arrow is labeled as follows with the arrow following the transition, 7 to 3, 6 to 3, 5 to 3 and 4 to 3. In the lower right of the figure, it reads "Each arrow represents an energy release, delta E, as an electron goes from a higher energy level to a lower one. The energy is given off as photons. The photons are in the part of the spetrum indiated above the arrows."',
                    centered: true,
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'The energy of a transition, for example, the one indicated by the left hand vertical arrow can be calculated by subtracting the energy of the initial state from the energy of the ﬁnal state (energy values are found on the left of the figure).',
                  },
                  {
                    order: 5,
                    type: 'Image',
                    imageFileName: '/images/spectra/energy-difference.jpg',
                    imageAlt: 'Delta E is equal to final energy minus initial energy. Below that, equal to (-21.8 x 10^-19 J) minus (-0.872 x 10^-19 J). Below that, equal to (-20.9 x 10^-19 J).',
                    centered: true,
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: 'The negative value indicates energy released. This ∆E is for n<sub>5</sub> → n<sub>1</sub> transition (see the right side of Figure for n values).',
                  },
                  {
                    order: 7,
                    type: 'Text',
                    text: 'Each line in the hydrogen spectrum has an energy, which can be calculated using the Planck relationship. The far right hand line has λ = 97.3 nm, or 97.3&times;10<sup>-9</sup> m.',
                  },
                  {
                    order: 7,
                    type: 'Text',
                    text: 'To calculate the energy, ﬁrst ﬁnd the ν of the line:',
                  },
                  {
                    order: 8,
                    type: 'Image',
                    imageFileName: '/images/spectra/UV-frequency.jpg',
                    imageAlt: 'The greek symbol, nu, is equal to 3.00 x 10^8 m per second divided by 97.3 x 10^-9 m is equal to 3.08 x 10^15 per second.',
                    centered: true,
                  },
                  {
                    order: 9,
                    type: 'Text',
                    text: 'Then',
                  },
                  {
                    order: 10,
                    type: 'Image',
                    imageFileName: '/images/spectra/UV-energy.jpg',
                    imageAlt: 'E is equal to (6.63 x 10^-24 Joule times seconds) times (3.08 x 10^15 per second). Below that, equal to 2.04 x 10^-18 Joule.',
                    centered: true,
                  },
                  {
                    order: 11,
                    type: 'Text',
                    text: 'To give consistency to the appearance of al the energies that will be calculated in this exercise, all energies will be shown with 10<sup>-19</sup> as the exponent. Then the energy of the 97.3 nm line just calculated is 20.4&times;10<sup>-19</sup> J. This is very close to the absolute value of the ∆E for the n<sub>5</sub> → n<sub>1</sub> transition calculated further above, which was 20.9&times;10<sup>-19</sup> J.',
                  },
                  {
                    order: 11,
                    type: 'Text',
                    text: 'If we consider the n<sub>4</sub> → n<sub>1</sub> transition, represented by the second from the left vertical arrow, we can learn of a more exact match to the energy difference associated with the 97.3 nm line.',
                  },
                  {
                    order: 12,
                    type: 'Image',
                    imageFileName: '/images/spectra/n4-n1.jpg',
                    imageAlt: 'Delta E is equal to (-21.8 x 10^-19 J) minus (-1.36 x 10^-19 J). Below that, equal to -20.4 x 10^-19 J.',
                    centered: true,
                  },
                  {
                    order: 13,
                    type: 'Text',
                    text: 'Take the absolute value , and this is exactly the same value as the Planck calculation for the λ = 97.3 nm. So we can conclude that the spectral line at 97.3 nm is caused by an electron dropping from n = 4 to n = 1 in the hydrogen atom.',
                  },
                  {
                    order: 14,
                    type: 'Text',
                    text: '<em>Your understanding of the calculations and discussion on this page are crucial for the completion of the calculation table on the next page.</em>',
                  },
                ],
              },
              {
                name: 'Calculation Table',
                order: 3,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Image',
                    imageFileName: '/images/spectra/hydrogen-spectrum.jpg',
                    imageAlt: 'The figure represents the "Hydrogen Atom Spectrum". A horizontal spectra is shown where wavelengths numbers are listed below specific vertical line locations. The bottom x-axis is labeled as lambda in nanometers. The wavenlengths from left to right are: 4,052, 1,876, 1,282, 1,094, 1,005, 955, 656, 486, 434, 410, 397, 390, 122, 102, 97.3. The spacing of the lines are proportional to the numbers listed. Above the lines are sections that correspond to the type of wavelength classification. 4,052 associates with Far IR, between 1,094 and 1,282 associates with IR. Between 410 and 656 associates with Visible. 97.3 to 122 associates with UV.',
                    centered: true,
                  },
                  {
                    order: 2,
                    type: 'Header',
                    text: 'Calculation Table',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'The calculations just performed in the previous page are summarized in the ﬁrst line of the table below. Continuing with the next wavelength from the hydrogen spectrum (still in the UV section), complete the ﬁrst two columns of the table below using the equations that calculate frequency and energy of a photon. Complete this table in your lab notebook. Show one set of example calculations for one wavelength of light. The third column of the table will be completed on the next page.',
                  },
                  {
                    order: 4,
                    type: 'Image',
                    imageFileName: '/images/spectra/calculation-table.jpg',
                    imageAlt: 'A table with the header labeled as "Calculation Table". There are 16 rows and 5 colunns. The first colunn starting at row 2 is numbers 1 to 15. The first row is as follows: empty content, lambda in nm, nu in per seconds, delta E in Joules and n sub high right arrow n sub low. The second row left colunns 2 to five have the following: 97.3, 30.8 x 10^15 and 20.4 x 10^-19 and 4 right arrow 1. Column 2 starting at row 3 to row 16 have wavelengths listed as 122, 389, 397, 410, 434, 486, 656, 955, 1005, 1094 ,1282 ,1876, and 4052. All other cells are empty.',
                    centered: true,
                  },
                ],
              },
              {
                name: 'Energy Transition Table',
                order: 4,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Energy Transition Table',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Use the following table below to calculate the energy values between two different energy levels. Draw the table in your lab notebook and fill in the white spaces with the answers to your calculations. Take note that every energy value listed across row 1 and down column 2, are &times;10<sup>-19</sup> J. For example, if n<sub>8</sub> is selected from the top row column 3 and n<sub>1</sub> is selected from the bottom row column 2, the energy difference for the n<sub>8</sub> → n<sub>1</sub> transition would equal -19.8 &times;10<sup>-19</sup> J (although it is not necessary to write &times;10<sup>-19</sup> J within the table.',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/spectra/matrix-calculation-table.jpg',
                    imageAlt: 'A table is presented as a matrix, 10 rows and 10 columns. The first cell is labeled "Level, n". Down the first column starting at row 3 to row 10 is the number 8 descending to 1. In Column 2 row 2 is the unit "x 10^-19 J" to imply that every cell with a number has this power of 10 attached to it. Column 3 lists the energies for every energy level starting at row 3 and going down the column. So n = 8 is -0.341, n = 7 is -0.445, n = 6 is -0.606, n = 5 is -0.872, n = 4 is -1.36, n = 3 is -2.42, n = 2 is -5.45, and n = 1 is -21.8.  At column 3 to column 10, first row, the number 8 descends to 1. Below each energy level are the same energies, but in row 3. So n = 8 is -0.341, n = 7 is -0.445, n = 6 is -0.606, n = 5 is -0.872, n = 4 is -1.36, n = 3 is -2.42, n = 2 is -5.45, and n = 1 is -21.8. The rest of the table matrix is split into two colors. The dark cells are to be ignored. The white cells are to be completed by the student. All white cells are empty except for 2 examples, which have 2 numbers that have been calculated in the text of the previous webpage. Column 6 row 10 represents the transition n = 5 to n = 1 and its energy is listed as -20.9. Column 7 row 10 represents the transition n = 4 to n = 1 and its energy is listed as -20.4. ',
                    centered: true,
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'You can compare these values to the &Delta;E values you obtained in the table from the previous page and find the transitions between the correct levels (n<sub>high</sub> → n<sub>low</sub>).',
                  },
                ],
              },
              {
                name: 'Rydberg Equation',
                order: 5,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Rydberg Equation',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'The Rydberg equation is a famous relationship that ﬁrst showed the order of the hydrogen spectrum. To calculate the wavelength of light for a transition, the equation has the form:',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/spectra/rydbergs.jpg',
                    imageAlt: '1 divided lambda is equal to Rydberg\'s constant times open parenthesis 1 divided by n sub final squared minus 1 divided by n sub initial squared close parenthesis.',
                    centered: true,
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: '<p>In this equation, n<sub>f</sub> is the same as n<sub>low</sub>, and n<sub>i</sub> is the same as n<sub>high</sub> as in the Calculation Table, and R is Rydberg\'s constant. Use the wavelength values associated with the transition, n<sub>low</sub> and n<sub>high</sub> from the ﬁrst and fifth entry in the Calculation Table, and solve this equation for R. Be sure to show your work in your lab notebook.</p>',
                  },
                  {
                    order: 5,
                    type: 'Input',
                    directions: 'Determine Rydberg\'s constant (to 3 signiﬁcant digits) at 97.3 nm.',
                    validAnswers: ['1.10E7', '1.10E+7'],
                    unitOfMeasure: 'm<sup>-1</sub>',
                    tip: 'Ensure that your wavelength is in meters. <p>Be sure to define in the correct order the initial and final energy levels in the equation.</p><p>Be sure to square your energy levels</p><p>Last, check the order of our operations when performing the calculation.</p>',
                  },
                  {
                    order: 6,
                    type: 'Input',
                    directions: 'Determine Rydberg\'s constant (to 3 signiﬁcant digits) at 397 nm.',
                    validAnswers: ['1.10E7', '1.10E+7'],
                    unitOfMeasure: 'm<sup>-1</sub>',
                    tip: 'Ensure that your wavelength is in meters. <p>Be sure to define in the correct order the initial and final energy levels in the equation.</p><p>Be sure to square your energy levels.</p><p>Last, check the order of our operations when performing the calculation.</p>',
                  },
                  {
                    order: 7,
                    type: 'Input',
                    directions: 'Now, use this calculated R value to <strong> calculate</strong> the frequency (to 3 signiﬁcant digits) for the energy transition listed in entry 10 of your Calculation Table.',
                    validAnswers: ['3.15E14', '3.15E+14'],
                    unitOfMeasure: 's<sup>-1</sub>',
                    tip: 'The equation given will solve for the wavelength of light corresponding to this electron transition. Ensure that you are using the relationship between wavelength and frequency using the speed of light to solve for the frequency. <p>speed of light = 3.00 x 10<sup>8</sup>m/s</p>',
                  },
                  {
                    order: 8,
                    type: 'Input',
                    directions: 'Does the calculated frequency compare to the observed frequency in the second column of the 10th entry? (yes/no)',
                    validAnswers: ['yes', 'YES', 'Yes', 'Y', 'y'],
                    tip: 'The calculated value should agree with your calculated value. If not, this indicates there is an error with your calculation in the Calculation Table.',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    rank: 6,
  }

export default lineSpectre