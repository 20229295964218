import React, { useState } from 'react';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from '@mui/material';
import Content from './Content';
import { v4 as uuid } from 'uuid';

const Tabs = (props) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo(0, 0);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo(0, 0);
  };

  const stepsFormat = (
    <Box sx={{ mt: 2 }}>
      <Stepper activeStep={activeStep} orientation='horizontal'>
        {props.steps
          .sort((a, b) => a - b)
          .map((step, index) => (
            <Step key={uuid()}>
              <StepLabel>
                <Typography>{step.name}</Typography>
              </StepLabel>
            </Step>
          ))}
      </Stepper>
      <Box sx={{ mt: 2, width: '100%' }}>
        {props.steps
          .sort((a, b) => a - b)
          .map((step, index) => (
            <Box
              key={uuid()}
              sx={{ display: () => (activeStep === index ? 'inline' : 'none') }}
            >
              <Content contentBlocks={step.contentBlocks} />
            </Box>
          ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          pt: 2,
        }}
      >
        <Button
          color='inherit'
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Button
          onClick={handleNext}
          sx={{
            visibility: () =>
              activeStep === props.steps.length - 1 ? 'hidden' : 'visible',
          }}
        >
          {activeStep === props.steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ width: '100%' }}>
      {props.steps[0].name === 'NOSTEP' ? (
        <Content contentBlocks={props.steps[0].contentBlocks} />
      ) : (
        stepsFormat
      )}
    </Box>
  );
};

export default Tabs;
