import React, { useState } from 'react';
import { Box, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import LightbulbTwoToneIcon from '@mui/icons-material/LightbulbTwoTone';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import HTMLContent from './HTMLContent';

const Tip = (props) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Tooltip title='Tip'>
        <IconButton
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <LightbulbOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            border: '1px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
            <LightbulbTwoToneIcon sx={{ color: 'blue' }} />
            <Typography variant='h6' sx={{ color: 'text.main' }}>
              Tip
            </Typography>
          </Box>
          <Box sx={{ pl: 3, mt: 2, color: 'text.secondary' }}>
            <HTMLContent content={props.tip} />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Tip;
