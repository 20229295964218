import ReactPlayer from "react-player";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ScienceExperimentVideo from "../science-experiment.mp4";

const Hero = () => {
  // const chemistryVideo = 'https://youtu.be/uLp0NhNszQU';
  // const chemistryImage = './images/hero-banner-image.jpg';
  return (
    <>
      <Box
        id="root"
        component="section"
        sx={{
          width: "100%",
          height: "80vh",
          position: "relative",
          "& video": {
            objectFit: "cover",
          },
        }}
      >
        <ReactPlayer
          url={ScienceExperimentVideo}
          aria-label="Video of chemistry experiment"
          title="Chemistry experiment"
          playing
          loop
          muted
          width="100%"
          height="100%"
        />
      </Box>
      <Box
        id="overlay"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        color="#fff"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          color="#fff"
        >
          <Typography variant="h2" component="h1" sx={{ pb: 4 }}>
            Chem Lab Online
          </Typography>
          <Button
            component={Link}
            to="/labs"
            color="primary"
            variant="contained"
          >
            View Labs
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Hero;
