const osmosis = {
    status: 'Active',
    name: 'Osmosis',
    imageFileName: '/images/osmosis-icon.jpg',
    imageAlt: 'An egg carton contains three different sized eggs. The egg on the right is a standard white egg. The egg in the middle is larger than the standard egg and is a cream color. The egg on the left is deflated and beige in color.',
    description: 'Observe osmosis using eggs and other consumables.',
    tags: ['Demo', 'Chem-101A'],
    page: '/osmosis',
    content: {
      overview: {
        name: 'Overview',
        link: 'overview',
        tabs: [
          {
            name: 'NOTAB',
            order: 1,
            steps: [
              {
                name: 'NOSTEP',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Text',
                    text: 'In this activity, you will observe the process of osmosis. You will describe your observations in order to make conclusions about the phenomenon. Proceed to the <strong><em>Experiment</em></strong> to watch a demonstration video and to learn what is expected from you after watching it.',
                  },
                ],
              },
            ],
          },
        ],
      },
      experiment: {
        name: 'Experiment',
        link: 'experiment',
        tabs: [
          {
            name: 'Demo Activity',
            order: 1,
            steps: [
              {
                name: 'Notebook Prep',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Notebook Prep',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'The video that you will watch presents the phenomenon of osmosis. The demo takes place over three days.<ol>Day 1: Vinegar (5% acetic acid) was poured over the eggs.</ol><ol>Day 2: A shell-less egg was added to a beaker of deionized water and a second shell-less egg was added to a beaker of corn starch.</ol><ol>Day 3: The two eggs are removed from their solutions and dried off. A third egg with shell is used as a comparison for the other two.</ol>',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'After watching the demo video, write a typed written document (1 to 2 pages) that discusses all points below:<li>What is the reaction that took place on Day 1?</li><li>Explain the phenomenon of osmosis.</li><li>Describe how the eggs demonstrated osmosis. Be specific to the each egg from Day 2 to Day 3.</li><li>Which egg underwent hemolysis and which crenation?</li>',
                  },
                ],
              },
              {
                name: 'Video',
                order: 2,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Demo Video',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Watch: Osmosis<p><iframe width="560" height="315" src="https://www.youtube.com/embed/SyVlLkzXz8E?&playlist=SyVlLkzXz8E&loop=1" title="Osmosis" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    rank: 19
  }

  export default osmosis