import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import LabTile from '../components/LabTile';
import Data from '../data/LabsData';

const Labs = (props) => {
  const labIds = Object.keys(Data);
  return (
    <>
      <Container maxWidth='xl'>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 2,
          }}
        >
          <Typography variant='h3'>Labs</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 3,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {labIds.map((labId) => {
            // Note: This is not a sorted list
            return (
              <LabTile
                key={labId}
                labId={labId}
                lab={Data[labId].name}
                description={Data[labId].description}
                imageFileName={Data[labId].imageFileName}
                imageAlt={Data[labId].imageAlt}
                tags={Data[labId].tags}
                status={Data[labId].status}
              />
            );
          })}
        </Box>
      </Container>
    </>
  );
};

export default Labs;
