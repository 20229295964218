import React from 'react';
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NavbarItem = ({ to, children }) => {
  const navigate = useNavigate();
  return (
    <ListItemButton onClick={() => navigate(to)} sx={{ textAlign: 'center' }}>
      <ListItemText primary={children} />
    </ListItemButton>
  );
};

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <AppBar
      position='fixed'
      sx={{ pr: 1, pl: 1, zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar disableGutters>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Button
              key='main'
              onClick={() => navigate('/')}
              sx={{ color: 'white' }}
            >
              <Typography variant='h6'>Chem Lab Online (beta)</Typography>
            </Button>
          </Box>
          <List>
            <ListItem disablePadding>
              <NavbarItem to='/labs'>Labs</NavbarItem>
              <NavbarItem to='/about'>About</NavbarItem>
              <NavbarItem to='/feedback'>Feedback</NavbarItem>
            </ListItem>
          </List>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
