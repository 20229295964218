import React from 'react';
import Hero from '../components/Hero';
import { Paper, Typography } from '@mui/material';

const Home = (props) => {
  return (
    <>
      <Hero />
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          textAlign: 'center',
        }}
      >
        <Typography variant='body1'>
          This project was sponsored by Lytton Center of Ohlone College
        </Typography>
      </Paper>
    </>
  );
};

export default Home;
