import React from 'react';
import { Box, Typography } from '@mui/material';
import InformationTable from './content-blocks/InformationTable';
import ExperimentTable from './ExperimentTable';
import HTMLContent from './content-blocks/HTMLContent';
import Image from './content-blocks/Image';
import { v4 as uuid } from 'uuid';
import ValidatedInput from './content-blocks/ValidatedInput';
import ValidatedMultipleChoice from './content-blocks/ValidatedMultipleChoice';
import ValidatedImageSelection from './content-blocks/ValidatedImageSelection';
import MagnifiedImage from './content-blocks/MagnifiedImage';

/* TODO: This code needs to be refactored into a dispatcher that calls the appropriate functions */
const Content = (props) => {
  const contentArray = props.contentBlocks
    .sort((a, b) => a - b)
    .map((contentBlock) => {
      switch (contentBlock.type) {
        case 'Header':
          return (
            <Typography key={uuid()} variant='h5' sx={{ mt: 3 }}>
              {contentBlock.text}
            </Typography>
          );
        case 'Text':
          return (
            <Box
              key={uuid()}
              sx={{
                display: 'flex',
                justifyContent: () =>
                  contentBlock.centered ? 'center' : 'left',
                pb: 2,
              }}
            >
              <HTMLContent content={contentBlock.text} />
            </Box>
          );
        case 'Image':
          return (
            <Box
              key={uuid()}
              sx={{
                display: 'flex',
                justifyContent: () =>
                  contentBlock.centered ? 'center' : 'left',
              }}
            >
              <Image
                imageFileName={contentBlock.imageFileName}
                imageAlt={contentBlock.imageAlt}
              />
            </Box>
          );
        case 'Images':
          return (
            <Box
              key={uuid()}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: () =>
                  contentBlock.centered ? 'center' : 'flex-start',
                flexWrap: 'wrap',
                gap: '1rem',
              }}
            >
              {contentBlock.images.map((image) => (
                <Image
                  key={image.imageFileName}
                  imageFileName={image.imageFileName}
                  imageAlt={image.imageAlt}
                  multiImage={true}
                  imageTitle={image.imageTitle}
                  landscape={image.landscape ? true : false}
                />
              ))}
            </Box>
          );
        case 'Information Table':
          return (
            <Box
              key={uuid()}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                justifyContent: 'flex-start',
              }}
            >
              <InformationTable
                headers={contentBlock.headers}
                rows={contentBlock.rows}
                annotation={contentBlock.annotation}
              />
            </Box>
          );
        case 'Experiment Table':
          return (
            <Box
              key={uuid()}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                justifyContent: 'center',
              }}
            >
              <ExperimentTable
                headers={contentBlock.headers}
                rows={contentBlock.rows}
              />
            </Box>
          );
        case 'Input':
          return (
            <Box
              key={uuid()}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: () => (contentBlock.groupedItem ? 2 : 3),
                gap: 2,
                alignItems: 'left',
              }}
            >
              {contentBlock.directions && (
                <HTMLContent
                  variant='body1'
                  content={contentBlock.directions}
                />
              )}
              <ValidatedInput
                equation={contentBlock.equation}
                answerLength={contentBlock.answerLength}
                field={contentBlock.label}
                unitOfMeasure={contentBlock.unitOfMeasure}
                validAnswers={contentBlock.validAnswers}
                tip={contentBlock.tip}
              />
            </Box>
          );
        case 'Magnified Image':
          return (
            <Box key={uuid()}>
              <MagnifiedImage
                imageFileName={contentBlock.imageFileName}
                imageZoomFileName={contentBlock.imageZoomFileName}
              />
            </Box>
          );
        case 'Image Selection':
          return (
            <Box
              key={uuid()}
              sx={{
                display: 'flex',
                justifyContent: 'left',
                gap: 2,
                pb: 2,
              }}
            >
              <ValidatedImageSelection
                choices={contentBlock.choices}
                tip={contentBlock.tip}
              />
            </Box>
          );
        case 'Multiple Choice':
          return (
            <Box
              key={uuid()}
              sx={{
                display: 'flex',
                justifyContent: 'left',
                gap: 2,
                py: 2,
              }}
            >
              <ValidatedMultipleChoice choices={contentBlock.choices} />
            </Box>
          );
        default:
          return <h1 key={uuid()}>Invalid content block</h1>;
      }
    });
  return contentArray;
};

export default Content;
