const density = {
  status: 'Active',
  name: 'Density',
  imageFileName: 'images/density-icon.jpg',
  imageAlt:
    'A stack of pennies are sunk to the bottom of a graduated cylinder filled with water.',
  description: 'How to calculate the density of a unknown liquid and a solid.',
  tags: ['Asynchronous Lab', 'Experiment', 'Tutorial', 'Chem-101A'],
  page: '/density',
  content: {
    overview: {
      name: 'Overview',
      link: 'overview',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'NOSTEP',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'The following activity is an asynchronous lab. It mimics the measurements and calculations required for the lab, <b>Density</b>. You will be presented with actual masses and volume measurements of an unknown liquid and of a solid in order to calculate their densities. Begin by reading the <b><em>Introduction</b></em> on density. Next, carry out the lab in the virtual <b><em>Experiment</b></em> in order to practice taking the proper measurements of mass and volume. In the <b><em>Tutorial</b></em>, you will carefully practice following the significant figure rules of subtraction and division in order to calculate density and its percent error.',
                },
              ],
            },
          ],
        },
      ],
    },
    introduction: {
      name: 'Introduction',
      link: 'introduction',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'Definition',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Density Definition',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Density is an intensive physical property of matter. (Intensive means that it does not depend on the size of the substance). It requires the experimental measurement of two quantities: mass (m) and volume (V) of a given substance. The ratio of these two quantities, mass per volume, gives the density (\u03C1) of the substance.',
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName: '/images/density/density-equation.jpg',
                  imageAlt: 'rho is equal to mass divided by volume.',
                  centered: true,
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'The SI unit of density is the kilogram per cubic meter (kg/m<sup>3</sup>), but in chemistry density is given in g/L or g/mL. The density units of g/mL equals g/cm<sup>3</sup> since 1 liter equals 1000 cm<sup>3</sup> and 1 mL = 1 cm<sup>3</sup>. Grams per liter is the unit reserved for gases, grams per milliliter is used for liquids and solids.',
                },
              ],
            },
            {
              name: 'Comparison',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Density Comparison',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The figure below compares the relative volume differences of different pure substances when each contains 0.5 g.',
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName: '/images/density/density-comparison.jpg',
                  imageAlt: 'Container illustrating density.',
                  centered: true,
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'The densities of these substances are shown in the table. Notice how the densities are given to varying amounts of significant digits. This depends on the source for the data and the care used to make the measurements. The value for wood has only 1 significant digit. It is an approximation, since there are many different kinds of wood with densities that vary.',
                },
                {
                  order: 5,
                  type: 'Information Table',
                  headers: ['Substance', 'Density'],
                  rows: [
                    { id: 1, cells: ['Osmium', '22.5 g/mL'] },
                    { id: 2, cells: ['Lead', '11.34 g/mL'] },
                    { id: 3, cells: ['Iron', '7.6 g/mL'] },
                    { id: 4, cells: ['Aluminum', '2.7 g/mL'] },
                    { id: 5, cells: ['Water', '1.0 g/mL'] },
                    { id: 6, cells: ['Gasoline', '0.67 g/mL'] },
                    { id: 7, cells: ['Wood', '0.6 g/mL'] },
                    { id: 8, cells: ['Oxygen', '1.43 g/L = 0.00143 g/mL'] },
                  ],
                  annotation:
                    '* The density of oxygen given is for 1 atmosphere of pressure and 0°C. For the other sub- stances, the density is measured at 20°C.',
                },
              ],
            },
            {
              name: 'Temperature',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'Density is temperature-dependent, since all substances change volume as the temperature changes. Gases are much more variable in this regard than solids or liquids.',
                },
              ],
            },
          ],
        },
      ],
    },
    experiment: {
      name: 'Experiment',
      link: 'experiment',
      tabs: [
        {
          name: 'Density of a Liquid',
          order: 1,
          steps: [
            {
              name: 'Notebook Prep',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Notebook Prep',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'This lab simulates how to determine the density of an unknown liquid. The lab equipment needed are:',
                },
                {
                  order: 3,
                  type: 'Images',
                  images: [
                    {
                      imageFileName: '/images/density/decigram-balance.jpg',
                      imageAlt: 'A photo of a decigram balance balance',
                      imageTitle: 'Decigram balance',
                    },
                    {
                      imageFileName: '/images/density/50mL.jpg',
                      imageAlt:
                        'A photo of a 100 milliliter graduated cylinder ',
                      imageTitle: '50 mL or 100 mL graduated cylinder',
                    },
                  ],
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'You will complete the lab by making observations of critical experimental steps. The information collected will be used for further data analysis. This dry lab will be carried out four times in order to collect data for four trials. <p>Begin the lab activity by preparing the following table in your notebook. You will be writing all trial measurements from the simulation into your notebook table.</p>',
                },
                {
                  order: 5,
                  type: 'Image',
                  imageFileName: '/images/density/density-experiment-table.png',
                  imageAlt:
                    'A 6 row by 5 column column is presented. Row 1 cell 1 is empty. Row 1 cells 2 to 5 are labeled Trial 1, Trial 2, Trial 3 and Trial 4. Column 1 has labels rows 2 to 6. Going down the column: Row 2 is Mass of empty graduated cylinder (g), Row 3 is Mass of graduated cylinder (g), Row 4 is Volume of liquid (mL), Row 5 is Mass of liquid (g), and Row 6 is Density of liquid (g/mL. All other cells are empty in the table.',
                  centered: true,
                },
                {
                  order: 6,
                  type: 'Text',
                  text: '<p>If you have your table ready in your notebook, move head and click the <b>Next</b> button to begin the simulated experiment. Follow all instructions provided.</p>',
                },
              ],
            },
            {
              name: 'First Trial',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'First Trial',
                },
                {
                  order: 2,
                  type: 'Header',
                  text: 'Step 1',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Weigh a dry 50 mL graduated cylinder on the decigram balance. Record its mass to the nearest 0.1 g. ',
                },
                {
                  order: 4,
                  type: 'Magnified Image',
                  imageFileName:
                    '/images/density/liquid/mass-empty-graduated-cylinder-small.jpg',
                  imageAlt:
                    'An empty 50-mL graduated cylinder rests on a decigram balance and the decigram balance reads 87.1 g',
                  imageZoomFileName:
                    '/images/density/liquid/mass-empty-graduated-cylinder.jpg',
                },
                {
                  order: 5,
                  type: 'Input',
                  directions:
                    'Record the mass in grams of the empty graduated cylinder',
                  validAnswers: ['87.1'],
                  unitOfMeasure: 'g',
                  tip: 'It is imperative that you write all digits of a mass balance as these are measured values.',
                },
                {
                  order: 6,
                  type: 'Header',
                  text: 'Step 2',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: 'For the first trial, add between 40 to 50 mL of the unknown liquid to the cylinder. Record the volume to the nearest 0.1 mL.',
                },
                {
                  order: 9,
                  type: 'Magnified Image',
                  imageFileName:
                    '/images/density/liquid/volume-trial-1-small.jpg',
                  imageAlt:
                    'A blue liquid is filled to 46.3 mL in a 50-mL graduated cylinder.',
                  imageZoomFileName:
                    '/images/density/liquid/volume-trial-1.jpg',
                },
                {
                  order: 10,
                  type: 'Input',
                  directions:
                    'Record the volume in milliliters of the graduated cylinder filled with the liquid',
                  validAnswers: ['46.2', '46.3', '46.4'],
                  unitOfMeasure: 'mL',
                  tip: 'Ensure you read the bottom of the meniscus. While hard to see sometimes due to the red vertical band on the graduated cylinder, this is how lab measurements sometimes go',
                },
                {
                  order: 11,
                  type: 'Header',
                  text: 'Step 3',
                },
                {
                  order: 12,
                  type: 'Text',
                  text: 'Weigh the liquid and cylinder <b>using the same decigram balance as in Step 1</b>, and again record the mass to the nearest 0.1 g.',
                },
                {
                  order: 13,
                  type: 'Magnified Image',
                  imageFileName:
                    '/images/density/liquid/mass-trial-1-small.jpg',
                  imageAlt:
                    'A blue liquid in a 50-mL graduated cylinder rests on a decigram balance and the decigram balance reads 138.9 g',
                  imageZoomFileName: '/images/density/liquid/mass-trial-1.jpg',
                },
                {
                  order: 14,
                  type: 'Input',
                  directions:
                    'Record the mass in grams of the graduated cylinder filled with the liquid.',
                  validAnswers: ['138.9'],
                  unitOfMeasure: 'g',
                  tip: ' It is imperative that you write all digits of a mass balance as these are measured values',
                },
              ],
            },
            {
              name: 'Second Trial',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Second Trial',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Follow the same three steps as in the <strong>First Trial</strong> to collect your data for the <strong>Second Trial</strong>. ',
                },
                {
                  order: 3,
                  type: 'Header',
                  text: 'Step 1',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Use the mass for the graduated cylinder when dry from Trial 1.',
                },
                {
                  order: 5,
                  type: 'Header',
                  text: 'Step 2',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'Choose a different volume of the same liquid. Record the volume to the nearest 0.1 mL.',
                },
                {
                  order: 7,
                  type: 'Magnified Image',
                  imageFileName:
                    '/images/density/liquid/volume-trial-2-small.jpg',
                  imageAlt:
                    'A blue liquid is filled to 34.4 mL in a 50-mL graduated cylinder.',
                  imageZoomFileName:
                    '/images/density/liquid/volume-trial-2.jpg',
                },
                {
                  order: 8,
                  type: 'Input',
                  directions:
                    'Record the volume in milliliters of the graduated cylinder filled with the liquid',
                  validAnswers: ['34.1', '34.2', '34.3', '34.4'],
                  unitOfMeasure: 'mL',
                  tip: 'Ensure you read the bottom of the meniscus. While hard to see sometimes due to the red vertical band on the graduated cylinder, this is how lab measurements sometimes go',
                },
                {
                  order: 9,
                  type: 'Header',
                  text: 'Step 3',
                },
                {
                  order: 10,
                  type: 'Text',
                  text: 'Weigh the liquid and cylinder <strong>using the same decigram balance</strong>, and record the mass to the nearest 0.1 g.',
                },
                {
                  order: 11,
                  type: 'Magnified Image',
                  imageFileName:
                    '/images/density/liquid/mass-trial-2-small.jpg',
                  imageAlt:
                    'A blue liquid in a 50-mL graduated cylinder rests on a decigram balance and the decigram balance reads 125.5 g',
                  imageZoomFileName: '/images/density/liquid/mass-trial-2.jpg',
                },
                {
                  order: 12,
                  type: 'Input',
                  directions:
                    'Record the mass in grams of the graduated cylinder filled with the liquid.',
                  validAnswers: ['125.5'],
                  unitOfMeasure: 'g',
                  tip: ' It is imperative that you write all digits of a mass balance as these are measured values',
                },
              ],
            },
            {
              name: 'Third Trial',
              order: 4,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Third Trial',
                },
                {
                  order: 2,
                  type: 'Header',
                  text: 'Step 1',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Use the mass for the graduated cylinder when dry from Trial 1.',
                },
                {
                  order: 4,
                  type: 'Header',
                  text: 'Step 2',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Continuing like the other trials, choose a different volume of the same liquid. Record the volume to the nearest 0.1 mL.',
                },
                {
                  order: 6,
                  type: 'Magnified Image',
                  imageFileName:
                    '/images/density/liquid/volume-trial-3-small.jpg',
                  imageAlt:
                    'A blue liquid is filled to 27.5 mL in a 50-mL graduated cylinder.',
                  imageZoomFileName:
                    '/images/density/liquid/volume-trial-3.jpg',
                },
                {
                  order: 7,
                  type: 'Input',
                  directions:
                    'Record the volume in milliliters of the graduated cylinder filled with the liquid',
                  validAnswers: ['27.3', '27.4', '27.5'],
                  unitOfMeasure: 'mL',
                  tip: 'Ensure you read the bottom of the meniscus. While hard to see sometimes due to the red vertical band on the graduated cylinder, this is how lab measurements sometimes go',
                },
                {
                  order: 8,
                  type: 'Header',
                  text: 'Step 3',
                },
                {
                  order: 9,
                  type: 'Text',
                  text: 'Weigh the liquid and cylinder <strong>using the same decigram balance</strong>, and record the mass to the nearest 0.1 g.',
                },
                {
                  order: 10,
                  type: 'Magnified Image',
                  imageFileName:
                    '/images/density/liquid/mass-trial-3-small.jpg',
                  imageAlt:
                    'A blue liquid in a 50-mL graduated cylinder rests on a decigram balance and the decigram balance reads 117.7 g',
                  imageZoomFileName: '/images/density/liquid/mass-trial-3.jpg',
                },
                {
                  order: 11,
                  type: 'Input',
                  directions:
                    'Record the mass in grams of the graduated cylinder filled with the liquid.',
                  validAnswers: ['117.7'],
                  unitOfMeasure: 'g',
                  tip: ' It is imperative that you write all digits of a mass balance as these are measured values',
                },
              ],
            },
            {
              name: 'Fourth Trial',
              order: 5,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Fourth Trial',
                },
                {
                  order: 2,
                  type: 'Header',
                  text: 'Step 1',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Use the mass for the graduated cylinder when dry from Trial 1.',
                },
                {
                  order: 4,
                  type: 'Header',
                  text: 'Step 2',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Continuing like the other trials, choose a different volume of the same liquid. Record the volume to the nearest 0.1 mL.',
                },
                {
                  order: 6,
                  type: 'Magnified Image',
                  imageFileName:
                    '/images/density/liquid/volume-trial-4-small.jpg',
                  imageAlt:
                    'A blue liquid is filled to 19.0 mL in a 50-mL graduated cylinder.',
                  imageZoomFileName:
                    '/images/density/liquid/volume-trial-4.jpg',
                },
                {
                  order: 7,
                  type: 'Input',
                  directions:
                    'Record the volume in milliliters of the graduated cylinder filled with the liquid',
                  validAnswers: ['19.7', '19.8', '19.9'],
                  unitOfMeasure: 'mL',
                  tip: 'Ensure you read the bottom of the meniscus. While hard to see sometimes due to the red vertical band on the graduated cylinder, this is how lab measurements sometimes go',
                },
                {
                  order: 8,
                  type: 'Header',
                  text: 'Step 3',
                },
                {
                  order: 9,
                  type: 'Text',
                  text: 'Weigh the liquid and cylinder <strong>using the same decigram balance</strong>, and record the mass to the nearest 0.1 g.',
                },
                {
                  order: 10,
                  type: 'Magnified Image',
                  imageFileName:
                    '/images/density/liquid/mass-trial-4-small.jpg',
                  imageAlt:
                    'A blue liquid in a 50-mL graduated cylinder rests on a decigram balance and the decigram balance reads 109.0 g',
                  imageZoomFileName: '/images/density/liquid/mass-trial-4.jpg',
                },
                {
                  order: 11,
                  type: 'Input',
                  directions:
                    'Record the mass in grams of the graduated cylinder filled with the liquid.',
                  validAnswers: ['109.0'],
                  unitOfMeasure: 'g',
                  tip: ' It is imperative that you write all digits of a mass balance as these are measured values',
                },
                {
                  order: 12,
                  type: 'Text',
                  text: 'Good work! <p>You have completed the data collection for determining <b>the Density of an Unknown Liquid</b>. In the lab at this point, you will return your assigned liquid to the stock container using the attached funnel. Rinse out the cylinder with deionized water and allow it to drain.</p><p>Now, proceed with the second half of the activity, which is to determine <b>the density of a solid</b>.</p>',
                },
              ],
            },
          ],
        },
        {
          name: 'Density of a Solid',
          order: 2,
          steps: [
            {
              name: 'Notebook Prep',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Notebook Prep',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'This lab simulates how to determine the density of solid.  This lab will focus on finding the density of U.S. pennies, but in your wet lab, you will be finding the density for other materials. Here are examples of the materials you may use:',
                },
                {
                  order: 3,
                  type: 'Images',
                  images: [
                    {
                      imageFileName:
                        '/images/density/solid/weighing-paper-2.jpg',
                      imageAlt:
                        'Weighing paper rests on a decigram balance and the decigram balance has been tared to 0.0 g.',
                      imageTitle: 'Decigram balance and weighing paper',
                    },
                    {
                      imageFileName: '/images/density/solid/50mL.jpg',
                      imageAlt: 'A photo of an empty 50-mL graduated cylinder ',
                      imageTitle: '50 mL graduated cylinder',
                    },
                    {
                      imageFileName: '/images/density/solid/solids.jpg',
                      imageAlt: 'tbd ',
                      imageTitle: 'Examples of solids',
                      landscape: true,
                    },
                  ],
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Similar to the density of a liquid, you will complete the lab by making observations of required experimental steps. The lab will be carried out two times in order to collect for two trials.',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: '<p>Begin the activity by preparing the following table in your notebook. You will be writing all trial measurements from the simulation into your notebook table.</p> ',
                },
                {
                  order: 6,
                  type: 'Image',
                  imageFileName: '/images/density/solid/solid-data-table.jpg',
                  imageAlt:
                    'A 6 row by 3 column column is presented. Row 1 cell 1 is empty. Row 1 cells 2 and 3 are labeled Trial 1 and Trial 2. Column 1 has labels rows 2 to 6. Going down the column: Row 2 is Mass of solid (g), Row 3 is Volume of water (mL), Row 4 is Volume of water and solid (mL), Row 5 is Volume of solid (mL), and Row 6 is Density of solid (g/mL. All other cells are empty in the table.',
                  centered: true,
                },
                {
                  order: 7,
                  type: 'Text',
                  text: '<p>Click the <b>Next</b> button to continue and begin the simulated experiment. Follow all instructions provided.</p>',
                },
              ],
            },
            {
              name: 'First Trial',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'First Trial',
                },
                {
                  order: 2,
                  type: 'Header',
                  text: 'Step 1',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Spread the solid onto a paper towel and check to see if it is dry. Dry it with a paper towel, if necessary. <p>Zero out the mass of weighing paper on a decigram balance. Add the solid to the balance and record this mass to the nearest 0.1 g.</p>',
                },
                {
                  order: 4,
                  type: 'Magnified Image',
                  imageFileName: '/images/density/solid/5-pennies.jpg',
                  imageAlt:
                    'Five pennies rests on top of weighing paper on a decigram balance and the decigram balance reads 15.5 g.',
                  imageZoomFileName:
                    '/images/density/solid/5-pennies-large.jpg',
                },
                {
                  order: 5,
                  type: 'Input',
                  directions: 'Record the mass in grams of five pennies.',
                  validAnswers: ['15.5'],
                  unitOfMeasure: 'g',
                  tip: 'It is imperative that you write all digits of a mass balance as these are measured values.',
                },
                {
                  order: 6,
                  type: 'Header',
                  text: 'Step 2',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: 'Add about 20 - 30 mL of deionized water to the 50 mL graduated cylinder and record the volume to the nearest 0.1 mL.',
                },
                {
                  order: 8,
                  type: 'Magnified Image',
                  imageFileName: '/images/density/solid/volume-water.jpg',
                  imageAlt:
                    'Deionized water is in a 50-mL graduated cylinder and the meniscus reaches the 20.0 mL subdivision line. ',
                  imageZoomFileName:
                    '/images/density/solid/volume-water-large.jpg',
                },
                {
                  order: 9,
                  type: 'Input',
                  directions:
                    'Record the volume in milliliters of the graduated cylinder filled with deionized water',
                  validAnswers: ['19.9', '20.0'],
                  unitOfMeasure: 'mL',
                  tip: 'Ensure you read the bottom of the meniscus. ',
                },
                {
                  order: 10,
                  type: 'Header',
                  text: 'Step 3',
                },
                {
                  order: 11,
                  type: 'Text',
                  text: 'Tilt the cylinder, and carefully add the pennies to the cylinder. Tap the cylinder a few times to dislodge any trapped air bubbles. Record the new volume to the nearest 0.1 mL.',
                },
                {
                  order: 12,
                  type: 'Magnified Image',
                  imageFileName: '/images/density/solid/volume-5-pennies.jpg',
                  imageAlt:
                    'Five pennies in water are in a 50-mL graduated cylinder and the volume is measured to be 21.5 mL',
                  imageZoomFileName:
                    '/images/density/solid/volume-5-pennies-large.jpg',
                },
                {
                  order: 13,
                  type: 'Input',
                  directions:
                    'Record the volume in milliliters of the graduated cylinder filled with 5 pennies and water.',
                  validAnswers: ['21.4', '21.5', '21.6'],
                  unitOfMeasure: 'mL',
                  tip: 'Do your best to read the bottom of the meniscus. ',
                },
                {
                  order: 14,
                  type: 'Header',
                  text: 'Step 4',
                },
                {
                  order: 15,
                  type: 'Text',
                  text: 'Remove the pennies from the graduated cylinder. Pat the pennies dry using a paper towel. ',
                },

                {
                  order: 16,
                  type: 'Text',
                  text: 'Repeat the experiment one more time. Click <b>Next</b> to continue using a different amount of pennies.',
                },
              ],
            },
            {
              name: 'Second Trial',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Second Trial',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Follow the same steps as in the <strong>First Trial</strong> to collect your data for <strong>Second Trial</strong>.',
                },
                {
                  order: 3,
                  type: 'Header',
                  text: 'Step 1',
                },
                {
                  order: 4,
                  type: 'Magnified Image',
                  imageFileName: '/images/density/solid/10-pennies.jpg',
                  imageAlt:
                    'Ten pennies rests on top of weighing paper on a decigram balance and the decigram balance reads 31.0 g.',
                  imageZoomFileName:
                    '/images/density/solid/10-pennies-large.jpg',
                },
                {
                  order: 5,
                  type: 'Input',
                  directions: 'Record the mass in grams of ten pennies.',
                  validAnswers: ['31.0'],
                  unitOfMeasure: 'g',
                  tip: 'It is imperative that you write all digits of a mass balance as these are measured values.',
                },
                {
                  order: 6,
                  type: 'Header',
                  text: 'Step 2',
                },
                {
                  order: 7,
                  type: 'Magnified Image',
                  imageFileName: '/images/density/solid/volume-water.jpg',
                  imageAlt:
                    'Deionized water is in a 50-mL graduated cylinder and the meniscus reaches the 20.0 mL subdivision line.',
                  imageZoomFileName:
                    '/images/density/solid/volume-water-large.jpg',
                },
                {
                  order: 8,
                  type: 'Input',
                  directions:
                    'Record the volume in milliliters of the graduated cylinder filled with deionized water',
                  validAnswers: ['20.0'],
                  unitOfMeasure: 'mL',
                  tip: 'Ensure you read the bottom of the meniscus. ',
                },
                {
                  order: 9,
                  type: 'Header',
                  text: 'Step 3',
                },
                {
                  order: 10,
                  type: 'Magnified Image',
                  imageFileName: '/images/density/solid/volume-10-pennies.jpg',
                  imageAlt:
                    'Ten pennies in water are in a 50-mL graduated cylinder and the volume is measured to be 23.3 mL',
                  imageZoomFileName:
                    '/images/density/solid/volume-10-pennies-large.jpg',
                },
                {
                  order: 11,
                  type: 'Input',
                  directions:
                    'Record the volume in milliliters of the graduated cylinder filled with ten pennies and water.',
                  validAnswers: ['23.2', '23.3', '23.4', '23.5'],
                  unitOfMeasure: 'mL',
                  tip: 'Ensure you read the bottom of the meniscus. ',
                },
                {
                  order: 12,
                  type: 'Text',
                  text: 'Good work! <p>You have completed the data collection for determining <b>the Density of a Solid</b>. Remove the contents from the graduated cylinder. Make sure the solid is dry and placed back into the sample vial.	<p>You are now ready to review your measured data and carry out the calculations to find the densities of these substances. Go to the <b>Tutorial</b>, to practice the calculatons that are dependent on the rules of significant figures. You will then compare your calculated densities to the actual densities of those substances.',
                },
              ],
            },
          ],
        },
      ],
    },
    tutorial: {
      name: 'Tutorial',
      link: 'tutorial',
      tabs: [
        {
          name: 'Density of a Liquid',
          order: 1,
          steps: [
            {
              name: 'Instructions',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Instructions',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Please have in hand a completed table containing the masses and volumes from each trial in the <strong>Experiment</strong>. You will use all data to determine the density of the liquid.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Once the density has been calculated for each trial, you will calculate the average density. The average density will then be compared to the actual density and a % error can be determined.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'All data tables and calculations in this <strong>Tutorial</strong> are to be completed in your notebook. <br>Your work will be submitted as specified by your instructor.',
                },
              ],
            },
            {
              name: 'Mass of the Liquid',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Mass of the Liquid',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: "Let's first calculate the mass of the liquid in each of the four trials. Please review the tip provided if hints are needed on how to do the calculation.",
                },
                {
                  order: 3,
                  type: 'Input',
                  directions: 'Calculate the mass of the liquid for:',
                  equation: 'Trial 1',
                  validAnswers: ['51.8'],
                  unitOfMeasure: 'g',
                  tip: 'Subtract the empty graduated cylinder mass from the mass of the filled graduated cylinder for Trial 1.',
                },
                {
                  order: 4,
                  type: 'Input',
                  equation: 'Trial 2',
                  groupedItem: true,
                  validAnswers: ['38.4'],
                  unitOfMeasure: 'g',
                  tip: 'Subtract the empty graduated cylinder mass from the mass of the filled graduated cylinder for Trial 2.',
                },
                {
                  order: 5,
                  type: 'Input',
                  equation: 'Trial 3',
                  groupedItem: true,
                  validAnswers: ['30.6'],
                  unitOfMeasure: 'g',
                  tip: 'Subtract the empty graduated cylinder mass from the mass of the filled graduated cylinder for Trial 3.',
                },
                {
                  order: 6,
                  type: 'Input',
                  equation: 'Trial 4',
                  groupedItem: true,
                  validAnswers: ['21.9'],
                  unitOfMeasure: 'g',
                  tip: 'Subtract the empty graduated cylinder mass from the mass of the filled graduated cylinder for Trial 4.',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: '&nbsp;',
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'Write these correct answers into your table. All next step calculations are based on these values.',
                },
              ],
            },
            {
              name: 'Density Calculation',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Density Calculation',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: "Let's now calculate the density of the liquid since the mass and its volume are available for each of the four trials.",
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName: '/images/density/density-equation.jpg',
                  imageAlt: 'rho is equal to mass divided by volume.',
                  centered: true,
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Write down in your table the <strong>unrounded version</strong> of this answer provided to you by your calculator, as you will continue to use this <strong>unrounded number</strong> when you take the average. In your table, place a line over the third sig fig to highlight where your significant figures actually end for the number. In the fields below, however, type in the rounded answer for density.',
                },
                {
                  order: 5,
                  type: 'Input',
                  directions: 'Calculate the density of the liquid for:',
                  equation: 'Trial 1',
                  validAnswers: ['1.12'],
                  unitOfMeasure: 'g/mL',
                  tip: 'Use the mass of the liquid and the volume of the liquid for a specific trial in the density equation. Following the division rule of significant figures, you will round to three sig figs.',
                },
                {
                  order: 6,
                  type: 'Input',
                  equation: 'Trial 2',
                  groupedItem: true,
                  validAnswers: ['1.12', '1.13'],
                  unitOfMeasure: 'g/mL',
                  tip: 'Make sure to round answer here to three sig figs',
                },
                {
                  order: 7,
                  type: 'Input',
                  equation: 'Trial 3',
                  groupedItem: true,
                  validAnswers: ['1.11', '1.12'],
                  unitOfMeasure: 'g/mL',
                  tip: 'Make sure to round answer here to three sig figs',
                },
                {
                  order: 8,
                  type: 'Input',
                  equation: 'Trial 4',
                  groupedItem: true,
                  validAnswers: ['1.10', '1.11', '1.15', '1.16', '1.17'],
                  unitOfMeasure: 'g/mL',
                  tip: 'Make sure to round answer here to three sig figs',
                },
              ],
            },
            {
              name: 'Average Density',
              order: 4,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Average Density',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: "Let's now calculate the average density of the liquid using all Trial's densities.",
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Be sure you are calculating using the <strong>unrounded</strong> densities from your table.',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions:
                    'Calculate the average density of the liquid based on all four trials:',
                  validAnswers: ['1.10', '1.11'],
                  unitOfMeasure: 'g/mL',
                  tip: ' Did you use <strong> unrounded</strong> values for all densities of Trials 1 to 4? This is a necessary steps as rounded numbers can impact the final answer, hence we do not round yet. The calculation is based on unrounded numbers although the final answer will only hold three significant figures.',
                },
              ],
            },
            {
              name: 'Percent Error',
              order: 5,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Percent Error',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The blue liquid is actually 20% NaCl. The density of 20% NaCl at room temperature is 1.1478 g/mL. Using this value and the percent error equation, calculate the % error.',
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName: '/images/density/liquid/percent-error.jpg',
                  imageAlt:
                    'Percent error is equal to the absolute value of experimental value - accepted value divided by the accepted value times 100%.',
                  centered: true,
                },
                {
                  order: 3,
                  type: 'Input',
                  directions:
                    'Determine the % error and present your answer as the absolute value. ',
                  validAnswers: ['3', '4', '5'],
                  unitOfMeasure: '%',
                  tip: 'Percent error can be a tricky calculation for significant figures as you are doing two back-to-back rules. <p> First, follow the subtraction rule. Second, carry out the division rule. Last, multiply by 100 to present your answer as a percent.</p>',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: '&nbsp;',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'You are now ready to go to the <strong>Tutorial</strong> of Part II: Find the density of the solid and its percent error.',
                  centered: true,
                },
              ],
            },
          ],
        },
        {
          name: 'Density of a Solid',
          order: 1,
          steps: [
            {
              name: 'Instructions',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Instructions',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Please have in hand a completed table containing the masses and volumes from each of the two trials in the <strong>Experiment</strong>. You will use all data to determine the density of the penny.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Once the density has been calculated for each trial, you will calculate the average density. The average density will then be compared to the actual density and a % error can be determined.',
                },
              ],
            },
            {
              name: 'Volume of a Penny',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Volume of a Penny',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: "Let's first calculate the volume of a stack of pennies for each trial. Please review the tip provided if hints are needed on how to do the calculation.",
                },
                {
                  order: 3,
                  type: 'Input',
                  directions: 'Calculate the volume of:',
                  equation: '5 pennies for Trial 1',
                  validAnswers: ['1.5', '1.6'],
                  unitOfMeasure: 'mL',
                  tip: 'Subtract the volume of water from the volume of water and the pennies.',
                },
                {
                  order: 4,
                  type: 'Input',
                  equation: '10 pennies for Trial 2',
                  groupedItem: true,
                  validAnswers: ['3.2', '3.3', '3.4', '3.5'],
                  unitOfMeasure: 'mL',
                  tip: 'Subtract the volume of water from the volume of water and the pennies.',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: '&nbsp;',
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'Be sure to write these correct answers into your table. The next step calculations are based on these values.',
                },
              ],
            },
            {
              name: 'Density Calculation',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Density Calculation',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: "Let's now calculate the density of a penny since the mass and its volume are available for each of the two trials.",
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName: '/images/density/density-equation.jpg',
                  imageAlt: 'rho is equal to mass divided by volume.',
                  centered: true,
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Write down in your table the <strong>unrounded version</strong> of this answer provided to you by your calculator, as you will continue to use this <strong>unrounded number</strong> when you take the average. In your table, place a line over the third sig fig to highlight where your significant figures actually end for the number.',
                },
                {
                  order: 5,
                  type: 'Input',
                  directions: 'Calculate the density of the pennies for:',
                  equation: 'Trial 1',
                  validAnswers: ['9.7', '10', '10.'],
                  unitOfMeasure: 'g/mL',
                  tip: 'Use the mass of the pennies and the volume of the pennies for its specific trial in the density equation. Following the division rule of significant figures, you will round to two sig figs.',
                },
                {
                  order: 6,
                  type: 'Input',
                  equation: 'Trial 2',
                  groupedItem: true,
                  validAnswers: ['8.8', '9.1', '9.4', '9.7'],
                  unitOfMeasure: 'g/mL',
                  tip: 'Make sure to round your answer here to two sig figs',
                },
              ],
            },
            {
              name: 'Average Density',
              order: 4,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Average Density',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: "Let's now calculate the average density of a penny from both Trials.",
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Be sure you are calculating using the <strong>unrounded</strong> densities from your table.',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions:
                    'Calculate the average density of a penny (shown here to the proper rounding of significant figures).',
                  validAnswers: ['9.6', '9.7', '9.8', '9.9', '10', '10.'],
                  unitOfMeasure: 'g/mL',
                  tip: ' Did you use <strong> unrounded</strong> values for the densities of Trials 1 and 2? This is a necessary steps as rounded numbers can impact the final answer, hence we do not round yet. The calculation is based on unrounded numbers although the final answer will only hold two significant figures.',
                },
              ],
            },
            {
              name: 'Percent Error',
              order: 5,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Percent Error',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'These pennies are all dated pre-1982. Those pennies have a higher amount of copper in them, which affects the density of the penny. The density of these pennies are 8.96 g/mL. Using this value and the percent error calculation, calculate the % error.',
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName: '/images/density/liquid/percent-error.jpg',
                  imageAlt:
                    'Percent error is equal to the absolute value of experimental value - accepted value divided by the accepted value times 100%.',
                  centered: true,
                },
                {
                  order: 3,
                  type: 'Input',
                  directions:
                    'Determine the % error and present your answer as the absolute value. ',
                  validAnswers: ['7', '9', '10'],
                  unitOfMeasure: '%',
                  tip: 'Percent error can be a tricky calculation for significant figures as you are doing two back-to-back rules. <p> First, follow the subtraction rule. Second, carry out the division rule. Last, multiply by 100 to present your answer as a percent.</p>',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: '&nbsp;',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: '<center>You are now done with this asynchronous lab. <br>Turn in your carbon copy notebook showing your completed data tables and all calculations.</center>',
                  centered: true,
                },
              ],
            },
          ],
        },
      ],
    },
  },
  rank: 4,
};
export default density;
