const avagadrosNumber = {
  status: 'Active',
  name: 'Avogadros Number',
  imageFileName: 'images/scum-icon.jpg',
  imageAlt:
    'A section of a watch glass with the appearance of scum on the surface.',
  description:
    "Experimental guide and calculation review to approximate Avogadro's number.",
  tags: ['Pre-Lab', 'Wet Lab', 'Experiment', 'Chem-101A'],
  page: 'avogadros-number',
  content: {
    overview: {
      name: 'Overview',
      link: 'overview',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'NOSTEP',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: "In this lab, you will use an interesting method using stearic acid to get a very rough idea of the value for Avogadro's number. Read through the <strong><em>Introduction</em></strong> to learn what is stearic acid and what happens when we carefully drop multiple droplets of stearic acid on a surface.",
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'In this simulation, you will first review the <strong><em>Experiment</em></strong> and then the<strong><em>Tutorial</em></strong>.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: "The <strong><em>Experiment's Procedure</em></strong> will walk you step-by-step through the protocol. Embedded within are several tips and tricks to the experiment. Move through all questions to better understand some of the crucial steps of the experiment.",
                },
                {
                  order: 4,
                  type: 'Text',
                  text: "Next, use the <strong><em>Tutorial</em></strong> to practice the series of calculations that depend on your experimental data to find a value very close to Avogadro's number.",
                },
              ],
            },
          ],
        },
      ],
    },
    introduction: {
      name: 'Introduction',
      link: 'introduction',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'Historical Perspective',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: "Avogadro's number is the number of particles in a mole and is deﬁned as 6.0221367&times;10<sup>23</sup>. This very large number is most reliably determined by X-ray diffraction of crystals as it once was done in 1986 using silicon crystals. Interestingly, at the beginning of the 1900s, scientists devised pretty ingenious methods to estimate the size of the number. For example, when a feather drops in the air, it is slowed by the many collisions it has with air molecules. Whereas if a feather is dropped in a vacuum, it falls as rapidly as a rock. Albert Einstein calculated the number of collisions that must take place between air molecules and a small particle to cause the small particle to drop as slowly as it does through the air. By calculating how many collisions must occur to slow the fall, he concluded that there must be about 5&times;10<sup>23</sup> particles in a mole. After Robert Millikan measured the charge of an electron in 1915, this value was divided into the charge of a mole of electrons to get 6.02&times;10<sup>23</sup> as the number. ",
                },
              ],
            },
            {
              name: 'Stearic Acid',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'Stearic acid, C<sub>18</sub>H<sub>36</sub>O<sub>2</sub>, has the Lewis structure:',
                },
                {
                  order: 2,
                  type: 'Image',
                  imageFileName: '/images/avogadros/stearic-acid.jpg',
                  imageAlt: 'The Lewis structure of stearic acid.',
                  centered: true,
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'The right side of the molecule is polar and is capable of hydrogen bonding with water. The bonds were drawn in an exaggerated way to emphasize this.  The rest of the molecule is a hydrocarbon, completely nonpolar.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'An expanded structural model of stearic acid shows the proper angles between atoms, but places the atoms farther apart than their actual relative distances:',
                },
                {
                  order: 5,
                  type: 'Image',
                  imageFileName: '/images/avogadros/stearic-acid-model.jpg',
                  imageAlt: 'The molecular model in 3D of stearic acid.',
                  centered: true,
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'We will call the right side of the molecule the head and the rest of the molecule the tail. Here is a shorthand notation for the molecule:',
                },
                {
                  order: 7,
                  type: 'Image',
                  imageFileName: '/images/avogadros/stearic-acid-structure.jpg',
                  imageAlt:
                    'The molecular structure of stearic acid using organic chemistry drawing. 3D of stearic acid.',
                  centered: true,
                },
              ],
            },
            {
              name: 'Experiment Overview',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'If stearic acid molecules are placed on a water surface, the polar head of the molecule hydrogen bonds to the water molecules on the surface. The rest of the molecule, the nonpolar tail, is not attracted to the water, but rather, is attracted to the tails of nearby stearic acid molecules. As a result, the molecules line up on the surface of the water with polar heads on the surface, and nonpolar tails sticking up away from the surface:',
                },
                {
                  order: 2,
                  type: 'Image',
                  imageFileName: '/images/avogadros/monolayer-structure.jpg',
                  imageTitle:
                    'Water molecules, H-bonding to the -COOH of stearic acid',
                  imageAlt:
                    'Six 3D models of stearic acid. The hydrocarbon section are aligned next to each other. The polar sections are aligned as well.',
                  centered: true,
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'If a small number of stearic acid molecules is placed on water, they cover the water with a layer one molecule thick. If more molecules are added, they begin to pile up on top of one another, but the ﬁrst ones added form a monolayer.',
                },
                {
                  order: 4,
                  type: 'Image',
                  imageFileName: '/images/avogadros/monolayer.jpg',
                  imageAlt:
                    'A water layer is on the bottom and a stearic acid layer is on top. Each layer is labeled respectively. The stearic acid layer is drawn as a row of sticks pointing up with a small spacing in between each stearic acid.',
                  centered: true,
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'The height of the stearic acid molecules above the water is the length of the molecule. The length is considerably shorter than the wavelength of visible light, so light striking the monomolecular layer does not interact with it and the layer is invisible to the eye. However, once the additional molecules added begin to pile up on top of one another, the thicker part of the layer becomes visible.',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'You will add, drop by drop, a dilute solution of stearic acid in hexane, a volatile solvent, to a surface of water. As each drop hits the water, the hexane will evaporate, leaving the stearic acid to spread out on the water. When the surface is entirely covered, additional drops of the stearic acid/hexane solution will cause the stearic acid to pile up and you will see a visible trace of the acid on the surface. That will be the signal to stop adding drops of solution.',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: "You will need to count the drops of solution required to cover the surface in order to calculate the mass of stearic acid added. From there, multiple calculation will be performed to take us to estimate Avogadro's number. All these will be reviewed in the <strong><em>Tutorial</em></strong>.",
                },
              ],
            },
          ],
        },
      ],
    },
    experiment: {
      name: 'Experiment',
      link: 'experiment',
      tabs: [
        {
          name: 'Start Here',
          order: 1,
          steps: [
            {
              name: 'Summary',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Summary: Experimental Steps',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The general experimental steps are: <li>Claim your watch  glass from the hood and rinse it in deionized water</li><li>Fill your watch glass to its brim with deionized water</li><li>Calibrate the fine-tip pipette with hexane to determine how many drops are needed to make 1 mL</li><li>Add dropwise stearic acid solution to the water surface</li><li>Observe the formation of a multi-layer of stearic acid</li>',
                },
              ],
            },
            {
              name: 'Equipment',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Equipment',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The following pieces of equipment are needed for this experiment:',
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName: '/images/avogadros/equipment.jpg',
                  imageAlt:
                    'The equipment needed are: 600 mL beaker, a large watch glass, a 50 mL beaker, 2 small test tubes, 10 mL graduated cylinder, a fine-tip pippette and its bulb. ',
                  centered: true,
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'You will also need a paper towel to rest the watch glass on to not have your fingertips or hand touch the watch glass.',
                },
              ],
            },
          ],
        },
        {
          name: 'Procedure',
          order: 2,
          steps: [
            {
              name: 'Prep Watch glass',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Prepping the Watch glass',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Set a 600 ml beaker on the lab bench.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'The watch glasses are soaking in a methanol-NaOH solution which cuts the grease from the glass surface. Take a piece of paper towel to the soaking tub, and use the tongs next to the tub to pick up a watch glass. While still holding onto the watch glass with the tongs, dip the watch glass into the container of deionized water provided.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Place the watch glass on the paper towel held in your hand. Do not touch the top of the watch glass with your hand.',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Take the watch glass back to your lab station, and rinse it well with tap water, then give a ﬁnal rinse with deionized water.',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'Set it on the 600 ml beaker. Pour deionized water into the watch glass from a small beaker (such as a 100 mL) until the watch glass is brim full.',
                },
              ],
            },
            {
              name: 'Calibration',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Calibration of the Dropper',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'You will now determine how many drops of hexane per milliliter your fine-tip pipet delivers. Do not use water on the pipet, graduated cylinder, or test tubes used to hold hexane or the stearic acid/hexane solution. Use a clean and dry 10 ml graduated cylinder to bring 2.5 ml of pure hexane to your work area.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Place the rubber bulb from your drawer onto the ﬁne tipped pipet. The bulb should ﬁt snugly. If it does not, see the instructor.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Have a small test tube handy. Support it in a 50 ml beaker.',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Place the pipet tip in the hexane in the graduated cylinder; squeeze and release the bulb a number of times. This saturates the air space in the pipet with hexane vapor, and prevents unwanted dripping of the hexane out of the pipet. Use the pipet to transfer hexane out of the graduated cylinder into the test tube until the level of hexane in the graduated cylinder is at the 1.00 ml mark.',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'Now, taking hexane from the test tube, and holding the pipet straight up, count how many drops it takes to ﬁll the graduated cylinder up to the 2.00 ml mark. Record this number in the data table. If you lose count, transfer the hexane out of the cylinder to the 1.00 mark, and count again.',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: 'Pour the hexane into the waste container provided.',
                },
              ],
            },
            {
              name: 'Counting Drops',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Counting Drops of Stearic Acid to the Water Surface',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Use the mark on the transfer pipet attached to the lab bottle of stearic acid/hexane solution to place 1 ml of the solution in a small, clean and dry test tube.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: "Record the strength of the solution, which is written on the bottle label, in the protocol's data table.",
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Draw up some of the solution into the calibrated pipet. Hold the pipet straight up, and squeeze one drop of the solution onto the center of the water in the watch glass. Watch as the solution spreads out and evaporates. Make sure each drop completely evaporates before adding the next drop. Get an angle of view that allows you to clearly see the drop evaporating.',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Now add another drop, and wait until it evaporates. Continue to do this, counting the drops.',
                },
                {
                  order: 6,
                  type: 'Input',
                  directions:
                    '(1) Which is the correct method to deliver the stearic acid solution to the water surface? (A or B)',
                  validAnswers: ['A', 'a'],
                  tip: 'The experimental protocol reads: ”Hold the pipette straight up, and squeeze one drop of the solution onto the center of the water in the watch glass.”',
                },
                {
                  order: 7,
                  type: 'Images',
                  images: [
                    {
                      imageFileName: '/images/avogadros/vertical-delivery.jpg',
                      imageAlt:
                        'A pipette hovers perpendicular to the watch glass that is resting on a beaker.',
                      imageTitle: 'A',
                    },
                    {
                      imageFileName: '/images/avogadros/sideways-delivery.jpg',
                      imageAlt:
                        'A pipette is slanted sideways with a drop formed at its tip. It hovers over the watch glass that is resting on a beaker.',
                      imageTitle: 'B',
                    },
                  ],
                },
                {
                  order: 8,
                  type: 'Input',
                  directions:
                    '(2) Determine the correct way for delivering the stearic acid solution to the water surface? (Option 1 or Option 2)',
                  validAnswers: [
                    '2',
                    'Option 2',
                    'option 2',
                    'B',
                    'b',
                    'Option B',
                    'option b',
                  ],
                  tip: 'The experimental protocol reads: “Make sure each drop completely evaporates before adding the next drop.” You need to count the drops so the drops must be delivered individually.',
                },
                {
                  order: 9,
                  type: 'Text',
                  text: '<p><iframe width="560" height="315" src="https://www.youtube.com/embed/0SdarLowEUg" title="Stearic acid dropwise delivery option 1 and Option 2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>',
                },
                {
                  order: 10,
                  type: 'Text',
                  text: '(3) What is wrong in this video?<p><iframe width="250" height="412" src="https://www.youtube.com/embed/tcy4uugr9F4?&playlist=tcy4uugr9F4&loop=1" title="Stearic acid dropwise delivery with a water leak" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>',
                },
                {
                  order: 11,
                  type: 'Multiple Choice',
                  choices: [
                    {
                      label: 'The drop delivery is too fast.',
                      tip: 'Fast delivery could be a problem as one cannot count the drops well or wait for the hexane to evaporate.',
                      validChecked: true,
                    },
                    {
                      label: 'The drops are too small.',
                      tip: 'Hard to tell if the drop size is a problem. The drops should be uniform, but that is not the statement.',
                      validChecked: false,
                    },
                    {
                      label: 'Water is leaking from the water surface',
                      tip: 'Water is added to the watchglass to its brim. Sometimes the surface tension of the water layer can break and a channel of water develops flowing water down the watch glass and to the bottom of the beaker. If this happens, you unfortunately have to start the your experiment over with a new watchglass as the multi-layer of stearic acid will likely not form as stearic acid is exiting with the channel of water.',
                      validChecked: true,
                    },
                  ],
                },
              ],
            },
            {
              name: 'Multi-layer Formation',
              order: 4,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Multi-layer Formation',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'You will notice that the drops begin to take longer and longer to evaporate. This happens as the surface gets more and more crowded with stearic acid molecules. Finally, you will see a very small amount of sediment, sort of a whitish scum, on the surface where a drop just evaporated. This is the multi-molecular pile of stearic acid molecules that occurs after the surface has been covered by the monomolecular layer. You might have to vary your angle of view of the surface so that the light is right to help you to see the deposit on the surface.',
                },
                {
                  order: 3,
                  type: 'Header',
                  text: 'How do you know when you have a multi-layer of stearic acid?',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Click on the magnifying glass to view the multi-layer',
                },
                {
                  order: 5,
                  type: 'Magnified Image',
                  imageFileName: '/images/avogadros/scum.jpg',
                  imageAlt:
                    'Reflections are seen on a watch glass. One specific smudge mark represents the multi-layer of stearic acid and is marked by a large red circle around it.',
                  imageZoomFileName: '/images/avogadros/scum-magnified.jpg',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'In general, it is hard to see the monolayer of stearic acid on the water surface. It is similar to seeing the reflection of grease on a water puddle after a rain. Search for reflective spots on the surface at several different angles.',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: 'The best technique is to watch a drop of the stearic acid solution evaporate. It takes time for the evaporation, so be patient. You may be able to distinguish the development of the multi-layer that way.',
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'Know that you are adding scores of drops. Much patience will be needed for this experiment!',
                },
                {
                  order: 9,
                  type: 'Text',
                  text: 'Once you have detected a multi-layer, you will stop the addition of stearic acid. Record in your notebook how many drops were added. However, <strong>do not count the last drop</strong>! You will record in your lab notebook, the number of drops delivered on the water surface <strong>up to that last drop</strong>.',
                },
                {
                  order: 10,
                  type: 'Text',
                  text: '<strong>What to record in your notebook: Total drops of stearic acid delivered minus 1.</strong>',
                  centered: true,
                },
              ],
            },
            {
              name: 'Water Surface',
              order: 5,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Measuring the Water Glass',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Use a ruler to measure the diameter of the water surface by measuring the watch glass. This measurement will be practiced in the <strong><em>Tutorial</em></strong>.',
                },
              ],
            },
            {
              name: 'Clean-up',
              order: 5,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Clean-up',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Slide the beaker holding the watch glass over to the sink, and dump the water into the sink.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Return the watch glass to the soaking tub.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Pour the excess stearic acid solution into the waste container provided.',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Pull the bulb off of the pipet. Do not rinse the pipet with water. Return the pipet to the cart.',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'Clean up your area, and proceed to the calculations. ',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: 'You are ﬁnished with the experiment guide!',
                  centered: true,
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'Head into the <strong><em>Tutorial</em></strong> to review all calculations.',
                  centered: true,
                },
              ],
            },
          ],
        },
      ],
    },
    tutorial: {
      name: 'Tutorial',
      link: 'tutorial',
      tabs: [
        {
          name: 'Initial Calculations',
          order: 1,
          steps: [
            {
              name: 'Stearic Acid',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Strength of the Stearic Acid Solution',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The bottle of stearic acid has a concentration written on its label. The units are grams per liter. The calculations that follow will require grams per milliliter. Take note.',
                },
              ],
            },
            {
              name: 'Monolayer',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Volume of a Stearic Acid Monolayer',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'In the lab, you formed a monolayer of stearic acid on top of the surface of water that took shape of a watch glass.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'To find the volume of that monolayer, what pieces of information do you need?',
                },
                {
                  order: 2,
                  type: 'Multiple Choice',
                  directions:
                    'To find the volume of that monolayer, what pieces of information do you need?',
                  choices: [
                    {
                      label:
                        'drops of hexane per milliliter (drops/mL) determined by the calibration of the pipette',
                      tip: 'If you cancel the drops of hexane, the milliliter term in the denominator can be useful.',
                      validChecked: true,
                    },
                    {
                      label: 'stearic acid solition strength (g/L)',
                      tip: 'While there is a volume term here, you are looking to reach units of mL/monolayer',
                      validChecked: false,
                    },
                    {
                      label:
                        'drops needed to form a stearic acid monolayer (drops/monolayer)',
                      tip: 'If you can cancel the drops of stearic acid whose solvent is hexane, the monolayer term in the denominator can be useful.',
                      validChecked: true,
                    },
                    {
                      label: 'diameter of water surface (cm)',
                      tip: 'While the radius could seem like a good start to find a volume of a cylinder (assuming for a monolayer), there is a more direct cenceling of units that lead to the volume of the monolayer.',
                      validChecked: false,
                    },
                    {
                      label: 'area of water surface (cm<sup>2</sup>)',
                      tip: 'While the area could seem like a good start to find a volume of a cylinder (assuming for a monolayer), there is a more direct cenceling of units that lead to the volume of the monolayer.',
                      validChecked: false,
                    },
                  ],
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'These values will be divided so that the units cancel and the volume in milliters per stearic acid monolayer is obtained. Write the equation and practice calculating the volume of a monolayer below.',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions:
                    'It if took 84 drops of hexane to reach a milliliter and 11 drops of stearic acid to form a monolayer across the watch glass, what is the volume of the monolayer? ',
                  validAnswers: ['0.13'],
                  unitOfMeasure: 'mL',
                  tip: 'This is a division. Ensure that the units  "drops" cancel out properly so that you are left with just milliliters.',
                },
                {
                  order: 5,
                  type: 'Input',
                  directions:
                    'Convert the answer from above to cubic centimeters',
                  validAnswers: ['0.13'],
                  unitOfMeasure: 'cm<sup>3</sup>',
                  tip: 'Use this conversion: 1 mL = 1 cm<sup>3</sup>.',
                },
              ],
            },
            {
              name: 'Area',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Area of the Water Surface',
                },
                {
                  order: 2,
                  type: 'Magnified Image',
                  imageFileName: '/images/avogadros/watch-glass.jpg',
                  imageAlt:
                    'A top view of a watch glass with a ruler laid across its diameter. Watch glass measures 10.05 cm.',
                  imageZoomFileName: '/images/avogadros/watch-glass-zoom.jpg',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions:
                    '(1) Measure the diameter of the water surface in centimeters based on the watch glass in the image above.',
                  validAnswers: [
                    '10.10',
                    '10.09',
                    '10.08',
                    '10.07',
                    '10.06',
                    '10.05',
                  ],
                  unitOfMeasure: 'cm',
                  tip: 'Be sure to measure using the ruler with the correct number of significant figures. This means you should have an estimated digit, which is easiest to view using the magnifier.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: '(2) What is the equation for the area of a circle?',
                },
                {
                  order: 4,
                  type: 'Multiple Choice',
                  directions:
                    '(2) What is the equation for the area of a circle?',
                  choices: [
                    {
                      label: 'b x h',
                      validChecked: false,
                    },
                    {
                      label: '&pi;r2h',
                      validChecked: false,
                    },
                    {
                      label: '&pi;r<sup>2</sup>',
                      validChecked: true,
                    },
                    {
                      label: '2&pi;r',
                      validChecked: false,
                    },
                  ],
                },
                {
                  order: 5,
                  type: 'Input',
                  directions:
                    '(3) What is the area of the water surface based on the measurement above? (round your answer to 2 digits)',
                  validAnswers: ['80', '80.', '79'],
                  tip: 'Since there are multiple assumptions further along in this exercise, significant figures become less important here.',
                },
              ],
            },
            {
              name: 'Mass',
              order: 4,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Mass of Stearic Acid ',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'What two pieces of information do you need to calculate mass of stearic acid?',
                },
                {
                  order: 3,
                  type: 'Multiple Choice',
                  directions:
                    'Review the units for each selection below. What two pieces of information will you need to calculate the mass of stearic acid?',
                  choices: [
                    {
                      label: 'drops of hexane per mL (drops/mL)',
                      tip: 'Drops do not seem useful here. Volume may have some use, but then again, you are stuck with drops, an unuseful unit.',
                      validChecked: false,
                    },
                    {
                      label: 'stearic acid solution strength (g/mL)',
                      tip: 'This option is most useful from all choices since it has the units of mass, you just need to cancel out the volume term.',
                      validChecked: true,
                    },
                    {
                      label:
                        'drops of stearic acid to form the monolayer (drops/monolayer)',
                      tip: 'Nothing here seems useful for cancelig out terms to result in a value with units of mass.',
                      validChecked: false,
                    },
                    {
                      label: 'diameter of water surface (cm)',
                      tip: 'Length does not seem useful for cancelig out terms to result in a value with units of mass.',
                      validChecked: false,
                    },
                    {
                      label:
                        'volume to form a stearic acid monolayer (cm<sup>3</sup>)',
                      tip: 'The volume term could be useful here.<p>Keep in mind that 1 mL = 1 cm<sup>3</sup>',
                      validChecked: true,
                    },
                    {
                      label: 'area of water surface (cm<sup>2</sup>)',
                      tip: 'Area does not seem useful for cancelig out terms to result in a value with units of mass.',
                      validChecked: false,
                    },
                  ],
                },
                {
                  order: 4,
                  type: 'Input',
                  directions:
                    'Given a stearic acid solution strength of 0.25 g/L, what would be its strength in units of g/mL?',
                  validAnswers: ['0.00025', '2.5E-4'],
                  unitOfMeasure: 'g/mL',
                  tip: 'Do a unit conversion from L to mL for the denominator.',
                },
                {
                  order: 5,
                  type: 'Input',
                  directions:
                    'Calculate the mass of stearic acid in grams (to 2 digits).',
                  validAnswers: ['3.2E-5', '3.3E-5', '0.000032', '0.000033'],
                  unitOfMeasure: 'g',
                  tip: 'Multiply the stearic acid strength in grams per mL by the volume of a monolayer. Ensure that you are canceling the units properly. ',
                },
              ],
            },
            {
              name: 'Volume',
              order: 5,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Volume of Stearic Acid',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'You will use the mass of stearic acid and the density of stearic acid to calculate the volume of stearic acid.',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The density of stearic acid is 0.85 g/cm<sup>3</sup>.',
                  centered: true,
                },
                {
                  order: 3,
                  type: 'Input',
                  directions: 'Calculate the volume of stearic acid added.',
                  validAnswers: ['3.9E-5', '0.000039', '3.8E-5', '0.000038'],
                  unitOfMeasure: 'cm<sup>3</sup>',
                  tip: 'Ensure that you are setting up your calculation carefully so that the units of mass cancel out.<p>Your two pieces of information is the density in grams per cm<sup>3</sup> and the mass of stearic acid in grams (from the previous step).</p>',
                },
              ],
            },
            {
              name: 'Thickness',
              order: 6,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Thickness of the Monolayer',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The thickness of the monolayer is one of the dimensions when in 3-dimensions. Consider thickness equivalent to the height. Therefore, if you have the area and the volume, you can use both to calculate the thickess.',
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName: '/images/avogadros/height.jpg',
                  imageAlt:
                    'A drawing of a water layer, labeled water, with a layer of stearic acid on top. The stearic acid is labeled "Area on Top of Water". To its left of the layer of stearic acid is the word height, which is marking the height in length of the layer.',
                  centered: true,
                },
                {
                  order: 4,
                  type: 'Input',
                  directions:
                    'Calculate the thickness in centimeters of the added stearic acid.',
                  validAnswers: ['4.9E-7', '4.8E-7'],
                  unitOfMeasure: 'cm',
                  tip: 'The units of thickness is centimeters. Meanwhile, the volume of a monolayer has units of cm<sup>3</sup> and area has units of cm<sup>2</sup>. Set the volume and area to cancel out the cm<sup>2</sup> unit and obtain just centimeters.',
                },
              ],
            },
          ],
        },
        {
          name: 'Continued Calculations',
          order: 2,
          steps: [
            {
              name: 'Assumptions',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'The next set of calculations are based on a number of assumptions that are only approximately true.',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Among these are the following:<li>The carbon atoms actually stick out in a zig-zag fashion, not in a straight line.</li><li>Carbon atoms are spherical. The calculations assume they are cubic.</li><li>The diamond crystalline structure includes some empty space between atoms. The calculations assume there is no empty space.</li>',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: "Let's now see how these assumptions are used.",
                },
              ],
            },
            {
              name: 'Height',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Height of a Carbon Atom',
                },
                {
                  order: 2,
                  type: 'Image',
                  imageFileName: '/images/avogadros/stearic-acid-model.jpg',
                  imageAlt: 'The molecular model in 3D of stearic acid.',
                  centered: true,
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'There are 18 carbons in stearic acid. If we divide the height of the stearic acid by 18, it gives an approximate height of one carbon atom.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Why is this an approximation? Because the carbons zig-zag in structure and are not in a straight line! Therefore, the height is only an approximation.',
                },
                {
                  order: 5,
                  type: 'Input',
                  directions: 'Calculate the height of one carbon atom.',
                  validAnswers: ['2.7E-8', '2.6E-8'],
                  unitOfMeasure: 'cm',
                  tip: 'Divide the thickness of a monolayer by the number 18 to give an approximation of the height of one carbon atom.',
                },
              ],
            },
            {
              name: 'Volume',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Volume of a Carbon Atom',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: "Let's now cube the height of a carbon atom to get an approximate volume of one carbon atom.",
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Why is this an approximation? Because atoms are spherical. The calculations here assume that they are cubic.',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions: 'Calculate the volume of a carbon atom.',
                  validAnswers: ['2.0E-23', '1.9E-23', '1.8E-23'],
                  unitOfMeasure: 'cm<sup>3</sup>/atom',
                  tip: 'Cube the height of one carbon atom to give the volume of one carbon atom.',
                },
              ],
            },
            {
              name: 'The Mole',
              order: 4,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Volume of a Mole of Carbon',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Since we are working with the carbon atom, diamond is a good approximation for a pure sample of carbon. If we use the density of diamond and the molar mass of carbon, we can calculate the volume of a mole of carbon atoms.<li>The density of diamond is 3.51 g/cm<sup>3</sup></li><li>Carbon has a molar mass of 12.0 g/mol</li>.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Why is this an approximation? The diamond crystalline structure includes some empty space between atoms. The calculations here assume that there are no empty spaces.',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions: 'Calculate the volume of a mole of carbon atoms.',
                  validAnswers: ['3.42'],
                  unitOfMeasure: 'cm<sup>3</sup>/mole',
                  tip: 'Using the parameters provided on this page, ensure that units are lined up correctly to cancel out the mass, resulting in the units of mole per cm<sup>3</sup>.',
                },
              ],
            },
            {
              name: 'Final Step',
              order: 5,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: "Avogadro's Number",
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Almost there! Use the values that you have recently calculated. You are looking to cancel out units that result in atoms per mole.',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions: "Calculate Avogadro's number (to 2 digits).",
                  validAnswers: [
                    '1.7E23',
                    '1.7E+23',
                    '1.8E23',
                    '1.8E+23',
                    '1.9E23',
                    '1.9E+23',
                  ],
                  unitOfMeasure: 'atoms/mole',
                  tip: 'If there is a number with units of mole/cm<sup>3</sup> and another number with units cm<sup>3</sup>/atom, line up these units in order to cancel out cm<sup>3</sup>. Ensure your final answer has units of atoms/mole.',
                },
              ],
            },
            {
              name: 'Number Check',
              order: 6,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Number Check',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: "Even with these assumptions, you should get a value for Avogadro's number that is within an order of magnitude of the book value. Since an order of magnitude is a 1000% variation, do not think in % error terms in this experiment. Instead, think of the marvelous nature of the investigation.",
                },
                {
                  order: 3,
                  type: 'Input',
                  directions:
                    "Thus, divide your calculated Avogadro's number by 6.02&times10<sup>23</sup> to get a ratio of how your value compares to the actual.",
                  validAnswers: ['0.28', '0.29', '0.30', '0.31'],
                  tip: "Is the number between 0.1 and 10? If so, you are within an order of magnitude of the book value for Avogadro's number. If you followed this simulation and received “correct” at every step, then you did the correct calculations to approximate Avogadro's number.",
                },
              ],
            },
          ],
        },
      ],
    },
  },

  rank: 7,
};

export default avagadrosNumber;
