import React from 'react';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import InnerImageZoom from 'react-inner-image-zoom';

const MagnifiedImage = (props) => {
  return (
    <InnerImageZoom
      src={props.imageFileName}
      zoomSrc={props.imageZoomFileName}
    />
  );
};

export default MagnifiedImage;
