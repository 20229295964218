import React, { useState } from 'react';
import { Box, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useParams } from 'react-router-dom';
import Tabs from '../../components/Tabs';
import Data from '../../data/LabsData';

const Experiment = (props) => {
  const { labId } = useParams();
  const sectionData = Data[labId].content.experiment;
  const [activeStep, setActiveStep] = useState(sectionData.tabs[0].name);

  const handleChangeStep = (event, newValue) => {
    setActiveStep(newValue);
  };

  return (
    <>
      <Typography variant='h5' sx={{ pb: 1 }}>
        Experiment
      </Typography>
      <TabContext value={activeStep}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '100%',
          }}
        >
          <TabList onChange={handleChangeStep} aria-label='Section tabs'>
            {sectionData.tabs.map((tab) => {
              return <Tab key={tab.name} label={tab.name} value={tab.name} />;
            })}
          </TabList>
        </Box>
        {sectionData.tabs
          .sort((a, b) => a - b)
          .map((tab) => {
            return (
              <TabPanel key={tab.name} value={tab.name}>
                <Tabs steps={tab.steps} />
              </TabPanel>
            );
          })}
      </TabContext>
    </>
  );
};

export default Experiment;
