const molarVolumeGas = {
    status: 'Active',
    name: 'Molar Volume of a Gas',
    imageFileName: 'images/molar-volume-icon.jpg',
    imageAlt: 'A coiled into a ball copper water is in a glass tube. The tube is partially submerged in water. ',
    description: 'Experimental guide to determine the molar volume of a gas.',
    tags: ['Pre-Lab', 'Wet Lab', 'Experiment', 'Chem 101A'],
    page: '/molar-volume-gas',
    content: {
      overview: {
        name: 'Overview',
        link: 'overview',
        tabs: [
          {
            name: 'NOTAB',
            order: 1,
            steps: [
              {
                name: 'NOSTEP',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Text',
                    text: 'In this experiment, you will be determining the molar volume of a gas at room temperature and pressure. The molar volume, V/n, of a gas is the number of liters occupied by 1 mole of the gas. You will be reacting magnesium metal with excess hydrochloric acid to obtain the products magnesium chloride and hydrogen gas. It is the hydrogen gas that will be collected, its number of moles calculated and molar volume of the gas determined at room temperature and pressure.',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Read through the <strong><em>Introduction</em></strong> to learn about the reaction and the importance of Dalton\'s Law. Next in the <strong><em>Experiment</em></strong>, learn how to set-up the eudiometer and watch the reaction proceed.',
                  },
                ],
              },
            ],
          },
        ],
      },
      introduction: {
        name: 'Introduction',
        link: 'introduction',
        tabs: [
          {
            name: 'NOTAB',
            order: 1,
            steps: [
              {
                name: 'Reaction',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Text',
                    text: 'Reacting magnesium metal with excess hydrochloric acid produces magnesium chloride and hydrogen gas according to the balanced reaction below.',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Mg(s) + 2HCl(aq) → MgCl<sub>2</sub>(aq) + H<sub>2</sub>(g)',
                    centered: true,
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'The net ionic equation of this reaction is:',
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Mg(s) + 2H<sup>+</sup>(aq) → Mg<sup>2+</sup>(aq) + H<sub>2</sub>(g)',
                    centered: true,
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'This is an oxidation-reduction reaction in which magnesium is becoming oxidized and hydrogen is becoming reduced. Elemental magnesium is losing electrons which are being accepted by hydrogen ions to form hydrogen gas. ',
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: 'Mg(s)→ Mg<sup>2+</sup>(aq) + 2&#233',
                    centered: true,
                  },
                  {
                    order: 7,
                    type: 'Text',
                    text: '2H<sup>+</sup>(aq) + 2&#233 → H<sub>2</sub>(g)',
                    centered: true,
                  },
                  {
                    order: 8,
                    type: 'Text',
                    text: 'Observe that the magnesium metal and hydrogen gas are in a 1:1 mole ratio. Since magnesium is the limiting reactant, this will make it easy for you to calculate the moles of hydrogen gas generated in this reaction. The volume of gas produced will be measured in a gas measuring tube, called an <b><i>eudiometer</b></i>.',
                  },
                  {
                    order: 9,
                    type: 'Image',
                   imageFileName:'/images/molar-volume/eudiometer.jpg',
                   imageAlt: 'An eudiometer is viewed from a top down view. The tube is rounded and closed at the top and extends down to the bottom of the ring stand. A burette clamp is visible about halfway down the tube. The graduated markings begin at 1 millimeter, the smallest increment is 0.2 milliliters anderror range of +/-0.1 milliliters.',
                   centered: true,
                  },
                ],
              },
              {
                name: 'Dalton\'s Law',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Text',
                    text: 'In a mixture of gases, each component exerts a certain amount of pressure of its own. This pressure, called <b>partial pressure</b>, is defined as that pressure which the component would exert if it alone occupied the total volume at the given temperature. Mathematically,',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'P = P<sub>1</sub> + P<sub>2</sub> + P<sub>3</sub>  +…',
                    centered: true,
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'where P is the total pressure of the mixture and P<sub>1</sub> + P<sub>2</sub> + P<sub>3</sub>…are the partial pressures of gases 1, 2, 3 etc. This relationship is known as <b>Dalton\'s Law of Partial Pressures</b>.  ',
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'When a confined gas is generated in contact with water, the gas becomes saturated with water vapor, producing a “wet” gas—a gaseous mixture. The partial pressure of water vapor, which depends on temperature, can be found here:',
                  },
                  {
                    order: 6,
                    type: 'Information Table',
                    headers: ['Temperature (&degC)', 'Vapor Pressure (torr) '],
                    rows: [
                      { id: 1, cells: ['17', '14.5'] },
                      { id: 2, cells: ['18', '15.5'] },
                      { id: 3, cells: ['19', '16.5'] },
                      { id: 4, cells: ['20', '17.5'] },
                      { id: 5, cells: ['21', '18.6'] },
                      { id: 6, cells: ['22', '19.8'] },
                      { id: 7, cells: ['23', '21.1'] },
                      { id: 8, cells: ['24', '22.4'] },
                      { id: 9, cells: ['25', '23.8'] },
                      { id: 10, cells: ['26', '25.2'] },
                      { id: 11, cells: ['27', '26.7'] },
                      { id: 11, cells: ['28', '28.3'] },
                    ],
                  },
                  {
                    order: 8,
                    type: 'Text',
                    text: 'The hydrogen gas in this experiment is collected over water. Using the ideal gas law and Dalton\'s law, the measured volume will be corrected to obtain the volume of <b><i>dry</b></i> hydrogen at room temperature. The volume of the hydrogen gas generated needs to be adjusted to room temperature and pressure before it can be used to calculate the molar volume of the gas in liters/mole. ',
                  },
                ],
              },
            ],
          },
        ],
      },
      experiment: {
        name: 'Experiment',
        link: 'experiment',
        tabs: [
          {
            name: 'Start Here',
            order: 1,
            steps: [
              {
                name: 'Summary',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Summary: Experimental Steps',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'The general experimental steps are: <li>Polish, weigh and roll up tightly a magnesium ribbon.</li><li>Create a copper cage to wrap around the Mg roll.</li><li>Add HCl to the eudiometer and fill to its brim with water.</li><li>Insert the cage to the mouth of the eudiometer and cap with a stopper.</li><li>Flip the eudiometer over and wait approximately 30 minutes for the reaction to take place.</li><li>Measure the height of water in the eudiometer, and the room temperature and pressure.</li>',
                  },
                ],
              },
              {
                name: 'Equipment',
                order: 2,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Equipment',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'The following pieces of equipment are needed for this experiment:',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/molar-volume/equipment.jpg',
                    imageAlt: 'The equipment is shown spread out on the lab bench. Numbers are next to each and a key is to its right 1. ring stand, 2. burette clamp, 3. eudiometer, 4. stee wool, 5. copper wire with Mg ribbon insider, 6. one-hole stopper, 7. 600 mL beaker, 8. 250 mL beaker, 9. 10 mL graduated cylinder. Below the key is a close-up view of items 5 and 6.',
                    centered: true,
                  },
                ],
              },
              {
                name: 'Notebook Prep',
                order: 3,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Notebook Preparation',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Draw the <strong>Data Table</strong> in your notebook:',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/molar-volume/data-table.jpg',
                    imageAlt: 'A 6 row 2 column table. The first row lists the headers of the columnss as: Data Table, Trial Data. Starting at row 2 to row 6: 1. Mass of magnesium, 2. Volume of gas generated from reaction, 3. Height of water column (above water level in beaker) in eudiometer, 4. Room temperature, and 5. Barometric reading. Column "Trial Data" lists the units of for the data to be written in by the student. Row 2 is in grams. Row 3 is literes. Row 4 is millimeters. Row 5 is degrees Celsius. Row 6 has inches of mercury.',
                    centered: true,
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Next, draw the <strong>Calculation Table</strong> in your notebook.',
                  },
                  {
                    order: 5,
                    type: 'Image',
                    imageFileName: '/images/molar-volume/calculation-table.jpg',
                    imageAlt: 'A 11 row 4 column table. The first row lists the headers of the columns 1 to 4 as: Step #, Calculation Table, Calculation Space Units. Column "Step #" lists the calculation steps for each row outlined in the lab protocol, 1 to 10. Starting at row 2 to column 2: h = height of waterMass of O2, P sub wet = Pressure of wet hydrogen, P sub dry = partial pressure of hydroge, V = volume of hydrogen, n sub theoretical = moles of hydrogen, V sub m  (experimental) = Molar Volume of hydrogen, V sub m (theoretical)= Molar Volume of hydrogen, % error of Molar Volume of hydrogen, n sub expermental = moles of hydrogen, % error of moles of hydrogen. All other cells are empty',
                    centered: true,
                  },
                ],
              },
            ],
          },
          {
            name: 'Procedure',
            order: 2,
            steps: [
              {
                name: 'Chemical Prep',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Chemical Preparation',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Fill the 250 ml beaker with 150 ml of deionized water. Let it stand until it is at room temperature and does not contain any more bubbles. ',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'Meanwhile, obtain a piece of magnesium ribbon with an arbitrary mass between 0.065 and 0.085 grams. With a piece of steel wool, rub the magnesium of both sides to remove any solid particles attached to it. Once it is clean, hold the magnesium with a piece of weighing paper and not fingertips because fingerprints will contribute to the weight. Measure and record the weight of your magnesium ribbon to the nearest milligram (0.001 gram).',
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: '<em>Remember that the balances are highly sensitive; so do not write on the bench next to the balance, as it will cause resonance in the balance.</em>',
                    centered: true,
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'Roll the magnesium ribbon into an irregular shaped ball. Wrap the copper wire around the magnesium, forming a “cage”. Make sure the “cage” doesn\'t have any large holes, as the magnesium will fall out during the reaction. Do not make it too tight either, as the reaction will take a long time. Leave about 5 cm extra wire at the end as a "handle".',
                  },
                  {
                    order: 6,
                    type: 'Image',
                    imageFileName: '/images/molar-volume/cage.jpg',
                    imageAlt: 'A copper wire rolled up into a web of a spherical cage with a long tail aboud 5 centimers with the end being made into a hook. Inside the cage is a rolled piece of magnesium.',
                    centered: true,
                  },
                ],
              },
              {
                name: 'Filling',
                order: 3,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Filling the Eudiometer',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Fill a 600 mL beaker with ~150 mL of tap water, and place it on the base of the ring stand. Attach a burette clamp to the ring stand.',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'Clean a 100 mL eudiometer by rinsing it with deionized water. It does not have to be dried. Fill it with about 5 mL of concentrated HCl.',
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: '<em>Because 6M hydrochloric acid is highly corrosive, handle the acid with care and do not inhale vapors.</em>',
                    centered: true,
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'Holding the eudiometer at an angle, slowly and gently pour the deionized water from the 250 mL beaker (which should now be at room temperature) into it slowly. As the eudiometer fills, raise it to a vertical position and fill it to the very top with water. Do this over a sink as a little water may overflow. Make sure there is no air in the eudiometer and it is filled to the brim with only water (and HCl).',
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: 'Clamp the eudiometer to the ring stand.',
                  },
                  {
                    order: 7,
                    type: 'Images',
                    images: [
                      {
                        imageFileName: '/images/molar-volume/HCl.jpg',
                        imageAlt: 'An upside down eudiometer holds hydrochloric acid. The numbers appear upside down and reach the 5 milliliter mark.',
                        imageTitle: 'The eudiometer filled with HCl',
                      },
                      {
                        imageFileName: '/images/molar-volume/filling.jpg',
                        imageAlt: 'A diagram of an upside down eudiometer titled to the side. At the top is a small beaker being used to pour a liquid inside the eudiometer.',
                        imageTitle: 'Filling the eudiometer with water',
                      },
                      {
                        imageFileName: '/images/molar-volume/brim.jpg',
                        imageAlt: 'An upside down eudiometer holds water all the way to its brim.',
                        imageTitle: 'The eudimeter filled to its brim',
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Cage Placement',
                order: 4,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Cage Placement',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'For this next part, you will have to work very quickly to get accurate results.',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'Place the encaged Mg ribbon approximately 4 cm into the eudiometer. <em>Try to avoid the cage entering the calibrated part of the eudiometer </em>. Bend the extra copper wire “handle” over the lip of the eudiometer. Quickly insert a 1 hole rubber stopper into the eudiometer, locking the wire in place.',
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Watch: Capping the Eudiometer<p><iframe width="560" height="315" src="https://www.youtube.com/embed/UA0Z9CUxX5M?&playlist=UA0Z9CUxX5M&loop=1" title="Capping the Eudiometer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>',
                  },
                ],
              },
              {
                name: 'Reaction',
                order: 5,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Starting the Reaction',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'With your finger, cover the hole in stopper and flip the eudiometer. Do not remove your finger until the stopper is under water. Working quickly, submerge it into the 600 mL beaker (with water). Once it is in water, you can remove your finger. Use the burette clamp to hold the eudiometer vertically in place. Make sure the stopper does not touch the bottom of the beaker. Leave about an inch of space between the stopper and bottom the beaker. The setup should look like this: ',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/molar-volume/setup.jpg',
                    imageAlt: 'A ring stand with an buret clamp holding a 100 mL eudiometer that is hovering a 600 mL beaker with water The eudiometer is touching the beaker water but not touch the bottom of the beaker. About 5 centimeters from the mouth of the eudiometer is the copper cage holding the magnesium rolled up ribbon. A close-up view of the mouth and cage of the eudiometer has labels of the different parts. One is the "Mg strip in wire cage". Another is the "one-hold rubber stopper" and the third is a "wire exending from cage".',
                    centered: true,
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Observe the wavy lines of hydrochloric acid moving through the water. As the acid reaches the magnesium, the reaction will begin, releasing hydrogen gas that will rise to the top. Watch to see that no small pieces of magnesium escape from the “cage”.',
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: 'Watch: How to flip the eudiometer, start the reaction, and observe how the reaction proceeds.<p><iframe width="560" height="315" src="https://www.youtube.com/embed/TvHOXaw7iC0?&playlist=TvHOXaw7iC0&loop=1" title="Mg and HCl reaction" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>',
                  },
                ],
              },
              {
                name: 'Data Collection',
                order: 5,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Data Collection',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'The reaction is complete when all the bubbling stops. Allow the system to stand for several minutes as the gas reaches room temperature. Gently tap the sides of the tube to collect bubbles trapped in the “cage” or stuck to the walls of the tube.',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'Use the graduations on the side of the eudiometer to record the <strong>volume</strong> of the gas collected using the proper number of significant digits.',
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Use a ruler to measure the <strong>height</strong> of the water column in the tube above the water level to the nearest millimeter.',
                  },
                  {
                    order: 5,
                    type: 'Image',
                    imageFileName: '/images/molar-volume/height-measurement.jpg',
                    imageAlt: 'A diagram of the eudiometer hovering over a  beaker with water. Water is inside the eudiometer and a bracket extends from the top of the beaker water to the top of the water in the eudiometer and labele by a height of "h". From the "h" a line extends to "Difference between the water level in the gas collection tube and the beaker". Above the water in the eudiometer to the top of the eudiometer is a bracket with the label "Volume of gas collected". The space in this area has a line extending out and says "H2 gas saturated with H2O vapor.',
                    centered: true,
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: 'In addition, record the <strong> room temperature</strong> in Celsius by lifting your thermometer and reading it and then measure atmospheric <strong>pressure</strong> using the lab barometer.',
                  },
                  {
                    order: 7,
                    type: 'Image',
                    imageFileName: '/images/molar-volume/barometer.jpg',
                    imageAlt: 'A digital barometer resting on the lab bench showing the air pressure of 29.71 inHg at the bottom of its display screen',
                    centered: true,
                  },

                  {
                    order: 8,
                    type: 'Header',
                    text: 'Clean-up',
                  },
                  {
                    order: 9,
                    type: 'Text',
                    text: 'Unroll the copper wire, rinse it, dry it and put it back where you found it. Rinse and dry all glassware before storing them. ',
                  },
                  {
                    order: 10,
                    type: 'Text',
                    text: 'You are ﬁnished with the experiment guide!',
                    centered: true,
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    rank: 17,
  }

export default molarVolumeGas