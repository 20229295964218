import React from 'react';
import { Chip } from '@mui/material';

const Tags = (props) => {
  return props.tags.map((tag) => {
    return (
      <Chip key={tag} label={tag} size='small' sx={{ mx: 0.5, my: 0.5 }} />
    );
  });
};

export default Tags;
