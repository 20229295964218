const conductivityOfSolutions = {
  status: 'Active',
  name: 'Conductivity of Solutions',
  imageFileName: 'images/bulb-icon.jpg',
  imageAlt: 'A bright light bulb shines. A hand is holding the board that the bulb is attached to. Beneath the board are two electrodes dipped in beaker with a clear solution.',
  description: 'A variety of solutions are explored for their conductivity.',
  tags: ['Demo', 'Chem-101A'],
  page: 'conductivity',
  content: {
    overview: {
      name: 'Overview',
      link: 'overview',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'NOSTEP',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'Various solutions will be observed for their chemical property, conductivity. Read through the <strong><em>Introduction</em></strong> to learn about what makes a solution conductive. Next, follow the instructions in the <strong><em>Experiment\'s Demo Activity</em></strong>, which will ask you to prepare for the activity by drawing a table in your notebook, and write your predictions about their conductivity in the table. Finally, watch the demo video to learn how close your predictions were. You will be asked to critically think of the substance\'s conductivity and explain the observations that you see.',
                },
              ],
            },
          ],
        },
      ],
    },
    introduction: {
      name: 'Introduction',
      link: 'introduction',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'NOSTEP',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'Conductivity is a chemical property that can be used to distinguish one solution type from another. For a solution to be conductive, it must consist of electrolytes, mobile particles that have charge, in other words, they must be ions. Conductivity increases with the concentration of ions. If there is a high concentration of ions in the solution, an electrical current will easily travel through the solution making it highly conductive, whereas if there is a low concentration of ions in the solution, there are less paths to carry an electrical current making the solution less conductive. If there are no ions at all, there is no pathway for an electrical current to pass through the solution and the solution is not conductive.',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'A qualitative method to observe conductivity is dipping a pair of electrodes connected to a light bulb. We can observe the light bulb shine brightly or dimly or not at all, and that can give us an indication whether there are ions present and if in high enough concentration.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Here is a light bulb attached to exposed electrodes. When the apparatus is dipped in deionized water (water that has its minerals removed), the bulb does not shine, as seen in the photo below. This proves that pure water is not conductive. While water does ionize, it is such a tiny fraction that it is insufficient to carry any electrical current.',
                },
                {
                  order: 4,
                  type: 'Images',
                  images: [
                    {
                      imageFileName: '/images/conductivity/lightbulb.jpg',
                      imageAlt:
                        'A light bulb is attached to a wooden board and two electrodes stick out below out. An electrical cable runs to the board but it is not shown where it is attached it. ',
                    },
                    {
                      imageFileName: '/images/conductivity/pure-water.jpg',
                      imageAlt:
                        'A lightbulb is attached to a wooden board and below the board are two electrodes. The board is resting on top of a beaker labeled D.I. water and the electrodes are immersed into the water. ',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    experiment: {
      name: 'Experiment',
      link: 'experiment',
      tabs: [
        {
          name: 'Demo Activity',
          order: 1,
          steps: [
            {
              name: 'Notebook Prep',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Notebook Prep',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The video that you will watch presents several different types of solutions: acids, bases, ionic solutions, molecular solutions, an ionic solid, a saturated solution and a glacial acidic solution. The electrodes connect to a light bulb and are dipped into each solution or solid to test the substance\'s property of conductivity.',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Prior to watching the video, <li>Draw the following table in your lab notebook:</li>',
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName: '/images/conductivity/table.jpg',
                  imageAlt: 'A table holding 17 rows and 4 columns. Going across the columns in the first row are the headers: Sample Predictions, Observations and Explanation. Column 1 under Sample lists the following chemicals: 0.001 M NaCl, 0.01 M NaCl, 0.1 M NaCl, solid NaCl, 0.1 M HCl, 0.1 M H2SO4, 0.1 M CH3COOH, glacial CH3COOH, 0.1 M NaOH, 6 M NH4OH, saturated Ca(OH)2, ethanol and dextrose.',
                  centered: true,
                },
                {
                  order: 2,
                  type: 'Text',
                  text: '<li>Predict for each solution whether the light bulb will be bright, dim or no light at all. Use: <p><center>Use B = Bright, D = Dim, N = No light</center></p></li><li>Once your predicitons are done, click <strong>Next</strong> to watch the video. Check your predictions and log your observations. No worries if your predictions are different from the observations. You may learn something new! </li><li>Now for the hard part. For each sample, provide an explanation on why the property exists as observed.</li>',
                },
              ],
            },
            {
              name: 'Video',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Demo Video',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Conductivity property of different solutions<p><iframe width="560" height="315" src="https://www.youtube.com/embed/QA-tVoDJBj4?&playlist=QA-tVoDJBj4&loop=1" title="Conductivity property of different solutions" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>',
                },
              ],
            },
          ],
        },
      ],
    },
  },

  rank: 10,
};

export default conductivityOfSolutions;
