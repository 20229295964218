const alum = {
  status: 'Active',
  name: 'The Synthesis of Alum',
  imageFileName: 'images/alum-icon.jpg',
  imageAlt:
    'A Buchner funnel resting on its side and a small white filter paper are on top of a white paper towel. Additionally, crystals of alum are spread across the paper towel.',
  description: 'Experimental guide for synthesizing the preservative alum.',
  tags: ['Pre-Lab', 'Wet Lab', 'Experiment', 'Chem-101A'],
  page: 'alum',
  content: {
    overview: {
      name: 'Overview',
      link: 'overview',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'NOSTEP',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'In this experiment, you will synthesize a type of alum called potassium aluminum sulfate dodecahydrate, KAl(SO<sub>4</sub>)<sub>2</sub>•12H<sub>2</sub>O.',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Begin by first reading through the short <strong><em>Introduction</em></strong>.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Next, read through the <strong><em>Experiment</em></strong> in order of <li>Summary</li><li>Materials</li><li>Procedure Part I, followed by Part II and Part III</li>',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'The <strong><em>Procedure Parts I, II and III</em></strong> will walk you step-by-step through the protocol. Embedded within are several tips and how-to videos to the experiment. Move through all questions to better understand some of the crucial steps of the experiment.',
                },
              ],
            },
          ],
        },
      ],
    },
    introduction: {
      name: 'Introduction',
      link: 'introduction',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'NOSTEP',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'The term alum is a general family name for a crystalline substance composed of cations with 1+ and 3+ charges. You will synthesize potassium aluminum sulfate dodecahydrate, KAl(SO<sub>4</sub>)<sub>2</sub>•12H<sub>2</sub>O by placing the appropriate ions in one container in aqueous solution and then evaporate the water to form the alum crystals.',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'This particular compound has been chosen because it is relatively simple to prepare a pure sample. The process of synthesizing this compound is interesting in that it involves both chemical and physical reactions. Chemically, aluminum is oxidized from aluminum metal to prepare the Al<sup>3+</sup> ions. In the first step, you will dissolve the aluminum in a potassium hydroxide solution:',
                },

                {
                  order: 3,
                  type: 'Text',
                  text: '2Al(s) + 2KOH(aq) + 6H<sub>2</sub>O(l) → 2KAl(OH)<sub>4</sub>(aq) + 3H<sub>2</sub>(g)',
                  centered: true,
                },

                {
                  order: 4,
                  type: 'Text',
                  text: 'In the second step, you will react the potassium aluminate with sulfuric acid:',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'KAl(OH)<sub>4</sub>(aq) + 2H<sub>2</sub>SO<sub>4</sub>(aq) → KAl(SO<sub>4</sub>)<sub>2</sub>(aq) + 4H<sub>2</sub>O(l)',
                  centered: true,
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'Physically, as the solution that contains the mixture of ions evaporates, crystals will form which contain six waters of hydration bonded to the aluminum ion and six waters bonded to the potassium ion, giving the final product:',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: 'KAl(SO<sub>4</sub>)<sub>2</sub>•12H<sub>2</sub>O(s)',
                  centered: true,
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'Aluminum is considered a reactive metal, but because its surface is usually protected by a thin film of aluminum oxide, it reacts slowly with acids. It does, however, dissolve quickly in basic solutions. Excess hydroxide ion converts the aluminum to the tetrahydroxoaluminate (Al(OH)<sub>3</sub>) precipitates. Continued addition of acid causes the hydroxide ions to be completely neutralized, and the aluminum exists in solution as the hydrated ion [Al(H<sub>2</sub>O)<sub>6</sub>]<sup>3+</sup>. Aluminum hydroxide is considered to be an amphoteric hydroxide because it dissolves in both acids and bases. The aluminum family alums are colorless, while the transition metal alums are colored.',
                },
              ],
            },
          ],
        },
      ],
    },
    experiment: {
      name: 'Experiment',
      link: 'experiment',
      tabs: [
        {
          name: 'Start Here',
          order: 1,
          steps: [
            {
              name: 'Summary',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Summary: Experimental Steps',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The general experimental steps are: <li>Cut out pieces of aluminum from an aluminum can </li><li>React aluminum with potassium hydroxide while heating in the fume hood</li><li>Gravity filter out any remnant aluminum can material</li><li>React filtrate with sulfuric acid and place on ice</li><li> Collect and rinse the formed crystals by vacuum filtration</li><li>Re-dissolve the crystals using heat</li><li>Allow for a slow re-crystallization of alum over several days</li>',
                },
              ],
            },
            {
              name: 'Equipment',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Equipment',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The equipment needed for this experiment:',
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName: '/images/alum/equipment.jpg',
                  imageAlt: 'A lay-out of equipment  has numbers next to each piece of equipment. To the right of the euqipment image is the key to the numbered list: 1. ring stand, 2. medium ring clamp, 3. funnel, 4. large filter paper, 5. three-fingered clamp, 6. Büchner funnel, filter flask and hose, 7. small filter paper, 8. 150 mL beaker, 9. 100 mL beaker, 10. 600 mL beaker, 11. 10 mL graduated cylinder,12. 25 mL graduated cylinder, 13. large Styrofoam cup, 14. stirring rod, 15. beaker tongs, 16. steel wool, 17. large watch glass, and 18. aluminum can',
                  imageTitle: 'equipment',
                  centered: true,
                },
                {
                  order: 4,
                  type: 'Images',
                  images: [
                    {
                      imageFileName: '/images/alum/milligram-balance.jpg',
                      imageAlt: 'A lay-out of equipment shows a 600 mL beaker holding a styrofoam cup, 150 mL beaker, 100 mL beaker, glass stirring rod, 25 mL and 10 mL graduated cylinder, funnel, watch glass, ring stand, beaker tongs, Büchner funnel and filter flask, filter paper, steel wool.',
                      imageTitle: 'milligram balance and weigh boat',
                    },
                    {
                      imageFileName: '/images/alum/hot-plate.jpg',
                      imageAlt: 'A hot plate inside a fume hood.',
                      imageTitle: 'Buchner funnel and filter paper',
                    },
                    {
                      imageFileName: '/images/alum/ice.jpg',
                      imageAlt: 'A 400 mL beaker containing ice.',
                      imageTitle: 'ice in a 400 mL beaker',
                    },
                  ],
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Other needed material (not shown here): scrap paper to protect the lab bench when scratching off the coating on the aluminum can.',
                },
              ],
            },
          ],
        },
        {
          name: 'Part I',
          order: 2,
          steps: [
            {
              name: 'Aluminum Can',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Prepping the Aluminum',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'You will use an empty and rinsed aluminum can as the source of aluminum. If a brand new can, poke a scissors into the aluminum can near the top of the can. Be careful here. There will be sharp edges. Cut around to remove the top. Cut down the length of the can.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Cut sections from around the can about 1 square inch. Cut each of these sections in half. Each piece contains enough material for 1 student.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Set the aluminum on a piece of scrap paper, and clean off the painted surface by vigorously rubbing with steel wool on top of a piece of paper to protect the countertop. The inside of the can is painted with a clear varnish, so scour it as well. It is hard to clean around the edges, so cut any painted edges off. ',
                },
                {
                  order: 5,
                  type: 'Images',
                  images: [
                    {
                      imageFileName: '/images/alum/steel-wool.jpg',
                      imageAlt: 'A rectangular strip from an aluminum can is partially in the scrapped in the middle. The can coat is white green and orange with lettering. A steel wool is next to it. Pieces of steel wool are scattered around.',
                    },
                    {
                      imageFileName: '/images/alum/scrapping.jpg',
                      imageAlt: 'A rectangular strip from an aluminum can is completely scrapped and appears shiny gray. A steel wool is next to it. Many pieces of steel wool and dust are scattered around from the scrapping.',
                    },
                  ],
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'Rinse and dry the cleaned aluminum. Make many parallel cuts along one edge (like making a hula skirt from construction paper), and then cut it into tiny confetti sized pieces (2 mm square) onto some dry paper towel. ',
                },
                {
                  order: 7,
                  type: 'Images',
                  images: [
                    {
                      imageFileName: '/images/alum/hula-skirt.jpg',
                      imageAlt: 'Three separate rectangular pieces of aluminum are each cut at one end multiple times. The strips that are cut are 2 to 3 millimeters in size. Each look have the appearance of a hula skirt. ',
                    },
                    {
                      imageFileName: '/images/alum/aluminum.jpg',
                      imageAlt: 'A top view of over fifty small aluminum pieces that are about the size of 2 millimeter x 2 millimeter in a weigh boat.',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Mass Measurement',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Mass Measurement',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Zero out a weigh boat on a milligram balance, and weigh between 0.2 and 0.3 grams of the aluminum, recording the weight to the nearest 0.001 g.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Place the pieces in a 100 mL beaker.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Mark your initials with a pencil in the white square of the beaker (since you will likely be sharing a hot plate with other classmates).',
                },
                {
                  order: 5,
                  type: 'Image',
                  imageFileName: '/images/alum/initials.jpg',
                  imageAlt: 'A 100 milliliter beaker contains small square pieces of aluminum. The beaker has written on its wite label "Your initials". Next to the beaker is a 10 milliliter graduated cylinder.',
                  centered: false,
                },
                {
                  order: 4,
                  type: 'Text',
                  text: '&nbsp;',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'What is the most important feature in the image above? (Select the one statement)',
                },
                {
                  order: 7,
                  type: 'Multiple Choice',
                  choices: [
                    {
                  label: 'The aluminum pieces are really small.',
                  tip: 'Smaller pieces are better as it will allow the reaction to carry out faster. While this could save time, it is not absolutely necessary to have tiny aluminum pieces as the reaction will still take place once the KOH solution is added.',
                  validChecked: false,
                    },
                    {
                  label: 'The beaker indicates where to place your initials.',
                  tip: 'It is important to know which is your beaker when you head to the next step as several beakers will be on hot plates. Do not use tape to write your name since it has the potential to catch fire when heating on the hot plate. ',
                  validChecked: true,
                    },
                    {
                  label: 'The aluminum pieces are in a 100 mL beaker.',
                  tip: 'This is the correct size beaker to use for convenience, but it is not a necessity.',
                  validChecked: false,
                    },
                  ],
                },
              ],
            },
            {
              name: 'Gravity Filtration Set-up',
              order: 4,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Gravity Filtration Set-up',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'You will heat reactants in the next step and then immediately (while the solution is hot) filter out a side product "junk" out of the mixture.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'To get ready for these steps, make sure the hot plate is in the fume hood and set between the setting 2 and low.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Set up a gravity filtration apparatus. Make sure you are using the large piece of filter paper.',
                },
                {
                  order: 5,
                  type: 'Image',
                  imageFileName: '/images/alum/gravity-filtration.jpg',
                  imageAlt: 'A funnel sets on a small ring that is attached to a ring stand. The funnel hovers over a 100 milliliter graduated cylinder.',
                  centered: false,
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'Watch: How to fold the filter paper for gravity filtration<p><iframe width="560" height="315" src="https://www.youtube.com/embed/gJ41ipe701Q?&playlist=gJ41ipe701Q&loop=1" title="Folding the filter paper for gravity filtration" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></p>',
                },
              ],
            },
          ],
        },
        {
          name: 'Part II',
          order: 2,
          steps: [
            {
              name: 'Reaction #1',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Reaction #1: Fume Hood Reaction',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'This part of the experiment will be done in the fume hood.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: '<em>CAUTION: Potassium hydroxide solution is caustic. Avoid spilling it on your skin or clothing.</em>',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Use a graduated cylinder to measure out 15 mL of 1.4 M KOH solution. Slowly add the KOH solution to the beaker of aluminum pieces.',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Place the beaker on the hot plate located in the fume hood. Do not boil! Remove the beaker from the hot plate if it is boiling. Stir occasionally.',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'Make sure you know which beaker is yours, and be careful of other student beakers when you stir yours. No more than 3 or 4 beakers should be on any one hot plate.',
                },
                {
                order: 7,
                type: 'Text',
                text: 'The aluminum will fizz as hydrogen gas is given off. The solution will turn dark from bits of paint and alloying elements in the aluminum can. All of the aluminum should dissolve in 10 to 20 minutes. When there is no more small bubbling, this indicates the reaction is complete.',
              },
              {
                order: 8,
                type: 'Image',
                imageFileName: '/images/alum/overload.jpg',
                imageAlt: 'Six 100 milliliter beakers rest on a hot plate in the fume hood. The reactions inside each beaker is at different stages. Three beakers are on the front part of the hot plate and three are in the back. The contets inside the ones in the back are not clearly visible. The three beakers in the front appear as: left has a clear solution, the middle a effervescet solution with some aluminum pieces, and the right is a black solution with condensation on the inner walls.',
                centered: false,
              },
              {
                order: 4,
                type: 'Text',
                text: '&nbsp;',
              },
              {
                order: 9,
                type: 'Text',
                text: '(1) What is wrong in this picture? (select all that apply)',
              },
              {
                order: 10,
                type: 'Multiple Choice',
                choices: [
                  {
                    label: 'Not all reactions are running.',
                    tip: 'Everyone may be at different stages of the reaction, so hard to know from the picture the beaker contents.',
                    validChecked: false,
                      },
                      {
                    label: 'The hot plate is crowded with beakers.',
                    tip: 'A hot plate should not have more than 4 beakers on it.',
                    validChecked: true,
                      },
                      {
                    label: 'Not all beakers are properly labeled.',
                    tip: 'Make sure to label your beaker since everyone\'s beaker has the same contents!',
                    validChecked: true,
                      },
                ],
              },
              {
                order: 4,
                type: 'Text',
                text: '&nbsp;',
              },
              {
                order: 11,
                type: 'Text',
                text: '(2) Watch: The aluminum and KOH reaction<p><iframe width="560" height="315" src="https://www.youtube.com/embed/5KftEH24aHk?&playlist=5KftEH24aHk&loop=1" title="Aluminum and KOH reaction" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>',
              },
              {
                order: 12,
                type: 'Input',
                directions:
                  '(3) Once the reaction is complete, which is the proper method to remove the beaker with the beaker tongs? ',
                validAnswers: ['A', 'a'],
                tip: 'Beaker tongs are only used for grasping the beaker all around the cylinder.',
              },
              {
                order: 13,
                type: 'Images',
                images: [
                    {
                      imageFileName: '/images/alum/beaker-tongs.jpg',
                      imageAlt: 'Beaker tongs hold a beaker containing a black solution. The tongs grasp the beaker all around the cylinder.',
                      imageTitle: 'A'
                    },
                    {
                      imageFileName: '/images/alum/beaker-tongs-2.jpg',
                      imageAlt: 'Beaker tongs pinch a beaker containing a black solution. The tongs pinch one side of the the beaker at the top.',
                      imageTitle: 'B'
                    },
                  ],
                },
              ],
            },
            {
              name: 'Filtration #1',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Filtration #1',
                },
                {
                  order: 2,
                  type: 'Image',
                  imageFileName: '/images/alum/filtration.jpg',
                  imageAlt: 'A gravity filtration set-up showing black solids embedded in the filter paper. .',
                  centered: false,
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'What step does this picture represent?',
                },
                {
                  order: 4,
                  type: 'Multiple Choice',
                  choices: [
                    {
                      label: 'Vacuum filtration',
                      tip: 'There is no vacuum line attached to this set-up.',
                      validChecked: false,
                        },
                        {
                      label: 'Gravity filtration',
                      tip: 'This is a gravity filtration set-up. The filtrate (the liquid in the beaker) is being collected and separated from all other contents of the aluminum can (black solids).',
                      validChecked: true,
                        },
                  ],
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Allow the solution to cool to near room temperature. ',
                },
              ],
            },
            {
              name: 'Filtration #2',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Filtration #2',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Set up a Büchner funnel with filter flask; prepare it for filtering that you need to do in Step 9. Use the small piece of filter paper.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'For a review, watch: How to set-up a Buchner Funnel <p><iframe width="560" height="315" src="https://www.youtube.com/embed/jhPE3aCeeHg?&playlist=jhPE3aCeeHg&loop=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>',
                },
              ],
            },
          ],
        },
        {
          name: 'Part III',
          order: 3,
          steps: [
            {
              name: 'Reaction #2',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Reaction #2: Addition of Sulfuric Acid',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: '<em>CAUTION: Handle the H<sub>2</sub>SO<sub>4</sub> solution with care. It can cause painful burns if it comes in contact with the skin.</em>',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Use a graduated cylinder to measure out 7 mL of 9 M H<sub>2</sub>SO<sub>4</sub> solution.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'The reaction mixture must be cooled to room temperature before proceeding. So after the reaction mixture has cooled, slowly add the sulfuric acid solution to the beaker of liquid. Stir the mixture constantly. The reaction is strongly exothermic, so be careful as you stir the mixture. Note that aluminum hydroxide will precipitate initially. It will dissolve as more sulfuric acid is added. ',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Keep stirring if there is some solid remaining in the beaker after the sulfuric acid has been added.',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'Watch: Potassium aluminate and sulfuric acid reaction<p><iframe width="560" height="315" src="https://www.youtube.com/embed/luWvbt9uVgQ?&playlist=luWvbt9uVgQ&loop=1" title="Potassium aluminate and sulfuric acid" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>',
                },
              ],
            },
            {
              name: 'Crystallization',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Crystallization',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'To get crystals, it is best to do a slow cool-down. Do so by preparing an ice bath. Put ice into a 400 mL beaker to fill it one third full. Add water to just under the ice level.  ',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Set the beaker with the filtrate in the ice bath. Stir the filtrate occasionally. Be careful that you do not get water from the ice bath into the beaker with the filtrate. You should see the solution turn cloudy in a few minutes.',
                },
                {
                  order: 4,
                  type: 'Images',
                  images: [
                      {
                        imageFileName: '/images/alum/on-ice.jpg',
                        imageAlt: 'A 150 mL beaker with a solution is inside a 400 mL beaker that contains ice water about one third full.',
                      },
                      {
                        imageFileName: '/images/alum/pre-filtration.jpg',
                        imageAlt: 'A beaker containing a solution with a white precipitate.',
                      },
                    ],
                  },
              {
                order: 5,
                type: 'Text',
                text: 'Sometimes the alum crystals do not come out of solution in a timely manner on ice. One trick that can be done is to scratch the bottom of the beaker with a glass rod. This typically gets your crystals to pop out of solution. ',
              },
              {
                order: 6,
                type: 'Text',
                text: 'Watch: How to scratch the beaker with a glass rod to induce precipitation<p><iframe width="560" height="315" src="https://www.youtube.com/embed/qR2Dpyp2dD4?&playlist=qR2Dpyp2dD4&loop=1" title="To induce precipitation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></p>',
              },
              {
                order: 7,
                type: 'Text',
                text: 'Note that there is no stirring during this process. Stirring could cause precipitates to return into solution.',
              },
             ],
            },
            {
              name: 'Sample Rinse',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Sample Rinse',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'When the filtrate is milky white and ice cold, collect your alum crystals by pouring them onto the Büchner funnel and filter-flask setup. Use vacuum filtration to wash the crystals on the filter paper with 25 mL of an aqueous ethanol solution (50%). The crystals will not dissolve in this solution. ',
                },
              {
                order: 3,
                type: 'Text',
                text: 'Watch: Vacuum filtration of alum<p><iframe width="560" height="315" src="https://www.youtube.com/embed/xFKCu5AMRVU?&playlist=xFKCu5AMRVU&loop=1" title="Vacuum filtration and sample rinsing" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></p>',
              },
              {
                order: 4,
                type: 'Text',
                text: 'Sometimes the crystals formed are so tiny that they will be smaller than the pores of the filter and will pass through with the filtrate. ',
              },
              {
                order: 5,
                type: 'Image',
                imageFileName: '/images/alum/filtrate.jpg',
                imageAlt: 'A vacuum filtration set-up focused on the flask. The flask contains a milky white solution.',
                centered: false,
              },
              {
                order: 6,
                type: 'Text',
                text: 'If you do not collect sufficient alum on the filter paper, it is recommended to reheat your filtrate to dissolve its powder alum. Place the solution on ice again for a few minutes then scratch the bottom of the beaker a couple times to induce precipitation. Then leave on ice for 20 minutes or so allowing the crystals to grow.',
              },
              {
                order: 7,
                type: 'Text',
                text: 'After 5 to 10 minutes, turn off the air, unplug the filter flask from the aspirator, and remove the top portion of the funnel. Have a square of paper towel ready on the table top, and slam the top portion of the funnel face down onto the paper to release the solid from the funnel. Spread the crystals out on the paper to dry for 10 minutes.',
              },
              {
                order: 8,
                type: 'Image',
                imageFileName: '/images/alum/drying-alum.jpg',
                imageAlt: 'A Buchner funnel resting on its side and a white small filter paper are on a white paper towel. Crystals of alum are spread across the paper towel.',
                centered: false,
              },
              {
                order: 8,
                type: 'Text',
                text: 'While they are drying, dispose the filtering flask solution into the waste container. Return the filtering flask and the aspirator to their proper place.',
              },
              {
                order: 9,
                type: 'Text',
                text: 'After the 10 minutes drying time, transfer the solid to a zeroed piece of weighing paper, and record the weight of the alum to the nearest 0.001 g.',
              },
              {
                order: 10,
                type: 'Image',
                imageFileName: '/images/alum/pre-recrystallization.jpg',
                imageAlt: 'A 150 mL beaker contains dry alum in powder form.',
                centered: false,
              },
             ],
            },
            {
              name: 'Recrystallization',
              order: 4,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Recyrstallization',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Put the alum into a dry 150 ml beaker.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Add an amount of deionized water based on the mass of your solid amount: <li>Round your weight of the solid</li><li>Double it</li><li>Add the number 2 to your value</li>',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'This will be your milliliter volume of deionized water to add to the beaker. For example, if the solid weighed 5.755 g, then 6 times 2 gives 12, plus 2 is 14, so add 14 ml of deionized water.',
                },
              {
                order: 5,
                type: 'Text',
                text: 'Place the beaker on the hotplate (set to 6), and stir occasionally until the liquid is near boiling and all the solid has completely dissolved.',
              },
              {
                order: 6,
                type: 'Text',
                text: 'Insert the styrofoam cup into the 600 ml beaker, and place the 150 ml beaker with the hot alum solution into the styrofoam cup. Cover with a watch glass.',
              },
              {
                order: 7,
                type: 'Text',
                text: 'Place the assembly in one of the asigned lab shelves marked with your lab section. Crystallization should occur over the next day or two.',
              },
              {
                order: 8,
                type: 'Text',
                text: 'Watch: Preparing for a slow recrystallization<p><iframe width="560" height="315" src="https://www.youtube.com/embed/Sr332eAL1aY?&rel=0&playlist=Sr332eAL1aY&loop=1" title="Preparing for a slow recrystallization" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>',
              },
              {
                order: 9,
                type: 'Image',
                imageFileName: '/images/alum/recrystallization.jpg',
                imageAlt: 'AA 600 mL graduated.cylinder holds a styrofoam cup. Fingertips are holding a 150 mL graduated cylinder and is placing the cylinder into the styrofoam cup. A watch glass rests next to this set-up and has label tape. The tape has listed on it Name, Section Number, Contents, and Date. ',
                centered: true,
              },
              {
                order: 10,
                type: 'Text',
                text: 'You are ﬁnished with the experiment guide!',
                centered: true,
              },
             ],
            },
          ],
        },
      ],
    },
  },
  rank: 9,
};

export default alum;
