const ionicReactions = {
  status: 'Active',
  name: 'Ionic Reactions',
  imageFileName: 'images/precipitation-icon.jpg',
  imageAlt:
    'A zoomed-in image of a test tube showing a precipitate in soltuion. The precipitate has a gel-like consistency and extends from the bottom to the top of the liquid',
  description: 'An exploration of double-displacement reactions.',
  tags: ['Pre-Lab', 'Wet Lab', 'Experiment', 'Chem-101A'],
  page: 'ionic-reactions',
  content: {
    overview: {
      name: 'Overview',
      link: 'overview',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'NOSTEP',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'In this lab, there will be 7 labeled bottles of chemicals and the same chemicals will be in 7 unlabeled bottles. ',
                },
                {
                  order: 2,
                  type: 'Image',
                  imageFileName:
                    '/images/ionic-reactions/unidentified-bottles.jpg',
                  imageAlt:
                    'A drawing of seven eyedrop bottles. Four are in the back labeled A through D from left to right. Three are in the front labeled E through G from left to right.',
                  centered: true,
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'The purpose of the experiment is to determine the identity of each bottle by observing the reactions that take place when mixing the solutions together in different ways and comparing them with the reactions of the labeled chemicals. The only reactions taking place between the solutions will be <strong>double-displacement reactions</strong>.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Read through the <strong><em>Introduction</em></strong> to review the different types of double-displacement reactions and solubility rules.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'There are two parts to the <strong><em>Experiment</em></strong>. Part I requires you to write 21 net ionic reactions. In addition, you will watch a demo video of all those 21 reactions to confirm the product predictions of your net ionic reactions. The observations made from the demo video will be logged into a reaction matrix. Part II will be the same exercise as the demo video except you are in person carrying out all 21 reactions using unlabeled bottles. Part II is dependent on your reaction matrix from Part I.',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: "Let's get started!",
                },
              ],
            },
          ],
        },
      ],
    },
    introduction: {
      name: 'Introduction',
      link: 'introduction',
      tabs: [
        {
          name: 'Types of Reactions',
          order: 1,
          steps: [
            {
              name: 'Introduction',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Introduction',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'There are several types of chemical reactions. One type of chemical reaction is a double-replacement reaction. This takes place between two ionic compounds.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'MX + NY → MY + NX',
                  centered: true,
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'The cation of one compound bonds with the anion of the second compound, and the cation of the second compound bonds with the anion of the first one. The result is that two new compounds are formed. If the product is a gas, weak acid, or precipitate, then the reaction is said to be product-favored. If none of the above products are present, then there is no chemical change. Therefore, there is no net ionic reaction.',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Example of a <b>gas-forming reaction</b>:',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: '2HCl(aq) + Na<sub>2</sub>CO<sub>3</sub>(aq) → 2NaCl(aq) + H<sub>2O</sub>(l) + CO<sub>2</sub>(g)',
                  centered: true,
                },
                {
                  order: 7,
                  type: 'Text',
                  text: 'Example of a <b>precipitation reaction</b>:',
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'Cu(NO<sub>3</sub>)<sub>2</sub>(aq) + 2NaOH(aq)  → Cu(OH)<sub>2</sub>(s) + 2NaNO<sub>3</sub>(aq)',
                  centered: true,
                },
                {
                  order: 9,
                  type: 'Text',
                  text: 'Example of a <b>formation of a weak acid</b>:',
                },
                {
                  order: 10,
                  type: 'Text',
                  text: 'HCl(aq) + NaCH<sub>3</sub>COO(aq)   → NaCl(aq) + CH<sub>3</sub>COOH(aq)',
                  centered: true,
                },
                {
                  order: 11,
                  type: 'Text',
                  text: 'Soluble compounds (aq) and strong acids and bases ionize completely in water. Weak acids, insoluble compounds, liquids, and gases are considered here not ionized and are not separated in net ionic equations.',
                },
                {
                  order: 12,
                  type: 'Text',
                  text: 'Whenever the product is H<sub>2</sub>CO<sub>3</sub>(aq), it automatically separates into H<sub>2</sub>O(l) and CO<sub>2</sub>(g). This is <b>a gas-forming reaction</b>; for example:',
                },
                {
                  order: 13,
                  type: 'Text',
                  text: 'HBr(aq) + KHCO<sub>3</sub>(aq) → KBr(aq) + H<sub>2</sub>O(l) + CO2(g)',
                  centered: true,
                },
                {
                  order: 14,
                  type: 'Text',
                  text: 'Whenever the product is H<sub>2</sub>SO<sub>3</sub>(aq), it automatically separates into H<sub>2</sub>O(l) and SO<sub>2</sub>(g). This is also <b>a gas-forming reaction</b>; for example:',
                },
                {
                  order: 15,
                  type: 'Text',
                  text: '2HCl(aq) + Na<sub>2</sub>SO<sub>3</sub>(aq)  → 2NaCl(aq) + H<sub>2</sub>O(l) + SO<sub>2</sub>(g)',
                  centered: true,
                },
              ],
            },
            {
              name: 'Net Ionic Equations',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Net Ionic Equations',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'A net ionic equation includes only those components that undergo changes in the reaction. Net ionic equations are very important because they allow you to focus on the actual chemical change that occurs.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'To arrive at a net ionic equation, write first the formula equation (FE). For example:',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: '<strong>Formula Equation</strong>:    2HCl(aq) + Na<sub>2</sub>SO<sub>3</sub>(aq)  → 2NaCl(aq) + H<sub>2</sub>O(l) + SO<sub>2</sub>(g)',
                  centered: true,
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Now (1) write all the ions that can be formed in aqueous solution. (2) Leave insoluble compounds and weakly ionized compounds in molecular form and (3) do not break up polyatomic ions. This will be the total ionic equation (TIE). For example:',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: '<strong>Total Ionic Equation</strong>:    2H<sup>+</sup>(aq) + Cl<sup>-</sup>(aq) + 2Na<sup>+</sup>(aq) + SO<sub>3</sub><sup>2-</sup>(aq) → 2Na<sup>+</sup>(aq) + Cl<sup>-</sup>(aq) + H<sub>2</sub>O(l) + SO<sub>2</sub>(g)',
                  centered: true,
                },
                {
                  order: 6,
                  type: 'Text',
                  text: "Let's look at each chemical from the FE and how it appears in the TIE:<li>HCl(aq) is a strong acid and a substance that dissociates nearly 100% that it is more accurate to show in an equation with its ions separated</li><li>Na<sub>2</sub>SO<sub>3</sub>(aq) is a soluble salt. This means that it is a substance that, as an aqueous solution, is dissolved in water so it is more accurate to have its ions separated as in the TIE.</li></li><li>NaCl(aq) is a soluble salt. This means that it is a substance, that as an aqueous solution, is dissolved in water so it is more accurate to have its ions separated as in the TIE.</li><li>H<sub>2</sub>O(l) is a weakly ionizable compound, it is best to leave it intact as that is how it accurately appears in the solution.</li><li>SO<sub>2</sub>(g) is a substance in gaseous form. It is how the chemical appears and so it remains as it appears in the TIE.</li>",
                },
                {
                  order: 7,
                  type: 'Text',
                  text: 'Anytime the same ions/compounds appear on both sides of the total ionic equation, in the same physical state, they are called <strong>spectators</strong>. They do not participate in the net reaction therefore they are canceled from both sides. This will be the net ionic equation (NIE). <strong>Make sure that the NIE is balanced in charge and atoms.</strong>',
                },
                {
                  order: 8,
                  type: 'Text',
                  text: '<strong>Net Ionic Equation</strong>: 2H<sup>+</sup>(aq) + SO<sub>3</sub><sup>2-</sup>(aq) → H<sub>2</sub>O(l) + SO<sub>2</sub>(g)',
                  centered: true,
                },
                {
                  order: 9,
                  type: 'Text',
                  text: '<li>Cl<sup>-</sup>(aq)is on the left and right of the TIE, hence it was removed from both sides.</li><li>2Na<sup>+</sup>(aq) is on the left and right of the TIE, hence it was removed from both sides.</li><li>All other elements are not exact on the left and right.Hence they remain in the equation.</li>',
                  centered: true,
                },
                {
                  order: 10,
                  type: 'Text',
                  text: 'To determine whether a compound dissolves or not, you will need review a solubility table. <p>Click <b>Next</b> to view a solubility table.</p>',
                },
              ],
            },
            {
              name: 'Solubility Table',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Solubility Table',
                },
                {
                  order: 2,
                  type: 'Information Table',
                  headers: ['Solule Compounds', 'Exceptions'],
                  rows: [
                    {
                      id: 1,
                      cells: [
                        'Na<sup>+</sup>, K<sup>+</sup>, NH<sub>4</sub><sup>+</sup>',
                        '',
                      ],
                    },
                    {
                      id: 2,
                      cells: [
                        'NO<sub>3</sub><sup>-</sup>, ClO<sub>3</sub><sup>-</sup>, ClO<sub>4</sub><sup>-</sup>, CH<sub>3</sub>CO<sub>2</sub><sup>-</sup>',
                        'Compounds of Hg<sub>2</sub><sup>2+</sup>',
                      ],
                    },
                    {
                      id: 3,
                      cells: [
                        'Cl<sup>-</sup>, Br<sup>-</sup>, I<sup>-</sup>',
                        'Compounds of Pb<sup>2+</sup>, Hg<sub>2</sub><sup>2+</sup>, Ag<sup>+</sup>, Cu<sup>+</sup>, and Hg<sup>2+</sup>',
                      ],
                    },
                    {
                      id: 4,
                      cells: [
                        'F<sup>-</sup>',
                        'Compounds of Mg<sup>2+</sup>, Ca<sup>2+</sup>, Sr<sup>2+</sup>, Ba<sup>2+</sup>, and Pb<sup>2+</sup>',
                      ],
                    },
                    {
                      id: 5,
                      cells: [
                        'SO<sub>4</sub><sup>2-</sup>',
                        'Compounds of Ca<sup>2+</sup>, Sr<sup>2+</sup>, Ba<sup>2+</sup>, Pb<sup>2+</sup>, Ag<sup>+</sup>, and Hg<sub>2</sub><sup>2+</sup>',
                      ],
                    },
                  ],
                },
                {
                  order: 3,
                  type: 'Information Table',
                  headers: ['Insoluble Compounds', 'Exceptions'],
                  rows: [
                    {
                      id: 1,
                      cells: [
                        'CO<sub>3</sub><sup>2-</sup>, PO<sub>4</sub><sup>3-</sup>, C<sub>2</sub>O<sub>4</sub><sup>2-</sup>,CrO<sub>4</sub><sup>2-</sup>',
                        'Compounds of Na<sup>+</sup>, K<sup>+</sup>, Rb<sup>+</sup>, Cs<sup>+</sup>, and NH<sub>4</sub><sup>+</sup>',
                      ],
                    },
                    {
                      id: 2,
                      cells: [
                        'O<sup>2-</sup>',
                        'Compounds of Na<sup>+</sup>, K<sup>+</sup>, Li<sup>+</sup>, Rb<sup>+</sup>, Cs<sup>+</sup>, Be<sup>2+</sup>, Mg<sup>2+</sup>, Sr<sup>2+</sup>, and Ba<sup>2+</sup>, and NH<sub>4</sub><sup>+</sup>',
                      ],
                    },
                    {
                      id: 3,
                      cells: [
                        'OH<sup>-</sup>',
                        'Compounds of Na<sup>+</sup>, K<sup>+</sup>, Li<sup>+</sup>, Sr<sup>2+</sup>, Ba<sup>2+</sup>, Ca<sup>2+</sup>, and NH<sub>4</sub><sup>+</sup>',
                      ],
                    },
                  ],
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'These solubility rules can be generalized. <p>Click <b>Next</b> to view some generalized solubility rules.</p>',
                },
              ],
            },
            {
              name: 'General Solubility Rules',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'General Solubility Rules',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'After glancing at the solubility table, general solubility rules can be generated.<li>All common salts of the Group 1A elements and ammonium and soluble.</li><li>	All common acetates and nitrates are soluble.</li><li>	All binary compounds on Group VIIIA elements (other than F) with metals are soluble, except those of silver, mercury(I), and lead.</li><li>All sulfates are soluble except those of barium, strontium, lead, calcium, silver, and mercury(I).</li><li>Except for those in the 1st rule, carbonates, hydroxides (with the exception of barium hydroxide), oxides, and phosphates are insoluble.</li>',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Click <b>Experiment</b> in the menu on the left to start Part I.',
                },
              ],
            },
          ],
        },
      ],
    },
    experiment: {
      name: 'Experiment',
      link: 'experiment',
      tabs: [
        {
          name: 'Start Here',
          order: 1,
          steps: [
            {
              name: '21 Reactions',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: '21 Reactions',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'There are 21 possible combinations for 7 solutions. The 7 solutions you will be using to create double-displacement reactions are: 1.5M H<sub>2</sub>SO<sub>4</sub>, 1M K<sub>3</sub>PO<sub>4</sub>, 0.5M Li<sub>2</sub>SO<sub>4</sub>, 0.1M Mg(NO<sub>3</sub>)<sub>2</sub>, 1M Na<sub>2</sub>CO<sub>3</sub>, 3M NaOH, 0.1M SrCl<sub>2</sub>.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: '<center><strong><em>It is very important that you develop a scheme for analyzing these reactions <br>before doing the experiment in the lab.</strong></em></center>',
                  centered: true,
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'On a separate piece of paper, write the <strong>formula equation (FE)</strong>, the <strong>total ionic (TIE)</strong> and the <strong>net ionic (NIE)</strong> equation for each reaction of the 21 reactions made from the 7 solutions involved in the lab.',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: "When no reaction is expected, write <strong>NAR</strong> for 'No apparent reaction'. These reactions must be written out prior to watching the demo video.",
                },
                {
                  order: 6,
                  type: 'Text',
                  text: '1) sulfuric acid + potassium phosphate →',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: '2) sulfuric acid + lithium sulfate →',
                },
                {
                  order: 8,
                  type: 'Text',
                  text: '3) sulfuric acid + magnesium nitrate →',
                },
                {
                  order: 9,
                  type: 'Text',
                  text: '4) sulfuric acid + sodium carbonate →',
                },
                {
                  order: 10,
                  type: 'Text',
                  text: '5) sulfuric acid + sodium hydroxide →',
                },
                {
                  order: 11,
                  type: 'Text',
                  text: '6) sulfuric acid + strontium chloride →',
                },
                {
                  order: 12,
                  type: 'Text',
                  text: '7) potassium phosphate + lithium sulfate →',
                },
                {
                  order: 13,
                  type: 'Text',
                  text: '8) potassium phosphate + magnesium nitrate →',
                },
                {
                  order: 14,
                  type: 'Text',
                  text: '9) potassium phosphate + sodium carbonate →',
                },
                {
                  order: 15,
                  type: 'Text',
                  text: '10) potassium phosphate + sodium hydroxide →',
                },
                {
                  order: 16,
                  type: 'Text',
                  text: '11) potassium phosphate + strontium chloride →',
                },
                {
                  order: 17,
                  type: 'Text',
                  text: '12) lithium sulfate + magnesium nitrate →',
                },
                {
                  order: 18,
                  type: 'Text',
                  text: '13) lithium sulfate + sodium carbonate →',
                },
                {
                  order: 19,
                  type: 'Text',
                  text: '14) lithium sulfate + sodium hydroxide →',
                },
                {
                  order: 20,
                  type: 'Text',
                  text: '15) lithium sulfate + strontium chloride →',
                },
                {
                  order: 21,
                  type: 'Text',
                  text: '16) magnesium nitrate + sodium carbonate →',
                },
                {
                  order: 22,
                  type: 'Text',
                  text: '17) magnesium nitrate + sodium hydroxide →',
                },
                {
                  order: 23,
                  type: 'Text',
                  text: '18) magnesium nitrate + strontium chloride →',
                },
                {
                  order: 24,
                  type: 'Text',
                  text: '19) sodium carbonate + sodium hydroxide →',
                },
                {
                  order: 25,
                  type: 'Text',
                  text: '20) sodium carbonate + strontium chloride →',
                },
                {
                  order: 26,
                  type: 'Text',
                  text: '21) sodium hydroxide + strontium chloride →',
                },
              ],
            },
            {
              name: 'Reaction Matrix',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Reaction Matrix of Known Solutions',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'In your lab notebook, create the following <strong>Reaction Matrix</strong> data table. This will prepare you for watching the demo video in the next step. You will use this table to take notes and describe what happens when two known solutions are mixed.',
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName: '/images/ionic-reactions/reaction-matrix.jpg',
                  imageAlt: 'tbd',
                },
              ],
            },
            {
              name: 'Demo Video',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Demo video',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Using your reaction table, do your best to describe the result for the 21 reactions that will be presented in the demo video. If there is a gas or heat evolved or a precipitate formed, write these descriptions in your table. The video will take 15 minutes to watch.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Watch: 21 Double-displacment Reactions <p><iframe width="560" height="315" src="https://www.youtube.com/embed/uq9DC0qbuf4?&playlist=uq9DC0qbuf4&loop=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Once your reaction matrix is complete, you will use it to compare with your in-lab experiment results. ',
                },
              ],
            },
            {
              name: 'Experiment Matrix',
              order: 4,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Experiment Matrix of Unknown Solutions',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'In your lab notebook, create the following <strong>Experiment Matrix</strong> data table. This will prepare you for carrying out the 21 reactions for the 7 unknowns.',
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName:
                    '/images/ionic-reactions/experiment-matrix.jpg',
                  imageAlt: 'tbd',
                },
              ],
            },
          ],
        },
        {
          name: 'Procedure: In-lab',
          order: 2,
          steps: [
            {
              name: 'Procedure: In-Lab',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Procedure for the In-Lab Experiment',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Obtain 7 test tubes and label them A through G. Add about 12 mL from each of the 7 unknowns to their labeled test tube.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: "Now obtain another 6 test tubes and label them with the combination you will test in them. For example, if you're testing solution A with all the other solution, label the test tubes A-B, A-C, A-D, A-E, A-F, A-G following your <strong>Experiment Matrix</strong> data table that is in your notebook. Place about 2 mL of the first solution (in this example, solution A) into each test tube.",
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Be prepared to record your observations into the <strong>Experiment Matrix</strong> in your lab notebook once a reaction starts. One by one, add each of the remaining solutions to their individual test tubes. For example, add 2 mL of solution D to the test tube labeled A-D. Mix the solutions by flicking the bottom of the test tube with your index finger. Carefully record your observations.',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Repeat this process for each of the solutions. Make sure you have tested each solution with every other solution. There are a total of 21 tests.',
                },
              ],
            },
            {
              name: 'Solubility Tips',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Solubility Tips',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Some important things to note before doing the actual experiment: ',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: '<li>Li<sub>3</sub>PO<sub>4</sub>, which is marginally soluble, is slow to form at room temperature. Any solution suspected of containing Li<sub>3</sub>PO<sub>4</sub> should be placed in a hot water bath to speed up the precipitation process.</li><li>The mixture of H<sub>2</sub>SO<sub>4</sub> with K<sub>3</sub>PO<sub>4</sub> gives a slightly exothermic reaction.</li><li>Commercial K<sub>3</sub>PO<sub>4</sub> is sometimes contaminated with CO<sub>3</sub><sup>3-</sup> so be on the alert if some bubbles form as a result of the CO<sub>3</sub><sup>3-</sup>  reacting with H<sub>2</sub>SO<sub>4</sub>.</li><li>Sr(OH)<sub>2</sub> is also marginally soluble and the formation of a precipitate is usually slow and dependent on the amount of reagents used. However, the slight cloudy precipitate that results might be useful for characterizing the precipitate.</li>',
                },
              ],
            },
            {
              name: 'Final Results',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Final Results',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'By studying and comparing the patterns of precipitates, gas and heat evolution from the two <strong>Matrices</strong>, you should be able to solve for the identity of the seven unknown solutions. Pay special attention to unique observations, such as heat or gas evolution, as these observations can considerably narrow down our choices.',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Label each unknown in your notebook according to its chemical properties.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: '<ul>A =</ul><ul>B =</ul><ul>C =</ul><ul>D =</ul><ul>E =</ul><ul>F =</ul><ul>G =</ul>',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  rank: 12,
};

export default ionicReactions;
