import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const Feedback = (props) => {
  return (
    <>
      <Container maxWidth='xl'>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 2,
          }}
        >
          <Typography variant='h3'>Feedback</Typography>
          <Box sx={{marginTop: 5}}>
          <Typography variant='p'>Thank you for taking time to give us feedback. Please fill out this online feedback <a href='https://docs.google.com/forms/d/e/1FAIpQLScidVLuOmdHwrMu1mAsFpZLRGbICjB99my6dfxk7G7Jy3dxAw/viewform'>form</a></Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Feedback;
