import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Skeleton,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Tags from './Tags';
import { storage } from '../firebase-config';
import { ref, getDownloadURL } from 'firebase/storage';

const LabTile = ({
  imageFileName = '',
  imageAlt = '',
  lab = '',
  description = '',
  status = '',
  labId = '',
  tags = [],
}) => {
  const [imageURL, setImageURL] = useState();
  const [mediaLoading, setMediaLoading] = useState(true);

  useEffect(() => {
    const getImageURL = async () => {
      try {
        const fileRef = ref(storage, imageFileName);
        const storageURL = await getDownloadURL(fileRef);
        setImageURL(storageURL);
      } catch (error) {
        console.error('Failed to load image:', error);
      }
    };
    getImageURL();
  }, [imageFileName]);

  return (
    <Card raised sx={{ p: 2, mt: 2, width: 300, height: 520 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 200,
          maxHeight: 200,
        }}
      >
        {mediaLoading && (
          <Skeleton variant='rectangular' width={300} height={200} />
        )}
        <CardMedia
          component='img'
          src={imageURL}
          alt={mediaLoading ? undefined : imageAlt}
          sx={{ maxHeight: '100%', maxWidth: '100%' }}
          onLoad={() => setMediaLoading(false)}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'top',
          height: 175,
        }}
      >
        <CardContent>
          <Typography variant='h5'>{lab}</Typography>
          <Typography variant='body2'>{description}</Typography>
        </CardContent>
      </Box>
      <CardActions>
        {status !== 'Active' ? (
          <Button disabled variant='text' component={Link} to={'/lab/' + labId}>
            Coming Soon
          </Button>
        ) : (
          <Button variant='text' component={Link} to={labId}>
            Start Lab
          </Button>
        )}
      </CardActions>
      <Tags tags={tags} />
    </Card>
  );
};

export default LabTile;
