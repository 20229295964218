import React, { useState, useEffect } from 'react';
import { storage } from '../../firebase-config';
import { ref, getDownloadURL } from 'firebase/storage';
import { Box, Skeleton } from '@mui/material';
import HTMLContent from './HTMLContent';

const Image = (props) => {
  const [imageURL, setImageURL] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getImageURL = async () => {
      try {
        setLoading(true);
        const fileRef = ref(storage, props.imageFileName);
        const storageURL = await getDownloadURL(fileRef);
        setImageURL(storageURL);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };

    getImageURL();
  }, [props.imageFileName]);

  const getWidth = () => {
    if (props.multiImage && props.landscape) {
      return '600px';
    } else if (props.multiImage) {
      return '400px';
    } else {
      return 'auto';
    }
  };

  return (
    <Box sx={{ display: 'flex', padding: '1rem' }}>
      {loading && <Skeleton variant='retangular' />}
      {!loading && (
        <Box
          key={imageURL}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            onLoad={() => setLoading(false)}
            src={imageURL}
            alt={props.imageAlt}
            loading='lazy'
            width={getWidth()}
            // maxHeight={props.multiImage && '500px'}
          />
          {props.imageTitle && <HTMLContent content={props.imageTitle} />}
        </Box>
      )}
    </Box>
  );
};

export default Image;
