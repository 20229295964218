import scientificNotation from './labObjs/scientificNotation'
import significantFigures from './labObjs/significantFigures'
import liquidVolumeMeasurements from './labObjs/liquidVolumeMeasurements'
import density from './labObjs/density'
import chemicalNomenclature from './labObjs/chemicalNomenclature'
import lineSpectre from './labObjs/lineSpectre'
import avagadrosNumber from './labObjs/avagadrosNumber'
import chemicalFormula from './labObjs/chemicalFormula'
import alum from './labObjs/alum'
import conductivityOfSolutions from './labObjs/conductivityOfSolutions'
import beersLaw from './labObjs/beersLaw'
import ionicReactions from './labObjs/ionicReactions'
import bombCalorimeter from './labObjs/bombCalorimeter'
import specificHeatCapacity from './labObjs/specificHeatCapacity'
import gasConstant from './labObjs/gasConstant'
import solidStateClosePacking from './labObjs/solidStateClosePacking'
import molarVolumeGas from './labObjs/molarVolumeGas'
import clausiusClapeyron from './labObjs/clausiusClapeyron'
import osmosis from './labObjs/osmosis'
import freezingPointDepression from './labObjs/freezingPointDepression'

const Labs = {
  'scientific-notation': scientificNotation,
  'significant-figures': significantFigures,
  'liquid-volume-measurements': liquidVolumeMeasurements,
  'density': density,
  'chemical-nomenclature': chemicalNomenclature,
  'line-spectra': lineSpectre,
  'avogadros-number': avagadrosNumber,
  'chemical-formula': chemicalFormula,
  'alum': alum,
  'conductivity-of-solutions': conductivityOfSolutions,
  'beers-law': beersLaw,
  'ionic-reactions': ionicReactions,
  'bomb-calorimetry': bombCalorimeter,
  'specific-heat-capacity': specificHeatCapacity,
  'gas-constant': gasConstant,
  'solid-state-close-packing': solidStateClosePacking,
  'molar-volume-gas': molarVolumeGas,
  'clausius-clapeyron': clausiusClapeyron,
  'osmosis': osmosis,
  'freezing-point-depression': freezingPointDepression,
};
export default Labs;
