const scientificNotation = {
  name: 'Scientific Notation',
  status: 'Active',
  imageFileName: 'images/number-icon.jpg',
  imageAlt:
    'The number 8.26 times ten to the 6 is presented. The 8.27 is labeled as the coeffient, both number and label are purple in color. The ten is labeled as the base, both number and label are green in color. The 6 is labeled as the exponent, both number and label are red in color.',
  description:
    'How to convert numbers in Standard Form to Scientific Notation.',
  tags: ['Math Review', 'Tutorial', 'Chem-101A'],
  page: '/scientific-notation',
  content: {
    overview: {
      name: 'Overview',
      link: 'overview',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'NOSTEP',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'The following activity will help you practice converting numbers in standard notation to scientific notation. To start, read through the <b><em>Introduction</b></em> to review how and why numbers are presented in scientific notation. Next, use the <strong><em>Tutorial</em></strong> to learn how to do the conversion and to properly write your number. Three different types of numbers are explored: large numbers, small numbers that involve a decimal, and small numbers that involve a decimal that have trailing zeros. Next, test your understanding in the <strong><em>Exercises</em></strong> and convert multiple standard numbers into scientific notation number, and vice versa.',
                },
              ],
            },
          ],
        },
      ],
    },
    introduction: {
      name: 'Introduction',
      link: 'introduction',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'NOSTEP',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'Scientific notation presents a number as the product of two numbers: a number between 1 and 10 often appearing as a decimal, and the number 10 raised to an exponent. Scientific notation is often used out of convenience when working with numbers that are either very large or very small. It takes a lot less time and space to report the mass of an electron as: 9.11&times;10<sup>-28</sup>, rather than 0.000000000000000000000000000911 g. Notice that 9.11&times;10<sup>-28</sup> has only one digit before the decimal point. This is the common way to present numbers in scientific notation. 9.11 is called the &ldquo;coefficient&rdquo;, and the -28 is called the &ldquo;exponent&rdquo; or &ldquo;power of 10&rdquo;. Scientific notation is sometimes referred to as exponential notation. A summary of the notation is illustrated in Table 1-1.',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: '<strong>Table 1-1: Scientific Notation</strong>',
                },
                {
                  order: 3,
                  type: 'Information Table',
                  headers: [
                    'Standard Notation',
                    'Exponential Factor',
                    'Scientific Notation',
                  ],
                  rows: [
                    {
                      id: 1,
                      cells: [
                        '1,000,000,000',
                        '10<sup>9</sup>',
                        '1.0x10<sup>9</sup>',
                      ],
                    },
                    {
                      id: 2,
                      cells: [
                        '100,000,000',
                        '10<sup>8</sup>',
                        '1.0x10<sup>8</sup>',
                      ],
                    },
                    {
                      id: 3,
                      cells: [
                        '10,000,000',
                        '10<sup>7</sup>',
                        '1.0x10<sup>7</sup>',
                      ],
                    },
                    {
                      id: 4,
                      cells: [
                        '1,000,000',
                        '10<sup>6</sup>',
                        '1.0x10<sup>6</sup>',
                      ],
                    },
                    {
                      id: 5,
                      cells: [
                        '100,000',
                        '10<sup>5</sup>',
                        '1.0x10<sup>5</sup>',
                      ],
                    },
                    {
                      id: 6,
                      cells: ['10,000', '10<sup>4</sup>', '1.0x10<sup>4</sup>'],
                    },
                    {
                      id: 7,
                      cells: ['1,000', '10<sup>3</sup>', '1.0x10<sup>3</sup>'],
                    },
                    {
                      id: 8,
                      cells: ['100', '10<sup>2</sup>', '1.0x10<sup>2</sup>'],
                    },
                    {
                      id: 9,
                      cells: ['10', '10<sup>1</sup>', '1.0x10<sup>1</sup>'],
                    },
                    {
                      id: 10,
                      cells: ['1', '10<sup>0</sup>', '1.0x10<sup>0</sup>'],
                    },
                    {
                      id: 11,
                      cells: ['0.1', '10<sup>-1</sup>', '1.0x10<sup>-1</sup>'],
                    },
                    {
                      id: 12,
                      cells: ['0.01', '10<sup>-2</sup>', '1.0x10<sup>-2</sup>'],
                    },
                    {
                      id: 13,
                      cells: [
                        '0.001',
                        '10<sup>-3</sup>',
                        '1.0x10<sup>-3</sup>',
                      ],
                    },
                    {
                      id: 14,
                      cells: [
                        '0.0001',
                        '10<sup>-4</sup>',
                        '1.0x10<sup>-4</sup>',
                      ],
                    },
                    {
                      id: 15,
                      cells: [
                        '0.00001',
                        '10<sup>-5</sup>',
                        '1.0x10<sup>--5</sup>',
                      ],
                    },
                    {
                      id: 16,
                      cells: [
                        '0.000001',
                        '10<sup>-6</sup>',
                        '1.0x10<sup>-6</sup>',
                      ],
                    },
                    {
                      id: 17,
                      cells: [
                        '0.0000001',
                        '10<sup>-7</sup>',
                        '1.0x10<sup>-7</sup>',
                      ],
                    },
                    {
                      id: 18,
                      cells: [
                        '0.00000001',
                        '10<sup>-8</sup>',
                        '1.0x10<sup>-8</sup>',
                      ],
                    },
                    {
                      id: 19,
                      cells: [
                        '0.000000001',
                        '10<sup>-9</sup>',
                        '1.0x10<sup>-9</sup>',
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    tutorial: {
      name: 'Tutorial',
      link: 'tutorial',
      description:
        'Go through each of the 3 cases in this tutorial to learn about the different patterns in scientific notation.',
      tabs: [
        {
          name: 'Large Number',
          order: 1,
          steps: [
            {
              name: 'Coefficent',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'The Coefficient for a Large Number',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The mass of the moon is 7.35&times;10<sup>25</sup> g, a number written in scientific notation. The <b>coefficient</b> of the number is the 7.35.',
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName: '/images/sci-not/moon-mass.jpg',
                  imageAlt:
                    'The number 7.35 times ten to the 25 is presented. The 7.35 is labeled as the coeffient, both number and label are purple in color. The ten is labeled as the base, both number and label are green in color. The 25 is labeled as the exponent, both number and label are red in color.',
                  centered: true,
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'To convert a number to scientific notation, a common approach is to move the decimal point in a direction that leaves one non-zero digit to the left of the decimal. In this simulation, we follow that any zeros following <strong><em>non-zero</em></strong> digits for large numbers <strong><em>are neglected</em></strong>.',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: "Let's consider the large number:",
                },
                {
                  order: 6,
                  type: 'Text',
                  text: '123,000',
                  centered: true,
                },
                {
                  order: 7,
                  type: 'Input',
                  directions: 'Determine its coefficient.',
                  validAnswers: ['1.23'],
                  tip: '<p>The coefficient is typically presented as a number 1 < x < 10. </p><p> When a large number has trailing zeros (for example: 1,000), it is commonly assumed that the zeros were not measured by a device. Therefore, we say the number was estimated to the thousands place. Therefore, one does not include any trailing zeros in the coefficient.</p>',
                },
              ],
            },
            {
              name: 'Exponent',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Exponents for a Large Number',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Positive exponents signify a <strong><em>large</em></strong> number. The exponent is positive if the decimal point was moved to the left. For example: The moon orbits the sun at 22,000 mi/hr or 2.2 × 10<sup>4</sup> mi/hr.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'The number in the exponent is equal to the number of positions the decimal point was shifted.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: '22,000 = 2.2 × 10<sup>4</sup>',
                  centered: true,
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Returning to our previous example:',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: '123,000',
                  centered: true,
                },
                {
                  order: 7,
                  type: 'Input',
                  directions: 'Determine its exponent given a base of 10.',
                  validAnswers: ['5', '+5'],
                  tip: '<p>Since this is a large number, count the number of places the decimal must move to the left  in order to achieve the coefficient with the format of 1.23. </p><p>Since the number is greater than 1, the exponent will be positive.</p>',
                },
              ],
            },
            {
              name: 'Final Answer',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Final Answer',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'When we write using paper and pencil, we write scientific notation as 2.2 × 10<sup>4</sup> (showing the multiplication symbol in between the coefficient and the base 10). Most calculators or digital devices use the letter “E” to represent "10 to the power of “. Therefore, the number would appear as 2.2E4 or  2.2E+4 in a digital device.',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions:
                    'Input the number 123,000 in scientific notation as it would appear on a calculator.',
                  validAnswers: ['1.23E5', '1.23E+5'],
                  tip: 'Combine the coefficient with the exponent as a power of 10. Input the answer as a digital device would display with a capital E to indicate the exponent.',
                },
              ],
            },
          ],
        },
        {
          name: 'Small Number',
          order: 2,
          steps: [
            {
              name: 'Coefficent',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Coefficient for a Small Number',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The mass of an electron is 9.11&times;10<sup>-28</sup> g, a number must easier to read when written in scientific notation. The <b>coefficient</b> of the number is the 9.11.',
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName: '/images/sci-not/electron-mass.jpg',
                  imageAlt:
                    'The number 9.11 times ten to the -28 is presented. The 9.11 is labeled as the coeffient, both number and label are purple in color. The ten is labeled as the base, both number and label are green in color. The -28 is labeled as the exponent, both number and label are red in color.',
                  centered: true,
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'For a small number that begins in the decimal places and written in standard notation, we move the decimal point to the <b>right</b> to convert it to scientific notation. We stop until there is one non-zero digit to the left of the decimal. In this simulation (though not applicable in the example below), we follow that any <b>trailing</b> zeros (zeros that appear <b>after</b> any non-zero digits in the decimal section are <strong>not</strong> neglected. (This is following significant figure rules to be seen in the next module).',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: "Let's consider the small number example:",
                },
                {
                  order: 6,
                  type: 'Text',
                  text: '0.000123',
                  centered: true,
                },
                {
                  order: 7,
                  type: 'Input',
                  directions: 'Determine its coefficient.',
                  validAnswers: ['1.23'],
                  tip: '<p>The coefficient is typically presented as a number 1 < x < 10. </p><p>  For this small decimal number, there are not any trailing zeros, therefore the coefficient will comprise of 3 non-zero digits.</p>',
                },
              ],
            },
            {
              name: 'Exponent',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Exponent for a Small Number',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Negative exponents signify a small number. The exponent is negative if the decimal point was moved to the right when converting a standard number to scientific notation. For example: A red blood cell has a diameter of about 0.00056 inches or 5.6 × 10<sup>-4</sup> in.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'The number in the exponent is equal to the number of positions the decimal point was shifted.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: '0.00056 = 5.6 × 10<sup>-4</sup>',
                  centered: true,
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Returning to our previous example:',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: '0.000123',
                  centered: true,
                },
                {
                  order: 7,
                  type: 'Input',
                  directions: 'Determine the exponent given a base of 10.',
                  validAnswers: ['-4'],
                  tip: '<p>Since this is a small number, count the number of places the decimal must move to the right in order to achieve the coefficient with the format of 1.23.  </p><p>Since the number is less  than 1, the exponent will be negative. </p>',
                },
              ],
            },
            {
              name: 'Final Answer',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Final Answer',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Take note that when we write scientific notation using a paper and pencil, you would write it as 5.6 × 10<sup>-4</sup>. However, a digital device or (most) calculators use the letter “E” to represent "10 to the power of“. Therefore, the number would appear as 5.6E-4 on the device.',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions:
                    'Input the number 0.000123 in scientific notation as it would appear on a calculator',
                  validAnswers: ['1.23E-4'],
                  tip: 'Combine the coefficient with the exponent as a power of 10. Input the answer as a digital device would display with a capital E to indicate the exponent.',
                },
              ],
            },
          ],
        },
        {
          name: 'Small Number #2',
          order: 3,
          steps: [
            {
              name: 'Coefficient',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Coefficient of a Small Number with Trailing Zeros',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'In this simulation, we follow that any zeros <b>after</b> any non-zero digits (called trailing zeros) for small numbers are <strong><em>not</em></strong> neglected when writing the coeffient. (This is following significant figure rules).',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Consider this type of example:',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: '0.012300',
                  centered: true,
                },
                {
                  order: 5,
                  type: 'Input',
                  directions: 'Determine its coefficient.',
                  validAnswers: ['1.2300'],
                  tip: '<p>The coefficient is typically presented as a number 1 < x < 10. </p><p>  This small decimal number has trailing zeros. This indicates the zeros were measured by the instrument and show a high level of precision. The trailing zeros must be included in the coefficient.</p>',
                },
              ],
            },
            {
              name: 'Exponent',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Exponent for a Small Number with Trailing Zeros',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'All small numbers in the decimal places will have a negative exponent for its scientific notation format. The decimal point was have moved to the right and stopped when there is one non-zero digit to the left of the decimal.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Given the number:',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: '0.012300',
                  centered: true,
                },
                {
                  order: 5,
                  type: 'Input',
                  directions: 'Determine its exponent.',
                  validAnswers: ['-2'],
                  tip: '<p>Since this is a small number, count the number of places the decimal must move to the right in order to achieve the coefficient with the format of 1.2300.</p><p>Since the number is less  than 1, the exponent will be negative.</p>',
                },
              ],
            },
            {
              name: 'Final Answer',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Final Answer',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Use the letter “E” to represent "10 to the power of“ when inputting your number into a calculator or digital device. Continue using scientific notation written as 5.6 × 10<sup>-4</sup> when using a paper and pencil.',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions:
                    'Input the number 0.012300 in scientific notation as it would appear on a calculator',
                  validAnswers: ['1.2300E-2'],
                  tip: 'Combine the coefficient with the exponent as a power of 10. Input the answer as a digital device would display with a capital E to indicate the exponent.',
                },
              ],
            },
          ],
        },
      ],
    },
    exercises: {
      name: 'Exercises',
      link: 'exercises',
      problemSets: [
        {
          name: 'Conversion Practice',
          order: 1,
          instructions:
            'Convert the following large numbers to Scientific Notation. You must get 5 correct answers.',
          correctAnswersRequired: 5,
          skipsAllowed: 0,
          problems: [
            {
              inputLabel: 'Scientific Notation',
              problem: '3,043,000',
              validAnswers: ['3.043E6', '3.043E+6'],
              answerLength: 'Xlarge',
              tip: 'Review the large estimated number example in the tutorial',
            },
            {
              inputLabel: 'Scientific Notation',
              problem: '0.0000420',
              answerLength: 'Xlarge',
              validAnswers: ['4.20E-5'],
              tip: 'Review the small number #2 example in the tutorial',
            },
            {
              inputLabel: 'Scientific Notation',
              problem: '6,780',
              answerLength: 'Xlarge',
              validAnswers: ['6.78E3', '6.78E+3'],
              tip: 'Review the large estimated number example in the tutorial',
            },
            {
              inputLabel: 'Scientific Notation',
              problem: '0.0301',
              answerLength: 'Xlarge',
              validAnswers: ['3.01E-2'],
              tip: 'Review the small number example in the tutorial',
            },
            {
              inputLabel: 'Scientific Notation',
              problem: '5,000',
              answerLength: 'Xlarge',
              validAnswers: ['5E3', '5E+3'],
              tip: 'Review the large estimated number example in the tutorial',
            },
          ],
        },
        {
          name: 'Conversion Practice #2',
          order: 2,
          instructions:
            'Convert the following scientific notation numbers to standard notation. <p>Any decimal must be written with a zero before the decimal, example: 0.123. </p><p>You must get 5 correct answers.</p>',
          correctAnswersRequired: 5,
          skipsAllowed: 0,
          problems: [
            {
              inputLabel: 'Scientific Notation',
              problem: '1.01 x 10<sup>-7</sup>',
              validAnswers: ['0.000000101', '0.000 000 1901'],
              answerLength: 'Xlarge',
              tip: 'Review the small number tutorial.',
            },
            {
              inputLabel: 'Scientific Notation',
              problem: '8.6500 x 10<sup>-1</sup>',
              validAnswers: ['0.86500'],
              answerLength: 'Xlarge',
              tip: ' Review the small number #2 tutorial. The zeros are trailing zero.',
            },
            {
              inputLabel: 'Scientific Notation',
              problem: '9.11 x 10<sup>3</sup>',
              validAnswers: ['9,110', '9110'],
              answerLength: 'Xlarge',
              tip: 'Review the large number tutorial.',
            },
            {
              inputLabel: 'Scientific Notation',
              problem: '5.40 x 10<sup>1</sup>',
              validAnswers: ['54.0'],
              answerLength: 'Xlarge',
              tip: 'The zero is a trailing zero.',
            },
            {
              inputLabel: 'Scientific Notation',
              problem: '1.76 x 10<sup>0</sup>',
              validAnswers: ['1.76'],
              answerLength: 'Xlarge',
              tip: 'The power of ten to the zero is equal to 1.',
            },
          ],
        },
      ],
    },
  },
  rank: 1,
};

export default scientificNotation;
