import React, { useState } from 'react';
import { Box, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useParams } from 'react-router-dom';
import Tabs from '../../components/Tabs';
import Data from '../../data/LabsData';

const Tutorial = (props) => {
  const { labId } = useParams();
  const sectionData = Data[labId].content[props.section];
  const [activeStep, setActiveStep] = useState(sectionData.tabs[0].name);

  const handleChangeStep = (event, newValue) => {
    setActiveStep(newValue);
  };

  const tabsFormat = (
    <TabContext value={activeStep}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          width: '100%',
        }}
      >
        <TabList onChange={handleChangeStep} aria-label='Section Tabs'>
          {sectionData.tabs.map((tab) => {
            return <Tab key={tab.name} label={tab.name} value={tab.name} />;
          })}
        </TabList>
      </Box>
      {sectionData.tabs
        .sort((a, b) => a - b)
        .map((tab) => {
          return (
            <TabPanel key={tab.name} value={tab.name}>
              <Tabs steps={tab.steps} />
            </TabPanel>
          );
        })}
    </TabContext>
  );

  return (
    <>
      <Typography variant='h5' sx={{ pb: 1 }}>
        {props.section.replace(
          props.section[0],
          props.section[0].toUpperCase()
        )}
      </Typography>
      {sectionData.tabs[0].name === 'NOTAB' ? (
        <Tabs steps={sectionData.tabs[0].steps} />
      ) : (
        tabsFormat
      )}
    </>
  );
};

export default Tutorial;
