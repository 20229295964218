const clausiusClapeyron = {
    status: 'Active',
    name: 'Clausius-Clapeyron',
    imageFileName: 'images/clausius-clapeyron-icon.jpg',
    imageAlt: 'A thousand milliliter is filled with water. Dipped in the water is an upside down graduated cylinder and a a thermometer. The beaker rests on a wire gauze and a large ring holds the beaker in place.',
    description: 'Experimental guide on how to determine the heat of vaporization of water.',
    tags: ['Pre-Lab', 'Wet Lab', 'Experiment', 'Chem-101A'],
    page: '/clausius-clapeyron',
    content: {
      overview: {
        name: 'Overview',
        link: 'overview',
        tabs: [
          {
            name: 'NOTAB',
            order: 1,
            steps: [
              {
                name: 'NOSTEP',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Text',
                    text: 'In this experiment, you will measure the vapor pressure of water over a range of temperatures. The data will be plotted in order to measure the slope of the line. The slope will lead to calculating the heat of vaporization of water, ∆H<sub>vap</sub>. Begin by reading through the <strong><em>Introduction</em></strong> to understand how to graphically obtain the Clasius-Clapeyron equation. Next, read through the <strong><em>Experiment</em></strong> to learn how to set-up the experiment and measure appropriately.',
                  },
                ],
              },
            ],
          },
        ],
      },
      introduction: {
        name: 'Introduction',
        link: 'introduction',
        tabs: [
          {
            name: 'NOTAB',
            order: 1,
            steps: [
              {
                name: 'The Clasius-Clapeyron Equation',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Text',
                    text: 'The relationship between the vapor pressure of a liquid and the temperature is shown graphically as:',
                  },
                    {
                      order: 2,
                      type: 'Image',
                      imageFileName: '/images/clausius-clapeyron/Vapor-pressure-vs-temperature.jpg',
                      imageAlt: 'A generic graph having vapor pressure on the y axis and temperature on the x axis. The graph presents an inverse relationship between the two parameters; a downward curve without reaching either axis',
                      centered: true,
                    },
                    {
                      order: 3,
                      type: 'Text',
                      text: 'The same data, can be expressed in a different manner, by manipulating it mathematically. The next graph uses the same data, but instead plots the natural log of the vapor pressure and 1 divided by the temperature. In this way, this particular set of data graphically results in a straight line.',
                    },
                    {
                      order: 4,
                      type: 'Image',
                      imageFileName: '/images/clausius-clapeyron/linear-form.jpg',
                      imageAlt: 'A generic graph having the natural log of vapor pressure on the y axis and 1 over the temperature on the x axis. The graph presents a direct relationship between the two parameters; a negative straight line without reaching either axis.',
                      centered: true,
                    },
                    {
                      order: 5,
                      type: 'Text',
                      text: 'This linear relationship can be expressed mathematically as:',
                    },
                    {
                      order: 6,
                      type: 'Image',
                      imageFileName: '/images/clausius-clapeyron/clausius-clapeyron-equation.jpg',
                      imageAlt: 'The national log of vapor pressure is equal to the negative heat of vaporization divided by R times 1 over temperature plus the constant, C.',
                      centered: true,
                    },
                    {
                      order: 7,
                      type: 'Text',
                      text: 'where ln P is the natural logarithm of the vapor pressure, ∆H<sub>vap</sub> is the heat of vaporization, R is the universal gas constant (8.31 J·K<sup>-1</sup>mol<sup>-1</sup>), T the absolute temperature, and C a constant (not related to heat capacity).',
                    },
                    {
                      order: 8,
                      type: 'Text',
                      text: 'This is the <b>Clausius-Clapeyron equation</b>, which gives us a way of ﬁnding the heat of vaporization, the energy that must be supplied to vaporize a mole of molecules in the liquid state.',
                    },
                    {
                      order: 9,
                      type: 'Text',
                      text: 'The Clausius-Clapeyron equation follows the straight line equation:',
                    },
                    {
                      order: 10,
                      type: 'Image',
                      imageFileName: '/images/clausius-clapeyron/y-mx-b.jpg',
                      imageAlt: 'y is equal to m x plus b.',
                      centered: true,
                    },
                    {
                      order: 11,
                      type: 'Text',
                      text: 'In this equation, <li>ln P is y</li><li>1/T is x </li><li>-∆H<sub>vap</sub>/R is m</li>',
                    },
                ],
              },
              {
                name: 'Experimental Overview',
                order: 2,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Text',
                    text: 'In this experiment, you will measure the vapor pressure of water over a range of temperatures, plot the data, measure the slope of the line, and then calculate ∆H<sub>vap</sub> for water.',
                  },
                    {
                      order: 2,
                      type: 'Text',
                      text: 'The method you will use to measure the vapor pressure will require careful readings of the volume of air and water vapor in an inverted graduated cylinder immersed in a beaker of water. You will half ﬁll the graduated cylinder with water, and then immerse it upside down in enough water to cover the cylinder. Then you will warm the water in the beaker. As the temperature increases, the air in the graduated cylinder expands, and the vapor pressure of the water increases. Once the water in the beaker reaches a high enough temperature, the assembly is allowed to cool. A series of volume and temperature readings are taken, and then the water in the beaker is iced. At ice temperatures, the vapor pressure of water is negligible. The volume at that point is due to the air alone, within the limits of the measurements. This volume is recorded. Using the gas law equation, the number of moles of air in the cylinder is calculated:',
                    },
                    {
                      order: 3,
                      type: 'Image',
                      imageFileName: '/images/clausius-clapeyron/moles.jpg',
                      imageAlt: 'Moles, n, is equal to pressure times volume of a cold gas divided by universal gas constant divided by temperature.',
                      centered: true,
                    },
                    {
                      order:5,
                      type: 'Text',
                      text: ' Then, for each of the other temperatures and volumes measured, the pressure from this number of moles of air is calculated. The total pressure in the graduated cylinder is atmospheric pressure. The difference, then, between the pressure caused by the air and the atmospheric pressure must be due to the vapor pressure of water. This is an application of Dalton’s law of partial pressure:',
                    },
                    {
                      order: 6,
                      type: 'Image',
                      imageFileName: '/images/clausius-clapeyron/daltons-law.jpg',
                      imageAlt: 'The total pressure is equal to the partial pressure of gas 1 plus the partial pressure of gas 2 plus so on.',
                      centered: true,
                    },
                ],
              },
            ],
          },
        ],
      },
      experiment: {
        name: 'Experiment',
        link: 'experiment',
        tabs: [
          {
            name: 'Start Here',
            order: 1,
            steps: [
              {
                name: 'Summary',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Summary: Experimental Steps',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'The general experimental steps are: <li>Fill a graduated tube partly with water.</li><li>Turn it upside down without letting water out and immerse it fully in a large beaker of water.</li><li>Heat the beaker with water.</li><li>Once the entrapped gas is nearly filling the tube, record the temperature of the beaker and the volume of gas in the tube.</li><li>Measure the temperature and volume 5-6x as the temperature decreases roughly every 5 degrees.</li><li>The last measurement will involve lowering the beaker\'s water temperature below 5&deg;C by adding ice.</li>',
                  },
                ],
              },
              {
                name: 'Equipment',
                order: 2,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Equipment',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'The following pieces of equipment are needed for this experiment:',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/clausius-clapeyron/equipment.jpg',
                    imageAlt: 'The equipment is shown spread out on the lab bench. Numbers are next to each and a key is to its right 1. ring stand, 2. three-prong clamp, 3. 2 large ring clamps, 4. wire mesh, 5. 1 L beaker with water, 6. Bunsen burner and hose, 7. striker, 8. thermometer, 9. stopper with slit, 10. copper wire, 11. graduated tube, 12. 2 small elastic bands, and 13. tube band (not shown).',
                    centered: true,
                  },
                ],
              },
              {
                name: 'Notebook Prep',
                order: 3,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Notebook Preparation',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Draw the <strong>Data Table</strong> in your notebook:',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/clausius-clapeyron/data-table.jpg',
                    imageAlt: 'A 10 row 2 column table. Row 2 and 2 have merged columns. The first row names the entire table as "Data Table". Row 2 has "Barometric Pressure:", a large space for the student to write in, then the units "torr". Row 3 separates into two columns with the label headers: Volume and Temperature. Row 4 to 1- have units for every cell that will be filled in by the student. Colum 1 has units of mL and column 2 has degrees Celsius. The last row has additional information. Column 1 refers to the "Cold:" and column 2 has "to be less than 5 degrees Celsius".',
                    centered: true,
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Next, draw the <strong>Calculation Table</strong> in your notebook.',
                  },
                  {
                    order: 5,
                    type: 'Image',
                    imageFileName: '/images/clausius-clapeyron/calculation-table.jpg',
                    imageAlt: 'A 7 row 6 column table. The first row lists the headers of the columns 1 to 6 as: 1. Volume(L), 2. T (K), 3. P sub air (torr), 4. P sub vapor (torr), 5. ln P, and 6 1/T (K to the -1). All other cells are empty ready for the student to fill in.',
                    centered: true,
                  },
                ],
              },
            ],
          },
          {
            name: 'Procedure',
            order: 2,
            steps: [
              {
                name: 'Thermometer Assembly',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Thermometer Assembly',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Collect a lab kit from your instructor and assemble the pieces around the thermometer as shown below.',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/clausius-clapeyron/thermometer-setup.jpg',
                    imageAlt: 'A main image is in the center presenting a thermometer/graduated cylinder assembly. A copper wire extends along the length of the thermometer. A graduated cylinder is attached to the copper wire about 2/3rds from the bottom of the thermometer.  Three sections of the thermometer are highlighted with dashed rectangle. Each box is labeled, from top to bottom, as a to c. Each box connects to a close-up view image. Box "a" is on the top right and presents the top the thermometer. A black one-hole stopper is near the top of the thermomete and it is apparent that the stopper is slit open. The thermometer and copper wire go through its slit. A small elastic band is around the thermometer within the stopper and is holding the copper wire. Box "b" is highlights a transparent tube band (a vinyl loop) that goes over the graduated cylinder and holds the copper wire. Box "c" displays the mouth of the graduated cylinder tube being adjacent just above the tip of the thermometer. The copper wire is held in place by a small elastic band that is around the thermometer.',
                    centered: true,
                  },
                ],
              },
              {
                name: 'Full Assembly',
                order: 2,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Full Assembly',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Here is a diagram of how the apparatus should appear after assembly:',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/clausius-clapeyron/apparatus-setup.jpg',
                    imageAlt: 'A diagram of a ring stand, 2 large ring clamps with a a 1000 mL beaker full of water nestled with one ring clamp and resting on the other and a wire mesh. Below the wire mesh and the one ring clamp is a lit Bunsen burner below. A 3-prong clamp holds the thermometer/graduated cylinder assembly at the stopper (or cork) so that is dips into the beaker of water all the way so that the mouth of the graduated cylinder nearly reaches the bottom of the beaker. The rubber bands are highlighted with the label "rubber loops" and the copper wire and cork are labeled as well.',
                    centered: true,
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Fill the 1 L with ~800 ml of deionized water.',
                  },
                  {
                    order: 5,
                    type: 'Images',
                    images: [
                      {
                        imageFileName: '/images/clausius-clapeyron/beaker-placement-A.jpg',
                        imageAlt: 'An image of a ring stand, 2 large ring clamps with a a 1000 mL beaker fullof water nestled with one ring clamp and resting on the other and a wire mesh. Below the wire mesh and the one ring clamp is a lit Bunsen burner below. The Bunsen burner is about 4 inches below the ring clamp.',
                        imageTitle: 'A',
                      },
                      {
                        imageFileName: '/images/clausius-clapeyron/beaker-placement-B.jpg',
                        imageAlt: 'An image of a ring stand, 2 large ring clamps with a a 1000 mL beaker fullof water nestled with one ring clamp and resting on the other and a wire mesh. Below the wire mesh and the one ring clamp is a lit Bunsen burner below. The Bunsen burner is about 3 inches below the ring clamp.',
                        imageTitle: 'B',
                      },
                      {
                        imageFileName: '/images/clausius-clapeyron/beaker-placement-C.jpg',
                        imageAlt: 'An image of a ring stand, 2 large ring clamps with a a 1000 mL beaker fullof water nestled with one ring clamp and resting on the other and a wire mesh. Below the wire mesh and the one ring clamp is a lit Bunsen burner below. The Bunsen burner is about 6 inches below the ring clamp. ',
                        imageTitle: 'C',
                      },
                      {
                        imageFileName: '/images/clausius-clapeyron/beaker-placement-D.jpg',
                        imageAlt: 'An image of a ring stand, 2 large ring clamps with a a 1000 mL beaker fullof water nestled with one ring clamp and resting on the other and a wire mesh. Below the wire mesh and the one ring clamp is a lit Bunsen burner below. The Bunsen burner is about 1.5 inch below the ring clamp.',
                        imageTitle: 'D',
                      },
                    ],
                  },
                  {
                    order: 6,
                    type: 'Input',
                    directions: 'Which of the above (A - D) is a good placement of the ring clamp over the Bunsen burner for heating the beaker of water?',
                    validAnswers: ['B', 'b'],
                    tip: 'Placing the beaker too close to the flame could cause the beaker glassware to heat up so quickly that it could get too hot and shatter it. <p>Placing the beaker too far from the flame could cause the heating process to take too long and is a waste of natural gas as you would likley have to open the Bunsen burner valve very wide to get the flame to be large and tall.</p><p>The best scenario is to have a Goldilocks approach, the beaker is placed not too far and not too close to the flame. Then, you can control the flame height for whether you want a gentle or strong heating.</p>',
                  },
                  {
                    order: 7,
                    type: 'Text',
                    text: 'Before placing the graduated cylinder/thermometer assembly into the beaker, ﬁll the cylinder with deionized water. The lab provides two types of graduated cylinders:<li>The cone-end cylinder should be filled to the 10 mL mark.</li><li>The flat-end cylinder should be filled to the 7 mL mark.</li>',
                  },
                  {
                    order: 8,
                    type: 'Text',
                    text: 'Now place the tip of your ﬁnger over the mouth of the cylinder, and invert the assembly and lower it into the beaker. Once the mouth of the cylinder is submerged into the water in the beaker, you can release your ﬁnger.',
                  },
                  {
                    order: 9,
                    type: 'Images',
                    images: [
                      {
                        imageFileName: '/images/clausius-clapeyron/inverted-tube-A.jpg',
                        imageAlt: 'An image of a ring stand, 2 large ring clamps with a a 1000 mL beaker fullof water nestled with one ring clamp and resting on the other and a wire mesh. Below the wire mesh and the one ring clamp is a lit Bunsen burner below. The Bunsen burner is about 4 inches below the ring clamp.',
                        imageTitle: 'A',
                      },
                      {
                        imageFileName: '/images/clausius-clapeyron/inverted-tube-B.jpg',
                        imageAlt: 'An image of a ring stand, 2 large ring clamps with a a 1000 mL beaker fullof water nestled with one ring clamp and resting on the other and a wire mesh. Below the wire mesh and the one ring clamp is a lit Bunsen burner below. The Bunsen burner is about 3 inches below the ring clamp.',
                        imageTitle: 'B',
                      },
                      {
                        imageFileName: '/images/clausius-clapeyron/inverted-tube-C.jpg',
                        imageAlt: 'An image of a ring stand, 2 large ring clamps with a a 1000 mL beaker fullof water nestled with one ring clamp and resting on the other and a wire mesh. Below the wire mesh and the one ring clamp is a lit Bunsen burner below. The Bunsen burner is about 6 inches below the ring clamp. ',
                        imageTitle: 'C',
                      },
                    ],
                  },
                  {
                    order: 10,
                    type: 'Input',
                    directions: 'Which of the above (A - C) is a good placement of the inverted tube in the beaker of water?',
                    validAnswers: ['C', 'c'],
                    tip: 'The directions explain to "Invert the assembly and lower it into the beaker...once the mouth of the cylinder is <strong>submerged</strong> into the water."',
                  },
                  {
                    order: 11,
                    type: 'Text',
                    text: 'Clamp the cork at the top of the thermometer so that the assembly is ﬁrmly in place. The inverted cylinder should be just above the bottom of the beaker, and the water in the beaker should just cover the top of the cylinder.',
                  },
                ],
              },
              {
                name: 'Heating',
                order: 4,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Heating',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Heat the beaker with a Bunsen burner turned on to its maximum heat output until the water reaches 72&deg to 75&deg C. ',
                  },
                  {
                    order: 3,
                    type: 'Images',
                    images: [
                      {
                        imageFileName: '/images/clausius-clapeyron/heating-A.jpg',
                        imageAlt: 'A Bunsen burner is lit underneath a beaker of water resting on a large ring clamp and wire mesh. The flame appears as a turquoise cone just beneath the ring clamp.',
                        imageTitle: 'A',
                      },
                      {
                        imageFileName: '/images/clausius-clapeyron/heating-B.jpg',
                        imageAlt: 'A Bunsen burner is lit underneath a beaker of water resting on a large ring clamp and wire mesh. The flame appears as a short turquoise cone about 1 centimeter from the ring clamp.',
                        imageTitle: 'B',
                      },
                      {
                        imageFileName: '/images/clausius-clapeyron/heating-C.jpg',
                        imageAlt: 'A Bunsen burner is lit underneath a beaker of water resting on a large ring clamp and wire mesh. The flame appears as a turquoise cylinder beneath the ring clamp.',
                        imageTitle: 'C',
                      },
                      {
                        imageFileName: '/images/clausius-clapeyron/heating-D.jpg',
                        imageAlt: 'A Bunsen burner is lit underneath a beaker of water resting on a large ring clamp and wire mesh. The flame appears wavering and yellow. The flame is does not have any unique shape, but can be seen heating the iron ring clamp',
                        imageTitle: 'D',
                      },
                      {
                        imageFileName: '/images/clausius-clapeyron/heating-E.jpg',
                        imageAlt: 'A Bunsen burner is lit underneath a beaker of water resting on a large ring clamp and wire mesh. The flame appears as a turquoise triangular shape just beneath the wire mesh.',
                        imageTitle: 'E',
                      },
                    ],
                  },
                  {
                    order: 4,
                    type: 'Input',
                    directions: 'Which of the above (A - E) is a flame setting clamp for heating the beaker of water? (Select all that apply)',
                    validAnswers: ['A and E', 'E and A','a and e','e and a', 'A, E', 'E, A', 'a, e', 'e, a', 'A,E', 'E,A', 'a,e', 'e,a', 'AE', 'EA', 'ae', 'ea'],
                    tip: 'If one is boiling water, the flame should be set up to heat fast, which is called strong heating. To do this, the flame should be blue with a turquoise cone-shape interior. The tip of the cone of the turquoise flame is the hottest part of the flame. The turquoise tip should be just beneath the ring clamp or wire mesh<p>If the turquoise cone is situated above the wire mesh, it could heat the beaker glassware too quickly and cause it to shatter.</p><p>A yellow flame means incomplete combusion (less oxygen is being brought in to the Bunsen burner). This flame is uncontrolled and can waver around unsafely. It also is not very hot, so will not heat your water to boil in a timely manner.</p>',
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'When the temperature has reached to 72&degC to 75&deg C, turn off the burner. The volume of entrapped gas in the cylinder should nearly ﬁll the cylinder. If gas bubbles out of the cylinder at the top temperature, do not be concerned. Stir the water in the beaker to ensure the beaker water is uniform.',
                  },
                ],
              },
              {
                name: 'Data Collection',
                order: 5,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Data Collection',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'You will now record the temperature and the volume of gas in the graduated cylinder (to the nearest 0.01 mL). Remember that the cylinder is inverted. In the diagram below, the meniscus is exaggerated in the drawing.',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/clausius-clapeyron/measurement-diagram.jpg',
                    imageAlt: 'An upside-down graduated cylinder. At the top is 5.0. At the bottom is 6.0. The bottom portion is labeled water and the top portion is labeled gas. The representation of a meniscus is shown with the concave section meeting the glass at 5.55 mL and the bottom of the meniscus reaching 5.63 mL.',
                    centered: true,
                  },
                  {
                    order: 4,
                    type: 'Input',
                    directions: 'Measure the volume of gas in the inverted graduated cylinder diagram above.',
                    validAnswers: ['5.65', '5.64', '5.63'],
                    unitOfMeasure: 'mL',
                    tip: 'You are measuring the gas, so you will measure top-down. The bottom of the meniscus still exists. Make sure you estimate between 5.60 and 5.70 mL.',
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'Record temperatures and volumes every 5 &degC or so until you have 5 or 6 readings. You can hasten the cooling by adding <strong> small amounts of water or ice</strong>  to the beaker, but the temperature in the beaker must be uniform when you make the measurements. <strong>This requires good stirring</strong>.',
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'After the 5th or 6th reading, it will be time to lower the temperature of the water in the beaker to below 5 &degC. Use a small beaker to remove about half of the water in the beaker, taking care that you do not disturb the water in the inverted cylinder. Fill the beaker with ice, and stir. If all of the ice melts, and the temperature is still above 5°C, remove more water, and add more ice. Stir the water in the beaker for a few minutes to allow the temperature of the water in the cylinder to equilibrate with the temperature in the beaker, and then make the cold temperature and volume reading.',
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: '<strong>Take care with this last measurement because all of your data will become tainted if this reading is off.</strong>',
                    centered: true,
                  },
                ],
              },
              {
                name: 'Clean-up',
                order: 5,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Clean-up',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Disassemble your temperature/graduated cylinder assembly. Dry all glassware and pieces before returning to the lab cart.',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: '<strong>You are ﬁnished with the experiment guide!</strong>',
                    centered: true,
                  },
                ],
              },
            ]
          },
        ],
      },
    },
    rank: 18
  }

export default clausiusClapeyron