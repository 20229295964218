import React, { useState, useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Problem from './Problem';
import HTMLContent from './content-blocks/HTMLContent';

const ProblemSet = ({ data, onCompletedProblemSet }) => {
  const { instructions, problems, correctAnswersRequired } = data;

  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [activeProblem, setActiveProblem] = useState(0);

  const handleNext = useCallback(() => {
    if (activeProblem < problems.length) {
      setActiveProblem(activeProblem + 1);
    }
  }, [activeProblem, problems.length]);

  const handleCheck = useCallback((correct) => {
    if (correct) {
      setCorrectAnswers((previousState) => previousState + 1);
    }
  }, []);

  const isProblemSetCompleted = correctAnswers === correctAnswersRequired;

  return (
    <>
      <Box sx={{ mb: 4 }}>
        <HTMLContent content={instructions} />
        <Typography
          variant='h6'
          sx={{ display: isProblemSetCompleted ? 'inline' : 'none' }}
        >
          Congratulations, you have completed the required exercises.
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', pb: 2, gap: 10 }}>
        <Typography
          variant='h6'
          sx={{ color: 'green' }}
        >{`Correct: ${correctAnswers}`}</Typography>
      </Box>
      <Box sx={{ display: isProblemSetCompleted ? 'none' : 'inline' }}>
        {problems.map((problem, index) => (
          <Problem
            key={problem.problem}
            id={index}
            inputLabel={problem.inputLabel}
            problem={problem.problem}
            validAnswers={problem.validAnswers}
            answerLength={problem.answerLength}
            tip={problem.tip}
            visible={activeProblem === index}
            onNext={handleNext}
            showNext={
              activeProblem < problems.length - 1 ? 'visible' : 'hidden'
            }
            onCheck={handleCheck}
          />
        ))}
      </Box>
      <Box
        sx={{
          flexDirection: 'row',
          mt: 1,
          justifyContent: 'center',
          display: isProblemSetCompleted ? 'flex' : 'none',
        }}
      >
        <Button variant='contained' onClick={onCompletedProblemSet}>
          Next set of problems
        </Button>
      </Box>
    </>
  );
};

export default ProblemSet;
