import React, { useState } from 'react';
import { Box, Button, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Link, useParams } from 'react-router-dom';
import ProblemSet from '../../components/ProblemSet';
import Data from '../../data/LabsData';

const Exercises = (props) => {
  const { labId } = useParams();
  const exerciseData = Data[labId].content.exercises;
  const problemSets = exerciseData.problemSets;

  const [activeProblemSet, setActiveProblemSet] = useState(problemSets[0].name);
  const [completedProblemSets, setCompletedProblemSets] = useState(0);
  const [completedExercise, setCompletedExercise] = useState(false);

  const handleChangeProblemSet = (event, newValue) => {
    setActiveProblemSet(newValue);
  };

  const handleCompletedProblemSet = (event) => {
    setCompletedProblemSets((previousValue) => previousValue + 1);
    if (completedProblemSets === problemSets.length) {
      setCompletedExercise(true);
    }
  };

  return (
    <>
      <Typography variant='h5' sx={{ pb: 1 }}>
        Exercise
      </Typography>
      <TabContext value={activeProblemSet}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChangeProblemSet}
            aria-label='Significant figures exercise problem sets'
          >
            {problemSets.map((set) => {
              return <Tab key={set.name} label={set.name} value={set.name} />;
            })}
          </TabList>
        </Box>
        {problemSets.map((problemSet) => {
          return (
            <TabPanel key={problemSet.name} value={problemSet.name}>
              <ProblemSet
                data={problemSet}
                onCompletedProblemSet={handleCompletedProblemSet}
              />
            </TabPanel>
          );
        })}
      </TabContext>
      <Box sx={{ display: completedExercise ? 'inline' : 'none' }}>
        <Button
          variant='outlined'
          key='student-dashboard'
          component={Link}
          to='/lab-dashboard'
        >
          Return to Lab Dashboard
        </Button>
      </Box>
    </>
  );
};

export default Exercises;
