const bombCalorimeter = {
  status: 'Active',
  name: 'Bomb Calorimetry',
  imageFileName: 'images/bomb-calorimetry-icon.jpg',
  imageAlt: 'A diagram of a bomb calorimeter. ',
  description: 'A tutorial guide on how to calculate the heat of combustion.',
  tags: ['Pre-Lab', 'Dry Lab', 'Experiment', 'Tutorial', 'Chem-101A'],
  page: '/calorimetry',
  content: {
    overview: {
      name: 'Overview',
      link: 'overview',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'NOSTEP',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Image',
                  imageFileName:
                    '/images/bomb-calorimetry/calorimeter-with-labels.jpg',
                  imageAlt:
                    'A diagram of a bomb calorimeter. Arrows point to the calorimeter and its contents. An outer jacket surrounds the calorimeter. The portion called the bomb is a small container that holds the sample. The bomb is resting in water and a stirrer and thermometer are in the water. Inside the bomb is the sample where a fuse touches it. The fuse connects to two electrical leads that stem outside of the bomb and the calorimeter apparatus. At the top of the bomb, is an oxygen inlet valve. Provided as a side-note in the image, "Not shown in the diagram: the heating and cooling system for the outer jacket, and the controls that keep the outer jacket at the same temperature as the calorimeter.',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The equipment shown in the above diagram will be used in an in-class video presentation of the experiment. Although you will not get a "hands-on" experience, you will be required to record data during lab, which requires you to be careful in the use of signiﬁcant digits, and to do calculations with the data obtained. After reading through the <strong><em>Introduction</em></strong>, prepare for lab in the <strong><em>Experiment</em></strong> section by writing several balanced hydrocarbon combustion reactions, which will be needed for your calculations.',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Only after the data has been collected during lab, is it recommended to view the <strong><em>Tutorial</em></strong>. The<strong><em>Tutorial</em></strong> will give guidance on the set of calculations needed to calculate the heat of combustion based on the calorimetric data.',
                },
              ],
            },
          ],
        },
      ],
    },
    introduction: {
      name: 'Introduction',
      link: 'introduction',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'Introduction',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Introduction',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'This lab demonstrates one of the most common thermodynamic measurements, the determination of the <strong>heat of combustion</strong> of a compound. By measuring the values of a series of similar compounds, you will see if there are equivalent amounts of energy associated with the combustion of equivalent groups of atoms. In particular, one class of compounds used is the primary straight-chain alcohols which differ one from another in the number of CH<sub>2</sub> groups in the middle of the molecule. These are:<li> 1-propanol, CH<sub>3</sub>CH<sub>2</sub>CH<sub>2</sub>OH</li><li> 1-butanol, CH<sub>3</sub>CH<sub>2</sub>CH<sub>2</sub>CH<sub>2</sub>OH</li><li>1-pentanol, CH<sub>3</sub>CH<sub>2</sub>CH<sub>2</sub>CH<sub>2</sub>CH<sub>2</sub>OH</li><p>Another class of compounds burned in this lab consists of 6 carbon atoms attached in a ring arrangement with hydrogen atoms attached to the carbons:</p><li>cyclohexane, C<sub>6</sub>H<sub>12</sub>(l)</li><li>cyclohexene, C<sub>6</sub>H<sub>10</sub>(l)</li><li>1,4-cyclohexadiene, C<sub>6</sub>H<sub>8</sub>(l))</li><li>1,3,5-cyclohexatriene, C<sub>6</sub>H<sub>6</sub>(l)</li>',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'While the structures of these molecules are not provided here, let\'s take note of their formulas. The formulas contain 12, 10, 8 and 6 hydrogens attached to the 6 carbons. Using a different way to describe these molecules, the one with 12 hydrogens is <strong>saturated</strong>, and each of the other three have increasing amounts of <strong>unsaturation</strong>. Using still another method of description, each molecule has a different number of double bonds, from none to three.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'There are two trends in composition for the compounds used in this experiment. You will see from the data that sometimes trends work, and sometimes they do not.',
                },
              ],
            },
            {
              name: 'Experiment Overview',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Experiment Overview',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The bomb and its calorimeter are set up in an identical manner for each of the experiments performed. Preliminary runs have shown that the standard lengths of fuse wire and cotton thread used to initiate each combustion do not make any signiﬁcant contribution to the total heat evolved for the sample sizes used. By burning a weighed sample of a pure compound of known heat combustion, such as benzoic acid, we are able to calculate the heat evolved within the calorimeter. Observation of the temperature rise caused by this amount of heat enables us to determine the heat capacity of the calorimeter which is later used to obtain unknown heats of combustion.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'A special feature of bomb calorimetry is that the pressure vessel or bomb ensures that combustions are carried out <strong>at constant volume</strong>. Since no overall expansion is possible, it follows that no work can be done by the reacting system, and under such conditions the heat evolved is known as the <strong>internal energy change (∆E)</strong>.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'However, the <strong>heat of reaction (∆H)</strong> normally encountered is that measured <strong>at constant pressure</strong>, for instance in an open container at atmospheric pressure. ∆H can be calculated from ∆E using the relationship:',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: '∆H = ∆E + ∆n(RT)',
                  centered: true,
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'In this expression, T is the average water temperature (in Kelvin) in the calorimeter, R is the gas constant (0.008314 kJK<sup>-1</sup>mol<sup>-1</sup>) and ∆n refers to the change in the number of moles of gases during the reaction. The sign and magnitude of ∆n may be determined from the balanced equation for the reaction:',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: '∆n = moles<sub>gas products</sub> — moles<sub>gas reactants</sub>',
                  centered: true,
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'For a technical point, a drop of water previously introduced into the bomb saturates the container with water vapor and insures that any water vapor formed during a combustion will condense to liquid and need not be considered in the ∆n term.',
                },
              ],
            },
          ],
        },
      ],
    },
    experiment: {
      name: 'Experiment',
      link: 'experiment',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'Straight-chain',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Straight-chain Hydrocarbon Combustion Reactions',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'You will watch in lab a video that carries out the combustion reactions of seven hydrocarbons in a bomb calorimeter.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'The first three hydrocarbons are primary chain alcohols. Here are their balanced reactions and ∆n. Take note that ∆n always refers to gaseous substances only.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: '<ul>1) 1-propanol, CH<sub>3</sub>CH<sub>2</sub>CH<sub>2</sub>OH</ul>',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'C<sub>3</sub>H<sub>8</sub>O(l) + <strong>9/2</strong> O<sub>2</sub>(g) -> <strong> </strong> CO<sub>2</sub>(g) + 4H<sub>2</sub>O(l) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;∆n = <strong>3</strong> moles CO<sub>2</sub> - <strong>4.5</strong> moles O<sub>2</sub> = -1.5 mole',
                  centered: true,
                },
                {
                  order: 6,
                  type: 'Text',
                  text: '<ul>2) 1-butanol, CH<sub>3</sub>CH<sub>2</sub>CH<sub>2</sub>CH<sub>2</sub>OH</ul>',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: 'C<sub>4</sub>H<sub>10</sub>O(l) + <strong>6</strong> O<sub>2</sub>(g) -> <strong>4</strong> CO<sub>2</sub>(g) + 5H<sub>2</sub>O(l) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;∆n = <strong>4</strong> moles CO<sub>2</sub> - <strong>6</strong> moles O<sub>2</sub> = -2.0 mole',
                  centered: true,
                },
                {
                  order: 8,
                  type: 'Text',
                  text: '<ul>3) 1-pentanol, CH<sub>3</sub>CH<sub>2</sub>CH<sub>2</sub>CH<sub>2</sub>CH<sub>2</sub>OH</ul>',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'C<sub>5</sub>H<sub>12</sub>O(l) + <strong>15/2</strong> O<sub>2</sub>(g) -> <strong>5</strong> CO<sub>2</sub>(g) + 6H<sub>2</sub>O(l) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;∆n = <strong>5</strong> moles CO<sub>2</sub> - <strong>7.5</strong> moles O<sub>2</sub> = -2.5 mole</p></ul>',
                  centered: true,
                },
              ],
            },
            {
              name: 'Ring Structure',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Ring-based Hydrocarbon Combustion Reactions',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The next four hydrocarbons contain 6 carbons in a ring structure. In your lab notebook, write and balance the combustion reactions (as reactions 1 to 3 on the previous page) and determine their ∆n in your lab notebook.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: '<ul>3) cyclohexane, C<sub>6</sub>H<sub>12</sub>(l)<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Balanced equation:</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;∆n =</p></ul>',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: '<ul>4) cyclohexene, C<sub>6</sub>H<sub>10</sub>(l)<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Balanced equation:</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;∆n =</p></ul>',
                  
                },
                {
                  order: 5,
                  type: 'Text',
                  text: '<ul>5) 1,4-cyclohexadiene, C<sub>6</sub>H<sub>8</sub>(l)<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Balanced equation:</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;∆n =</p></ul>',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: '<ul>6) 1,3,5-cyclohexatriene,C<sub>6</sub>H<sub>6</sub>(l)<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Balanced equation:</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;∆n =</p></ul>',
                },
              ],
            },
            {
              name: 'Procedure',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Procedure',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'You are not being required to draw the several tables found within the lab protocol into your notebook. Instead, use your notebook as the space to carry out the multiple calculations and be aware to show examples of each unique calculation for all steps.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'You will watch a video of the data collection for the combustion of several hydrocarbons in a bomb calorimeter. Be prepared to read and write down the data from the observations made during the video in your lab worksheet. The video will be set to pause while readings are made, and you should check with other students to make sure readings are accurately recorded. Be sure to measure data with the proper number of signiﬁcant digits.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'While watching the video, the first observations will give the weight of the pellet of benzoic acid used to determine the <strong>heat capacity</strong> of the bomb, and the <strong>initial and ﬁnal temperatures</strong> as shown on the thermometer.',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Here is an image of the thermometer in the video. To get acquainted with it, notice the mercury in the middle and how it has risen. The increments of the thermometer reach the thousanth place. In the image below, one would measure this temperature as 15.070 &degC. Review this carefully, as you will be taking several temperature measurements all to the nearest 0.001 &degC.',
                },
                {
                  order: 6,
                  type: 'Image',
                  imageFileName: '/images/bomb-calorimetry/temp-measurement.jpg',
                  imageAlt: 'A close-up view of a thermometer measurement. The thermometer shows the scale of 15 with a large increment labeled as 0.1. There are smaller increments at the scale of 0.01. The mercury in the center of the thermometer shows to be rising. A red arrow points to the top of the risen mercury and says in a red box next to it "mercury rising in the thermometer. Another red arrow points to the 0.1 and in a red box connected to the arrow says "increment line marked as 0.1". A third thinner arrow points to the top of the risen mercury and another red box connects to it and says "mercury reaches 15.070.',
                  centered: true,
                },
                {
                  order: 7,
                  type: 'Text',
                  text: 'For the other seven combustions, the weights involved are given in the first data table. Only <strong>initial and ﬁnal temperatures</strong> of each reaction will need to be recorded.',
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'Once all the data has been collected, visit the <strong><em>Tutorial</em></strong> for guidance on the first combustion reaction. All other reactions will require the same set of calculations as the first and can be carried out on your own.',
                },
              ],
            },
          ],
        },
      ],
    },
    tutorial: {
      name: 'Tutorial',
      link: 'tutorial',
      tabs: [
        {
          name: 'Calorimeter',
          order: 1,
          steps: [
            {
              name: 'Data Collection',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Data Collection',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Prior to any measurement for a sample in the calorimeter, its heat capacity must be determined. This will be obtained by calibrating the calorimeter with a known pure sample. A weighed mass of benzoic acid is combusted because its heat combustion is known. The observed temperature rise is used to determine the heat capacity of the calorimeter.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Type in the data that you obtained from the video for the combustion of benzoic acid:',
                },
                {
                  order: 5,
                  type: 'Input',
                  directions:
                    '1) Mass of benzoic acid:',
                  validAnswers: ['0.7934'],
                  unitOfMeasure: 'g',
                  tip: 'Always include the zero to the left of the decimal.',
                },
                {
                  order: 6,
                  type: 'Input',
                  directions:
                    '2) Initial temperature:',
                  validAnswers: ['15.070'],
                  unitOfMeasure: '&degC',
                  tip: 'Always include the training zero since it was measured.',
                },
                {
                  order: 7,
                  type: 'Input',
                  directions:
                    '3) Final temperature:',
                  validAnswers: ['17.107'],
                  unitOfMeasure: '&degC',
                  tip: 'Make sure your temperature reading reaches to the nearest 0.001 &degC.',
                },
              ],
            },
            {
              name: 'Heat Capacity',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Heat Capacity',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Using your data for the combustion of benzoic acid, calculate the heat capacity of the calorimeter.',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions: 
                    '1) Calculate the temperature rise, ∆T:',
                  validAnswers: ['2.037'],
                  unitOfMeasure: 'K',
                  tip: 'Notice that the units changed from &degCelsius to Kelvin. ∆T has the same numerical value since the two temperature scales have the same magnitude unit (1 &degC = 1 K).<p> Ensure you are following the subtraction significant rules.</p>',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions: 
                    '2) If the molar mass of benzoic acid is 122.0 g/mol, calculate the moles of benzoic acid that was combusted:',
                  validAnswers: ['0.006503', '6.503E-3', '65.03E-4', '650.3E-5', '6503E-6','6,503E-6'],
                  unitOfMeasure: 'moles',
                  tip: 'Make sure you are using the mass of benzoic acid.<p> You should have 4 significant figures.</p>',
                },
                {
                  order: 5,
                  type: 'Input',
                  directions: 
                    '3) If benzoic acid\'s change in internal energy, ∆E is -3,227 kJ/mol, calculate ∆E<sub>comb</sub> for the combustion of the above amount of benzoic acid:',
                  validAnswers: ['-20.99'],
                  unitOfMeasure: 'kJ',
                  tip: 'The energy value is negative.<p> While the correct answer maintains 4 significant figures, make sure you keep the unrounded number for this answer for the next question.</p>',
                },
                {
                  order: 6,
                  type: 'Input',
                  directions: 
                    '4) Since this is a bomb calorimeter (a constant volume apparatus), we can use this relationship: <p><center>∆E = -C<sub>cal</sub>∆T</center></p><p>solve for the heat capacity of the calorimeter, C<sub>cal</sub>:</p>',
                  validAnswers: ['10.30'],
                  unitOfMeasure: 'kJ/K',
                  tip: 'While the correct answer maintains 4 significant figures, make sure you use any unrounded values in your calculation.',
                },
              ],
            },
          ],
        },
        {
          name: 'Combustion',
          order: 2,
          steps: [
            {
              name: 'Combustion Data',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Combustion Data',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'You observed the temperature rise of 7 hydrocarbons. In this <strong>Tutorial</strong>, we will review your data and calculations for only the first compound <strong> 1-propanol</strong>. All other compounds follow the same set of calculation steps.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Type in the data that you obtained for the combustion of 1-propanol:',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions:
                    '1) Mass of 1-propanol:',
                  validAnswers: ['0.7653'],
                  unitOfMeasure: 'g',
                  tip: 'While this number was already written in your data table in the lab protocol, you will need to use this number in a future calculation, that it is useful to have here.',
                },
                {
                  order: 5,
                  type: 'Input',
                  directions:
                    '2) Initial temperature:',
                  validAnswers: ['19.623', '19.622', '19.624'],
                  unitOfMeasure: '&degC',
                  tip: 'Make sure your temperature reading reaches to the nearest 0.001 &degC.<p>If your value does not match here, review the video and your temperature measurment.</p>',
                },
                {
                  order: 6,
                  type: 'Input',
                  directions:
                    '3) Final temperature:',
                  validAnswers: ['22.083', '22.082', '22.084'],
                  unitOfMeasure: '&degC',
                  tip: 'Make sure your temperature reading reaches to the nearest 0.001 &degC.<p>If your value does not match here, review the video and your temperature measurment.</p>',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions: 
                    '4) Calculate the temperature rise, ∆T:',
                  validAnswers: ['2.458', '2.459', '2.460', '2.461', '2.462'],
                  unitOfMeasure: 'K',
                  tip: 'Ensure you are following the subtraction significant rules, which means your value will include to the nearest 0.001 &degC.',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions: 
                    '5) Calculate the average temperature, T<sub>ave</sub>:',
                  validAnswers: ['294.002', '294.003', '294.004'],
                  unitOfMeasure: 'K',
                  tip: 'The average temperature refers to the two temperature values that were measured, the initial and final temperatures. This value needs to change to Kelvin (see the units), so ensure you are following the calculation: <center>T<sub>ave</sub> = (T<sub>i</sub> + T<sub>f</sub>)/2 + 273.15</center>',
                },
              ],
            },
            {
              name: 'Internal Energy',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: '∆E for Compounds',
                }, 
                {
                  order: 2,
                  type: 'Text',
                  text: '',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions: '1) Determine the molar mass of 1-proponal.',
                  validAnswers: ['60.09'],
                  unitOfMeasure: 'g/mol',
                  tip: 'Use the chemical formulas given in the <strong><em>Experiment</em></strong> section to determine its its molar mass. To find the molar mass, remember to add up the individual element\'s molar masses. <p>This molar mass value has 4 significant figures.</p>',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions: 
                    '2) Using the relationship: <center>∆E = -C<sub>cal</sub>∆T</center>and knowing the heat capacity of the calorimeter, C<sub>cal</sub>, and the measured ∆T, calculate the change in internal energy, ∆E,  for 1-propanol:',
                  validAnswers: ['-25.38','-25.39','-25.40', '-25.41', '-25.42', '-25.34', '-25.32', '-25.33', '-25.34', '-25.35', '-25.36'],
                  unitOfMeasure: 'kJ',
                  tip: 'B sure that you are using the ∆T, which is the temperature rise. <p>Combustion is an exothermic reaction, make sure there should be a negative sign.</p>.',
                },
                {
                  order: 5,
                  type: 'Input',
                  directions: 
                    '3) Calculate the change in internal energy per gram combusted, ∆E/g, for 1-propanol:',
                  validAnswers: ['-33.16', '-33.17', '-33.19', '-33.20', '-33.21', '-33.14', '-33.12', '-33.11', '-33.10', '-33.09'],
                  unitOfMeasure: 'kJ/g',
                  tip: 'Divide the calculated ∆E value for 1-propanol by the sample mass that combusted (revert to the combustion data).<p>Combustion is an exothermic reaction, make sure there should be a negative sign.</p><p>Watch that all answers are to 4 significant figures, but the calculation itself uses unrounded values that are beyond the 4 significant figures.</p>',
                },
                {
                  order: 6,
                  type: 'Input',
                  directions: 
                    '4) Calculate the change in internal energy per mole combusted, ∆E/mol, for 1-propanol:',
                  validAnswers: ['-1992', '-1,992','-1,993', '-1993','-1,994', '-1994','-1,995', '-1995','-1,996', '-1996','-1,988', '-1988','-1,989', '-1989','-1,990', '-1990','-1,991', '-1991'],
                  unitOfMeasure: 'kJ/mol',
                  tip: 'Multiply the ∆E/g by the molar mass to obtain ∆E/mol. Ensure that your mass units cancel out. <p>Combustion is an exothermic reaction, make sure there should be a negative sign.</p><p>Watch that all answers are to 4 significant figures, but the calculation itself uses unrounded values that are beyond the 4 significant figures.</p>',
                },
              ],
            },
            {
              name: 'Heat of the Reaction',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Heat of the Reaction, ∆H',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The total energy of a reaction shows up as heat (q) and/or work (w).',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: '∆E = q + w &nbsp;&nbsp;&nbsp;&nbsp;&nbsp&nbsp;&nbsp;&nbsp;&nbsp&nbsp&nbsp;&nbsp(1)',
                  centered: true,
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Heat, q, is also known as ∆H (the heat of a reaction) if there is an open container, which means there is a constant pressure. We can rearrange equation 1 to appear as:', 
                },
                {
                  order: 5,
                  type: 'Text',
                  text: '∆E = ∆H - ∆nRT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp(2)',
                  centered: true,
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'and further to',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: '∆H = ∆E + ∆nRT<sub>ave</sub>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp(3)',
                  centered: true,
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'Let\'s look at each parameter in equation 3:',
                },
                {
                  order: 9,
                  type: 'Text',
                  text: '<li>∆H refers <em>1 mole</em> of the combusting compound. Thus, ∆E in the equation refers to (∆E per mol times <em>1 mole</em>). Be sure to use the value ∆E per mol that was calculated table III in the lab protocol sheet.</li><li>∆n was determined from the balanced equations in the <strong><em>Experiment</em></strong> section. Take note that ∆n is an exact number.</li><li>R = 0.008314 kJK<sup>-1</sup>mol<sup>-1</sup></li><li>T can be approximated using the average temperature from your Table II in the lab protocol sheet.</li>',
                  centered: true,
                },

                {
                  order: 10,
                  type: 'Input',
                  directions: '1) Calculate the heat of the reaction, ∆H, for 1-propanol.',
                  validAnswers: ['-1,992', '-1992', '-1,993', '-1993', '1993', '-1,994', '-1994', '-1,995', '-1995', '-1,996', '-1996', '-1,997', '-1997', '-1,998', '-1998', '-1,999', '-1999', '-2,000', '-2000'],
                  unitOfMeasure: 'kJ',
                  tip: 'Use the equation: ∆H = ∆E + ∆nRT<sub>ave</sub> using the bullets listed above this question.',
                },
                {
                  order: 11,
                  type: 'Input',
                  directions: '2) Rewrite again your previous answer for the change in internal energy, ∆E/mol, for 1-propanol.',
                  validAnswers: ['-1992', '-1,992', '-1,993', '-1993', '-1,994', '-1994', '-1,995', '-1995', '-1,996', '-1996', '-1,988', '-1988', '-1,989', '-1989', '-1,990', '-1990', '-1,991', '-1991'],
                  unitOfMeasure: 'kJ',
                  tip: 'Type your ∆E/mol from the previous page for 1-propanol. The "mol" of the ∆E/mol is being multiplied by <em>1 mole</em>) since the question ∆H = ∆E + ∆nRT<sub>ave</sub> is based off 1 mole of combustion of the hydrocarbon.',
                },
                {
                  order: 12,
                  type: 'Input',
                  directions: 'Calculate the % difference between ∆H and ∆E.',
                  validAnswers: ['0.05', '0.06', '0.07', '0.08', '0.09', '0.1', '0.2', '0.3', '0.4', '0.5', '0.50', '0.55', '0.60'],
                  unitOfMeasure: '%',
                  tip: '<center>%difference = (∆H - ∆E)/∆E x 100%</center><p>Follow your subtraction significant rule for the numerator of the division!</p><p>The % difference should be tiny!',
                },
                {
                  order: 13,
                  type: 'Text',
                  text: 'If you were able to complete the % difference for 1-propanol, you can follow the <strong>same set of calculations</strong> required in the Combustion Table, Internal Energy Table and the Heat of Reaction Table for the other six hydrocarbons.',
                  },
                 {
                  order: 14,
                  type: 'Text',
                  text: 'Once the three Tables are complete, you can use them to continue with the next <strong>Tutorial</strong> discussion, to analyze <strong>Possible Trends</strong>.',
                  },
              ],
            },
          ],
        },
        {
          name: 'Possible Trends',
          order: 3,
          steps: [
            {
              name: 'Discussion',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Discussion',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Assuming you have determined the Internal Energies and the Heat of Reactions for the remaining six, hydrocarbons,let\'s now study further your calculations.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Does the addition of a CH<sub>2</sub> group to a straight-chain compound cause a constant factor in the heat of combustion per mole burned? What about the successive introduction of double bonds to a six-membered cyclic system? Do they cause a constant factor in the heat of combustion per mole burned?',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Your analysis may require careful thought about the actual bonding in the compounds. While you may not yet have the background in bonding theory to know why the data comes out like it does, what you do have is the ability to see that the data has inconsistencies. A consolidation is that much of the present day bonding theory was developed because of the information obtained from experiments similar to this one!',
                },
              ],
            },
            {
              name: 'Straight-chain Alcohols',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Straight-chain Alcohols',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The three straigh-chain hydrocarbons burned in the calorimeter are called primary chain alcohols because the alcohol group is attached to one carbon at the end of a chain of carbons. ',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: '<li>1-propanol, CH<sub>3</sub>CH<sub>2</sub>CH<sub>2</sub>OH</li>',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: '<li>1-butanol, CH<sub>3</sub>CH<sub>2</sub>CH<sub>2</sub>CH<sub>2</sub>OH</li>',
                },
                {
                  order: 5,
                  type: 'Text',
                  text: '<li>1-pentanol, CH<sub>3</sub>CH<sub>2</sub>CH<sub>2</sub>CH<sub>2</sub>CH<sub>2</sub>OH</li>',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: '1-butanol has one more CH<sub>2</sub> group than 1-propanol. 1-pentanol has  two more CH<sub>2</sub> groups. In order to see if the changes in the energy of combustion between the alcohols are in proportion to the added CH<sub>2</sub> groups, use the values in the last column of the your <strong>Internal Energy Table</strong> (Table III.2 in the lab protocol) and do the following calculations:',
                },
                {
                  order: 7,
                  type: 'Image',
                  imageFileName: '/images/bomb-calorimetry/trends-alcohols.jpg',
                  imageAlt: 'Two equations are shown. The top equation has delta E for 1-butanol per mol minus delta E for 1-propanol is equal to a blank division with unit so kJ/mol. The bottom equation has delta E for 1-pentanol per mol minus delta E for 1-propanol is equal to a blank division with unit so kJ/mol.',
                  centered: true,
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'Does the difference in energy correspond to the difference in the number of  CH<sub>2</sub> groups?  (That is, is the second answer roughly twice the ﬁrst answer, corresponding to the CH<sub>2</sub> group increase?). Explain your answer in your lab protocol sheet.',
                },
              ],
            },
            {
              name: 'Cyclic Double Bond Compounds',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Cyclic Double Bond Compounds',
                }, 
                {
                  order: 2,
                  type: 'Text',
                  text: 'The four cyclic compounds burned in the calorimeter all contain one 6-member ring of carbons. They each differ however in a successive addition of one double bond.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: '<li>cyclohexane, C<sub>6</sub>H<sub>12</sub>(l)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 0 double bond</li>',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: '<li>cyclohexene, C<sub>6</sub>H<sub>10</sub>(l)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1 double bond</li>',
                  
                },
                {
                  order: 5,
                  type: 'Text',
                  text: '<li>1,4-cyclohexadiene, C<sub>6</sub>H<sub>8</sub>(l)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2 double bonds</li>',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: '<li>1,3,5-cyclohexatriene,C<sub>6</sub>H<sub>6</sub>(l)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3 double bonds</li>',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'Use the same method as the alcohol energy comparison to see if energy differences correspond to double bond differences. Using the <strong>Internal Energy Table</strong> (Table III.2 in the lab protocol), subtract ∆E/mol of cyclohexane from the ∆E/mol of each of the other three compounds.',
                },
                {
                  order: 7,
                  type: 'Image',
                  imageFileName: '/images/bomb-calorimetry/trends-ring.jpg',
                  imageAlt: 'Three equations are shown. The top equation has delta E for cyclohexene per mol minus delta E for cyclohexane is equal to a blank division with unit so kJ/mol. The equation underneath this first one has delta E for 1,4-cyclohexadiene per mol minus delta E for cyclohexane is equal to a blank division with unit so kJ/mol. The equation underneath the middle one has delta E for 1,3,5-cyclohexatriene per mol minus delta E for cyclohexane is equal to a blank division with unit so kJ/mol.',
                  centered: true,
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'Is the second equation twice the first? Answer in your worksheet.',
                },
                {
                  order: 9,
                  type: 'Text',
                  text: 'Is the third equation twice first? Answer in your worksheet.',
                },
                {
                  order: 10,
                  type: 'Text',
                  text: '1,3,5-cyclohexatriene in the above list of double bond compounds is classiﬁed as a substance with a different type of bonding  than the other three. Explain how this is justified from the above calculations.',
                },
              ],
            },
          ],
        },
      ],
  },
  },
  rank: 13,
};

export default bombCalorimeter;
