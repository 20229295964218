import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Typography,
  ListItemText,
  List,
  ListItemButton,
  Breadcrumbs,
  Link as MuiLink,
} from '@mui/material';
import { Outlet, NavLink, useLocation, useParams } from 'react-router-dom';

const ContentFrame = ({ data, labName, description }) => {
  const { content } = data;
  const sections = Object.keys(content);
  const location = useLocation();
  const { labId } = useParams();

  const createMenuItems = (sections) =>
    sections.map((section) => ({
      name: content[section].name,
      link: `/labs/${labId}/${content[section].link}`,
    }));

  const menuItems = createMenuItems(sections);

  const [selectedItem, setSelectedItem] = useState('');
  const [selectedIndex, setSelectedIndex] = useState();

  useEffect(() => {
    const currentPath = location.pathname;
    const menuItem = menuItems.find((item) => item.link === currentPath);
    if (menuItem) {
      handleSelectItem(menuItem.name, menuItem.link);
    }
  }, [location, menuItems]);

  const handleSelectItem = (selection, link) => {
    setSelectedItem(link);
    setSelectedIndex(selection);
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    mt: 1,
  };

  const sidebarContainerStyle = {
    display: 'flex',
  };

  const contentBoxStyle = {
    pl: 5,
    pt: 1,
    width: '100%',
  };

  const sidebarStyle = {
    display: 'flex',
    minWidth: 150,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    mt: 3,
  };

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <MuiLink color='inherit' href='/'>
          Home
        </MuiLink>
        <MuiLink color='inherit' href='/labs/'>
          Labs
        </MuiLink>
        <Typography color='text.primary'>{labName}</Typography>
      </Breadcrumbs>
      <Box sx={containerStyle}>
        <Typography variant='h3' sx={{ alignSelf: 'center' }}>
          {labName}
        </Typography>
        <Box sx={{ mt: 1, mb: 1 }}>{description}</Box>
        <Divider />
      </Box>
      <Box sx={sidebarContainerStyle}>
        <Box sx={sidebarStyle}>
          <List sx={{ width: '100%' }}>
            {menuItems.map((item) => (
              <ListItemButton
                key={item.link}
                component={NavLink}
                to={item.link}
                selected={selectedIndex === item.name}
                onClick={() => handleSelectItem(item.name, item.link)}
              >
                <ListItemText primary={item.name} />
              </ListItemButton>
            ))}
          </List>
          <Divider flexItem orientation='vertical' />
        </Box>
        <Box sx={contentBoxStyle}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default ContentFrame;
