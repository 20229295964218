import React from 'react';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import ContentFrame from '../components/ContentFrame';

import Data from '../data/LabsData';

const Lab = (props) => {
  const { labId } = useParams();
  const selectedLab = Data[labId];

  return (
    <>
      <Container maxWidth='lg'>
        <ContentFrame
          data={selectedLab}
          labName={selectedLab.name}
          description={selectedLab.description}
          menuItems={selectedLab.sections}
        />
      </Container>
    </>
  );
};

export default Lab;
