const chemicalFormula = {
  status: 'Active',
  name: 'Chemical Formula',
  imageFileName: 'images/heating-icon.jpeg',
  imageAlt:
    'A crucible resting on a crucible trianble contains a green solid substance. Sections of the solid appear to be turning brown.',
  description:
    'Experimental guide for determining the chemical formula of a hydrate.',
  tags: ['Pre-Lab', 'Wet Lab', 'Experiment', 'Chem-101A'],
  page: 'chemical-formula',
  content: {
    overview: {
      name: 'Overview',
      link: 'overview',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'NOSTEP',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'In this experiment, you will use the law of definite proportions to find the chemical formula of a hydrated compound containing copper, chlorine, and water molecules locked in the crystal structure of the solid compound. The general formula for the compound is Cu<sub>x</sub>Cl<sub>y</sub>•zH<sub>2</sub>O, and its name is copper chloride hydrate. The letters x, y, and z represent integers that will establish the proper chemical formula for this substance.',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Start by reading the short <strong><em>Introduction</em></strong>, which reviews the experimental steps for determining the chemical formula. ',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Next, read through the <strong><em>Experiment</em></strong> in order of <li>Summary</li><li>Materials</li><li>Procedure Part I followed by Part II</li>',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'The <strong><em>Procedure Parts I and II</em></strong> will walk you step-by-step through the protocol. Embedded within are several tips and how-to videos to the experiment. Move through all questions to better understand some of the crucial steps of the experiment.',
                },
              ],
            },
          ],
        },
      ],
    },
    introduction: {
      name: 'Introduction',
      link: 'introduction',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'Historical Perspective',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'John Dalton was an Englishman, a teacher, and an exceptional theoretical chemist. He developed and wrote the modern atomic theory at the turn of the 19th century (documents point to 1803). He was influenced by the experiments of two Frenchmen, Antoine Lavoisier and Joseph Louis Proust. A fundamental component of the modern atomic theory is that the mole ratios of elements in a compound will be small whole numbers (<b><i>law of definite proportions</b></i>). The whole number mole ratio is commonly referred to as the empirical formula of a compound.',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'One of the challenges in finding the proper chemical formula for a compound is that there may be more than one plausible mole ratio for the elements in that compound. Dalton called this <b><i>the law of multiple proportions</b></i>. For example, if you were testing a compound that contained iron and sulfur, the plausible chemical formula could be FeS or Fe<sub>2</sub>S<sub>3</sub>. However, if you determine the mass of iron and the mass of sulfur present in a given mass of the compound, you will be able to establish the true chemical formula of the compound.',
                },
              ],
            },
            {
              name: 'Experimental Overview',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'In this experiment, you will first gently heat a sample of the copper chloride hydrate to drive off the water of hydration. By measuring the mass of the sample before and after heating you can determine the amount of water in the sample and identify the value of z in the formula, Cu<sub>x</sub>Cl<sub>y</sub>•zH<sub>2</sub>O.',
                },
                {
                  order: 2,
                  type: 'Image',
                  imageFileName: '/images/chemical-formula/prior-heating.jpg',
                  imageAlt:
                    'A top-down view of a green solid substance in a crucible.',
                  centered: true,
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Next, you will conduct a chemical reaction with the dried sample, Cu<sub>x</sub>Cl<sub>y</sub>, to produce elemental copper. By measuring the mass of copper that forms, you will have the necessary information to determine the moles of copper and chlorine in the sample, and from there, you will be able to establish the proper chemical formula.',
                },
              ],
            },
          ],
        },
      ],
    },
    experiment: {
      name: 'Experiment',
      link: 'experiment',
      tabs: [
        {
          name: 'Start Here',
          order: 1,
          steps: [
            {
              name: 'Summary',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Summary: Experimental Steps',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The general experimental steps are: <li>Weigh Cu<sub>x</sub>Cl<sub>y</sub>•zH<sub>2</sub>O before and after gently heating it to drive off the water of hydration</li><li>Rehydrate Cu<sub>x</sub>Cl<sub>y</sub></li><li>React Cu<sub>x</sub>Cl<sub>y</sub>•zH<sub>2</sub>O with aluminum</li><li>Separate the solid copper product by filtering the liquid</li><li>Dry and weigh the copper product</li>',
                },
              ],
            },
            {
              name: 'Equipment',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Equipment',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The following pieces of equipment are needed for this experiment:',
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName: '/images/chemical-formula/equipment.jpg',
                  imageAlt:
                    'The materials needed with the experiment (not including chemicals) are presented: a crucible with its lid, crucible tongs, spatula, ring stand, medium ring, clay triangle, bunsen-burner, a 50 mL beaker, a Büchner funnel, flask for filtering, filter paper to fit Büchner funnel, glass stirring rod and a watch glass.',
                  centered: true,
                },
              ],
            },
          ],
        },
        {
          name: 'Part I',
          order: 2,
          steps: [
            {
              name: 'Ring Stand',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Ring Stand Set-up',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Set up a ring stand, a medium ring, clay triangle and crucible (without a lid). Ensure that the crucible fits snugly on the clay triangle. If not, search for a triangle and crucible that fit each other.',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions:
                    'Which picture(s) display a proper set-up with a crucible resting on a clay triangle?',
                  validAnswers: [
                    'B and C',
                    'b and c',
                    'B, C',
                    'b, c',
                    'bc',
                    'cb',
                    'b,c',
                    'c,b',
                  ],
                  tip: 'As long as some amount of crucible is visible under the clay triangle, the crucible is safely nestled on the clay triangle.',
                },
                {
                  order: 3,
                  type: 'Images',
                  images: [
                    {
                      imageFileName:
                        '/images/chemical-formula/crucible-triangle-1.jpg',
                      imageAlt:
                        'A crucible rests on a clay triangle. The crucible nestles on the edges of the clay triangle.',
                      imageTitle: 'A',
                    },
                    {
                      imageFileName:
                        '/images/chemical-formula/crucible-triangle-2.jpg',
                      imageAlt:
                        'A crucible rests on a clay triangle. The crucible nestles within the clay triangle so that the crucible is visible for about a centimeter under the clay triangle.',
                      imageTitle: 'B',
                    },
                    {
                      imageFileName:
                        '/images/chemical-formula/crucible-triangle-3.jpg',
                      imageAlt:
                        'A crucible rests on a clay triangle. The crucible nestles within the clay triangle so that the crucible is visible for about 3 to 4 millimeters under the clay triangle.',
                      imageTitle: 'C',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Initial Mass',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Initial Mass',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Measure and record the mass of a clean, dry crucible without its cover. Once measured, use crucible tongs to hold the crucible. Obtain about 1 g of the unknown copper chloride hydrate and place it in the crucible. Use a spatula to break up any large pieces of the substance by pressing the pieces against the wall of the crucible. Measure and record the mass of the crucible with compound.',
                },
                {
                  order: 3,
                  type: 'Images',
                  images: [
                    {
                      imageFileName:
                        '/images/chemical-formula/crucible-mass.jpg',
                      imageAlt:
                        'A crucible rests on a milligram balance. The balance reads 18.583 g. A large beaker covers the balance.',
                      imageTitle: 'Crucible mass',
                    },
                    {
                      imageFileName:
                        '/images/chemical-formula/adding-solid.jpg',
                      imageAlt:
                        'A crucible rests on a milligram balance. The balance reads 18.584 g. Hovering over the balance is the left hand holding an open jar of the green copper chloride hydrate solid and the right hand holding a gray spatula with the green solid just about to add to the crucible.',
                      imageTitle: 'Adding the solid',
                    },
                    {
                      imageFileName:
                        '/images/chemical-formula/mass-solid-crucible.jpg',
                      imageAlt:
                        'A crucible filled with a green solid, copper chloride hydrate, rests on a milligram balance. The balance reads 19.584 g. A large beaker covers the balance..',
                      imageTitle: 'Mass of solid and crucible',
                    },
                  ],
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Ensure that you write all digits that the balance tells you, even if the last digit is a zero. Any zeros in the decimal places were measured by the milligram balance. Also, make sure that you have you have the cover over the balance when taking the mass measurement so that air current does not disrupt the measurement. Wait for the balance to settle.',
                },
                {
                  order: 5,
                  type: 'Input',
                  directions:
                    'Since at this stage, the crucible tongs (and not fingers) are to hold the crucible, which is the proper way to hold the crucible using the tongs? (A or B)',
                  validAnswers: ['A', 'a'],
                  tip: 'The crucible tong has an inner section of the metal that concaves together. This is the area that you pick up and hold the crucible. While the pinchers look attractive to use to pick up the crucible, the curvature of the crucible makes its side easy to slip when using the tongs. <p>It is advised to use a watch glass or paper towel underneath the crucible when walking across the lab and holding the crucible with the tongs. The tongs require a tension hold and slippage of the crucible is possible.</p>',
                },
                {
                  order: 6,
                  type: 'Images',
                  images: [
                    {
                      imageFileName: '/images/chemical-formula/tongs-1.jpg',
                      imageAlt:
                        'A crucible rests within concave section of the crucible tongs.',
                      imageTitle: 'A',
                    },
                    {
                      imageFileName: '/images/chemical-formula/tongs-2.jpg',
                      imageAlt:
                        'A crucible tongs are being used by pinching the side of a crucible that holds a green solid.',
                      imageTitle: 'B',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Bunsen Burner',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'The Bunsen Burner',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Rest the crucible on the clay triangle. Set up a lab burner and ignite the burner away from the crucible. Adjust the burner to get a small flame.  ',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Watch: How to use a Bunsen Burner. <p><iframe width="560" height="315" src="https://www.youtube.com/embed/-RpS8XyRA7E?&playlist=-RpS8XyRA7E&loop=1" title="How to turn on and off a Bunsen burner" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Watch: How to determine the proper flame height. <p><iframe width="560" height="315" loop src="https://www.youtube.com/embed/Z_qLcdRRUOk?&playlist=Z_qLcdRRUOk&loop=1" title="How to determine the proper flame height" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>',
                },
              ],
            },
            {
              name: 'Gentle Heating',
              order: 4,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Gentle Heating',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Hold the burner in your hand and move the flame slowly back and forth underneath the crucible to gently heat the sample. Do not overheat the compound. Note the color change, from blue-green to brownish, as the water of hydration is driven out of the crystals. When the sample has turned brown, gently heat the crucible for two more minutes.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: '(1) Watch: How to heat gently using the Bunsen burner. <p><iframe width="560" height="315" src="https://www.youtube.com/embed/Gljb2CozumM?&playlist=Gljb2CozumM&loop=1" title="How to gently heat using the Bunsen burner" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions:
                    '(2) Which sample(s) would best represent how your sample should appear after all the gentle heating? Select the picture(s) that display a dehydrated sample. (A, B, C and/or D)',
                  validAnswers: ['B', 'b'],
                  tip: 'Image A represents incomplete heating as there is green solid still present. <p>Image B represents the perfect amount of heating of the sample as the sample is completely dehydrated represented by the brown flakes.To achieve this result, ensure you are waving your Bunsen burner flame below the crucible to control the heat.</p><p>Image C represents an overheated sample. The gray specks indicate that the solid got too hot and started to decompose into its elements.</p><p>Image D represents a melted sample. The dark gray/black solid is not flaky. When the sample is heated at too high a temperature, you may see smoke and some of the pieces splatter out.</p>',
                },
                {
                  order: 5,
                  type: 'Magnified Image',
                  imageFileName:
                    '/images/chemical-formula/incomplete-heating.jpg',
                  imageAlt:
                    'A crucible contains a brown and green flaky solid.',
                  imageZoomFileName:
                    '/images/chemical-formula/incomplete-heating-zoom.jpg',
                },
                {
                  order: 6,
                  type: 'Magnified Image',
                  imageFileName: '/images/chemical-formula/after-heating.jpg',
                  imageAlt: 'A crucible contains a brown flaky solid.',
                  imageZoomFileName:
                    '/images/chemical-formula/after-heating-zoom.jpg',
                },
                {
                  order: 7,
                  type: 'Magnified Image',
                  imageFileName: '/images/chemical-formula/charred-solid.jpg',
                  imageAlt:
                    'A crucible contains a brown and white flaky solid.',
                  imageZoomFileName:
                    '/images/chemical-formula/charred-solid-zoom.jpg',
                },
                {
                  order: 8,
                  type: 'Magnified Image',
                  imageFileName: '/images/chemical-formula/burnt-solid.jpg',
                  imageAlt:
                    'A crucible contains a dark gray and black chunky solid.',
                  imageZoomFileName:
                    '/images/chemical-formula/burnt-solid-zoom.jpg',
                },
                {
                  order: 9,
                  type: 'Text',
                  text: '(3) What types of evidence seen in the next video could indicate that the sample is being overheated? Select all that apply.<p><iframe width="250" height="412" src="https://www.youtube.com/embed/cgeELU2x0uw?&playlist=cgeELU2x0uw&loop=1" title="Signs of overheating" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>',
                },
                {
                  order: 10,
                  type: 'Multiple Choice',
                  choices: [
                    {
                      label: 'Smoke appears',
                      tip: 'Smoke does mean that the sample is decomposing, which is not what we are trying to do.',
                      validChecked: true,
                    },
                    {
                      label: 'Crackling sounds',
                      tip: 'Any crackling does mean that the sample is overheating and splattering around.',
                      validChecked: true,
                    },
                    {
                      label: 'A color change',
                      tip: 'This may not indicate overheating because the sample will undergo a color change. The question is what type of color. If gray specks or black appears, you overheated. If it sample is brown, then it is the correct color. ',
                      validChecked: false,
                    },
                    {
                      label: 'A flame appears inside the crucible',
                      tip: 'The sample is overheated and is burning',
                      validChecked: true,
                    },
                  ],
                },
              ],
            },
            {
              name: 'Cooled Crucible',
              order: 5,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Cooling and Measuring the Mass of the Sample',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Remove and turn off the burner. Cover the crucible and allow the sample to cool for about ten minutes.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Remove the crucible cover and inspect your sample. If you see any blue-green crystals, reheat the sample until the crystals have turned brown. Please refer to the pictures to decide if your sample is ready.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Measure and record the mass of the cooled crucible of your copper chloride sample.',
                },
              ],
            },
          ],
        },
        {
          name: 'Part II',
          order: 2,
          steps: [
            {
              name: 'Re-hydration',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Re-hydrating the Sample',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Transfer the brown solid to a clean and empty 50 mL beaker.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: ' Rinse out the crucible with two 8 mL aliquots of distilled water and pour the water into the 50 mL beaker. ',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Gently swirl the beaker to completely dissolve the solid. Note that the color of the solution is green as the copper ions are rehydrated.',
                },
                {
                  order: 5,
                  type: 'Images',
                  images: [
                    {
                      imageFileName:
                        '/images/chemical-formula/adding-to-liquid.jpg',
                      imageAlt:
                        'A beaker contains a colorless liquid with a brown flaky solid not yet dissolved.',
                      imageTitle: 'Upon adding solid to water',
                    },
                    {
                      imageFileName: '/images/chemical-formula/hydrated.jpg',
                      imageAlt: 'A small beaker contains a turquoise solution.',
                      imageTitle: 'Dissolved solid',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Reaction',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Aluminum reacting with the Copper Chloride Hydrate',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Measure out about 20 cm of aluminum wire, coil the wire, and place the wire in the beaker of solution so that it is completely immersed in the copper chloride solution. Note that the reaction produces a gas, elemental copper is forming on the surface of the aluminum wire, and the color of the solution is fading.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'The reaction will take about 30 minutes to complete.',
                },
                {
                  order: 4,
                  type: 'Images',
                  images: [
                    {
                      imageFileName: '/images/chemical-formula/start-rxn.jpg',
                      imageAlt:
                        'A small beaker contains a turquoise solution and a coiled rusty-colored solid.',
                      imageTitle: 'Start of the reaction',
                    },
                    {
                      imageFileName:
                        '/images/chemical-formula/aluminum-rxn.jpg',
                      imageAlt:
                        'A small beaker contains a slightly tinted turquoise solution and a coiled rusty-colored solid.',
                      imageTitle: 'Reaction underway',
                    },
                    {
                      imageFileName: '/images/chemical-formula/end-rxn.jpg',
                      imageAlt:
                        'A small beaker contains a clear solution and a coiled rusty-colored solid.',
                      imageTitle: 'End of the reaction',
                    },
                  ],
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'How do you know when the reaction is complete?',
                },
                {
                  order: 6,
                  type: 'Multiple Choice',
                  choices: [
                    {
                      label: 'The copper falls off the aluminum',
                      tip: 'It is not a requisite that copper must fall off the wire. It may, but you do not need to wait for that to happen.',
                      validChecked: false,
                    },
                    {
                      label: 'The solution is completely colorless',
                      tip: 'Yes, a colorless solution tells us copper is exhausted in the solution.',
                      validChecked: true,
                    },
                    {
                      label: 'Bubbling of gas stops',
                      tip: 'It can be helpful to tap the beaker to “stir” the reaction. When this happens, you may see more bubbling occurring as the movement helped the solution come into better contact with the aluminum wire, which is being coated with copper. ',
                      validChecked: true,
                    },
                  ],
                },
              ],
            },
            {
              name: 'Reaction Complete',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'The Completion of the Reaction',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'When the reaction is done, the solution will be colorless. Most of the elemental copper will be on the aluminum wire. Add 5 drops of 6 M HCl solution to dissolve any insoluble aluminum salts in the mixture, which should make the solution clear.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: '<em>CAUTION: Handle the hydrochloric acid with care. It can cause painful burns if it comes in contact with the skin.</em>',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Use a glass stirring rod to scrape off as much copper as possible from the Al wire. Slide the wire up the wall of the beaker and out of the solution with the glass stirrer and rinse off any remaining copper with distilled water. If any of the copper refuses to wash off the aluminum wire, wash it with one or two drops of 6 M HCl solution. Put the Al wire aside. ',
                },
              ],
            },
            {
              name: 'Product Washing',
              order: 4,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Washing the Product',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Collect and wash the copper produced in the reaction. Start by setting up a Büchner funnel for vacuum filtration.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Obtain a piece of filter paper. Measure and record its mass, and then place the filter paper on the funnel. Start the vacuum filtration.',
                },
                {
                  order: 4,
                  type: 'Images',
                  images: [
                    {
                      imageFileName:
                        '/images/chemical-formula/buchnell-funnel.jpg',
                      imageAlt: 'A Buchner funnel rests on a flask',
                      imageTitle: 'Buchnell funnel',
                    },
                    {
                      imageFileName:
                        '/images/chemical-formula/buchner-funnel-filter-paper.jpg',
                      imageAlt:
                        'A Buchner funnel rests on a flask and a hand is placing a white filter paper inside it.',
                      imageTitle: 'Buchner funnel and filter paper',
                    },
                  ],
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'Watch: How to set-up a Buchner Funnel for filtration <p><iframe width="560" height="315" src="https://www.youtube.com/embed/jhPE3aCeeHg?&playlist=jhPE3aCeeHg&loop=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'Use small amounts of distilled water to wash all of the copper onto the filter paper on the Büchner funnel. Use the glass stirring rod to break up the larger pieces of copper.',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: 'Wash the copper twice more with small amounts of distilled water.',
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'Turn off the suction on the vacuum filtration apparatus. Add 10 mL of 95% ethanol to the copper on the filter paper and let it sit for about 1 minute. Turn the suction back on and let the vacuum filtration run for about five minutes.',
                },
              ],
            },
            {
              name: 'Dry Product',
              order: 5,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Drying the Product',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Measure and record the mass of a clean, dry watch glass. Transfer the copper to the watch glass. Make sure that you have scraped all of the copper onto the watch glass.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Dry the watch glass of copper under a heat lamp or in a drying oven for five minutes.',
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'Click on the magnifier to see what to do with your watch glass that will be placed under the heater.',
                },
                {
                  order: 5,
                  type: 'Magnified Image',
                  imageFileName: '/images/chemical-formula/heating-setup.jpg',
                  imageAlt:
                    'A heating element points down to a rust-colored on solid on a watch glass. A piece of white tape is stuck to the watch glass and words on written on the tape, but are illegible. The zoomed in image shows a rust-colored solid rests on a watch glass and a white tape is taped to the watch glass with the words "Label your name".',
                  imageZoomFileName: '/images/chemical-formula/label.jpg',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'When the watch glass is cool enough to touch, measure the mass of the watch glass plus copper. Repeat the drying and weighing of the copper until you are sure that it is completely dry. Make sure that the copper will not turn black. ',
                },
                {
                  order: 7,
                  type: 'Input',
                  directions:
                    'How can you tell when your product is dry? Select all photo(s) that represent a dry product. (A, B, and/or C)',
                  validAnswers: [
                    'B, C',
                    'b, c',
                    'B and C',
                    'b and c',
                    'bc',
                    'cb',
                    'BC',
                    'CB',
                    'b,c',
                    'c,b',
                    'B,C',
                    'C,B',
                  ],
                  tip: 'All pictures show the product to have chunky pieces so to determine that it is dry is based more on the color of the product. Picture 1 has a darker hue than the other two, which indicates that it is wet. .',
                },
                {
                  order: 8,
                  type: 'Images',
                  images: [
                    {
                      imageFileName: '/images/chemical-formula/wet-product.jpg',
                      imageAlt:
                        'A close-up of a dark rust-colored clumpy solid on a watch glass.',
                      imageTitle: 'A',
                    },
                    {
                      imageFileName: '/images/chemical-formula/dry-product.jpg',
                      imageAlt:
                        'A close-up of a light rust-colored clumpy solid on a watch glass.',
                      imageTitle: 'B',
                    },
                    {
                      imageFileName:
                        '/images/chemical-formula/dry-product-2.jpg',
                      imageAlt:
                        'A close-up of a light rust-colored clumpy solid on a watch glass.',
                      imageTitle: 'C',
                    },
                  ],
                },
                {
                  order: 9,
                  type: 'Text',
                  text: 'Place the copper in a provided container; you will need to attach it to your report. Place the aluminum wire in the proper waste container in the fume hood, dispose of the filtered liquid in the waste bottle. ',
                },
                {
                  order: 10,
                  type: 'Text',
                  text: 'Place the copper in a provided container; you will need to attach it to your report. Place the aluminum wire in the proper waste container in the fume hood, dispose of the filtered liquid in the waste bottle. ',
                },
              ],
            },
            {
              name: 'Clean-up',
              order: 6,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Clean-up',
                },
                {
                  order: 6,
                  type: 'Text',
                  text: 'Place the copper in a provided container; you will need to attach it to your report.',
                },
                {
                  order: 7,
                  type: 'Text',
                  text: 'Place the aluminum wire in the proper waste container in the fume hood, dispose of the filtered liquid in the waste bottle. ',
                },
                {
                  order: 8,
                  type: 'Text',
                  text: '<strong>You are ﬁnished with the experiment guide!</strong>',
                  centered: true,
                },
              ],
            },
          ],
        },
      ],
    },
  },

  rank: 8,
};

export default chemicalFormula;
