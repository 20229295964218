import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  Typography,
} from '@mui/material';
import CorrectIcon from '@mui/icons-material/Check';
import IncorrectIcon from '@mui/icons-material/Cancel';
import Tip from './Tip';
import HTMLContent from './HTMLContent';

const ValidatedMultipleChoice = (props) => {
  const [userInput, setUserInput] = useState([]);
  const [correct, setCorrect] = useState(false);
  const [incorrect, setIncorrect] = useState(false);

  function handleCheck() {
    let correctAnswerCount = 0;

    const validAnswers = props.choices
      .filter((choice) => choice.validChecked === true)
      .map((filteredChoice) => filteredChoice.label);

    validAnswers.forEach((validAnswer) => {
      if (userInput.indexOf(validAnswer) > -1) {
        correctAnswerCount += 1;
      }
    });

    if (
      correctAnswerCount === validAnswers.length &&
      userInput.length === validAnswers.length
    ) {
      setCorrect(true);
      setIncorrect(false);
    } else {
      setCorrect(false);
      setIncorrect(true);
    }
  }

  function handleChange(event) {
    if (event.target.checked) {
      setUserInput((prevState) => [...prevState, event.target.value]);
    } else {
      setUserInput((prevState) =>
        prevState.filter(
          (prevStateElement) => prevStateElement !== event.target.value
        )
      );
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', gap: 1, mb: 3 }}>
        <FormControl component='fieldset'>
          <FormGroup>
            {props.choices.map((choice) => {
              return (
                <Box
                  key={choice.label}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                  }}
                >
                  <FormControlLabel
                    value={choice.label}
                    control={<Checkbox />}
                    label={<HTMLContent content={choice.label} />}
                    onChange={handleChange}
                  />
                  <Tip tip={choice.tip} />
                </Box>
              );
            })}
          </FormGroup>
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button variant='outlined' onClick={handleCheck} disabled={correct}>
          Check
        </Button>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: correct ? 'flex' : 'none', gap: 1 }}>
            <CorrectIcon fontSize='large' sx={{ color: 'lightgreen' }} />{' '}
            <Typography variant='h6' sx={{ color: 'green' }}>
              Correct
            </Typography>
          </Box>
          <Box sx={{ display: incorrect ? 'flex' : 'none', gap: 1 }}>
            <IncorrectIcon fontSize='large' sx={{ color: 'red' }} />{' '}
            <Typography variant='h6' sx={{ color: 'red' }}>
              Incorrect
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ValidatedMultipleChoice;
