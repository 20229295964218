import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { v4 as uuid } from 'uuid';
import CorrectIcon from '@mui/icons-material/Check';
import IncorrectIcon from '@mui/icons-material/Cancel';
import Tip from './Tip';

const ValidatedImageSelection = (props) => {
  console.log(props);
  const [userInput, setUserInput] = useState([]);
  const [correct, setCorrect] = useState(false);
  const [incorrect, setIncorrect] = useState(false);

  function handleCheck() {
    let correctAnswerCount = 0;

    const validAnswers = props.choices
      .filter((choice) => choice.validChecked === true)
      .map((filteredChoice) => filteredChoice.label);

    validAnswers.forEach((validAnswer) => {
      if (userInput.indexOf(validAnswer) > -1) {
        correctAnswerCount += 1;
      }
    });

    if (
      correctAnswerCount === validAnswers.length &&
      userInput.length === validAnswers.length
    ) {
      setCorrect(true);
      setIncorrect(false);
    } else {
      setCorrect(false);
      setIncorrect(true);
    }
  }

  function handleClick(imageFileName) {
    setUserInput((prevState) => [...prevState, imageFileName]);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        key={uuid()}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
        }}
      >
        {props.choices.map((choice, index) => {
          return (
            <Button
              key={choice.imageFileName}
              onClick={() => handleClick(choice.imageFileName)}
              sx={{
                border: () =>
                  userInput[choice.ImageFileName] ? '5px solid red' : 'none',
              }}
            >
              <img
                height='300px'
                object-fit='contain'
                src={choice.imageFileName}
                alt={choice.imageAlt}
              />
            </Button>
          );
        })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Tip tip={props.tip} />
        <Typography variant='h6'>Tip</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant='outlined' onClick={handleCheck} disabled={correct}>
          Check
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: correct ? 'flex' : 'none', gap: 1 }}>
          <CorrectIcon fontSize='large' sx={{ color: 'lightgreen' }} />{' '}
          <Typography variant='h6' sx={{ color: 'green' }}>
            Correct
          </Typography>
        </Box>
        <Box sx={{ display: incorrect ? 'flex' : 'none', gap: 1 }}>
          <IncorrectIcon fontSize='large' sx={{ color: 'red' }} />{' '}
          <Typography variant='h6' sx={{ color: 'red' }}>
            Incorrect
          </Typography>
        </Box>
      </Box>
      {console.log(userInput)}
    </Box>
  );
};

export default ValidatedImageSelection;
