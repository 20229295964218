import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Image from '../components/content-blocks/Image';

const About = (props) => {
  return (
    <>
      <Container maxWidth='xl'>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 2,
          }}
        >
          <Typography variant='h3'>About Us</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 5,
            }}
          >
            <a href='https://www.ohlone.edu/lyttoncenter'>
              <Image
                imageFileName='/images/lytton-center-logo.jpg'
                imageAlt='Lytton Center Logo'
              />
            </a>
            <Typography variant='p' sx={{ my: 3 }}>
              This project was sponsored by the Lytton Center for Ohlone College
            </Typography>
            <Typography variant='p'>
              All content is created and owned by Lisa Wesoloski, Ph.D.
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default About;
