const significantFigures = {
  status: 'Active',
  name: 'Significant Figures',
  imageFileName: 'images/sigfigs-icon.jpg',
  imageAlt:
    'The number 0.0048070 is presented with arrows pointing to the different type of zeros represented in the context of significant figures. The first zeros, 0.00, are labeled as leading zeros The numbers 4, 8 and 7 are labeled as non-zero digits The zero in between the 8 and the 7 are labeled as sandwiched zeros. Last, the zero to the far right right after the 7 is labeled as a trailing zero.',
  description: 'How to identify the number of significant figures in a number.',
  tags: ['Math Review', 'Tutorial', 'Chem-101A'],
  page: '/significant-figures',
  content: {
    overview: {
      name: 'Overview',
      link: 'overview',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'NOSTEP',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'The following activity reviews the purpose and the rules of significant figures. Start with the <b><em>Introduction</b></em> to review how we measure, which leads to the concept of significant figures, using common equipment in the chemistry lab. Use the <b><em>Tutorial</b></em> to examine each significant figure rule followed by examples to test the rule yourself. Rounding is reviewed as well in order to set you up for determiing the number of significant figure when performing calculations. Next, test your understanding in the <b><em>Exercises</b></em> to identify the correct number of significant figures for a variety of numbers and calculations.',
                },
              ],
            },
          ],
        },
      ],
    },
    introduction: {
      name: 'Introduction',
      link: 'introduction',
      tabs: [
        {
          name: 'NOTAB',
          order: 1,
          steps: [
            {
              name: 'Limits of Precision',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'In chemistry, we take measurements in order to obtain our data. The act of measuring is done by using laboratory equipment, whether digital or analog. Every instrument, however, has a limit to how precise it can give data. For example, the ruler below has its limit based on the smallest increment lines of 0.1 mm (meaning the smallest marks on the ruler are spaced every 0.1 mm)',
                },
                {
                  order: 2,
                  type: 'Magnified Image',
                  imageFileName: '/images/sig-figs/red-pencil.jpg',
                  imageAlt: 'Red pencil measured against ruler.',
                  imageZoomFileName: '/images/sig-figs/red-pencil-zoom-2.jpg',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Try to zoom in using the magnifying glass to get a close up view of the measurement.',
                },
              ],
            },
            {
              name: 'The Estimated Digit',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Image',
                  imageFileName:
                    '/images/sig-figs/red-pencil-ruler-closeup.png',
                  imageAlt:
                    'Close up picture of red pencil measured against ruler. A red arrow points to the 9.38 cm on the ruler.',
                  centered: true,
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'If a measured object has a length which <strong>falls in between</strong> the smallest increments, then we estimate further to the next decimal place. For the pencil, we can measure it to be 9.38 mm long. Let’s classify the digits in this measurement:',
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName: '/images/sig-figs/sig-fig-digit-diagram.png',
                  imageAlt:
                    'The number 9.38 is highlighted with two colors. The 9.3 is highlighted in light blue. Below it are two light blue arrows pointing to the numbers with the text "Certain Digits". The 8 is highlights in a peach color. A peach-colored arrow points to it and reads "The Estimated Digit, also known as the Uncertain Digit".',
                  centered: true,
                },
                {
                  order: 4,
                  type: 'Text',
                  text: 'We classify any numbers that are <strong>not estimated</strong> as <strong>certain digits</strong>. With certainty, we know the length of the pencil pertains to those values based on the increment lines it has reached. The <strong>one</strong> digit found to the far right of the measurement is the <strong>estimated digit</strong>, also referred to as the uncertain digit. While the 8 was estimated, another person may conclude it as a 9. There is room for interpretation since there are no definite lines indicating the value. We say that the estimated digit falls under a range of error of +/- 0.05 mm for this example (basically the entire length between the two smallest increment lines). Take note that we can never estimate past the estimated digit as the instrument has a limit to how much precision it can give.',
                },
              ],
            },
            {
              name: 'Significant Figures',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'Measuring will yield any certain digits and the one estimated digit.',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: '<strong>All digits in the measurement are considered to be the significant figures of a number</strong>.',
                  centered: true,
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'How many significant figures does 9.38 mm have? It has 3 significant figures.',
                },
              ],
            },
            {
              name: 'One the Line',
              order: 4,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'Prior to taking a measurement, one should be cognizant of the precision for the device. This is done by reviewing the smallest increments and going one digit further for the estimation digit. Since we determined that the red pencil reached the hundredth place, that concludes all measurements for this ruler will <strong>always</strong> obtain significant figures to the hundredth place. Therefore for the green pencil, the same ruler will provide a measurement length to the hundredth place, even if we do not read the measurement yet.',
                },
                {
                  order: 2,
                  type: 'Magnified Image',
                  imageFileName: '/images/sig-figs/green-pencil.jpg',
                  imageAlt: 'Green pencil measured against ruler.',
                  imageZoomFileName: '/images/sig-figs/green-pencil-zoom.jpg',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'The measurement for the green pencil falls on the increment line. Since we know the ruler has the precision to provide data up till the hundredth place, we thus measure 8.30 mm. The green pencil is an example how we can obtain a trailing zero that is a significant figure.',
                },
                {
                  order: 4,
                  type: 'Image',
                  imageFileName:
                    '/images/sig-figs/green-pencil-ruler-closeup.png',
                  imageAlt:
                    'Close-up picture of green pencil measured against ruler. A red arrow points to the 8.30 cm on the ruler. ',
                  centered: true,
                },
              ],
            },
            {
              name: 'On the Line #2',
              order: 4,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'For one last example, lets measure the length of the brown pencil and determine its number of significant figures.',
                },
                {
                  order: 2,
                  type: 'Magnified Image',
                  imageFileName: '/images/sig-figs/brown-pencil.jpg',
                  imageAlt: 'Brown pencil measured against ruler.',
                  imageZoomFileName: '/images/sig-figs/brown-pencil-zoom.jpg',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'The pencil reaches the increment line 8. The measured length is 8.00 mm and has 3 significant figures.',
                },
                {
                  order: 4,
                  type: 'Image',
                  imageFileName:
                    '/images/sig-figs/brown-pencil-ruler-closeup.png',
                  imageAlt:
                    'A close-up view of a brown pencil measured against a ruler. A red arrow points to the 8.00 cm on the ruler.',
                  centered: true,
                },
              ],
            },
          ],
        },
      ],
    },
    tutorial: {
      name: 'Tutorial',
      link: 'tutorial',
      description:
        'Go through each of the 3 cases in this tutorial to learn about the different patterns in determining significant figures.',
      tabs: [
        {
          name: 'Identifying Sig Figs',
          order: 1,
          steps: [
            {
              name: 'Non-zero Digits',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Non-zero Digits',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The number of significant figures equals the number of digits present in the measurement. Keep in mind a measurement includes any certain digits and the one estimated digit',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions:
                    'Determine the number of significant figures in the number 63.45',
                  validAnswers: ['4'],
                  tip: '<p>The digits have all been measured including the estimated digit (5) that is furthest to the right. Therefore, each digit is to be counted as significant.</p>',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions:
                    'Determine the number of significant figures in the number 25,444',
                  validAnswers: ['5'],
                  tip: '<p>The rule is: All non-zero digits are counted as significant figures.</p>',
                },
              ],
            },
            {
              name: 'Sandwiched Zeros',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Sandwiched Zeros',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Zeroes found in between other non-zero digits are always certain digits. The zeros are like any other number and their location in the number signifies it was measured.',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions:
                    'Determine the number of significant figures in the number 6.023',
                  validAnswers: ['4'],
                  tip: '<p>Zeros in between non-zero digits represent a measured digit that happens to fall on the digit zero. All non-zero digits and the sandwiched zero are significant.</p>',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions:
                    'Determine the number of significant figures in the number 1.020304',
                  validAnswers: ['7'],
                  tip: '<p>The rule is: All non-zero digits and the sandwiched zero(s) are significant.</p>',
                },
              ],
            },
            {
              name: 'Leading Zeros',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Leading Zeros in a Small Number',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'A number that is small would have a decimal and zeros preceding a non-zero digit. These leading zeros are merely placeholders, which are not significant. It is easiest to convert the small number into scientific notation to remove the view of placeholder zeros.',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions:
                    'Determine the number of significant figures in the number 0.00345',
                  validAnswers: ['3'],
                  tip: '<p>It is best to view the number in scientific notation, 3.45 x 10<sup>-3</sup> as this visually shows that the leading zeros in the small number are merely placeholders to get to the first non-zero digit.</p><p>The rule is: Placeholder zeros are not significant.</p>',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions:
                    'Determine the number of significant figures in the number 1.08 x 10<sup>-5</sup>',
                  validAnswers: ['3'],
                  tip: '<p>The rule is: All non-zero digits and the sandwiched zero(s) are significant.</p>',
                },
              ],
            },
            {
              name: 'Trailing Zeros',
              order: 4,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Trailing Zeros in Large Number',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Large numbers could be confusing when it comes to counting their significant figures. A general rule of thumb is if a decimal is present, all digits to the left of the decimal were measured. However, if there is not a decimal, then there is ambiguity on what the measurement is displaying. Many textbooks like to dismiss the ambiguity and simply rule that any zeros following non-zero digits for a large number are placeholder zeros. While trend with caution in these cases, but instructors tend rule if there is no decimal present, the trailing zeros are not significant.',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions:
                    'Determine the number of significant figures in the number 1010.',
                  validAnswers: ['4'],
                  tip: '<p>A decimal right after the last zero would be labeled as a trailing zero and is counted as being significant.</p>',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions:
                    'Determine the number of significant figures in the number 1010',
                  validAnswers: ['3'],
                  tip: '<p>This example does not have a decimal, so the trailing zero may or may not have been measured. Sticking to the later assumption, we will classify this trailing zero as not significant. </p> <p>The rule is: Include a decimal if you want to present all trailing zeros as significant. If you do not include the decimal, you left it for ambiguity and the experimenter will assume the trailing zero is not counted as significant. </p>',
                },
              ],
            },
            {
              name: 'Error Range',
              order: 5,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Error Range for a Large',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The error range is another way to identify the number of significant figures in a measured number and is useful for removing any ambiguity for trailing zeros in a large number that does not show a decimal. The error range highlights the location of the estimated digit, which tells us the last digit to count as being significant in the number.',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions:
                    'Determine the number of significant figures in the number 2000 +/- 10',
                  validAnswers: ['3'],
                  tip: 'The error range is +/- 10, which means that the number with certainty falls between 1,990 and 2,010. Based off these two values (1,990 and 2,010), the estimated digit falls at the tens place. The estimated digit is the last digit to count as being significant. <p>It is common to place a line over the zero at the tens place to highlight that it will be counted as significant.</p>',
                },
                {
                  order: 4,
                  type: 'Input',
                  directions:
                    'Determine the number of significant figures in the number 1000 +/-100',
                  validAnswers: ['2'],
                  tip: '<p>The error range is +/- 100, which highlights the estimated digit at the hundreds place. Place a line over the hundreds place zero to highlight it being significant, while the other two zeros are not.</p> <p>It is never sufficient to only place a line over a zero to represent that it is significant without the error range as that is not any rule that has been accepted. </p> <p>The rule is: Include an error range if you need to label zeros as significant when they are ambiguous.',
                },
              ],
            },
            {
              name: 'Exact Numbers',
              order: 6,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Exact Numbers',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'Exact numbers refer to numbers that are not measured. They represent numbers that are exact by definition. For example, 12 inches is defined to be 1 foot. Both the 12 and the 1 are called exact numbers. Another example relates to counting. The number 24 from 24 students in the lab is considered to be an exact number. Exact numbers have an unlimited number of significant figures.',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'Which numbers are considered exact? Check all that apply.',
                },
                {
                  order: 3,
                  type: 'Multiple Choice',
                  directions:
                    'Which numbers are considered exact? Check all that apply.',
                  choices: [
                    {
                      label: '1 m = 100 cm',
                      tip: 'The length in centimeters defines exactly one meter.',
                      validChecked: true,
                    },
                    {
                      label: 'Temperature of the day',
                      tip: 'Temperature is a measurement made with a thermometer.',
                      validChecked: false,
                    },
                    {
                      label: 'A dozen eggs',
                      tip: 'The eggs have been counted.',
                      validChecked: true,
                    },
                    {
                      label: '2.54 cm = 1 inch',
                      tip: 'The length in centimeter defines exactly once inch.',
                      validChecked: true,
                    },
                    {
                      label: 'The human population',
                      tip: 'A population has been counted.',
                      validChecked: true,
                    },
                    {
                      label: 'The world record for speed skating',
                      tip: 'Speed is measured.',
                      validChecked: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Rounding',
          order: 2,
          steps: [
            {
              name: 'Rounding Down',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'While performing the many calculations in your chemistry class, you will want to round your final answer to the correct number of significant figures.',
                },
                {
                  order: 2,
                  type: 'Header',
                  text: 'Rounding Down',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'When rounding to a digit that needs to be retained, any digits to its right will be dropped if the digit immediately to its right is less than five. The retained digit stays the same.',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions:
                    'Round the number 0.1632257 to three significant figures',
                  validAnswers: ['0.163'],
                  tip: 'Always ensure you remain in the same magnitude as the original number.<p>1.63 does not represent the same number as 0.163</p><p>A decimal number should always have a zero placed before the decimal point. Make sure it is not missing!',
                },
              ],
            },
            {
              name: 'Rounding Up',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Rounding Up',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'When rounding to a digit that needs to be retained, any digits to its right will be dropped if the digit immediately to its right is greater than five. However, the retained digit will increase by one.',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions:
                    'Round the number 1,367 to three significant figures',
                  validAnswers: ['1,370', '1370', '1.37E3', '1.37E+3'],
                  tip: 'Always ensure you remain in the same magnitude as the original number.<p>1,370 does not represent the same number as 137.</p>',
                },
              ],
            },
            {
              name: 'The number 5',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'The number 5',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'When rounding to a digit that needs to be retained and if the digit immediately to its right is a five, then the rounding is dependent on whether the last retained digit is even or odd. If the retained digit is odd, round up and drop the rest to its right. If the retained digit is even, round down and drop the rest to its right.',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions:
                    'Round the number 13.85 to three significant figures',
                  validAnswers: ['13.8'],
                  tip: 'The 8 is even and is the furthest to the right place and will be counted as significant. We then look at the 5 and follow this rule: Drop the 5 because the 8 is even.',
                },
                {
                  order: 3,
                  type: 'Input',
                  directions:
                    'Round the number 1.475 to three significant figures',
                  validAnswers: ['1.48'],
                  tip: 'The 7 is odd and is the furthest to the right place and will be counted as significant. We then look at the 5 next to it and follow this rule: Drop the 5 and increase the 7 by one digit since 7 is odd.',
                },
              ],
            },
          ],
        },
        {
          name: 'Calculations',
          order: 3,
          steps: [
            {
              name: 'Addition and Subtraction',
              order: 1,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Text',
                  text: 'Each number used while performing calculations in chemistry is either measured or exact. The mathematical operations using these numbers will impact the number of significant figures in the final answer.',
                },
                {
                  order: 2,
                  type: 'Header',
                  text: 'Addition and Subtraction',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'The number of significant figures when adding or subtracting is dependent on the precision of each of the values involved in the calculation. The final answer can only have the precision of the most uncertain value. This can be visualized by performing the calculation vertically and lining up the decimal points. In the example below, 6.5 g is the value that has the least precision since its decimal places only reach the tenth place. Therefore, the final answer will round to tenth place, regardless of the number of significant figures in each of the participating values. The final answer will then round to 11.8 g to have 3 significant figures.',
                },
                {
                  order: 4,
                  type: 'Image',
                  imageFileName:
                    '/images/sig-figs/addition-subtraction-diagram.png',
                  imageAlt:
                    'An addition is written vertically with a specific placement of a dotted red line. The addition is 6.5 g plus 5.20 g plus 0.123 g with the answer below it equal to 11.823 g. The dotted vertical red line is placed right after the tenth place of all digits in the addition and then a second horizontal dotted red line arrow is placed below the answer 11.8 meeting the vertical dotted red line.',
                  centered: true,
                },
                {
                  order: 5,
                  type: 'Input',
                  directions: 'Calculate 8.2198 - 5.32',
                  validAnswers: ['2.90'],
                  tip: '5.32 reaches the hundredth place while 8.2198 reaches the ten thousandth place, therefore the final answer will round to the hundredth place, following the subtraction rule.',
                },
                {
                  order: 6,
                  type: 'Input',
                  directions: 'Calculate 7.56 + 0.153',
                  validAnswers: ['7.71'],
                  tip: '7.56 reaches the hundredth place while 0.153 reaches the thousandth place, therefore the final answer will round to the hundredth place, following the addition rule.',
                },
              ],
            },
            {
              name: 'Multiplication and Division',
              order: 2,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Multiplication and Division',
                },
                {
                  order: 2,
                  type: 'Text',
                  text: 'The number of significant figures when multiplying or dividing is a completely separate rule from addition and subtraction. The values used in these operations are inspected for their number of significant figures. The value with the fewer significant figures will determine the number of significant figures in the final answer. For the example below, your calculator will show 190.8146, but you will round your final answer to 3 significant figures.',
                },
                {
                  order: 3,
                  type: 'Image',
                  imageFileName:
                    '/images/sig-figs/multiplication-division-diagram.png',
                  imageAlt:
                    'a multiplication of 13.1 times 14.566 is equal to 191. Below 13.1 is labeled (3 sig figs), below 14.566 is labeled (5 sig figs) and below 191 is labeled (3 sig figs).',
                  centered: true,
                },
                {
                  order: 4,
                  type: 'Input',
                  directions: 'Calculate 32.27 x 1.54',
                  validAnswers: ['49.7'],
                  tip: 'The number 1.54 has 3 significant figures, while 32.27 has 4 significant figures. Following the multiplication rule, the fewer significant figures dictates the number of significant figures in the final answer.',
                },
                {
                  order: 5,
                  type: 'Input',
                  directions: 'Calculate 3.68 / 0.07925',
                  validAnswers: ['46.4'],
                  tip: 'The number 3.68 has 3 significant figures, while 0.07925 has 4 significant figures. Following the division rule, the fewer significant figures dictates the number of significant figures in the final answer.',
                },
              ],
            },
            {
              name: 'Consecutive Calculations',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Consecutive Calculations',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: 'When performing consecutive calculations, <strong>do not round off</strong> until you reach the final number. Take this problem as an  example where two additions will first be performed followed by a multiplication.',
                },
                {
                  order: 4,
                  type: 'Image',
                  imageFileName:
                    '/images/sig-figs/consecutive-calculations-diagram-1.png',
                  imageAlt:
                    'A multi-step calculation is presention. The multiplication of (8.52 plus 4.1586) times (18.73 plus 153.2) is equal to.',
                  centered: true,
                },
                {
                  order: 5,
                  type: 'Text',
                  text: 'The additions are first operated on and their significant figures noted. It is common to place a line above or below the digit that you want to mark as the digit to count up to for significant figures. Next, the results from the additions are multiplied together',
                },
                {
                  order: 6,
                  type: 'Image',
                  imageFileName:
                    '/images/sig-figs/consecutive-calculations-diagram-2.png',
                  imageAlt:
                    'Two steps are provided in the image. The first step outlines two separate additions. The additions are written vertically with a specific placement of a dotted red line. The first addition is 8.52 plus 4.1586 with the answer below it equal to 12.6786. The dotted vertical red line is placed right after the hundreth place of all digits in the addition and then a second horizontal dotted red line arrow is placed below the answer 12.67 meeting the vertical dotted red line. The second vertical addition is 18.73 plus 153.2 with the answer below it equal to 171.93. Its dotted vertical red line is placed right after the tenth place of all digits in the addition and then a second horizontal dotted red line arrow is placed below the answer 171.9 meeting the vertical dotted red line. The second step shows a multiplication of (12.6786) times (171.93) is equal to 2.180 times 10^3. Above specific numbers in each, the 7 in 12.6785 and the 9 in 171.93, there is a short red line which defines the where to stop counting the number significant figures. Below 12.6786 is labeled 4 sig figs, below 171.93 is labeled 4 sig figs and below 2.180 is labeled 4 sig figs.',
                  centered: true,
                },
                {
                  order: 7,
                  type: 'Text',
                  text: 'Very important, the results from the additions <strong>are not rounded</strong>. Their full values will be multiplied together. The final answer will then be rounded to 4 significant figures based on the significant figures defined by the additions.',
                },
                {
                  order: 8,
                  type: 'Text',
                  text: 'The final answer could appear as 2.180 x 10<sup>3</sup> or 2,180. or 2,180 +/- 1.',
                },
                {
                  order: 9,
                  type: 'Input',
                  directions: 'Calculate (8.52 x 4.1586) + (18.73 x 153.2)',
                  validAnswers: ['2905', '2905.', '2,905', '2,905.'],
                  tip: '<p>Two multiplications are performed first, followed by the addition of the multiplication results. </p><p>Follow the multiplication rule for significant figures: Place a line over the last number to the right of the multiplication results to represent where the counting of significant figures will stop. (This will be a 4 in the first multiplication and a 9 in the second multiplication). Make sure you do not round yet! </p><p>Next, add the two results following the addition rule for significant figures: A vertical line should be drawn at the decimal place since the ones place had been marked as the value with least precision.</p>',
                },
              ],
            },
            {
              name: 'Logarithms',
              order: 3,
              contentBlocks: [
                {
                  order: 1,
                  type: 'Header',
                  text: 'Logarithms',
                },
                {
                  order: 3,
                  type: 'Text',
                  text: "For logarithms and natural logarithms, let’s first review the operation in detail with an example. The number 5,687 has four significant figures and when we take its logarithm (log 5,687), the result is 3.7549. Looking at this same number in scientific notation, helps us see how the exponent of the power of ten becomes the three in the one's place of the final answer. The number of significant figures in the original number becomes the significant figures in the decimal place of the final answer.",
                },
                {
                  order: 4,
                  type: 'Image',
                  imageFileName: '/images/sig-figs/logarithms-diagram.png',
                  imageAlt:
                    "log 5.687 times 10^3 is equal to 3.7549. Below 5.687 is an orange line that extends from 5 to 7 and below it is labeled (4 sig figs. Below the answer portion 7549 is an orange line that extends from 7 to 9 and below it is labeled (4 sig figs. A blue line with arrows points to the 3 of the power of ten at one end and then points to the answer's 3 in 3.7549.",
                  centered: true,
                },
                {
                  order: 5,
                  type: 'Input',
                  directions: 'Calculate log 22.6',
                  validAnswers: ['1.354'],
                  tip: "The number 22.6 has 3 significant figures. Write the number in scientific notation, 2.26 x 10<sup>1</sup>. The exponent 1, will be the digit in the ones place in the final answer and you haven't even used the calculator yet! Your final answer will have the format 1.XXX, where XXX refer to the 3 significant figures in the final answer.",
                },
                {
                  order: 6,
                  type: 'Input',
                  directions: 'Calculate antilog 2.500',
                  validAnswers: ['316', '3.16E+2', '3.16E2', '316.'],
                  tip: '<p>Antilog is the same thing as taking the inverse log. To see how that looks, one can write the equation as log x = 2.500 and solve for x. Next, take the power of 10 (the antilog) on both sides. </p><p>10<sup>2.500</sup> = x </p><p>The number 2.500 has 3 significant figures since it is the result from a logarithm. Therefore, the antilog of 2.500 will have 3 significant figures.',
                },
              ],
            },
          ],
        },
      ],
    },
    exercises: {
      name: 'Exercises',
      link: 'exercises',
      problemSets: [
        {
          name: 'Identifying Sig Figs',
          instructions:
            'Provide the number of significant figures for each number. You must get 10 correct.',
          correctAnswersRequired: 10,
          skipsAllowed: 0,
          problems: [
            {
              inputLabel: 'Answer',
              problem: '45.8736',
              validAnswers: ['6'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the sig fig rule for "Numbers without Zeros".</p>',
            },
            {
              inputLabel: 'Answer',
              problem: '93.00',
              validAnswers: ['4'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the sig fig rule for "Trailing Zeros in a Small Number".</p>',
            },
            {
              inputLabel: 'Answer',
              problem: '0.00590',
              validAnswers: ['3'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the sig fig rule for "Leading Zeros in a Small Number" and "Trailing Zeros in a Small Number". </p>',
            },
            {
              inputLabel: 'Answer',
              problem: '3800',
              validAnswers: ['2'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the sig fig rule for "Trailing Zeros in a Large Number".</p>',
            },
            {
              inputLabel: 'Answer',
              problem: '3.982 x 10<sup>6</sup>',
              validAnswers: ['4'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the sig fig rule for "Trailing Zeros in a Large Number".</p>',
            },
            {
              inputLabel: 'Answer',
              problem: '4.00000',
              validAnswers: ['6'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the sig fig rule for "Trailing Zeros in a Small Number". </p>',
            },
            {
              inputLabel: 'Answer',
              problem: '507.809',
              validAnswers: ['6'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the sig fig rule for "Sandwiched Zeros". </p>',
            },
            {
              inputLabel: 'Answer',
              problem: '1.00400',
              validAnswers: ['6'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the sig fig rule for "Sandwiched Zeros" and "Trailing Zeros in a Small Number". </p>',
            },
            {
              inputLabel: 'Answer',
              problem: '1.70 x 10<sup>-4</sup>',
              validAnswers: ['3'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the sig fig rule for "Leading Zeros in a Small Number" and "Trailing Zeros in a Small Number". </p>',
            },
            {
              inputLabel: 'Answer',
              problem: '23,000 +/-100',
              validAnswers: ['3'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the sig fig rule for "Error Range for a Large Number"</p>',
            },
          ],
        },
        {
          name: 'Rounding',
          instructions:
            'Round all numbers to three significant figures<ul><li>Any scientific notation must be written in the format 1.23E+2 or 9.87E-2</li><li>Any decimal must be written with a zero before a decimal, example: 0.123</li></ul>You must get 5 correct.',
          correctAnswersRequired: 5,
          skipsAllowed: 0,
          problems: [
            {
              inputLabel: 'Answer',
              problem: '0.0037421',
              validAnswers: ['0.00374', '3.74E-3'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the rounding rule of "Round Down".</p>',
            },
            {
              inputLabel: 'Answer',
              problem: '8.995 x 10<sup>3</sup>',
              validAnswers: ['9.00E3', '9.00E+3'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the special 5 rounding rule for the odd digit.</p>',
            },
            {
              inputLabel: 'Answer',
              problem: '1.6638 x 10<sup>-4</sup>',
              validAnswers: ['1.66E-4', '0.000166'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the rounding rule of "Round Down".</p>',
            },
            {
              inputLabel: 'Answer',
              problem: '3825',
              validAnswers: ['3820', '3.82E3', '3.82E+3'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the special 5 rounding rule for the even digit.</p>',
            },
            {
              inputLabel: 'Answer',
              problem: '0.05555555',
              validAnswers: ['0.0556', '5.56E-2'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the special 5 rounding rule for the odd digit.</p>',
            },
          ],
        },
        {
          name: 'Calculations',
          instructions:
            'Provide the answer for each calculation including the proper number of significant figures.<ul><li>Any scientific notation must be written in the format 1.23E+2 or 9.87E-2</li><li>Any decimal must be written with a zero before a decimal, example: 0.123</li></ul>You must get 10 correct.',
          correctAnswersRequired: 10,
          skipsAllowed: 0,
          problems: [
            {
              inputLabel: 'Answer',
              problem: '1.750 x 0.0342',
              validAnswers: ['0.0598', '5.98E-2'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the multiplication sig fig rule. </p>',
            },
            {
              inputLabel: 'Answer',
              problem: '(3.2650 x 10<sup>24</sup>) x (4.85 x 10<sup>3<sup>)',
              validAnswers: ['1.58E28', '1.58E+28'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the multiplication sig fig rule.</p>',
            },
            {
              inputLabel: 'Answer',
              problem: '(6.75 x 10<sup>-8</sup>) + (5.43 x 10<sup>-7</sup>)',
              validAnswers: ['6.10E-7', '0.000000610'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the addition sig fig rule. One way to line up the numbers vertically is to convert both numbers to standard notation so that you can better see the number with least precision.</p>',
            },
            {
              inputLabel: 'Answer',
              problem: '0.00957 / 2.9465',
              validAnswers: ['0.00325', '3.25E-3'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the division sig fig rule.</p>',
            },
            {
              inputLabel: 'Answer',
              problem: '10.052 - 9.8742',
              validAnswers: ['0.178', '1.78E-1'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the subtraction sig fig rule.</p>',
            },
            {
              inputLabel: 'Answer',
              problem: '((8.32 x 10<sup>-3</sup>)<sup>1/2</sup>)',
              validAnswers: ['0.0912', '9.12E-2'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the multiplication sig fig rule.</p>',
            },
            {
              inputLabel: 'Answer',
              problem:
                '(0.000738 - 8.3 x 10<sup>-5</sup>) / 6.298 x 10<sup>-8</sup>',
              validAnswers: ['1.04E+4', '1.04E4', '1,040', '1040'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the subtraction sig fig rule first with a vertical set-up. Determine the number of sig figs in the subtraction answer. Next, follow the division sig fig rule.</p>',
            },
            {
              inputLabel: 'Answer',
              problem: '(3.84 x 10<sup>-2</sup>)<sup>3</sup>',
              validAnswers: ['5.66E-5', '0.0000566'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the multiplication sig fig rule.</p>',
            },
            {
              inputLabel: 'Answer',
              problem: 'ln 12.55',
              validAnswers: ['2.5297'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the logarithm sig fig rule.</p>',
            },
            {
              inputLabel: 'Answer',
              problem: 'Antilog 3.592',
              validAnswers: ['3.91E3', '3.91E+3', '3,910', '3910'],
              answerLength: 'Xlarge',
              tip: '<p>Follow the logarithm sig fig rule.</p>',
            },
          ],
        },
      ],
    },
  },
  rank: 2,
};

export default significantFigures;
