import React, { useState, useCallback } from 'react';
import { Box, Button } from '@mui/material';
import ValidatedInput from './content-blocks/ValidatedInput';
import HTMLContent from './content-blocks/HTMLContent';

const Problem = ({
  visible,
  problem,
  inputLabel,
  validAnswers,
  tip,
  answerLength,
  onCheck,
  onNext,
  showNext,
}) => {
  const [complete, setComplete] = useState(false);

  const handleValidation = useCallback(
    (correct) => {
      setComplete(correct);
      onCheck(correct);
    },
    [onCheck]
  );

  const display = visible ? 'inline' : 'none';

  return (
    <Box sx={{ mb: 4, display }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          alignItems: 'center',
        }}
      >
        <HTMLContent content={problem} />
        <ValidatedInput
          field={inputLabel}
          validAnswers={validAnswers}
          tip={tip}
          answerLength={answerLength}
          onValidation={handleValidation}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          mt: 4,
          justifyContent: 'right',
        }}
      >
        <Button
          variant='contained'
          onClick={onNext}
          disabled={!complete}
          sx={{ visibility: showNext }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default Problem;
