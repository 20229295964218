import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import CorrectIcon from '@mui/icons-material/Check';
import IncorrectIcon from '@mui/icons-material/Cancel';
import Tip from './Tip';
import HTMLContent from './HTMLContent';

const ValidatedInput = (props) => {
  const [userInput, setUserInput] = useState('');
  const [correct, setCorrect] = useState(false);
  const [incorrect, setIncorrect] = useState(false);

  function handleCheck() {
    const checkCorrect = props.validAnswers.find(
      (validAnswer) => userInput === validAnswer
    );

    if (checkCorrect) {
      setCorrect(true);
      setIncorrect(false);
      if (props.onValidation) props.onValidation(true);
    } else {
      setIncorrect(true);
      setCorrect(false);
      if (props.onValidation) props.onValidation(false);
    }
  }

  const setWidth = (answerLength) => {
    switch (answerLength) {
      case 'Small':
        return 0.08;
      case 'Medium':
        return 0.15;
      case 'Large':
        return 0.25;
      case 'Xlarge':
        return 0.5;
      default:
        return 0.15;
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        {props.equation && (
          <HTMLContent variant='body1' content={`${props.equation} = `} />
        )}
        <TextField
          label='Answer'
          size='small'
          type='text'
          value={userInput}
          onChange={(event) => {
            setUserInput(event.target.value);
          }}
          sx={{
            width: setWidth(props.answerLength),
          }}
        />
        {props.unitOfMeasure && (
          <HTMLContent variant='body1' content={props.unitOfMeasure} />
        )}
        <Tip tip={props.tip} />
        <Button variant='outlined' onClick={handleCheck} disabled={correct}>
          Check
        </Button>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: correct ? 'flex' : 'none', gap: 1 }}>
            <CorrectIcon fontSize='large' sx={{ color: 'lightgreen' }} />{' '}
            <Typography variant='h6' sx={{ color: 'green' }}>
              Correct
            </Typography>
          </Box>
          <Box sx={{ display: incorrect ? 'flex' : 'none', gap: 1 }}>
            <IncorrectIcon fontSize='large' sx={{ color: 'red' }} />{' '}
            <Typography variant='h6' sx={{ color: 'red' }}>
              Incorrect
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ValidatedInput;
