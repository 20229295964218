import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import Home from './pages/Home';
import Labs from './pages/Labs';
import Lab from './pages/Lab';
import Overview from './pages/lab-sections/Overview';
import Tutorial from './pages/lab-sections/Tutorial';
import Exercises from './pages/lab-sections/Exercises';
import Introduction from './pages/lab-sections/Introduction';
import Experiment from './pages/lab-sections/Experiment';
import Feedback from './pages/Feedback';
import About from './pages/About';
import PageNotFound from './pages/PageNotFound';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />}>
          <Route index element={<Home />} />
          <Route path='labs' element={<Labs />} />
          <Route path='labs/:labId/*' element={<Lab />}>
            <Route index element={<Overview section='overview' />} />
            <Route path='overview' element={<Overview section='overview' />} />
            <Route path='tutorial' element={<Tutorial section='tutorial' />} />
            <Route
              path='exercises'
              element={<Exercises section='exercises' />}
            />
            <Route
              path='introduction'
              element={<Introduction section='introduction' />}
            />
            <Route
              path='experiment'
              element={<Experiment section='experiment' />}
            />
          </Route>
          <Route path='about' element={<About />} />
          <Route path='feedback' element={<Feedback />} />
          <Route path='*' element={<PageNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
