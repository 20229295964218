import * as React from 'react';
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import HTMLContent from './HTMLContent';
import { v4 as uuid } from 'uuid';

const InformationTable = (props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        my: 3,
        minWidth: 500,
      }}
    >
      <TableContainer component={Paper}>
        <Table size='small' aria-label='information table'>
          <TableHead>
            <TableRow>
              {props.headers.map((header) => (
                <TableCell key={uuid()}>
                  <HTMLContent content={header} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row) => (
              <TableRow
                key={uuid()}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {row.cells.map((cell) => (
                  <TableCell key={uuid()} align='left'>
                    <HTMLContent content={cell} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant='body2'>{props.annotation}</Typography>
    </Box>
  );
};

export default InformationTable;
