const freezingPointDepression = {
    status: 'Active',
    name: 'Freezing Point Depression',
    imageFileName: 'images/freezing-pt-icon.jpg',
    imageAlt: 'A zoomed-in image of a test tube holding a liquid that is solidying. A temperature probe is dipped into the freezing liquid.',
    description: 'Experimental guide on how to find the molecular weight of a solute based on the freezing point depression',
    tags: ['Pre-Lab', 'Wet Lab', 'Experiment', 'Chem-101A'],
    page: '/freezing-point-depression',
    content: {
      overview: {
        name: 'Overview',
        link: 'overview',
        tabs: [
          {
            name: 'NOTAB',
            order: 1,
            steps: [
              {
                name: 'NOSTEP',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Text',
                    text: 'In this experiment, you will determine the freezing point of a pure solvent. You will then determine the freezing point of a solution containing the same solvent and a solute. Based on the comparison of their freezing point curves, the lowering of the freezing point will allow you to calculate the molecular weight of the solute.',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Read through the <strong><em>Introduction</em></strong> to learn how to measure the freezing point depression. Then, in the <strong><em>Experiment</em></strong>, observe some useful techniques for ensuring your experiment will run smoothly.',
                  },
                ],
              },
            ],
          },
        ],
      },
      introduction: {
        name: 'Introduction',
        link: 'introduction',
        tabs: [
          {
            name: 'NOTAB',
            order: 1,
            steps: [
              {
                name: 'Freezing Point Depression',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Freezing Point Depression',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'A colligative property refers to a property that is dependent on the number of particles in the system. It does not depend on the nature of the substance. The lowering of the freezing point for a solution is a colligative property. That is, it depends on the number of solute particles added to a solvent. For example, when 1 mole of NaCl dissolves in water, 2 moles of ionic particles would result in solution due to the ionic bond separating upon dissolution. If 1 M of ethanol were to dissolve in water, there would still be 1 M of solute in the water as the ethanol molecules do not break down further when dissolving. The freezing point of the 1 M NaCl solution will lower 2x more than the 1 M of ethanol solution.',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'A generic form of the equation that shows this freezing point depression relationship is:',
                  },
                  {
                    order: 4,
                    type: 'Image',
                    imageFileName: '/images/freezing-pt/lowering.jpg',
                    imageAlt: 'The change in temperature is equal to the freezing point depression constant that is based on the solvent times the molality of the solution.',
                    centered: true,
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: '&#916T is the freezing point depression, K<sub>f</sub> is the freezing point depression constant that is based on the specific solvent, and <i>m</i> is the molality of the solution. Remember molality is the concentration of moles of solution per kilogram of solvent',
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: '<em>m<em> = mol solute/kg solvent',
                    centered: true,
                  },
                ],
              },
              {
                name: 'Supercooling',
                order: 2,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Supercooling',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Something to watch out for during your experiment is the phenomenon of supercooling. Supercooling occurs when the substance that is undergoing the freezing process is not initially freezing at its freezing point temperature. Because freezing was delayed, the temperature of the substance lowers below the freezing temperature. Once a nucleation site (the start of the formation of a crystal solid) begins, the freezing process kicks off. Supercooling can occur if stirring is too quick preventing a nucleation site from forming when expected at the freezing point.',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'In the cooling curve for the pure solvent below, line AB marks the freezing process as there is a constant temperature occuring over time. During this event, the liquid is converting to solid. The freezing point temperature is this constant temperature. ',
                  },
                  {
                    order: 4,
                    type: 'Image',
                    imageFileName: '/images/freezing-pt/solvent-freezing-pt.jpg',
                    imageAlt: 'A cooling curve of a pure solvent (or substance). The graph is temperature versis time and displays a steady linear decrease to a minima. The minima defines a point marked by a vertical line reaching to the x-axis. To the left of the line is labeled liquid and to the right of the line is labeled liquid and solid. From the minima the curve increases a little bit to point A. From point A the line becomes constant and a dotted line extends from point A to the y-axis and is labeled freezing point temperature. Under the dotted "freezing point temperature", but above the minima curve is the label, supercooling. The curve from point A is constant to a point B. At point B, another vertical line reaches to the x-axis. To the right of the vertical line is laeled solid. From point B, the curve steadily decreases.',
                    centered: true,
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'For a mixture of solute and solvent, the freezing point is lowered due to the additional particles of solute. The freezing process will also be marked by a gradual temperature decrease (line BC on the cooling curve for a solution below). For a solution, it is the solvent that is freezing first from the starter nucleation site. As that site becomes one of the locations for the solid solvent crystal to grow, the rest of the solution becomes more concentrated with solute particles, which will lower the freezing point even further for the solution. Once again, supercooling may occur here too! To find the freezing point temperature of the solution, extrapolate the line BC to point A. Point A will be marked as the freezing point of the solution, since that is the expected start of the freezing process whether supercooling occured or not.',
                  },
                  {
                    order: 6,
                    type: 'Image',
                    imageFileName: '/images/freezing-pt/solution-freezing-pt.jpg',
                    imageAlt: 'A cooling curve of a solution. The graph is temperature versis time and displays a steady linear decrease to a minima. The minima defines a point marked by a vertical line reaching to the x-axis. To the left of the line is labeled liquid and to the right of the line is labeled liquid and solid. From the minima the curve increases a little bit to point B. From point B the line gradually decreases, but not as steep as the start of the curve. The curve decreases to point C. At point C, another vertical line reaches to the x-axis. To the right of the vertical line is laeled solid. From point C, the curve steadily decreases as a steeper rate than BC. From point B, a dotted line is extrapolated from BC and reaches the initial curve decrease (to the left of the minima) marked as point A. Under this dotted line from AB, but above the minima curve is the label, supercooling. The dotted line continues to the y-axis and is labeled freezing point temperature. ',
                    centered: true,
                  },
                ],
              },
              {
                name: 'Experimental Overview',
                order: 3,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Experimental Overview',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'In this experiment, lauric acid, CH<sub>3</sub>(CH<sub>2</sub>)<sub>10</sub>COOH will be warmed and then observed to freeze. Its freezing point will be determined from graphical analysis. Next, a small amount of benzoic acid, C<sub>6</sub>H<sub>5</sub>COOH, will be added to the solvent. Again, the solution will be warmed and observed to freeze with its freezing point determined from a graph. The difference in freezing points, &#916T, called the <b>freezing point depression</b>, will be determined.',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'Using the freezing point depression equation:',
                  },
                  {
                    order: 4,
                    type: 'Image',
                    imageFileName: '/images/freezing-pt/lowering.jpg',
                    imageAlt: 'The change in temperature is equal to the freezing point depression constant that is based on the solvent times the molality of the solution.',
                    centered: true,
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'The molar mass (g/mol) of benzoic acid can be calculated, given the experimental mass of benzoic acid added to the solvent and the freezing point depression constant for the solvent (K<sub>f,lauric acid)</sub> = 3.9°C&middot;kg<sup>-1</sup>mol<sup>-1</sup>).',
                  },
                ],
              },
            ],
          },
        ],
      },
      experiment: {
        name: 'Experiment',
        link: 'experiment',
        tabs: [
          {
            name: 'Start Here',
            order: 1,
            steps: [
              {
                name: 'Summary',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Summary: Experimental Steps',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'The general experimental steps are: <li>Weigh a pure solvent.</li><li>Melt the solvent in a hot bath.</li><li>Observe and record its process of freezing.</li><li>Weigh a solute.</li><li>Add the solute to the solvent and melt together in a hot bath.</li><li>Observe and record its process of freezing.</li><li>Follow graphical analysis to obtain the freezing points of the pure solvent and of the mixture.</li>',
                  },
                ],
              },
              {
                name: 'Equipment',
                order: 2,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Equipment',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'The following pieces of equipment are needed for this experiment:',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/freezing-pt/equipment.jpg',
                    imageAlt: 'A large picture shows equipment spread out on the lab bench. Numbers are next to each item and a key is to the right. A smaller picture is on the bottom right showing items 10 and 11.  1. 3 utility clamps, 2. temperature probe, 3. large test tube, 4. ring stand, 5. large ring clamp, 6. 600 mL beaker, 7. hot plate, 8. 150 mL beaker, 9. Labquest hand-held device, test tube holder, and 11. hair dryer (remains in fume hood).',
                    centered: true,
                  },
                ],
              },
              {
                name: 'Notebook Prep',
                order: 3,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Notebook Preparation',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Draw the <strong>Data Table</strong> in your notebook:',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/freezing-pt/data-table.jpg',
                    imageAlt: 'A 5 row 2 column table. The first row lists the headers of the columns as: Data Table and Trial Data. Starting at row 2 to row 6: 1. Mass of lauric acid, 2. Freezing temperature of pure lauric acid, 3. Mass of benzoic acid, and 4. Freezing temperature of benzoic acid-lauric acid mixture. Column "Trial Data" lists the units of the data to be written in by the student. Row 2 and 4 are in grams. Row 3 and 5 are degrees Celsius.',
                    centered: true,
                  },
                ],
              },
            ],
          },
          {
            name: 'LabQuest',
            order: 2,
            steps: [
              {
                name: 'NOSTEP',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'LabQuest',
                  },
                  {
                    order: 2,
                    type: 'Image',
                    imageFileName: '/images/freezing-pt/labquest.jpg',
                    imageAlt: 'A labquest hand-held device rests upright. Its stylus and a temperature probe are in its forefront. The screen is on and can read the temperature 20.6 degrees Celsius.',
                    centered: true,
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'Connect the Temperature Probe to LabQuest and ensure that it is a tight connection. Select <strong>New</strong> from the File menu.',
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Next, go to the Meter screen and tap <strong>Rate</strong>. Change the data-collection rate to <strong>0.5 sample/second</strong> (interval of 2 seconds/sample) and the data-collection length to <strong>600 seconds</strong>. Data collection will last 10 minutes. Exit by tapping <strong>OK</strong>.',
                  },
                ],
              },
            ],
          },
          {
            name: 'Lauric Acid',
            order: 3,
            steps: [
              {
                name: 'Setup',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Setup to Freeze Lauric Acid',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Collect a large test tube from the lab cart and rest it in a 150 mL beaker. Ensure that the test tube is dry and clean <em>(not one single drop of water)</em>.',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Next, assemble the equipment as below:',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/freezing-pt/equipment-setup.jpg',
                    imageAlt: 'A hot plate rests on a ring stand. A beaker is on the hot plate with a large ring clamp around it. Above it are three utility clamps. The middle utility clamp is holding a large test tube. The top utility lamp is holding the LabQuest temperature probe and is inside test tube, but not touching the test tube side walls. The temperature probe cable is carefully strung to the right and held away from the hot place using the bottom utility clamp. In front of the hot plate is the LaQuest hand-held device with its stylus out. An empty 150 mL beaker lies to the left of the labquest.',
                    centered: true,
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Set a hot plate on the platform of a ring stand so that it is stable. Fasten three utility clamps or test tube clamps (depending on what is available) and a large ring clamp',
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'Fill a 600 mL beaker with approximately 400 mL of water and place it on the hot plate nestled within the large ring clamp.',
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: 'Clamp the temperature probe and carefully string its cable through the bottom utility clamp away from the hot plate to prevent it from getting burned or melted.',
                  },
                  {
                    order: 7,
                    type: 'Text',
                    text: 'Start heating the beaker filled with water. Do not boil the water. Be sure that the temperature probe cable is not touching any hot surface.',
                  },
                  {
                    order: 8,
                    type: 'Text',
                    text: '<em>Be sure that the temperature probe cable is not touching any hot surface.</em>',
                    centered: true,
                  },
                  {
                    order: 9,
                    type: 'Image',
                    imageFileName: '/images/freezing-pt/prevention.jpg',
                    imageAlt: 'A hot plate rests on a ring stand. A beaker is on the hot plate with a large ring clamp around it. Above it is oneutility clamp and is holding the cable of the temperature problem (although the probe is not visible in the image).',
                    centered: true,
                  },
                ],
              },
              {
                name: 'Melting Lauric Acid',
                order: 2,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Melting Lauric Acid',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Place the empty 150 ml beaker with the large test tube on the milligram balance, and tare it. Take the test tube out and put about 2 g of lauric acid into it. Put the test tube back in the beaker and record its mass.',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'Once the hot bath is ready and the water is hot, secure the test tube with lauric acid with the clamp that is on top of the beaker. Lower the test tube into the water bath. Make sure the water level outside the test tube is higher than the lauric acid level inside the test tube. Heat it up until all lauric acid has melted.',
                  },
                  {
                    order: 4,
                    type: 'Images',
                    images: [
                      {
                        imageFileName: '/images/freezing-pt/melting.jpg',
                        imageAlt: 'A close-up view of the top of the hot plate holding a 600 mL beaker with a large ring clamp around it containing approximately 450 mL of water. The water appears to be hot as indicated by the small bubbles on the beaker side walls and bottom. A test tube containing a white solid is in the middle of the beaker, but not touching its bottom.',
                        imageTitle: 'Beginning to melt',
                      },
                      {
                        imageFileName: '/images/freezing-pt/melted.jpg',
                        imageAlt: 'A close-up view of the top of the hot plate holding a 600 mL beaker with a large ring clamp around it containing approximately 450 mL of water. The water appears to be hot as indicated by the small bubbles on the beaker side walls and bottom. A test tube containing a clear liquid is in the middle of the beaker, but not touching its bottom.',
                        imageTitle: 'Melted lauric acid',
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Data Collection',
                order: 3,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Data Collection',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Take out the test tube from the hot bath, still in the clamp, rotate it away from the hot bath. Insert the Temperature Probe into the hot lauric acid. About 30 seconds are required for the probe to warm up to the temperature of its surroundings and give correct temperature readings.',
                  },
                  {
                    order: 3,
                    type: 'Image',
                    imageFileName: '/images/freezing-pt/freezing-pt.jpg',
                    imageAlt: 'A view of the large test tube wih a small amount of clear liquid. The temperature probe is inserted in the test tube and touches the liquid.',
                    centered: true,
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'On the Labquest, press <strong>Play</strong> to begin data collection.',
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'With a very slight up and down motion of the Temperature Probe, continuously stir the lauric acid during the cooling. Hold the top of the probe and not its wire.',
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: 'Which is the best way to stir the liquid as it freezes?',
                  },
                  {
                    order: 7,
                    type: 'Text',
                    text: '<iframe width="560" height="315" src="https://www.youtube.com/embed/iXCrdJYRlqY?&playlist=iXCrdJYRlqY&loop=1" title="Options A and B " frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                  },

                  {
                    order: 8,
                    type: 'Multiple Choice',
                    choices: [
                      {
                      label: 'Option A',
                      tip: 'Quick stirring could prevent a nucleation site (the beginning of freezing) from forming. This is what leads to the supercooling effect.',
                      validChecked: false,
                    },
                      {
                    label: 'Option B',
                    tip: 'The experimental directions say to continuously stir "with a very slight up and down motion." The reason for this is it attempts to minimize supercooling of the pure substance.',
                    validChecked: true,
                      },
                    ],
                  },
                  {
                    order: 9,
                    type: 'Text',
                    text: 'You can stop stirring once signs of freezing have been observed.',
                  },
                  {
                    order: 10,
                    type: 'Text',
                    text: 'Continue collecting data until the temperature has stabilized and starts dropping. All of the lauric acid must be frozen. Data collection will stop after 10 minutes. If the data collection is not complete after 10 minutes, press <strong>Play</strong> again and select <strong>amend to latest</strong>.',
                  },
                  {
                    order: 11,
                    type: 'Text',
                    text: 'Once frozen, place the test tube with the probe inside the frozen sample back into the hot water bath to melt the probe out of the solid lauric acid.',
                  },
                  {
                    order: 12,
                    type: 'Text',
                    text: '<em><strong>Do not attempt to pull the probe out as it could damage the probe.</strong></em>',
                    centered: true,
                  },
                  {
                    order: 13,
                    type: 'Image',
                    imageFileName: '/images/freezing-pt/freezing.jpg',
                    imageAlt: 'A close-up view of the bottom half of the large test tube. Inside is splatterings of white reside on the side walls and the bottom of the test tube is nearly completely white in color. The temperature probe is dipped in the frozen white solid. ',
                    centered: true,
                  },
                ],
              },
              {
                name: 'Graph Analysis',
                order: 4,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Graph Analysis',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'To determine the freezing temperature of pure lauric acid, you need to analyze the portion of the graph with nearly constant temperature. ',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'To do this: <li>Identify a flat portion of the graph.</li><li>Tap and drag your stylus across the flat portion of the graph to select the region.</li><li>Select <strong>Statistics</strong> from the <strong>Analyze</strong> menu.</li><li>Record the mean (average) temperature that represents the freezing temperature of pure lauric acid</li>.',
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Store the data from the first run by tapping the <strong>File Cabinet</strong> icon.',
                  },
                ],
              },
            ],
          },
          {
            name: 'Benzoic Acid-Lauric Acid Mixture',
            order: 4,
            steps: [
              {
                name: 'Melting the Mixture',
                order: 1,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Melting the Benzoic Acid-Lauric Acid Mixture',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Using weighing paper, tare the balance. Add and weigh about 0.25 g of benzoic acid. Record its mass. ',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'Melt the lauric acid again in the hot bath. When lauric acid completely melts, add the benzoic acid. Stir gently with the temperature probe. Make sure both are acids are liquids, if not continue to heat.',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'After the solution is melted take out the test tube from the hot bath while still in the clamp, rotate it away from the hot bath.',
                  },

                ],
              },
              {
                name: 'Data Collection',
                order: 2,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Data Collection',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Start measuring the temperature by clicking <strong>Play</strong> on the Labquest screen as you did for the lauric acid.',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'Holding the top of the Temperature probe, continuously stir the mixture with a very slight up and down motion, until signs of freezing is observed.',
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'Unlike the pure solvent, a mixture does not have a sharply defined freezing point. Instead, the cooling of a mixture results in a gradual linear decrease in temperature during the time period when freezing takes place. Continue recording data until you can observe a definite linear decrease.',
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'Once the mixture is frozen, place the test tube with the probe back in the hot water to melt the solution.',
                  },
                ],
              },
              {
                name: 'Clean-up',
                order: 3,
                contentBlocks: [

                  {
                    order: 1,
                    type: 'Header',
                    text: 'Clean-up',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'Using a test tube holder, pour the test tube contents into the waste bottle.',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'There will likely be residue of the mixture on the inside of the test tube. Use the hairdryer to melt the residue and pour into the waste bottle. If not, rinse the test tube with 1-2 ml of acetone',
                  },
                  {
                    order: 4,
                    type: 'Image',
                    imageFileName: '/images/freezing-pt/clean-up.jpg',
                    imageAlt: 'In the fume hood is a large glass waste bottle. In front is a white hair dryer held by a left hand and on the right is is the large test tube held by a test tube holder. The hair dryer is pointed at the bottom of the tube.',
                    centered: true,
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'For any last remaining bits of residue, rinse the test tube with 1 to 2 mL of acetone.',
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: 'This experiment leads to a bit of splatter at your lab bench. Do remove any splatter prior to continuing with your analysis. Your instructor will take note if this step has been skipped.',
                  },
                  {
                    order: 7,
                    type: 'Magnified Image',
                    imageFileName:
                      '/images/freezing-pt/splatter-1.jpg',
                    imageAlt:
                      'A view of the black lab bench where in the top right is the hot plate with a beaker of water. On the botton left, white residue is visible on the table-top.',
                    imageZoomFileName:
                      '/images/freezing-pt/splatter-2.jpg',
                    centered: true,
                  },
                ],
              },
              {
                name: 'Graph Analysis',
                order: 4,
                contentBlocks: [
                  {
                    order: 1,
                    type: 'Header',
                    text: 'Graph Analysis',
                  },
                  {
                    order: 2,
                    type: 'Text',
                    text: 'To determine the freezing point of the benzoic acid-lauric acid solution, you need to determine the temperature at which the mixture initially started to freeze.',
                  },
                  {
                    order: 3,
                    type: 'Text',
                    text: 'As you move the mouse cursor across the graph, the temperature (y) and time (x) data points are displayed in the <strong>Data</strong> screen. Locate the initial freezing temperature of the solution. Record the freezing point in your data table.',
                  },
                  {
                    order: 4,
                    type: 'Text',
                    text: 'A good way to compare the freezing curves of the pure substance and the mixture is to view both sets of data on one graph. Tap <strong>Run 2</strong> and select <strong>All Runs</strong>. Both temperature runs should now be displayed on the same graph.',
                  },
                  {
                    order: 5,
                    type: 'Text',
                    text: 'Print a graph of temperature vs. time (with two curves displayed). Label each curve by hand as pure lauric acid and benzoic acid-lauric acid mixture.',
                  },
                  {
                    order: 6,
                    type: 'Text',
                    text: 'You are ﬁnished with the experiment guide!',
                    centered: true,       
                  },
                ],
              },
            ],
          },
        ],
      },
  },
    rank: 20
  }

export default freezingPointDepression